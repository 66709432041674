import { Alert, AlertTitle, Grid } from '@mui/material'
import React from 'react'
import CircleIcon from '@mui/icons-material/Circle';

export const IllinoisAlert = () => {
    return (
        <div style={{ marginTop: "10px" }}>
            <Alert variant="outlined" severity="warning" className="alertIllinois">
                <AlertTitle sx={{ color: "#ee7919" }}>Notice</AlertTitle>
                <Grid container>
                    <Grid item xs={12} mb={1}>
                        <CircleIcon sx={{ fontSize: '10px', color: "#014361" }} />&nbsp;<a className="linkIl" href="https://farmers-dashboard.instabrain.io/view-resource?google_storage_id=1170" target="_blank">
                            Notice of Protection Provided - IL
                        </a>
                    </Grid>
                    <Grid item xs={12}>
                        <CircleIcon sx={{ fontSize: '10px', color: "#014361" }} />&nbsp;<a className="linkIl" href="https://farmers-dashboard.instabrain.io/view-resource?google_storage_id=1171" target="_blank">
                            Complaint Notice - IL
                        </a>
                    </Grid>
                </Grid>
            </Alert>
        </div>
    )
}
