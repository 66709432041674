import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  Input,
  InputAdornment,
  Button,
} from "@mui/material";
import { Context } from "../../../context/V3/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import "./harvestDisclosureForm.css";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";

const HarvestDisclosureForm = (props) => {
  const { SetHarvestDisclosureForm } = useContext(Context);
  const {
    harvestDisclosureForm,
    disableAllInputs,
    flagShowProducerDisclosure
  } = useContext(Context).state;

  const CommonContextUpdate = (type, value) => {
    SetHarvestDisclosureForm({
      type: type,
      value: value,
    });
  };

  const ConditionToShowTransferExchangeForm = () => {

  };

  return (
    <Fragment>
      <Box className="transfer_exchange_request_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head">
              <Box>
                <Typography variant="span">
                  {"Harvest® Fixed Index Annuity Disclosure"}
                </Typography>
              </Box>
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>
            <Box className="section_head_root">
              <Box className="section_open_close">
                <Typography variant="span">
                  Please check the appropriate box:
                </Typography>
              </Box>
            </Box>
            <Box className="disclosure_form_radio_button">
              <RadioGroup
                name="question_b"
                className="radio_group_shift_right"
                row={true}
                value={harvestDisclosureForm.flagHarvestClosure}
                onChange={(e) => {
                  CommonContextUpdate("flagHarvestClosure", e.target.value);
                }}
              >
                <FormControlLabel
                  disabled={disableAllInputs === true ? true : false}
                  control={
                    <Radio
                      value={"qualified annuity"}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label="Qualified Annuity"
                />
                <FormControlLabel
                  disabled={disableAllInputs === true ? true : false}
                  control={
                    <Radio
                      value={"non - qualified annuity"}
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                    />
                  }
                  label="Non - Qualified Annuity"
                />
              </RadioGroup>
            </Box>
            <Box className="annuity_disclosure_desc">
              <Typography className="annuity_disclosure_desc_1" variant="span">
                This Disclosure summarizes important points for you to consider before you purchase Farmers Life Insurance Company’s
                Harvest® Fixed Index Annuity (FIA).
              </Typography>
              <Typography className="annuity_disclosure_desc_2" variant="span">
                The Harvest FIA is an individual fixed modified single-premium deferred index-linked annuity. You purchase the annuity with
                a single premium payment, but premiums are accepted during the first contract year to accommodate multiple roll-overs,
                transfers and exchanges. Interest is earned during the accumulation phase and annuity benefit payments are deferred
                until the Maturity Date or upon Annuitization. The Harvest FIA is an index annuity that offers annuity account allocation
                options: (a) a fixed interest account; and (b) one or more indexed interest account(s). You may allocate premium among
                the available accounts.
              </Typography>
              <Typography className="annuity_disclosure_desc_2" variant="span">
                Under current tax law: (a) the principal and earnings are not subject to income taxes until funds are withdrawn or distributed;
                and (b) a 10% IRS early-withdrawal penalty may apply to withdrawals or distributions prior to age 59½. Tax law is subject
                to change. Please consult your financial or tax professional for any exceptions to the early-withdrawal penalty.
              </Typography>
              <Typography className="annuity_disclosure_desc_2" variant="span">
                The main purposes of a deferred annuity are: (a) to save money for retirement; and (b) to receive retirement income for
                life. It is not meant for short-term financial goals.
              </Typography>

            </Box>

            <Box className="previous_next_btn" style={{ margin: "10px 0" }}>
              <Button
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                onClick={() => {
                  if (ConditionToShowTransferExchangeForm() === false) {
                    props.suitabilityAnalysisPage();
                  } else {
                    props.previousPage();
                  }
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Previous
              </Button>
              {flagShowProducerDisclosure &&
                <Button
                  endIcon={<DoubleArrowIcon />}
                  className="btn_next"
                  onClick={() => {
                    props.nextPage();
                  }}
                  style={{
                    background: "#074a6e",
                    color: "white",
                    textTransform: "capitalize",
                    margin: "0 10px",
                  }}
                >
                  Next - Producer Disclosure
                </Button>}
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default HarvestDisclosureForm;
