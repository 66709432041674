import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Input,
  Snackbar,
  Alert,
  InputAdornment,
  Typography,
  Autocomplete,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
  useLayoutEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import Delete from "../../../assets/delete.svg";
import Edit from "../../../assets/edit.svg";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Popup from "./popup";
import "./group.css";
import { Context as userLoaderContext } from "../../../context/userLoaderContext";
import { CreateCategoryGroupAPI, DeleteResourceGroupCaetgoryAPI, GetCategoryGroupDataAPI, GetResourceCategoryListAPI, UpdateCategoryGroupNameAPI } from "../../../api/resourceConfig";

const Group = (props) => {
  //   const { state } = useContext(userLoaderContext);
  const { SetFlagLoader } = useContext(userLoaderContext);

  const [groupList, setGroupList] = useState([]);
  const [groupName, setGroupName] = useState();
  const [categoryId, setCategoryId] = useState();
  const navigate = useNavigate();
  const [flagAddPopup, setFlagAddPopup] = useState(false);
  const [flagEditPopup, setFlagEditPopup] = useState({
    flag: false,
    categoryId: "",
    categoryName: "",
    groupName: "",
    groupCategoryId: "",
  });
  const [flagDeletePopup, setFlagDeletePopup] = useState({
    flag: false,
    groupCategoryId: "",
    categoryId: ""
  });
  const [getCategoryData, setGetCategoryData] = useState();
  const [ShowMsg, setShowMsg] = useState({
    status: false,
    error: false,
    msg: "",
  });
  const [editGroupCategory, setEditGroupCategory] = useState();


  useEffect(() => {
    SetFlagLoader(true);
  }, []);
  useEffect(() => {
    GetCategoryGroupDataAPI("",
      (res) => {
        if (res.data.status === true) {
          SetFlagLoader(false);
          setGroupList(res.data.data);
        } else {
          SetFlagLoader(false);
        }
      },
      (err) => {
        SetFlagLoader(false);
        console.log("err", err);
      }
    );

    GetResourceCategoryListAPI(
      (res) => {
        if (res.data.status === true) {
          SetFlagLoader(false);
          setGetCategoryData(res.data.data);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }, []);

  // useEffect(() => {
  //   if (flagAddPopup == true || flagEditPopup.flag == true) {
  //     GetResourceCategoryListAPI(
  //       (res) => {
  //         if (res.data.status === true) {
  //           SetFlagLoader(false);
  //           setGetCategoryData(res.data.data);
  //         }
  //       },
  //       (err) => {
  //         console.log("err", err);
  //       }
  //     );
  //   }
  // }, [flagAddPopup, flagEditPopup.flag]);

  const openEditHandle = () => {
    return (
      <>
        <DialogContent className="edit_pdf_popup2">
          <>
            <h4
              style={{
                color: "#D9A22E",
                margin: "20px auto 40px auto",
                // minWidth: "550px",
                minWidth: "400px",
              }}
            >
              Edit Group
            </h4>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ShowMsg.error && (
                <Alert className="errCategory" severity="error">
                  {ShowMsg.msg}
                </Alert>
              )}
              {ShowMsg.status && (
                <Alert className="msgCategory" severity="success">
                  {ShowMsg.msg}
                </Alert>
              )}
            </Box>
            {/* <TextField
              disabled
              label="Category"
              type="text"
              defaultValue={flagEditPopup.categoryName}
              variant="standard"
              sx={{ width: "70%", marginBottom: "10px" }}
            /> */}

            <Autocomplete
              id="disable-clearable"
              className="autocomplete_group"
              options={getCategoryData}
              getOptionLabel={(options) =>
                options?.pdf_category_name !== undefined
                  ? options?.pdf_category_name
                  : ""
              }
              value={editGroupCategory}
              defaultValue={editGroupCategory}
              sx={{ marginBottom: "7px" }}
              onChange={(e, newValue) => {
                setEditGroupCategory(newValue)
                setCategoryId(newValue.pdf_category_id);
                setShowMsg({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Category" variant="standard" />
              )}
            />

            <TextField
              label="Group"
              type="text"
              defaultValue={flagEditPopup.groupName}
              variant="standard"
              sx={{ width: "70%", margintop: "10px !important" }}
              onChange={(e) => {
                setFlagEditPopup(old => {
                  let temp = { ...old }
                  temp.groupName = e.target.value
                  return temp
                })
                return setShowMsg({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (flagEditPopup.categoryName === undefined || flagEditPopup.categoryName === "") {
                return setShowMsg({
                  status: false,
                  error: true,
                  msg: "Please fill Group name feild.",
                });
              } else {
                SetFlagLoader(true);
                UpdateCategoryGroupNameAPI(
                  {
                    pdf_group_category_name: flagEditPopup.groupName,
                    pdf_group_category_id: flagEditPopup.groupCategoryId,
                    pdf_category_id: editGroupCategory?.pdf_category_id
                  },
                  (res) => {
                    if (res?.data?.status) {
                      setGroupList(old => {
                        let tempList = [...old]
                        tempList?.map((obj) => {
                          if (obj.pdf_group_category_id == flagEditPopup.groupCategoryId) {
                            obj.pdf_category_id = editGroupCategory.pdf_category_id
                            obj.pdf_group_category_name = flagEditPopup.groupName
                            obj.pdf_category_name = editGroupCategory.pdf_category_name
                          }
                        })
                        return tempList
                      })
                      setFlagEditPopup({
                        flag: false,
                        categoryName: "",
                        groupName: "",
                        groupCategoryId: "",
                      })
                      setCategoryId("");
                      setEditGroupCategory("");
                    }
                    SetFlagLoader(false);
                  },
                  (err) => {
                    SetFlagLoader(false);
                  }
                );
              }
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setFlagEditPopup({
                flag: false,
                categoryName: "",
                groupName: "",
                groupCategoryId: "",
              })
              setEditGroupCategory("");
              setCategoryId("");
            }}
          >
            No
          </Button>
        </DialogActions>
      </>
    );
  };

  const openDeleteHandle = () => {
    return (
      <>
        <DialogContent className="edit_pdf_popup2">
          <>
            <Grid item xs={12}>
              <h4
                style={{
                  color: "#D9A22E"
                }}
              >
                Delete Category
              </h4>
            </Grid>
            <Grid item xs={12}>
              <WarningRoundedIcon sx={{ fontSize: "80px", color: "#d9a22e" }} />
            </Grid>
            <Grid item xs={12}>
              <span>Are you sure you want to delete it?</span>
            </Grid>
            <Grid item xs={12}>
              <span className="hint_span">
                <b>Hint: </b>
                if you click yes than it only delete group name
              </span>
              <span className="span_all_data">
                if you click yes With all data than it delete all the data
                inside the group name
              </span>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              DeleteResourceGroupCaetgoryAPI(
                {
                  pdf_group_category_id: flagDeletePopup.groupCategoryId,
                  flag_delete_all: false
                },
                (res) => {
                  if (res?.data?.status) {
                    setGroupList(old => {
                      let tempList = [...old]
                      tempList = tempList.filter(obj => obj.pdf_group_category_id != flagDeletePopup.groupCategoryId)
                      return tempList
                    });
                    setFlagDeletePopup({
                      flag: false,
                      groupCategoryId: "",
                      categoryId: ""
                    })
                  }
                },
                (err) => {
                  console.log("11", err);
                }
              );
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              DeleteResourceGroupCaetgoryAPI(
                {
                  pdf_group_category_id: flagDeletePopup.groupCategoryId,
                  flag_delete_all: true
                },
                (res) => {
                  if (res?.data?.status) {
                    setGroupList(old => {
                      let tempList = [...old]
                      tempList = tempList.filter(obj => obj.pdf_category_id != flagDeletePopup.categoryId)
                      return tempList
                    });
                    setFlagDeletePopup({
                      flag: false,
                      groupCategoryId: "",
                      categoryId: ""
                    })
                  }
                },
                (err) => {
                  console.log("11", err);
                }
              );
            }}
          >
            Yes with all data inside
          </Button>
          <Button
            onClick={() => {
              setFlagDeletePopup({
                flag: false,
                groupCategoryId: "",
                categoryId: ""
              })
            }}
          >
            No
          </Button>
        </DialogActions>
      </>
    );
  };

  const AddGroupHandle = () => {
    return (
      <>
        <DialogContent className="edit_pdf_popup2_group">
          <>
            <h4
              style={{
                color: "#D9A22E",
                margin: "20px auto 40px auto",
                minWidth: "400px",
              }}
            >
              Add Group
            </h4>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ShowMsg.error && (
                <Alert className="errCategory" severity="error">
                  {ShowMsg.msg}
                </Alert>
              )}
              {ShowMsg.status && (
                <Alert className="msgCategory" severity="success">
                  {ShowMsg.msg}
                </Alert>
              )}
            </Box>

            <Autocomplete
              id="disable-clearable"
              className="autocomplete_group"
              options={getCategoryData}
              getOptionLabel={(options) =>
                options?.pdf_category_name !== undefined
                  ? options?.pdf_category_name
                  : ""
              }
              sx={{ marginBottom: "7px" }}
              onChange={(e, newValue) => {
                setCategoryId(newValue.pdf_category_id);
                setShowMsg({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
              renderInput={(params) => (
                <TextField {...params} label="Category" variant="standard" />
              )}
            />

            <TextField
              label="Group Name"
              type="text"
              variant="standard"
              sx={{ width: "70%" }}
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (categoryId === "" || groupName === "" || categoryId === undefined || groupName === undefined) {
                return setShowMsg({
                  status: false,
                  error: true,
                  msg: "Please fill all the fields.",
                });
              }
              CreateCategoryGroupAPI(
                {
                  pdf_category_id: categoryId,
                  pdf_group_category_name: groupName
                },
                (res) => {
                  if (res?.data?.status) {
                    setGroupList(old => {
                      let temp = [...old]
                      temp.push(res.data.data)
                      return temp
                    });
                    setCategoryId("");
                    setGroupName("");
                    setShowMsg({
                      status: false,
                      error: false,
                      msg: "",
                    });
                    setFlagAddPopup(false)
                  } else {
                    setFlagAddPopup(false)
                  }
                },
                (err) => {
                  setShowMsg({
                    status: false,
                    error: false,
                    msg: "",
                  });
                }
              );
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setFlagAddPopup(false)
              setShowMsg({
                status: false,
                error: false,
                msg: "",
              });
            }}
          >
            No
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      {flagEditPopup && <Popup open={flagEditPopup.flag} data={openEditHandle()} />}
      {flagDeletePopup.flag && <Popup open={flagDeletePopup.flag} data={openDeleteHandle()} />}
      {flagAddPopup && <Popup open={flagAddPopup} data={AddGroupHandle()} />}
      <Box className="page_title_annuity_application page_title">
        <Typography variant="span">Group</Typography>
      </Box>
      <Box className="main_container_history">
        {/* <Box className="first_div">Category</Box> */}
        <Box className="btn-search-div" sx={{ marginTop: "20px" }}>
          <Box className="btn_group">
            <Button
              onClick={() => {
                navigate("/pdf-config");
              }}
              className="btn1_group"
              variant="contained"
            >
              Back
            </Button>
            <Button
              onClick={() => {
                setFlagAddPopup(true);
              }}
              className="btn2_group"
              variant="contained"
            >
              New Group
            </Button>
          </Box>
        </Box>

        <Box className="Grid_box_group">
          <Grid
            xs={12}
            sm={12}
            md={12}
            container
            direction="column"
            justifyContent="space-evenly"
            className="Grid_main_group"
          >
            <Grid item className="item1 grid_item head box_height" xs={12}>
              <Grid
                container
                justifyContent="space-around"
                xs={12}
                sx={{ height: "30px" }}
              >
                <Grid item xs={3} sm={3}>
                  Group Name
                </Grid>
                <Grid item xs={3} sm={3}>
                  Category Name
                </Grid>
                <Grid item xs={3} sm={3}>
                  Created by
                </Grid>
                <Grid item xs={3} sm={3}></Grid>
              </Grid>
            </Grid>

            {groupList.map((groupRow, index) => {
              return (
                <Grid
                  item
                  className="item1 grid_item  box_height"
                  xs={12}
                  sm={10}
                >
                  <Grid container justifyContent="space-around" xs={12}>
                    <Grid item xs={3} sm={3}>
                      {groupRow.pdf_group_category_name}
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      {groupRow.pdf_category_name}
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      {groupRow.pdf_group_category_created_by_user_name}
                    </Grid>
                    <Grid item xs={3} sm={3}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={Edit}
                          onClick={() => {
                            setEditGroupCategory(groupRow)
                            setFlagEditPopup({
                              flag: true,
                              categoryName: groupRow.pdf_category_name,
                              groupName: groupRow.pdf_group_category_name,
                              groupCategoryId: groupRow.pdf_group_category_id,
                            });
                          }}
                          width="20px"
                          height="20px"
                          style={{
                            marginRight: "7px",
                            cursor: "pointer",
                          }}
                        />

                        <img
                          src={Delete}
                          width="20px"
                          height="20px"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setFlagDeletePopup({
                              flag: true,
                              groupCategoryId: groupRow.pdf_group_category_id,
                              categoryId: groupRow.pdf_category_id
                            });
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Group;