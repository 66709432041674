import React, {
    useContext,
    useEffect,
    useRef,
    useState,
    Fragment,
} from "react";
import {
    Typography,
    Box,
    Divider,
    TextField,
    Select,
    MenuItem,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    FormControl,
    InputLabel,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Radio,
    RadioGroup,
    Input,
    InputAdornment,
    Grid,
    CircularProgress,
    IconButton,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import "./producerDisclosureForm.css";
import { Context } from "../../../context/V3/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Link } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context as UserLoaderContext } from "../../../context/userLoaderContext";
import { Context as ErrorValidationContext } from "../../../context/V3/errorValidationContext";
import ClearIcon from '@mui/icons-material/Clear';
import MuiPhoneNumber from "material-ui-phone-number";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
const ProducerDisclosureForm = (props) => {
    const {
        SetProducerDisclosureForm,
        SetWholeAnnuityDisclosureForm,
        updateTransferOrExchangeRequestFlag,
        SetWholeIndividualAnnuityApplication
    } = useContext(Context);
    const { state } = useContext(Context);
    console.log("state", state.producerDisclosureForm)
    const {
        producerDisclosureForm,
        disableAllInputs,
        annuityDisclosureForm,
    } = useContext(Context).state;

    const [flagJointOwnerRequired, setFlagJointOwnerRequired] = useState(false);
    // const [flagClearAllBtn, setFlagClearAllBtn] = useState({
    //   annuitant:false,
    //   owner:false,
    //   jointOwner:false,
    // });

    console.log("disableAllInputs", disableAllInputs);
    const { SetApplicationFormFlagSectionData } = useContext(
        ErrorValidationContext
    );

    const {
        flagSixthPageFirstSection,
        flagSixthPageSecondSection,
        flagSixthPageThirdSection,
        flagSixthPageFourthSection,
        flagSixthPageFifthSection
    } = useContext(ErrorValidationContext).state;

    const [isOwnerDetailReq, setIsOwnerDetailReq] = useState(false);

    const [producerValidationError, setProducerValidationError] = useState({
        flagProducerWebsiteValid: false,
        flagProducerZipValid: false,
        flagProducerPhoneValid: false,
        flagProducerEmailValid: false,
        flagProducerNPNValid: false
    });
    const [ownerValidationError, setOwnerValidationError] = useState({
        flagOwnerZipValid: false,
        flagOwnerPhoneValid: false,
        flagOwnerEmailValid: false,
    });

    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("Printing disable all inputs value", disableAllInputs);
    }, []);

    const StatesName = [
        "Alabama",
        "Alaska",
        // "American Samoa",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "District Of Columbia",
        // "Federated States Of Micronesia",
        "Florida",
        "Georgia",
        // "Guam",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        // "Marshall Islands",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        // "Northern Mariana Islands",
        "Ohio",
        "Oklahoma",
        "Oregon",
        // "Palau",
        "Pennsylvania",
        // "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        // "Virgin Islands",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
    ];

    const CommonContextUpdate = (type, value) => {
        SetProducerDisclosureForm({
            type: type,
            value: value,
        });
    };

    const ClearAnnuitantDetails = () => {
        let annuitantFields = [
            "annuitantFullName",
            "annuitantSSN",
            "annuitantGender",
            "annuitantDOB",
            "annuitantStreetAddress",
            "annuitantZip",
            "annuitantCity",
            "annuitantState",
            "annuitantPhone",
            "annuitantEmail",
        ]

        for (let i = 0; i < annuitantFields.length; i++) {
            if (annuitantFields[i] == "annuitantDOB") {
                SetProducerDisclosureForm({
                    type: annuitantFields[i],
                    value: null,
                });
            } else {
                SetProducerDisclosureForm({
                    type: annuitantFields[i],
                    value: "",
                });
            }
        }

        setProducerValidationError({
            flagProducerWebsiteValid: false,
            flagProducerZipValid: false,
            flagProducerPhoneValid: false,
            flagProducerEmailValid: false,
            flagProducerNPNValid: false
        });
    }

    const ClearOwnerDetails = () => {
        let ownerFields = [
            "ownerFullName",
            "ownerSSN",
            "ownerGender",
            "ownerDOB",
            "ownerStreetAddress",
            "ownerZip",
            "ownerCity",
            "ownerState",
            "ownerEmail",
            "ownerRelationToAnnuitant",
            "ownerPhone"
        ]

        for (let i = 0; i < ownerFields.length; i++) {
            if (ownerFields[i] == "ownerDOB") {
                SetProducerDisclosureForm({
                    type: ownerFields[i],
                    value: null,
                });
            } else {
                SetProducerDisclosureForm({
                    type: ownerFields[i],
                    value: "",
                });
            }
        }
        setOwnerValidationError({
            flagOwnerZipValid: false,
            flagOwnerEmailValid: false,
        });
    }

    function validatePhoneNumberLatest(input_str) {
        return input_str?.replace(/\D/g, '').length >= 11
    }

    return (
        <>
            <Box className="application_page_root">
                <Box className="application_form">
                    <Box className="application_form_card">
                        <Box className="application_form_head ">
                            <Box className="forsitemap"></Box>
                            <Box className="application_form_head_1">
                                <Typography variant="span">
                                    Insurance Agent (Producer) Disclosure for Annuities
                                </Typography>
                            </Box>
                            <Box className="application_form_head_bottom_line">
                                <Divider />
                            </Box>
                        </Box>

                        <Box
                            className="annuitant_head_root header_1"
                            onClick={() => {
                                // setFlagFirstSection(!flagSixthPageFirstSection)
                                SetApplicationFormFlagSectionData({
                                    type: "Producer Disclosure",
                                    section: "flagSixthPageFirstSection",
                                    data: !flagSixthPageFirstSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    1. INSURANCE AGENT (PRODUCER) INFORMATION (“Me”, “I”, “My”)
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagSixthPageFirstSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagSixthPageFirstSection === true ? (
                            <Box className="annuitant_input_fields">
                                <Box className="annuitant_fullname_and_ssn_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            id="standard-basic"
                                            label="First name"
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "producerFirstName",
                                                    e.target.value
                                                );
                                            }}
                                            disabled={disableAllInputs === true ? true : false}
                                            value={producerDisclosureForm.producerFirstName}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            label="Last name"
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "producerLastName",
                                                    e.target.value
                                                );
                                            }}
                                            value={producerDisclosureForm.producerLastName}
                                            disabled={disableAllInputs === true ? true : false}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_fullname_and_ssn_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            id="standard-basic"
                                            label="Business name"
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "producerBusinessName",
                                                    e.target.value
                                                );
                                            }}
                                            disabled={disableAllInputs === true ? true : false}
                                            value={producerDisclosureForm.producerBusinessName}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width not_required"
                                            error={producerValidationError.flagProducerWebsiteValid ? true : false}
                                            helperText={producerValidationError.flagProducerWebsiteValid ? "Invaild Website" : ""}
                                            label="Website"
                                            disabled={disableAllInputs === true ? true : false}
                                            // type="email"
                                            variant="standard"
                                            onChange={(e) => {
                                                let temp = { ...producerValidationError }
                                                if (e.target.value?.length) {
                                                    temp.flagProducerWebsiteValid = !/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/.test(e.target.value)
                                                    setProducerValidationError(temp)
                                                } else {
                                                    temp.flagProducerWebsiteValid = false
                                                    setProducerValidationError(temp)
                                                }
                                                CommonContextUpdate("producerWebsite", e.target.value);
                                            }}
                                            onBlur={() => {
                                                let temp = { ...producerValidationError }
                                                if (producerDisclosureForm.producerWebsite?.length) {
                                                    temp.flagProducerWebsiteValid = !/^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/.test(producerDisclosureForm.producerWebsite)
                                                    setProducerValidationError(temp)
                                                } else {
                                                    temp.flagProducerWebsiteValid = false
                                                    setProducerValidationError(temp)
                                                }
                                            }}
                                            value={producerDisclosureForm.producerWebsite}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_streetaddress_and_zip_row ">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            label="Business mailing address"
                                            disabled={disableAllInputs === true ? true : false}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("producerBusinessMailingAddress", e.target.value);
                                            }}

                                            value={producerDisclosureForm.producerBusinessMailingAddress}

                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            label="City"
                                            disabled={disableAllInputs === true ? true : false}
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("producerCity", e.target.value);
                                            }}
                                            value={producerDisclosureForm.producerCity}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_city_and_state_row">
                                    <Box className="left_section">
                                        <FormControl
                                            className="input_field_width"
                                            variant="standard"
                                            style={{ width: "50%" }}
                                        >
                                            <InputLabel id="demo-simple-select-label" disabled={disableAllInputs === true ? true : false}>
                                                State
                                            </InputLabel>
                                            <Select
                                                className="state_dropdown"
                                                onChange={(e) => {
                                                    CommonContextUpdate("producerState", e.target.value);
                                                }}
                                                value={producerDisclosureForm.producerState}
                                                disabled={disableAllInputs === true ? true : false}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {StatesName.map((stateName) => {
                                                    return (
                                                        <MenuItem value={stateName}>{stateName}</MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            error={producerValidationError?.flagProducerZipValid ? true : false}
                                            helperText={producerValidationError?.flagProducerZipValid ? "ZIP number only contains 5 digits" : ""}
                                            onBlur={() => {
                                                let temp = { ...producerValidationError }
                                                if (producerDisclosureForm.producerZip?.length) {
                                                    temp.flagProducerZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(producerDisclosureForm.producerZip))
                                                    setProducerValidationError(temp)
                                                } else {
                                                    temp.flagProducerZipValid = false
                                                    setProducerValidationError(temp)
                                                }
                                            }}
                                            label="ZIP"
                                            type="number"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate("producerZip", e.target.value);
                                                if (e.target.value?.length == 5) {
                                                    let temp = { ...producerValidationError }
                                                    temp.flagProducerZipValid = false
                                                    setProducerValidationError(temp)
                                                }
                                                if (e.target.value?.length > 5 || e.target.value?.length < 5) {
                                                    let temp = { ...producerValidationError }
                                                    temp.flagProducerZipValid = true
                                                    setProducerValidationError(temp)
                                                }
                                            }}
                                            disabled={disableAllInputs === true ? true : false}
                                            value={producerDisclosureForm.producerZip}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_phone_and_email_row">
                                    <Box className="left_section">
                                        <MuiPhoneNumber
                                            sx={{
                                                pointerEvents: disableAllInputs === true ? "none" : "auto",
                                                '& .MuiPhoneNumber-flagButton': {
                                                    pointerEvents: "none"
                                                }
                                            }}
                                            name="phone"
                                            label="Business telephone number"
                                            data-cy="user-phone"
                                            defaultCountry={"us"}
                                            onlyCountries={["us"]}
                                            autoFormat={true}
                                            countryCodeEditable={false}
                                            value={producerDisclosureForm.producerTelephone}
                                            className="input_field_width"
                                            onChange={(value) => {
                                                let temp = { ...producerValidationError }
                                                temp.flagProducerPhoneValid = !validatePhoneNumberLatest(value)
                                                setProducerValidationError(temp)
                                                CommonContextUpdate("producerTelephone", value)
                                            }}
                                            error={producerValidationError.flagProducerPhoneValid ? true : false}
                                            helperText={producerValidationError.flagProducerPhoneValid ? "Invaild Phone" : ""}
                                            disabled={disableAllInputs === true ? true : false}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            error={producerValidationError.flagProducerEmailValid ? true : false}
                                            helperText={producerValidationError.flagProducerEmailValid ? "Invaild Email" : ""}
                                            label="Email"
                                            disabled={disableAllInputs === true ? true : false}
                                            type="email"
                                            variant="standard"
                                            onChange={(e) => {
                                                let temp = { ...producerValidationError }
                                                if (e.target.value?.length) {
                                                    temp.flagProducerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))
                                                    setProducerValidationError(temp)
                                                } else {
                                                    temp.flagProducerEmailValid = false
                                                    setProducerValidationError(temp)
                                                }
                                                CommonContextUpdate("producerEmail", e.target.value);
                                            }}
                                            onBlur={() => {
                                                let temp = { ...producerValidationError }
                                                if (producerDisclosureForm.producerEmail?.length) {
                                                    temp.flagProducerEmailValid = !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(producerDisclosureForm.producerEmail))
                                                    setProducerValidationError(temp)
                                                } else {
                                                    temp.flagProducerEmailValid = false
                                                    setProducerValidationError(temp)
                                                }
                                            }}
                                            value={producerDisclosureForm.producerEmail}
                                        />
                                    </Box>
                                </Box>
                                <Box className="annuitant_city_and_state_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            error={producerValidationError?.flagProducerNPNValid ? true : false}
                                            helperText={producerValidationError?.flagProducerNPNValid ? "Invalid national producer number" : ""}
                                            label="National producer number"
                                            type="number"
                                            variant="standard"
                                            onChange={(e) => {
                                                let temp = { ...producerValidationError }
                                                if (e.target.value?.length) {
                                                    temp.flagProducerNPNValid = !/^\d{1,10}$/.test(e.target.value)
                                                    setProducerValidationError(temp)
                                                } else {
                                                    temp.flagProducerNPNValid = false
                                                    setProducerValidationError(temp)
                                                }
                                                CommonContextUpdate("producerNationalNumber", e.target.value);
                                            }}
                                            onBlur={() => {
                                                let temp = { ...producerValidationError }
                                                if (producerDisclosureForm.producerNationalNumber?.length) {
                                                    temp.flagProducerNPNValid = !/^\d{1,10}$/.test(producerDisclosureForm.producerNationalNumber)
                                                    setProducerValidationError(temp)
                                                } else {
                                                    temp.flagProducerNPNValid = false
                                                    setProducerValidationError(temp)
                                                }
                                            }}
                                            value={producerDisclosureForm.producerNationalNumber}
                                            disabled={disableAllInputs === true ? true : false}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            id="standard-basic"
                                            label="In the state of"
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "producerInStateOf",
                                                    e.target.value
                                                );
                                            }}
                                            value={producerDisclosureForm.producerInStateOf}
                                            disabled={disableAllInputs === true ? true : false}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}
                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Producer Disclosure",
                                    section: "flagSixthPageSecondSection",
                                    data: !flagSixthPageSecondSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    2. CUSTOMER INFORMATION (“You”, “Your”)
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagSixthPageSecondSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagSixthPageSecondSection === true ? (
                            <Box className="annuitant_owner_section">
                                <Box className="annuitant_fullname_and_ssn_row">
                                    <Box className="left_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            disabled={disableAllInputs === true ? true : false}
                                            id="standard-basic"
                                            label="First name"
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "producerCustomerFirstName",
                                                    e.target.value
                                                );
                                            }}
                                            value={producerDisclosureForm.producerCustomerFirstName}
                                        />
                                    </Box>
                                    <Box className="right_section">
                                        <TextField
                                            className="input_field_width req_fields"
                                            label="Last name"
                                            type="text"
                                            variant="standard"
                                            onChange={(e) => {
                                                CommonContextUpdate(
                                                    "producerCustomerLastName",
                                                    e.target.value
                                                );
                                            }}
                                            disabled={disableAllInputs === true ? true : false}
                                            value={producerDisclosureForm.producerCustomerLastName}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}
                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Producer Disclosure",
                                    section: "flagSixthPageThirdSection",
                                    data: !flagSixthPageThirdSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    3. What Types of Products Can I Sell You?
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagSixthPageThirdSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagSixthPageThirdSection === true ? (
                            <Box className="annuitant_owner_section">
                                <Box className="owner_replacement_question_main">
                                    <Typography variant="span" style={{ margin: "20px 0" }}>
                                        I am licensed to sell annuities to you in accordance with state law. If I recommend that You buy an annuity, it means I believe that it effectively meets Your financial situation, insurance needs, and financial objectives. Other financial products, such as life insurance or stocks, bonds and mutual funds, also may meet Your needs.
                                    </Typography>
                                    <Box
                                        className="owner_replacement_first_question"
                                        style={{ marginTop: "20px" }}
                                    >
                                        <Typography variant="span">
                                            1. I offer the following products:
                                            <span style={{ color: "red" }} color="red">
                                                *
                                            </span>
                                        </Typography>
                                        <FormGroup row={true} className="radio_group_shift_right">
                                            <FormControlLabel
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Fixed or fixed indexed annuities"}
                                                control={
                                                    <Checkbox
                                                        value={"fixed annuities"}
                                                        checked={
                                                            producerDisclosureForm.producerOfferedProducts.indexOf(
                                                                "fixed annuities"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerOfferedProducts;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerOfferedProducts", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Variable annuities"}
                                                control={
                                                    <Checkbox
                                                        value={"variable annuities"}
                                                        checked={
                                                            producerDisclosureForm.producerOfferedProducts.indexOf(
                                                                "variable annuities"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerOfferedProducts;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerOfferedProducts", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Life insurance"}
                                                control={
                                                    <Checkbox
                                                        value={"life insurance"}
                                                        checked={
                                                            producerDisclosureForm.producerOfferedProducts.indexOf(
                                                                "life insurance"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerOfferedProducts;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                                CommonContextUpdate(
                                                                    "producerOfferedProducts",
                                                                    ""
                                                                );
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerOfferedProducts", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>
                                <Box className="owner_replacement_question_main">
                                    <Box
                                        className="owner_replacement_first_question"
                                        style={{ marginTop: "20px" }}
                                    >
                                        <Typography variant="span">
                                            2. I need a separate license to provide advice about or to sell non-insurance financial products. I have checked below any non-insurance financial products that I am licensed and authorized to provide advice about or to sell.
                                            <span style={{ color: "red" }} color="red">
                                                *
                                            </span>
                                        </Typography>
                                        <FormGroup row={true} className="radio_group_shift_right">
                                            <FormControlLabel
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Mutual funds"}
                                                control={
                                                    <Checkbox
                                                        value={"mutual funds"}
                                                        checked={
                                                            producerDisclosureForm.producerNonInsuranceProducts.indexOf(
                                                                "mutual funds"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerNonInsuranceProducts;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerNonInsuranceProducts", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Stocks/bonds"}
                                                control={
                                                    <Checkbox
                                                        value={"stocks"}
                                                        checked={
                                                            producerDisclosureForm.producerNonInsuranceProducts.indexOf(
                                                                "stocks"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerNonInsuranceProducts;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerNonInsuranceProducts", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Certificates of deposits"}
                                                control={
                                                    <Checkbox
                                                        value={"deposit certificate"}
                                                        checked={
                                                            producerDisclosureForm.producerNonInsuranceProducts.indexOf(
                                                                "deposit certificate"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerNonInsuranceProducts;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                                CommonContextUpdate(
                                                                    "producerNonInsuranceProducts",
                                                                    ""
                                                                );
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerNonInsuranceProducts", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>
                            </Box>
                        ) : null}
                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Producer Disclosure",
                                    section: "flagSixthPageFourthSection",
                                    data: !flagSixthPageFourthSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span">
                                    4. Whose Annuities Can I Sell to You?
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagSixthPageFourthSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagSixthPageFourthSection === true ? (
                            <Box className="annuitant_owner_section">
                                <Box sx={{ width: "100%" }} className="owner_replacement_question_main">
                                    <Box
                                        className="owner_replacement_first_question"
                                        style={{ marginTop: "20px" }}
                                    >
                                        <Typography variant="span">
                                            1. I am authorized to sell:
                                            <span style={{ color: "red" }} color="red">
                                                *
                                            </span>
                                        </Typography>
                                        <FormGroup row={false} className="radio_group_shift_right">
                                            <FormControlLabel
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Annuities from only one (1) insurer"}
                                                control={
                                                    <Checkbox
                                                        value={"1 insurer"}
                                                        checked={

                                                            producerDisclosureForm.producerAuthorizedToSell.indexOf(
                                                                "1 insurer"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerAuthorizedToSell;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerAuthorizedToSell", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Annuities from two (2) or more insurers"}
                                                control={
                                                    <Checkbox
                                                        value={"2 insurer"}
                                                        checked={
                                                            producerDisclosureForm.producerAuthorizedToSell.indexOf(
                                                                "2 insurer"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerAuthorizedToSell;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerAuthorizedToSell", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Annuities from two or more insurers, although I primarily sell annuities from:"}
                                                control={
                                                    <Checkbox
                                                        value={"more insurer"}
                                                        checked={
                                                            producerDisclosureForm.producerAuthorizedToSell.indexOf(
                                                                "more insurer"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerAuthorizedToSell;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                                CommonContextUpdate(
                                                                    "producerAuthorizedToSellDescription",
                                                                    ""
                                                                );
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerAuthorizedToSell", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                            <TextField
                                                variant="standard"
                                                type="text"
                                                disabled={
                                                    (producerDisclosureForm.producerAuthorizedToSell.indexOf("more insurer") < 0 || disableAllInputs === true)
                                                        ? true
                                                        : false
                                                }
                                                value={producerDisclosureForm.producerAuthorizedToSellDescription}
                                                onChange={(e) => {
                                                    CommonContextUpdate(
                                                        "producerAuthorizedToSellDescription",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                    </Box>
                                </Box>

                            </Box>
                        ) : null}
                        <Box
                            className="annuitant_head_root"
                            onClick={() => {
                                SetApplicationFormFlagSectionData({
                                    type: "Producer Disclosure",
                                    section: "flagSixthPageFifthSection",
                                    data: !flagSixthPageFifthSection,
                                });
                            }}
                        >
                            <Box className="section_open_close">
                                <Typography variant="span" style={{ margin: "20px 0" }}>
                                    5. How I’m Paid for My Work
                                </Typography>
                            </Box>
                            <Box className="section_open_close">
                                {flagSixthPageFifthSection === true ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon />
                                )}
                            </Box>
                        </Box>
                        {flagSixthPageFifthSection === true ? (
                            <Box className="annuitant_owner_section">
                                <Box sx={{ width: "100%" }} className="owner_replacement_question_main">
                                    <Box
                                        className="owner_replacement_first_question"
                                        style={{ marginTop: "20px" }}
                                    >
                                        <Typography variant="span" style={{ margin: "20px 0" }}>
                                            It’s important for You to understand how I’m paid for my work. Depending on the particular annuity You purchase, I may be paid a commission or a fee. Commissions are generally paid to Me by the insurance company, while fees are generally paid to Me by the consumer. If You have questions about how I’m paid, please ask Me.
                                        </Typography>
                                        <Typography variant="span">
                                            1. I am authorized to sell:
                                            <span style={{ color: "red" }} color="red">
                                                *
                                            </span>
                                        </Typography>
                                        <FormGroup row={false} className="radio_group_shift_right">
                                            <FormControlLabel
                                                sx={{ alignItems: "start", padding: "5px 0px" }}
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Commission, which is usually paid by the insurance company or other sources. If other sources, describe:"}
                                                control={
                                                    <Checkbox
                                                        value={"commission"}
                                                        checked={
                                                            producerDisclosureForm.producerPaidForWork.indexOf(
                                                                "commission"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerPaidForWork;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                                CommonContextUpdate("producerPaidForWorkCommissionDescription", "");
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerPaidForWork", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                            <TextField
                                                variant="standard"
                                                type="text"
                                                disabled={
                                                    (disableAllInputs === true || producerDisclosureForm.producerPaidForWork.indexOf("commission") < 0)
                                                        ? true
                                                        : false
                                                }
                                                value={producerDisclosureForm.producerPaidForWorkCommissionDescription}
                                                onChange={(e) => {
                                                    CommonContextUpdate(
                                                        "producerPaidForWorkCommissionDescription",
                                                        e.target.value
                                                    );
                                                }}
                                                sx={{ marginBottom: "7px" }}
                                            />
                                            <FormControlLabel
                                                sx={{ alignItems: "start", padding: "5px 0px" }}
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Fees (such as a fixed amount, an hourly rate, or a percentage of your payment), which are usually paid directly by the customer."}
                                                control={
                                                    <Checkbox
                                                        value={"fees"}
                                                        checked={
                                                            producerDisclosureForm.producerPaidForWork.indexOf(
                                                                "fees"
                                                            ) < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerPaidForWork;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerPaidForWork", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                            <FormControlLabel
                                                disabled={disableAllInputs === true ? true : false}
                                                label={"Other"}
                                                control={
                                                    <Checkbox
                                                        value={"other"}
                                                        checked={
                                                            producerDisclosureForm.producerPaidForWork.indexOf("other") < 0
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => {
                                                            console.log(e.target.checked);
                                                            let temp =
                                                                producerDisclosureForm.producerPaidForWork;
                                                            if (e.target.checked === false) {
                                                                temp = temp.filter((v) => {
                                                                    if (v !== e.target.value) {
                                                                        return v;
                                                                    }
                                                                });
                                                                CommonContextUpdate(
                                                                    "producerPaidForWorkOtherDescription",
                                                                    ""
                                                                );
                                                            } else {
                                                                temp.push(e.target.value);
                                                            }

                                                            console.log("context", temp);
                                                            CommonContextUpdate("producerPaidForWork", temp);
                                                        }}
                                                    />
                                                }
                                            />
                                            <TextField
                                                variant="standard"
                                                type="text"
                                                disabled={
                                                    (disableAllInputs === true ||
                                                        producerDisclosureForm.producerPaidForWork.indexOf("other") < 0)
                                                        ? true
                                                        : false
                                                }
                                                value={producerDisclosureForm.producerPaidForWorkOtherDescription}
                                                onChange={(e) => {
                                                    CommonContextUpdate(
                                                        "producerPaidForWorkOtherDescription",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </FormGroup>
                                        <Typography sx={{ margin: "20px 0px !important" }}>
                                            I may also receive other indirect compensation resulting from this transaction (sometimes called “non-cash” compensation) such as health or retirement benefits, office rent and support, or other incentives from the insurance company or other sources.
                                        </Typography>
                                    </Box>
                                </Box>

                            </Box>
                        ) : null}
                        <Box
                            className="previous_next_btn"
                            style={{
                                margin: "25px 0",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                className="btn_prev"
                                startIcon={<KeyboardDoubleArrowLeftIcon />}
                                onClick={() => {
                                    props.previousPage();
                                }}
                                // onClick={() => {

                                //     //   if (ConditionToShowReplacementForm() === true) {
                                //     //     props.changePageIndex(1)
                                //     //   } else {
                                //     //     props.changePageIndex(2)
                                //     //   }
                                // }}
                                style={{
                                    background: "#074a6e",
                                    color: "white",
                                    textTransform: "capitalize",
                                    margin: "0 10px",
                                }}
                            >
                                Previous
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default ProducerDisclosureForm;
