import React from "react";
import styled from "@emotion/styled";
import { Draggable, Droppable } from "react-beautiful-dnd";
import Resource from "./Resource";
import { Grid } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const Container = styled("div")`
  border: 1px solid #eec45c;
  margin-bottom: 8px;
  border-radius: 2px;
  ${'' /* height:auto; */}
  background: ${props => (props.isDragging ? "#d9a22e" : "white")};
`;

const ResourceList = styled("div")`
  padding: 16px;
  flex-grow: 1;
  min-height: 100px;
  transition: background-color ease 0.2s;
  background-color: ${props =>
        props.isDraggingOver ? "#55A3FB" : "white"};
`;

const Group = ({ GroupRow, pdfCategoryId, index, dragItem, setDraggedItemDetails, MoveResourceOut, resourceListData, MoveResourceInGroup }) => {
    return (
        <Draggable draggableId={"group-drag-" + pdfCategoryId + "-" + GroupRow?.pdf_group_category_id.toString()} index={index} type="group">
            {provided => (
                <Container
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    {console.log("provided", provided.draggableProps.style.transition)}
                    <Grid container sx={{ paddingLeft: "8px", backgroundColor: "#EEC45C" }} alignItems="center">
                        <Grid item><DragIndicatorIcon /></Grid>
                        <Grid item><p>{GroupRow.pdf_group_category_name}</p></Grid>
                    </Grid>

                    <Droppable
                        droppableId={"group-drop-" + pdfCategoryId + "-" + GroupRow?.pdf_group_category_id.toString()}
                        index={index}
                        // type={dragItem !== "group" ? "resource" : "group"}
                        type={"group"}
                    >
                        {(provided, snapshot) => (
                            <>
                                <ResourceList
                                    isDraggingOver={snapshot.isDraggingOver}
                                    ref={provided.innerRef}
                                    id={index}
                                    {...provided.droppableProps}
                                >
                                    {provided.placeholder}
                                    {GroupRow?.pdf_group_resource_data?.length ?
                                        GroupRow?.pdf_group_resource_data?.map((resourceDetails, GroupIndex) => (
                                            <Resource
                                                key={resourceDetails.resource_sub_category_id}
                                                PdfGroupCategoryId={GroupRow?.pdf_group_category_id.toString()}
                                                pdfCategoryId={pdfCategoryId}
                                                resourceDetails={resourceDetails}
                                                id={GroupIndex}
                                                index={GroupIndex}
                                                setDraggedItemDetails={setDraggedItemDetails}
                                                MoveResourceOut={MoveResourceOut}
                                                MoveResourceInGroup={MoveResourceInGroup}
                                                resourceListData={resourceListData}
                                            />
                                        ))
                                        : ""
                                    }
                                </ResourceList>
                            </>
                        )}
                    </Droppable>
                </Container>
            )}
        </Draggable>
    )

    // } else {
    //     return (
    //         <Draggable draggableId={"group-draggable-" + task?.pdf_group_category_id.toString()} index={index} type="task">
    //             {provided => (
    //                 <Container
    //                     ref={provided.innerRef}
    //                     {...provided.draggableProps}
    //                     {...provided.dragHandleProps}
    //                 >
    //                     <Droppable droppableId={"group-droppable-" + task?.pdf_group_category_id.toString()} type="task">
    //                         {(provided, snapshot) => (
    //                             <Container
    //                                 ref={provided.innerRef}
    //                                 {...provided.dragHandleProps}
    //                                 {...provided.draggableProps}
    //                                 isDragging={snapshot.isDragging}
    //                             >
    //                                 {task.pdf_group_category_name}
    //                             </Container>
    //                         )}
    //                     </Droppable>
    //                 </Container>
    //             )}
    //         </Draggable>
    //     );
    // }
};

export default Group;