import axios from "../axios";

const SubmitSaveDraftFiaApplication = async (data, onSuccess, onError) => {
    try {
        const response = await axios.post("/api/application_v2/add-new-fia-application", data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}

const GetFiaAppListAPI = async (onSuccess, onError) => {
    try {
        const response = await axios.get("/api/application_v2/get-fia-application-list", {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}

const GetSingleFiaDataAPI = async (applicationId, onSuccess, onError) => {
    try {
        const response = await axios.get("/api/application_v2/get-fia-application-data?applications_id=" + applicationId, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}

export { SubmitSaveDraftFiaApplication, GetSingleFiaDataAPI, GetFiaAppListAPI }