import React, { Fragment, useState, useContext, useEffect } from "react";
import Logo from "../../assets/FLIC.svg";
import {
  Box,
  TextField,
  Typography,
  Button,
  FormControl,
  Alert,
} from "@mui/material";
import "./login.css";
import PasswordIcon from "../../assets/passwordIcon.png";
import { LoginUserAPI } from "../../api/userAPI";
import { Context as UserLoaderContext } from "../../context/userLoaderContext";
import { Link } from "react-router-dom";
import UserIcon from "../../assets/usericon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { ResetPasswordAPI, UpdatePasswordAPI } from "../../api/resetPassword";
import SuccessDialog from "../../components/successDialog/successDialog";
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const sp = new URLSearchParams(location.search);

  const { SetFlagLoader, state } = useContext(UserLoaderContext);
  const [userDetail, setUserDetail] = useState({
    email: "",
    password: "",
  });
  const [pageIndex, setPageIndex] = useState(0);
  const [flagEmailSent, setFlagEmailSent] = useState(false);
  const [resetPasswordContent, setResetPasswordContent] = useState({
    email: "",
    token: "",
    password: "",
    confirmPassword: "",
  });
  const [ShowMsg, setShowMsg] = useState({
    status: false,
    error: false,
    msg: "",
  });
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (location.pathname.includes("/login") === true) {
      setUserDetail({
        email: "",
        password: "",
      });
      setPageIndex(1);
    } else if (location.pathname.includes("/forgot-password") === true) {
      setUserDetail({
        email: "",
        password: "",
      });
      setPageIndex(2);
    } else if (location.pathname.includes("/resetPassword") === true) {
      if (sp.has("email") === true) {
        setUserDetail({
          email: "",
          password: "",
        });
        setResetPasswordContent({
          email: sp.get("email"),
          token: sp.get("token"),
          password: "",
          confirmPassword: "",
        });
      }
      setPageIndex(3);
    } else {
      setUserDetail({
        email: "",
        password: "",
      });
      setPageIndex(1);
    }
  }, [location.pathname]);

  const LoginUser = () => {
    const { email, password } = userDetail;
    if (email === "" || password === "") {
      // alert("Please fill all the fields.");
      setShowMsg({
        status: false,
        error: true,
        msg: "Please fill the required fields",
      });
    } else {
      // SetFlagLoader(true);
      console.log("abc");
      LoginUserAPI(
        {
          email: email,
          password: password,
        },
        (res) => {
          // SetFlagLoader(false);
          console.log(res);
          console.log("Pritning response of login api user", res);

          if (res.data.status === true) {
            console.log("success");
            let domain = window.location.host;
            let protocol = window.location.protocol;
            console.log("Printing state", state);

            let role = res.data.data.roles.map((v) => {
              console.log("Printing each role in login file", v.user_type);
              return v.user_type;
            });
            console.log("Printing final role in login file", role);
            if (role.indexOf("Agent") >= 0) {
              window.location.replace(`${protocol}//${domain}/`);
            } else {
              window.location.replace(`${protocol}//${domain}/`);
            }
          } else {
            // alert(res.data.error.message);
            setShowMsg({
              status: false,
              error: true,
              msg: res.data.error.message,
            });
          }
        },
        (err) => {
          // SetFlagLoader(false);
          console.log("got error for login user api", err);
          // alert("Something went wrong, Please try again later.!");
          setShowMsg({
            status: false,
            error: true,
            msg: "Something went wrong, Please try again later.!",
          });
        }
      );
    }
  };

  const ResetPassword = () => {
    if (userDetail.email !== "") {
      SetFlagLoader(true);
      ResetPasswordAPI(
        { email: userDetail.email },
        (res) => {
          SetFlagLoader(false);
          console.log("Printing response for reset password", res);

          if (res.data.status === true) {
            setFlagEmailSent(true);
            setTimeout(() => {
              setFlagEmailSent(false);
              navigate("/login");
            }, 5000);
          } else {
            setShowSuccessDialog({
              status: true,
              data: res.data?.error?.message,
              error: true,
            });
          }
        },
        (err) => {
          SetFlagLoader(false);
          console.log("Something went wrong, Please try again later.!", err);
          // alert("Something went wrong, Please try again later.!");
          setShowMsg({
            status: false,
            error: true,
            msg: "Something went wrong, Please try again later.!",
          });
        }
      );
    }
  };

  const UpdatePassword = () => {
    if (
      resetPasswordContent.password !== "" &&
      resetPasswordContent.confirmPassword !== ""
    ) {
      if (
        resetPasswordContent.password === resetPasswordContent.confirmPassword
      ) {
        SetFlagLoader(true);
        UpdatePasswordAPI(
          {
            token: resetPasswordContent.token,
            password: resetPasswordContent.password,
          },
          (res) => {
            SetFlagLoader(false);
            console.log("Printing response", res);

            if (res.data.status === true) {
              setShowSuccessDialog(res.data);
            } else {
              setShowSuccessDialog({
                status: true,
                data: "Something went wrong, Please try again later.!",
                error: true,
              });
            }
          },
          (err) => {
            SetFlagLoader(false);
            console.log("err", err);
          }
        );
      }
    }
  };

  const ResetPasswordValidation = () => {
    if (
      resetPasswordContent.password === "" ||
      resetPasswordContent.confirmPassword === ""
    ) {
      return true;
    } else if (
      resetPasswordContent.password !== "" &&
      resetPasswordContent.confirmPassword !== "" &&
      resetPasswordContent.password !== resetPasswordContent.confirmPassword
    ) {
      return true;
    } else if (
      resetPasswordContent.password !== "" &&
      resetPasswordContent.confirmPassword !== "" &&
      resetPasswordContent.password === resetPasswordContent.confirmPassword &&
      resetPasswordContent.password.length >= 8
    ) {
      return false;
    } else {
      return true;
    }
  };

  const SendEmailValidation = () => {
    const emailRegx =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegx.test(userDetail.email) === true) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Fragment>
      {showSuccessDialog.status === true && (
        <SuccessDialog
          error={showSuccessDialog?.error}
          redirect={showSuccessDialog?.error === true ? "/" : "/login"}
          firstText={showSuccessDialog.data}
          closeSuccessDialog={() => {
            setShowSuccessDialog(false);
            if (showSuccessDialog?.error !== true) {
              navigate("/login");
            }
          }}
        />
      )}
      <Box className="login_page_root">
        <Box className="page_title login_page_title">
          <Typography variant="span">
            {pageIndex == 1 ? "Login" : "Forgot Password"}
          </Typography>
        </Box>
        <Box className="login_page_form">
          <Box className="login_page_card">
            <Box className="login_page_card_left">
              {/* <img src={LoginFormBackground} alt="logo" /> */}
              <Box className="login_left_img_text">
                <Typography variant="span">
                  Welcome To Farmers
                  <br />
                  Life Insurance Company
                </Typography>
              </Box>
            </Box>

            {pageIndex === 1 && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  LoginUser();
                }}
                className="login_page_card_right"
              >
                <Box className="flic_logo">
                  <img src={Logo} width="300px" alt="logo" />
                </Box>
                <Box style={{ width: "100%" }}>
                  {ShowMsg.error && (
                    <Alert
                      className="errLogin"
                      style={{ width: "50%" }}
                      severity="error"
                    >
                      {ShowMsg.msg}
                    </Alert>
                  )}
                  {ShowMsg.status && (
                    <Alert
                      className="msgLogin"
                      severity="success"
                      style={{ width: "50%" }}
                    >
                      {ShowMsg.msg}
                    </Alert>
                  )}
                </Box>
                <Box className="login_page_email_root">
                  <Box className="login_page_email_icon">
                    <img src={UserIcon} alt="usericon" />
                  </Box>
                  <Box className="login_page_email_field">
                    <TextField
                      type="email"
                      placeholder="Email Id"
                      value={userDetail?.email}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setUserDetail({
                          email: e.target.value,
                          password: userDetail?.password,
                        });
                      }}
                      className="login_email_field"
                    />
                  </Box>
                </Box>
                <Box className="login_page_password_root">
                  <Box className="login_page_password_icon">
                    <img src={PasswordIcon} width="50px" height="50px" />
                  </Box>
                  <Box className="login_page_password_field">
                    <TextField
                      type={showPassword ? "text" : "password"}
                      className="login_password_field"
                      placeholder="Password"
                      value={userDetail?.password}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setUserDetail({
                          email: userDetail?.email,
                          password: e.target.value,
                        });
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            sx={{ alignItems: "baseline", cursor: "pointer" }}
                          >
                            {
                              showPassword ?
                                <VisibilityOff
                                  fontSize="small"
                                  sx={{ color: "#d9a22e", }}
                                />
                                :
                                <Visibility
                                  fontSize="small"
                                  sx={{ color: "#d9a22e" }}
                                />
                            }

                          </InputAdornment>
                        )
                      }}
                    />
                  </Box>
                </Box>
                <Box className="login_page_submit_root">
                  <Button className="login_submit_btn" type="submit">
                    Login
                  </Button>
                </Box>
                <Box className="login_page_card_right_other">
                  <Typography
                    onClick={() => {
                      setPageIndex(2);
                      navigate("/forgot-password");
                    }}
                    className="login_forgot_password"
                    variant="span"
                  >
                    Forgot Password?
                  </Typography>
                  <Typography className="lofin_not_have_account" variant="span">
                    Don't have an account?{" "}
                    <Link
                      style={{
                        color: "#D9A22E",
                        textDecoration: "none",
                        fontWeight: 500,
                      }}
                      to="/register"
                    >
                      Register
                    </Link>
                  </Typography>
                </Box>
              </form>
            )}
            {pageIndex === 2 && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="login_page_card_right"
              >
                <Box className="flic_logo">
                  <img src={Logo} width="300px" alt="logo" />
                </Box>
                <Box className="login_page_email_root">
                  <Box className="login_page_email_icon">
                    <img src={UserIcon} alt="usericon" />
                  </Box>
                  <Box className="login_page_email_field">
                    <TextField
                      type="email"
                      placeholder="Email Id"
                      value={userDetail?.email}
                      onChange={(e) => {
                        console.log(e.target.value);
                        setUserDetail({
                          email: e.target.value,
                          password: userDetail?.password,
                        });
                      }}
                      className="login_email_field"
                    />
                  </Box>
                </Box>
                <Box
                  className="login_page_submit_root"
                  sx={{ textAlign: "center" }}
                >
                  <Button
                    disabled={SendEmailValidation()}
                    onClick={ResetPassword}
                    className="login_submit_btn"
                    type="submit"
                  >
                    Send Email
                  </Button>
                  {flagEmailSent === true && (
                    <Typography
                      sx={{
                        color: "#04385d",
                        marginTop: "20px",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      We have sent the link to Email Address. Please check.
                    </Typography>
                  )}
                </Box>
              </form>
            )}
            {pageIndex === 3 && (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className="login_page_card_right"
              >
                <Box className="flic_logo">
                  <img src={Logo} width="300px" alt="logo" />
                </Box>
                <Box className="login_page_password_root">
                  <Box className="login_page_password_icon">
                    <img src={PasswordIcon} width="50px" height="50px" />
                  </Box>
                  <Box className="login_page_email_field">
                    <TextField
                      type="password"
                      placeholder="New Password"
                      value={resetPasswordContent?.password}
                      onChange={(e) => {
                        setResetPasswordContent({
                          ...resetPasswordContent,
                          password: e.target.value,
                        });
                      }}
                      className="login_password_field"
                    />
                  </Box>
                </Box>
                <Box className="login_page_password_root">
                  <Box className="login_page_password_icon">
                    <img src={PasswordIcon} width="50px" height="50px" />
                  </Box>
                  <Box className="login_page_email_field">
                    <TextField
                      type="password"
                      placeholder="Confirm New Password"
                      value={resetPasswordContent?.confirmPassword}
                      onChange={(e) => {
                        setResetPasswordContent({
                          ...resetPasswordContent,
                          confirmPassword: e.target.value,
                        });
                      }}
                      className="login_password_field"
                    />
                  </Box>
                </Box>
                <Box
                  className="login_page_submit_root"
                  sx={{ textAlign: "center" }}
                >
                  <Button
                    disabled={ResetPasswordValidation()}
                    onClick={UpdatePassword}
                    className="login_submit_btn"
                  >
                    Reset Password
                  </Button>
                </Box>
              </form>
            )}
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Login;
