import Axios from "./axios";

export const  DownloadPdfAPI = async(data,onSuccess ,onError)=>{
    try{
        // console.log("ID Data ",data);
        // debugger;
        //console.log("dataaaaaaaaaaaaaaaaa",data);
        const responce =await Axios.post('/api/user/pdf',data,  {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
       // console.log("printing responce from view", responce)
        // debugger;
        onSuccess && onSuccess(responce);    
        // debugger;
    }
    catch(err){
       // console.log("got an error from view",err);
        onError && onError(err)
    }
}