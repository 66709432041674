import axios from './axios';

const GeneratePublicURLAPI = async (data, onSuccess, onError)=>{
    try{
        console.log("Printing data to pass in generate public url", data);
        const response = await axios.get(`/api/application/public_url?applications_id=${data}`, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(response);
    }
    catch(err){
        onError && onError(err);
    }
};

export default GeneratePublicURLAPI;