import React, { useState, useEffect, useContext } from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Alert,
    Snackbar,
} from "@mui/material";

import "./resource_order.css";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../context/userLoaderContext";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Category from "../../../components/categoryList/Category";
import initialData from "../../../components/categoryList/data";
import styled from "@emotion/styled";
import { GetResourceDataAPI } from "../../../api/dragAndDropAPI";
import { DragAndDropAPI, GetCategoryGroupDataAPI } from "../../../api/resourceConfig";
import Popup from "./popup";

const Container = styled("div")`
  display: flex;
  width:100%;
  background-color: ${props => (props.isDraggingOver ? "#639ee2" : "inherit")};
`;

const ResourceOrder = () => {
    const { state, SetFlagLoader } = useContext(Context);
    const [resourceListData, setResourceListData] = useState([]);
    const [duplicateResourceListData, setDuplicateResourceListData] = useState([]);
    const [draggedItemDetails, setDraggedItemDetails] = useState([{
        dragged_item_pdf_category_id: "",
        dragged_item_pdf_category_seq_num: "",
        dragged_item_pdf_group_category_id: "",
        dragged_item_resource_sub_category_id: "",
        update_type_flag: "",
    }]);
    const [dragItem, setDragItem] = useState("")
    const navigate = useNavigate();
    const [groupList, setGroupList] = useState([]);
    const [snackBarState, setSnackBarState] = useState({
        flag: false,
        message: ""
    });

    console.log("---resourceListData", resourceListData)
    console.log("---duplicateResourceListData", duplicateResourceListData)

    const ReorderResources = (index, draggedItem, dropArea, droppedCategoryId, droppedGroupId) => {
        let tempList = [...resourceListData]
        let draggedElement;
        if (draggedItem == "category") {
            draggedElement = tempList.filter((nn) => nn.pdf_category_id == draggedItemDetails.dragged_item_pdf_category_id)[0]
            tempList = [...tempList].filter((nn) => nn.pdf_category_id != draggedItemDetails.dragged_item_pdf_category_id)
            tempList.splice(index, 0, draggedElement);
        }
        if (dropArea == "resource") {
            if (draggedItem == "resource") {
                // Remove Dragged Element from old place
                tempList?.map((category) => {
                    if (category?.pdf_category_resource_data?.length) {
                        category?.pdf_category_resource_data?.map((group) => {
                            if (draggedItemDetails?.dragged_item_pdf_group_category_id) {
                                if (group.pdf_resource_flag == false) {
                                    if (group?.pdf_group_category_id == draggedItemDetails?.dragged_item_pdf_group_category_id) {
                                        if (group?.pdf_group_resource_data?.length) {
                                            group?.pdf_group_resource_data?.map((resLine) => {
                                                if (resLine.resource_sub_category_id == draggedItemDetails.dragged_item_resource_sub_category_id) {
                                                    resLine.pdf_resource_flag = true
                                                    draggedElement = resLine
                                                }
                                            })
                                            group["pdf_group_resource_data"] = group?.pdf_group_resource_data?.filter((nn) => nn.resource_sub_category_id != draggedItemDetails.dragged_item_resource_sub_category_id)
                                        }
                                    }
                                }

                            } else {
                                if (group?.resource_sub_category_id == draggedItemDetails?.dragged_item_resource_sub_category_id) {
                                    draggedElement = group
                                    if (!draggedItemDetails?.dragged_item_pdf_group_category_id) {
                                        category["pdf_category_resource_data"] = category?.pdf_category_resource_data?.filter(
                                            (resRow) => ((resRow.pdf_resource_flag == true && resRow.resource_sub_category_id != draggedItemDetails?.dragged_item_resource_sub_category_id)
                                                || resRow.pdf_resource_flag == false)
                                        )
                                    }
                                }
                            }
                        })
                    }

                })
                // Set Dragged Element at new place
                tempList?.map((category) => {
                    if (category?.pdf_category_resource_data?.length) {
                        if (category.pdf_category_id == droppedCategoryId) {
                            category?.pdf_category_resource_data?.splice(index, 0, draggedElement);
                        }
                    } else {
                        if (category.pdf_category_id == droppedCategoryId) {
                            category["pdf_category_resource_data"][0] = draggedElement;
                        }
                    }
                })
            }

            if (draggedItem == "group") {
                // Remove Dragged Element from old place
                tempList?.map((category) => {
                    if (category?.pdf_category_resource_data?.length) {
                        category?.pdf_category_resource_data?.map((group) => {
                            if (group?.pdf_group_category_id == draggedItemDetails?.dragged_item_pdf_group_category_id) {
                                draggedElement = group
                                category["pdf_category_resource_data"] = category?.pdf_category_resource_data?.filter(
                                    (resRow) => ((resRow.pdf_resource_flag == false && resRow.pdf_group_category_id != draggedItemDetails?.dragged_item_pdf_group_category_id)
                                        || resRow.pdf_resource_flag == true)
                                )
                            }

                        })
                    }
                })

                // Set Dragged Element at new place
                tempList?.map((category) => {
                    if (category?.pdf_category_resource_data?.length) {
                        if (category.pdf_category_id == droppedCategoryId) {
                            category?.pdf_category_resource_data?.splice(index, 0, draggedElement);
                        }
                    } else {
                        if (category.pdf_category_id == droppedCategoryId) {
                            category["pdf_category_resource_data"][0] = draggedElement;
                        }
                    }
                })
            }
        }

        if (dropArea == "group") {
            if (draggedItem == "resource") {
                // Remove Dragged Element from old place
                tempList?.map((category) => {
                    if (category?.pdf_category_resource_data?.length) {
                        category?.pdf_category_resource_data?.map((group) => {
                            if (draggedItemDetails?.dragged_item_pdf_group_category_id) {
                                if (group.pdf_resource_flag == false) {
                                    if (group?.pdf_group_category_id == draggedItemDetails?.dragged_item_pdf_group_category_id) {
                                        if (group?.pdf_group_resource_data?.length) {
                                            group?.pdf_group_resource_data?.map((resLine) => {
                                                if (resLine.resource_sub_category_id == draggedItemDetails.dragged_item_resource_sub_category_id) {
                                                    draggedElement = resLine
                                                }
                                            })
                                            group["pdf_group_resource_data"] = group?.pdf_group_resource_data?.filter((nn) => nn.resource_sub_category_id != draggedItemDetails.dragged_item_resource_sub_category_id)
                                        }
                                    }
                                }
                            } else {
                                if (group.pdf_resource_flag) {
                                    if (group?.resource_sub_category_id == draggedItemDetails.dragged_item_resource_sub_category_id) {
                                        draggedElement = group
                                        category["pdf_category_resource_data"] = category?.pdf_category_resource_data?.filter(
                                            (resRow) => ((resRow.pdf_resource_flag == true && resRow.resource_sub_category_id != draggedItemDetails?.dragged_item_resource_sub_category_id)
                                                || resRow.pdf_resource_flag == false)
                                        )
                                    }
                                }
                            }
                        })
                    }
                })

                // Set Dragged Element at new place
                tempList?.map((category) => {
                    if (category?.pdf_category_resource_data?.length) {
                        if (category.pdf_category_id == droppedCategoryId) {
                            category.pdf_category_resource_data.map((group) => {
                                if (group.pdf_resource_flag == false) {
                                    if (group.pdf_group_category_id == droppedGroupId) {
                                        group?.pdf_group_resource_data?.splice(index, 0, draggedElement);
                                    }
                                }
                            });
                        }
                    }
                })
            }

            if (draggedItem == "group") {
                tempList?.map((category) => {
                    if (category?.pdf_category_resource_data?.length) {
                        category?.pdf_category_resource_data?.map((group) => {
                            if (group?.pdf_group_category_id == draggedItemDetails?.dragged_item_pdf_group_category_id) {

                                if (group?.pdf_group_resource_data?.length) {
                                    group?.pdf_group_resource_data?.map((resLine) => {
                                        if (resLine.resource_sub_category_id == draggedItemDetails.dragged_item_resource_sub_category_id) {
                                            draggedElement = resLine
                                        }
                                    })
                                    group["pdf_group_resource_data"] = group?.pdf_group_resource_data?.filter((nn) => nn.resource_sub_category_id != draggedItemDetails.dragged_item_resource_sub_category_id)
                                }
                            }

                        })
                    }
                })

                tempList?.map((category) => {
                    if (category?.pdf_category_resource_data?.length) {
                        if (category.pdf_category_id == droppedCategoryId) {
                            category.pdf_category_resource_data.map((group) => {
                                if (group.pdf_resource_flag == false) {
                                    if (group.pdf_group_category_id == droppedGroupId) {
                                        group?.pdf_group_resource_data?.splice(index, 0, draggedElement);
                                    }
                                }
                            });
                        }
                    }
                })
            }
        }

        setResourceListData(tempList)
        // setDuplicateResourceListData(tempList)
    }
    const onDragEnd = ({ destination, source, draggableId, type }) => {
        // debugger;
        SetFlagLoader(true);
        setDragItem("")
        // let payload = {
        //     pdf_category_id: "",
        //     pdf_group_category_id: "",
        //     resource_sub_category_id: "",
        //     prev_pdf_category_id: "",
        //     prev_pdf_group_category_id: "",
        //     prev_resource_sub_category_id: "",
        //     update_type_flag: "",
        // }
        let payload = {}

        let tempDestinationArray = destination?.droppableId?.split("-")
        let droppedArea;
        let droppedCategoryId;
        let droppedGroupId;

        if (tempDestinationArray?.length) {
            droppedArea = tempDestinationArray[0]
            droppedCategoryId = tempDestinationArray[2]
            droppedGroupId = tempDestinationArray[3]
        }
        if (droppedArea == "category") {
            ReorderResources(destination.index, "category", "category", droppedCategoryId)
            if (destination.index != 0) {
                let destination_row;
                if (duplicateResourceListData[destination.index]?.pdf_category_seq_num < draggedItemDetails?.dragged_item_pdf_category_seq_num) {
                    destination_row = duplicateResourceListData[destination.index - 1]
                } else if (duplicateResourceListData[destination.index]?.pdf_category_seq_num > draggedItemDetails?.dragged_item_pdf_category_seq_num) {
                    destination_row = duplicateResourceListData[destination.index]
                } else {
                    return;
                }
                payload["prev_pdf_category_id"] = destination_row.pdf_category_id
            } else {
                if (duplicateResourceListData[destination.index]?.pdf_category_seq_num == draggedItemDetails?.dragged_item_pdf_category_seq_num) {
                    return;
                }
                payload["prev_pdf_category_id"] = 0
            }
            payload["pdf_category_id"] = parseInt(draggedItemDetails.dragged_item_pdf_category_id)
            payload["update_type_flag"] = draggedItemDetails.update_type_flag
        }

        if (droppedArea == "group") {
            if (dragItem == "resource") {
                ReorderResources(destination.index, "resource", "group", droppedCategoryId, droppedGroupId)
                if (destination.index != 0) {
                    duplicateResourceListData?.map((category) => {
                        category?.pdf_category_resource_data?.map((group) => {
                            if (group.pdf_group_category_id == droppedGroupId) {
                                payload["prev_resource_sub_category_id"] = group.pdf_group_resource_data[destination.index - 1]?.resource_sub_category_id
                            }
                        })
                    })
                } else {
                    payload["prev_resource_sub_category_id"] = 0
                }
                payload["update_type_flag"] = null

                payload["pdf_category_id"] = parseInt(droppedCategoryId)
                payload["pdf_group_category_id"] = parseInt(tempDestinationArray[3])
                payload["resource_sub_category_id"] = parseInt(draggedItemDetails.dragged_item_resource_sub_category_id)
                // payload["prev_pdf_group_category_id"] = parseInt(prev_pdf_group_category_id)
            } else {
                return;
            }
        }

        if (droppedArea == "resource") {
            if (dragItem == "resource") {
                ReorderResources(destination.index, "resource", "resource", droppedCategoryId)
                if (destination.index != 0) {
                    duplicateResourceListData?.map((category) => {
                        if (category.pdf_category_id == droppedCategoryId) {
                            if (category?.pdf_category_resource_data?.[destination.index - 1]?.resource_sub_category_id) {
                                payload["prev_pdf_category_data_sequence_id"] = category?.pdf_category_resource_data?.[destination.index - 1]?.pdf_category_data_sequence_id
                                // payload["prev_resource_sub_category_id"] = category?.pdf_category_resource_data?.[destination.index - 1]?.resource_sub_category_id
                            } else {
                                payload["prev_pdf_category_data_sequence_id"] = category?.pdf_category_resource_data?.[destination.index - 1]?.pdf_category_data_sequence_id
                                // payload["prev_pdf_group_category_id"] = category?.pdf_category_resource_data?.[destination.index - 1]?.pdf_group_category_id
                            }
                        }
                    })
                } else {
                    payload["prev_pdf_category_data_sequence_id"] = 0
                    // payload["prev_resource_sub_category_id"] = 0
                }
                payload["update_type_flag"] = null
                payload["resource_sub_category_id"] = parseInt(draggedItemDetails.dragged_item_resource_sub_category_id)
                // payload["pdf_group_category_id"] = 0
            }
            if (dragItem == "group") {
                ReorderResources(destination.index, "group", "resource", droppedCategoryId)
                if (destination.index != 0) {
                    duplicateResourceListData?.map((category) => {
                        if (category.pdf_category_id == droppedCategoryId) {
                            if (category?.pdf_category_resource_data[destination.index - 1]?.pdf_group_category_id) {
                                payload["prev_pdf_category_data_sequence_id"] = category?.pdf_category_resource_data[destination.index - 1]?.pdf_category_data_sequence_id
                            } else {
                                payload["prev_pdf_category_data_sequence_id"] = category?.pdf_category_resource_data[destination.index - 1]?.pdf_category_data_sequence_id
                            }
                        }
                    })
                } else {
                    payload["prev_pdf_category_data_sequence_id"] = 0
                }
                payload["update_type_flag"] = false
                payload["pdf_group_category_id"] = parseInt(draggedItemDetails?.dragged_item_pdf_group_category_id)
            }
            payload["pdf_category_id"] = parseInt(droppedCategoryId)
        }
        DragAndDropAPI(payload, (res) => {
            if (res?.data?.status) {
                console.log("dnd res", res.data.new_data)
                setResourceListData(res.data.new_data)
                setDuplicateResourceListData(res.data.new_data)
                SetFlagLoader(false);
            } else {
                setSnackBarState({
                    flag: true,
                    message: "Somthing went wrong!"
                })
                SetFlagLoader(false);
                setResourceListData(res.data.data)
                setDuplicateResourceListData(res.data.data)
                // setResourceListData(res?.data?.data)
            }
        }, (err) => {
            SetFlagLoader(false);
            setSnackBarState({
                flag: true,
                message: "Somthing went wrong!"
            })
            console.log("errr", err)

        })
    };
    const onDragStart = ({ destination, source, draggableId, type }) => {
        // let myObj1 = JSON.parse(draggableId)
        let draggable_id = draggableId
        let draggableIdArray = draggableId.split("-")
        let dragItemType = draggableIdArray[0]
        let actionType = draggableIdArray[1]
        let fromCategoryId = draggableIdArray[2]

        let fromGroupId;
        let draggedCategorySeq = 0;
        if (dragItemType == "category") {
            draggedCategorySeq = draggableIdArray[3]
        } else {
            fromGroupId = draggableIdArray[3]
        }
        let fromResourceId = draggableIdArray[4]
        let updateFlag = draggableIdArray[0] == "category" ? true : draggableIdArray[0] == "group" ? false : null;
        setDragItem(dragItemType)
        setDraggedItemDetails({
            dragged_item_pdf_category_id: fromCategoryId,
            dragged_item_pdf_category_seq_num: parseInt(draggedCategorySeq),
            dragged_item_pdf_group_category_id: fromGroupId != "null" ? fromGroupId : "",
            dragged_item_resource_sub_category_id: fromResourceId ? fromResourceId : "",
            update_type_flag: updateFlag,
        })
    };

    const MoveResourceOut = (params) => {
        SetFlagLoader(true);
        let payload = {
            pdf_category_id: "",
            resource_sub_category_id: "",
            prev_pdf_category_data_sequence_id: "",
            update_type_flag: "",
        }
        duplicateResourceListData?.map((category) => {
            if (category.pdf_category_id == params.category_id) {
                let droppedPoint
                if (category?.pdf_category_resource_data?.length) {
                    droppedPoint = category?.pdf_category_resource_data[category?.pdf_category_resource_data?.length - 1].pdf_category_data_sequence_id
                } else {
                    droppedPoint = 0
                }
                payload["prev_pdf_category_data_sequence_id"] = droppedPoint
                payload["update_type_flag"] = null
                payload["resource_sub_category_id"] = params.resource_id
                payload["pdf_category_id"] = params.category_id
            }
        })
        DragAndDropAPI(payload, (res) => {
            if (res?.data?.status) {
                console.log("dnd res", res.data.new_data)
                setResourceListData(res.data.new_data)
                setDuplicateResourceListData(res.data.new_data)
                SetFlagLoader(false);
            } else {
                setSnackBarState({
                    flag: true,
                    message: "Somthing went wrong!"
                })
                SetFlagLoader(false);
                setResourceListData(res.data.data)
                setDuplicateResourceListData(res.data.data)
                // setResourceListData(res?.data?.data)
            }
        }, (err) => {
            SetFlagLoader(false);
            setSnackBarState({
                flag: true,
                message: "Somthing went wrong!"
            })
            console.log("errr", err)

        })
    }

    const MoveResourceInGroup = (params) => {
        let payload = {
            pdf_category_id: "",
            resource_sub_category_id: "",
            prev_resource_sub_category_id: "",
            update_type_flag: "",
        }
        duplicateResourceListData?.map((category) => {
            if (category?.pdf_category_id == params.category_id) {
                category.pdf_category_resource_data.map((group) => {
                    if (group?.pdf_resource_flag == false) {
                        if (group?.pdf_group_category_id == params?.group_id) {
                            if (group.pdf_group_resource_data.length) {
                                payload["prev_resource_sub_category_id"] = group.pdf_group_resource_data[group.pdf_group_resource_data.length - 1].resource_sub_category_id
                            } else {
                                payload["prev_resource_sub_category_id"] = 0
                            }
                            payload["pdf_category_id"] = params.category_id
                        }
                    }
                })
            }


        })
        payload["update_type_flag"] = null
        payload["resource_sub_category_id"] = params.resource_id
        payload["pdf_group_category_id"] = parseInt(params.group_id)

        DragAndDropAPI(payload, (res) => {
            if (res?.data?.status) {
                console.log("dnd res", res.data.new_data)
                setResourceListData(res.data.new_data)
                setDuplicateResourceListData(res.data.new_data)
                SetFlagLoader(false);
            } else {
                setSnackBarState({
                    flag: true,
                    message: "Somthing went wrong!"
                })
                SetFlagLoader(false);
                setResourceListData(res.data.data)
                setDuplicateResourceListData(res.data.data)
                // setResourceListData(res?.data?.data)
            }
        }, (err) => {
            SetFlagLoader(false);
            setSnackBarState({
                flag: true,
                message: "Somthing went wrong!"
            })
            console.log("errr", err)

        })
    }

    useEffect(() => {
        SetFlagLoader(true);
        // setResourceListData([
        //     {
        //         "pdf_category_id": 1,
        //         "pdf_category_created_by_user_id": 16,
        //         "pdf_category_created_by_user_name": "Test",
        //         "pdf_category_name": "Category 1",
        //         "pdf_category_resource_data": [
        //             {
        //                 "pdf_category_data_sequence_id": 1,
        //                 "pdf_group_category_id": 1,
        //                 "pdf_resource_flag": false,
        //                 "resource_hidden": false,
        //                 "pdf_group_category_assignment_id": 3,
        //                 "pdf_group_category_name": "Directions 1",
        //                 "pdf_group_resource_data": [
        //                     {
        //                         "resource_sub_category_id": 1,
        //                         "resource_name": "Text Resource 11",
        //                         "resource_type_id": 6,
        //                         "resource_type_name": "URL",
        //                         "resource_link": "https://mui.com/material-ui/",
        //                         "google_storage_id": null,
        //                         "google_storage_file": null,
        //                         "google_storage_original_file_name": null,
        //                         "google_storage_file_size": null,
        //                         "resource_sub_category_seq_num": 1
        //                     },
        //                     {
        //                         "resource_sub_category_id": 2,
        //                         "resource_name": "Text Resource Name 12",
        //                         "resource_type_id": 6,
        //                         "resource_type_name": "URL",
        //                         "resource_link": "https://mui.com/material-ui/",
        //                         "google_storage_id": null,
        //                         "google_storage_file": null,
        //                         "google_storage_original_file_name": null,
        //                         "google_storage_file_size": null,
        //                         "resource_sub_category_seq_num": 1
        //                     },
        //                     {
        //                         "resource_sub_category_id": 3,
        //                         "resource_name": "Text Resource Name 13",
        //                         "resource_type_id": 6,
        //                         "resource_type_name": "URL",
        //                         "resource_link": "https://mui.com/material-ui/",
        //                         "google_storage_id": null,
        //                         "google_storage_file": null,
        //                         "google_storage_original_file_name": null,
        //                         "google_storage_file_size": null,
        //                         "resource_sub_category_seq_num": 1
        //                     },
        //                 ]
        //             },
        //             {
        //                 "resource_sub_category_id": 4,
        //                 "pdf_resource_flag": true,
        //                 "pdf_category_data_sequence_id": 3,
        //                 "resource_hidden": false,
        //                 "resource_name": "Test resource 14",
        //                 "resource_type_id": 4,
        //                 "resource_type_name": "File upload",
        //                 "resource_link": null,
        //                 "google_storage_id": 1051,
        //                 "google_storage_file": "https://dev.api.farmers.instabrain.io/assets/MvwEN7tUkfxH85r16878438752021687843875202--table_field_1687843875196_16.PNG",
        //                 "google_storage_original_file_name": ""
        //             },
        //             {
        //                 "resource_sub_category_id": 5,
        //                 "pdf_resource_flag": true,
        //                 "pdf_category_data_sequence_id": 3,
        //                 "resource_hidden": false,
        //                 "resource_name": "Test resource 15",
        //                 "resource_type_id": 4,
        //                 "resource_type_name": "File upload",
        //                 "resource_link": null,
        //                 "google_storage_id": 1051,
        //                 "google_storage_file": "https://dev.api.farmers.instabrain.io/assets/MvwEN7tUkfxH85r16878438752021687843875202--table_field_1687843875196_16.PNG",
        //                 "google_storage_original_file_name": ""
        //             }
        //         ],
        //         "pdf_category_seq_num": 1,
        //         "created_at": "2023-06-26T14:08:53.000Z",
        //         "updated_at": null
        //     },
        //     {
        //         "pdf_category_id": 2,
        //         "pdf_category_created_by_user_id": 16,
        //         "pdf_category_created_by_user_name": "Test",
        //         "pdf_category_name": "Category 2",
        //         "pdf_category_resource_data": [
        //             {
        //                 "pdf_group_category_id": 2,
        //                 "pdf_resource_flag": false,
        //                 "pdf_category_data_sequence_id": 1,
        //                 "resource_hidden": false,
        //                 "pdf_group_category_assignment_id": 3,
        //                 "pdf_group_category_name": "Directions 2",
        //                 "pdf_group_resource_data": [
        //                     {
        //                         "resource_sub_category_id": 6,
        //                         "resource_name": "Text Resource 21",
        //                         "resource_type_id": 6,
        //                         "resource_type_name": "URL",
        //                         "resource_link": "https://mui.com/material-ui/",
        //                         "google_storage_id": null,
        //                         "google_storage_file": null,
        //                         "google_storage_original_file_name": null,
        //                         "google_storage_file_size": null,
        //                         "resource_sub_category_seq_num": 1
        //                     },
        //                     {
        //                         "resource_sub_category_id": 7,
        //                         "resource_name": "Text Resource Name 22",
        //                         "resource_type_id": 6,
        //                         "resource_type_name": "URL",
        //                         "resource_link": "https://mui.com/material-ui/",
        //                         "google_storage_id": null,
        //                         "google_storage_file": null,
        //                         "google_storage_original_file_name": null,
        //                         "google_storage_file_size": null,
        //                         "resource_sub_category_seq_num": 1
        //                     },
        //                     {
        //                         "resource_sub_category_id": 8,
        //                         "resource_name": "Text Resource Name 23",
        //                         "resource_type_id": 6,
        //                         "resource_type_name": "URL",
        //                         "resource_link": "https://mui.com/material-ui/",
        //                         "google_storage_id": null,
        //                         "google_storage_file": null,
        //                         "google_storage_original_file_name": null,
        //                         "google_storage_file_size": null,
        //                         "resource_sub_category_seq_num": 1
        //                     },
        //                 ]
        //             },
        //             {
        //                 "resource_sub_category_id": 9,
        //                 "pdf_resource_flag": true,
        //                 "pdf_category_data_sequence_id": 3,
        //                 "resource_hidden": false,
        //                 "resource_name": "Test resource 24",
        //                 "resource_type_id": 4,
        //                 "resource_type_name": "File upload",
        //                 "resource_link": null,
        //                 "google_storage_id": 1051,
        //                 "google_storage_file": "https://dev.api.farmers.instabrain.io/assets/MvwEN7tUkfxH85r16878438752021687843875202--table_field_1687843875196_16.PNG",
        //                 "google_storage_original_file_name": ""
        //             },
        //             {
        //                 "resource_sub_category_id": 10,
        //                 "pdf_resource_flag": true,
        //                 "pdf_category_data_sequence_id": 3,
        //                 "resource_hidden": false,
        //                 "resource_name": "Test resource 25",
        //                 "resource_type_id": 4,
        //                 "resource_type_name": "File upload",
        //                 "resource_link": null,
        //                 "google_storage_id": 1051,
        //                 "google_storage_file": "https://dev.api.farmers.instabrain.io/assets/MvwEN7tUkfxH85r16878438752021687843875202--table_field_1687843875196_16.PNG",
        //                 "google_storage_original_file_name": ""
        //             }
        //         ],
        //         "pdf_category_seq_num": 1,
        //         "created_at": "2023-06-26T14:08:53.000Z",
        //         "updated_at": null
        //     }
        // ])
        // setDuplicateResourceListData([
        // {
        //     "pdf_category_id": 1,
        //         "pdf_category_created_by_user_id": 16,
        //             "pdf_category_created_by_user_name": "Test",
        //                 "pdf_category_name": "Category 1",
        //                     "pdf_category_resource_data": [
        //                         {
        //                             "pdf_group_category_id": 1,
        //                             "pdf_resource_flag": false,
        //                             "pdf_category_data_sequence_id": 1,
        //                             "resource_hidden": false,
        //                             "pdf_group_category_assignment_id": 3,
        //                             "pdf_group_category_name": "Directions 1",
        //                             "pdf_group_resource_data": [
        //                                 {
        //                                     "resource_sub_category_id": 1,
        //                                     "resource_name": "Text Resource 11",
        //                                     "resource_type_id": 6,
        //                                     "resource_type_name": "URL",
        //                                     "resource_link": "https://mui.com/material-ui/",
        //                                     "google_storage_id": null,
        //                                     "google_storage_file": null,
        //                                     "google_storage_original_file_name": null,
        //                                     "google_storage_file_size": null,
        //                                     "resource_sub_category_seq_num": 1
        //                                 },
        //                                 {
        //                                     "resource_sub_category_id": 2,
        //                                     "resource_name": "Text Resource Name 12",
        //                                     "resource_type_id": 6,
        //                                     "resource_type_name": "URL",
        //                                     "resource_link": "https://mui.com/material-ui/",
        //                                     "google_storage_id": null,
        //                                     "google_storage_file": null,
        //                                     "google_storage_original_file_name": null,
        //                                     "google_storage_file_size": null,
        //                                     "resource_sub_category_seq_num": 1
        //                                 },
        //                                 {
        //                                     "resource_sub_category_id": 3,
        //                                     "resource_name": "Text Resource Name 13",
        //                                     "resource_type_id": 6,
        //                                     "resource_type_name": "URL",
        //                                     "resource_link": "https://mui.com/material-ui/",
        //                                     "google_storage_id": null,
        //                                     "google_storage_file": null,
        //                                     "google_storage_original_file_name": null,
        //                                     "google_storage_file_size": null,
        //                                     "resource_sub_category_seq_num": 1
        //                                 },
        //                             ]
        //                         },
        //                         {
        //                             "resource_sub_category_id": 4,
        //                             "pdf_resource_flag": true,
        //                             "pdf_category_data_sequence_id": 3,
        //                             "resource_hidden": false,
        //                             "resource_name": "Test resource 14",
        //                             "resource_type_id": 4,
        //                             "resource_type_name": "File upload",
        //                             "resource_link": null,
        //                             "google_storage_id": 1051,
        //                             "google_storage_file": "https://dev.api.farmers.instabrain.io/assets/MvwEN7tUkfxH85r16878438752021687843875202--table_field_1687843875196_16.PNG",
        //                             "google_storage_original_file_name": ""
        //                         },
        //                         {
        //                             "resource_sub_category_id": 5,
        //                             "pdf_resource_flag": true,
        //                             "pdf_category_data_sequence_id": 3,
        //                             "resource_hidden": false,
        //                             "resource_name": "Test resource 15",
        //                             "resource_type_id": 4,
        //                             "resource_type_name": "File upload",
        //                             "resource_link": null,
        //                             "google_storage_id": 1051,
        //                             "google_storage_file": "https://dev.api.farmers.instabrain.io/assets/MvwEN7tUkfxH85r16878438752021687843875202--table_field_1687843875196_16.PNG",
        //                             "google_storage_original_file_name": ""
        //                         }
        //                     ],
        //                         "pdf_category_seq_num": 1,
        //                             "created_at": "2023-06-26T14:08:53.000Z",
        //                                 "updated_at": null
        // },
        // {
        //     "pdf_category_id": 2,
        //         "pdf_category_created_by_user_id": 16,
        //             "pdf_category_created_by_user_name": "Test",
        //                 "pdf_category_name": "Category 2",
        //                     "pdf_category_resource_data": [
        //                         {
        //                             "pdf_group_category_id": 2,
        //                             "pdf_resource_flag": false,
        //                             "pdf_category_data_sequence_id": 1,
        //                             "resource_hidden": false,
        //                             "pdf_group_category_assignment_id": 3,
        //                             "pdf_group_category_name": "Directions 2",
        //                             "pdf_group_resource_data": [
        //                                 {
        //                                     "resource_sub_category_id": 6,
        //                                     "resource_name": "Text Resource 21",
        //                                     "resource_type_id": 6,
        //                                     "resource_type_name": "URL",
        //                                     "resource_link": "https://mui.com/material-ui/",
        //                                     "google_storage_id": null,
        //                                     "google_storage_file": null,
        //                                     "google_storage_original_file_name": null,
        //                                     "google_storage_file_size": null,
        //                                     "resource_sub_category_seq_num": 1
        //                                 },
        //                                 {
        //                                     "resource_sub_category_id": 7,
        //                                     "resource_name": "Text Resource Name 22",
        //                                     "resource_type_id": 6,
        //                                     "resource_type_name": "URL",
        //                                     "resource_link": "https://mui.com/material-ui/",
        //                                     "google_storage_id": null,
        //                                     "google_storage_file": null,
        //                                     "google_storage_original_file_name": null,
        //                                     "google_storage_file_size": null,
        //                                     "resource_sub_category_seq_num": 1
        //                                 },
        //                                 {
        //                                     "resource_sub_category_id": 8,
        //                                     "resource_name": "Text Resource Name 23",
        //                                     "resource_type_id": 6,
        //                                     "resource_type_name": "URL",
        //                                     "resource_link": "https://mui.com/material-ui/",
        //                                     "google_storage_id": null,
        //                                     "google_storage_file": null,
        //                                     "google_storage_original_file_name": null,
        //                                     "google_storage_file_size": null,
        //                                     "resource_sub_category_seq_num": 1
        //                                 },
        //                             ]
        //                         },
        //                         {
        //                             "resource_sub_category_id": 9,
        //                             "pdf_resource_flag": true,
        //                             "pdf_category_data_sequence_id": 3,
        //                             "resource_hidden": false,
        //                             "resource_name": "Test resource 24",
        //                             "resource_type_id": 4,
        //                             "resource_type_name": "File upload",
        //                             "resource_link": null,
        //                             "google_storage_id": 1051,
        //                             "google_storage_file": "https://dev.api.farmers.instabrain.io/assets/MvwEN7tUkfxH85r16878438752021687843875202--table_field_1687843875196_16.PNG",
        //                             "google_storage_original_file_name": ""
        //                         },
        //                         {
        //                             "resource_sub_category_id": 10,
        //                             "pdf_resource_flag": true,
        //                             "pdf_category_data_sequence_id": 3,
        //                             "resource_hidden": false,
        //                             "resource_name": "Test resource 25",
        //                             "resource_type_id": 4,
        //                             "resource_type_name": "File upload",
        //                             "resource_link": null,
        //                             "google_storage_id": 1051,
        //                             "google_storage_file": "https://dev.api.farmers.instabrain.io/assets/MvwEN7tUkfxH85r16878438752021687843875202--table_field_1687843875196_16.PNG",
        //                             "google_storage_original_file_name": ""
        //                         }
        //                     ],
        //                         "pdf_category_seq_num": 1,
        //                             "created_at": "2023-06-26T14:08:53.000Z",
        //                                 "updated_at": null
        // }
        // ])

        GetCategoryGroupDataAPI("",
            (res) => {
                if (res.data.status === true) {
                    setGroupList(res.data.data)
                }
            },
            (err) => {
                console.log("err", err);
            }
        );



        GetResourceDataAPI(
            (res) => {
                if (res.data.status === true) {
                    console.log("ressssss", res)
                    setResourceListData(res.data.data)
                    setDuplicateResourceListData(res.data.data)
                    SetFlagLoader(false);
                } else {
                    SetFlagLoader(false);
                }
            },
            (err) => {
                SetFlagLoader(false);
                console.log("err", err);
            }
        );
    }, []);

    useEffect(() => {
        if (snackBarState.flag == true) {
            setTimeout(() => {
                setSnackBarState({
                    flag: false,
                    message: ""
                })
            }, 5000);
        }
    }, [snackBarState.flag])

    console.log("groupList", groupList)

    return (
        <>
            {snackBarState.flag &&
                <Snackbar open={snackBarState.flag} autoHideDuration={6000} onClose={() => { setSnackBarState({ flag: false, message: "" }) }}>
                    <Alert severity="error">{snackBarState.message}</Alert>
                </Snackbar>
            }

            <Box className="page_title_annuity_application page_title">
                <Typography variant="span">Order Resources</Typography>
            </Box>
            <Grid container>
                <Grid item xs={12} textAlign="start" margin="10px 0px 0px 10px">
                    <Button
                        className="btn_New_Pdf"
                        variant="contained"
                        onClick={() => {
                            navigate("/pdf-config");
                        }}
                    >
                        Back
                    </Button>
                </Grid>
            </Grid>
            {/* <div style={{ width: "100%" }}> */}
            {resourceListData?.length > 0 &&
                <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                    <Droppable droppableId="category-droppable" type="category" direction="horizontal">
                        {(provided, snapshot) => (
                            <Container
                                isDraggingOver={snapshot.isDraggingOver}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {resourceListData?.map((ResourcesListRow, index) => {
                                    return (
                                        <Category
                                            key={index}
                                            index={index}
                                            category={ResourcesListRow}
                                            dragItem={dragItem}
                                            setDraggedItemDetails={setDraggedItemDetails}
                                            MoveResourceOut={MoveResourceOut}
                                            MoveResourceInGroup={MoveResourceInGroup}
                                            resourceListData={resourceListData}
                                        />
                                    );
                                })}
                                {provided.placeholder}
                            </Container>
                        )}
                    </Droppable>
                </DragDropContext>}
            {/* </div> */}


        </>
    )
}
export default ResourceOrder;