import React, { Fragment } from 'react';
import { Typography, Dialog, Box, Button } from '@mui/material';
import SuccessImage from '../../assets/success_dialog.png';
import ErrorImage from '../../assets/error.png';

const SuccessDdialog = (props) => {

    return (
        <Fragment>
            <Dialog
                open={true}
                onClose={() => {
                    props.closeSuccessDialog()
                }}
            >
                <Box className='dialog_content' style={{ display: "flex", justifyContent: "center", textAlign: 'center', flexDirection: "column", padding: "20px" }}>
                    {props.error !== true ? <Box className='success_image' style={{ margin: "15px 0" }}>
                        <img src={SuccessImage} alt="success_image" />
                    </Box> : <Box className='success_image' style={{ margin: "15px 0" }}>
                        <img src={ErrorImage} alt="success_image" />
                    </Box>}
                    <Box className='success_text' style={{ margin: "10px 0" }}>
                        {/* <Typography fontWeight={550} variant='span'>Application Submitted Successfully!</Typography><br /><br />
                        <Typography fontWeight={550} variant='span'>DocuSign request to sign the envelop will be sent to applicant in 5 minutes.</Typography> */}
                        <Typography fontWeight={550} variant='span'>{props.firstText}</Typography><br /><br />
                        <Typography fontWeight={550} variant='span'>{props.secondText}</Typography>
                    </Box>
                    <Box className='ok_btn' style={{ margin: "10px 0" }}>
                        <Button onClick={() => {
                            props.closeSuccessDialog()
                            let domain = window.location.host;
                            let protocol = window.location.protocol;
                            if(props?.error !== true)
                                window.location.replace(`${protocol}//${domain}${props.redirect}`);
                        }} style={{ background: "#074a6e", color: "#fff" }}>Ok</Button>
                    </Box>
                </Box>
            </Dialog>
        </Fragment>
    )
};

export default SuccessDdialog;