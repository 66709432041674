import Axios from "./axios";

export const  SaveDataAPI = async(data,onSuccess ,onError)=>{
    try{
        // console.log("ID Data ",data);
        // debugger;
        const responce =await Axios.post('/api/user/save', data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
      //  console.log("printing responce", responce)
        // debugger;
        onSuccess && onSuccess(responce);
        // debugger;
    }
    catch(err){
      //  console.log("got an error",err);
        onError && onError(err)
    }
}