import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  InputAdornment,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
} from "@mui/material";
import { Context } from "../../../context/V3/newApplicationFormContext";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context as ErrorValidationContext } from "../../../context/V3/errorValidationContext";
import "./replacementForm.css";

const ReplacementForm = (props) => {
  const { SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );
  const { flagReplacementPageFirstSection } = useContext(
    ErrorValidationContext
  ).state;
  const { SetNoticeOfReplacement } = useContext(Context);
  const { noticeOfReplacement, disableAllInputs } = useContext(Context).state;
  const [disableQuestions, setDisableQuestions] = useState(true)

  const CommonContextUpdate = (type, value) => {
    SetNoticeOfReplacement({
      type: type,
      value: value,
    });
  };

  useEffect(() => {
    console.log("Printing notice of replacement, ", noticeOfReplacement);
  }, [noticeOfReplacement]);

  useEffect(() => {
    if (noticeOfReplacement.flagDiscontinuingPremiumPayment == "yes" || noticeOfReplacement.flagPayForPremiumPolicy == "yes") {
      setDisableQuestions(false)
    } else {
      setDisableQuestions(true)
      let emptyArray = [
        {
          id: 1,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
        {
          id: 2,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
        {
          id: 3,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
        {
          id: 4,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
      ]
      SetNoticeOfReplacement({
        type: "insurerPolicyDetailFieldArray",
        value: emptyArray,
      });
      CommonContextUpdate("reasonForReplacement", "");
    }
  }, [noticeOfReplacement.flagDiscontinuingPremiumPayment, noticeOfReplacement.flagPayForPremiumPolicy])

  return (
    <Fragment>
      <Box className="transfer_exchange_request_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head">
              <Box>
                <Typography variant="span">
                  Notice of Replacement of Life Insurance or Annuities
                </Typography>
              </Box>
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>
            <Box
              className="section_head_root"
              onClick={() => {
                // debugger;
                SetApplicationFormFlagSectionData({
                  type: "Notice of Replacement",
                  section: "flagReplacementPageFirstSection",
                  data: !flagReplacementPageFirstSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">
                  1. Important Notice: Replacement of Life Insurance or
                  Annuities
                </Typography>
              </Box>
              <Box className="section_open_close">
                {flagReplacementPageFirstSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagReplacementPageFirstSection === true ? (
              <Box className="important_notice_detail">
                <Typography
                  sx={{ marginTop: "20px" }}
                  className="notice_detail_desc"
                  variant="span"
                >
                  (This notice must be signed by the applicant(s) and broker,
                  with the original sent to Farmers Life Insurance Company and a
                  copy left with the applicant(s).)
                </Typography>
                <Typography className="notice_detail_desc" variant="span">
                  You are contemplating the purchase of a life insurance policy
                  or annuity contract. In some cases this purchase may involve
                  discontinuing or changing an existing policy or contract. If
                  so, a replacement is occurring. Financed purchases are also
                  considered replacements.
                </Typography>

                <Typography className="notice_detail_desc" variant="span">
                  A replacement occurs when a new policy or contract is
                  purchased and, in connection with the sale, you discontinue
                  making premium payments on the existing policy or contract, or
                  an existing policy or contract is surrendered, forfeited,
                  assigned to the replacing insurer, or otherwise terminated or
                  used in a financial purchase.
                </Typography>

                <Typography className="notice_detail_desc" variant="span">
                  A financed purchase occurs when the purchase of a new life
                  insurance policy involves the use of funds obtained by the
                  withdrawal or surrender of or by borrowing some of all of the
                  policy values, including accumulated dividends, of an existing
                  policy or contract to pay all or part of any premium or
                  payment due on the new policy. A financed purchase is a
                  replacement.
                </Typography>

                <Typography className="notice_detail_desc" variant="span">
                  You should carefully consider whether a replacement is in your
                  best interests. You will pay acquisition costs and there may
                  be surrender costs deducted from your policy or contract. You
                  may be able to make changes to your existing policy or
                  contract to meet your insurance needs at less cost. A financed
                  purchase will reduce the value of your existing policy and may
                  reduce the amount paid upon the death of the insured
                  individual.
                </Typography>

                <Typography className="notice_detail_desc" variant="span">
                  We want you to understand the effects of replacements before
                  you make your purchase decision and ask that you answer
                  <br />
                  the following questions and consider the questions on page 2
                  of this form.
                </Typography>
                <Box className="question_A_important_notice">
                  <Typography variant="span">
                    A. Are you considering discontinuing making premium
                    payments, surrendering, forfeiting, assigning to the insurer
                    or otherwise terminating your existing policy or contract?{" "}
                    <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RadioGroup
                    name="question_a"
                    className="radio_group_shift_right"
                    row={true}
                    value={noticeOfReplacement.flagDiscontinuingPremiumPayment}
                    onChange={(e) => {
                      CommonContextUpdate(
                        "flagDiscontinuingPremiumPayment",
                        e.target.value
                      );
                    }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"yes"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"no"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Box>
                <Box className="question_B_important_notice">
                  <Typography variant="span">
                    B. Are you considering using funds from your existing
                    policies or contracts to pay premiums due on the new policy
                    or contract? <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RadioGroup
                    name="question_b"
                    className="radio_group_shift_right"
                    row={true}
                    value={noticeOfReplacement.flagPayForPremiumPolicy}
                    onChange={(e) => {
                      CommonContextUpdate(
                        "flagPayForPremiumPolicy",
                        e.target.value
                      );
                    }}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"yes"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      disabled={disableAllInputs === true ? true : false}
                      control={
                        <Radio
                          value={"no"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </Box>
                <Box className="question_C_important_notice">
                  <Typography variant="span">
                    C. If you answered “Yes” to either of the above questions,
                    list each existing policy or contract you are contemplating
                    replacing (include the name of the insurer, the insured or
                    annuitant, and the policy or contract number, if available)
                    and whether each policy or contract will be replaced or used
                    as a source of financing:{" "}
                    {(noticeOfReplacement.flagDiscontinuingPremiumPayment ===
                      "yes" ||
                      noticeOfReplacement.flagPayForPremiumPolicy ===
                      "yes") && <span style={{ color: "red" }}>*</span>}
                  </Typography>
                  {flagReplacementPageFirstSection === true ? (
                    <Box
                      style={{ width: "100%", left: "0%", position: "relative" }}
                    >
                      <TableContainer className="replacementTableContainer">
                        <Table sx={{ minWidth: 650 }} aria-label="simple table" className="ma_tbl">
                          <TableHead>
                            <TableRow className="replacementTableHead noticeOfReplacementHead">
                              <TableCell>Carrier Name{(noticeOfReplacement.flagDiscontinuingPremiumPayment === "yes" || noticeOfReplacement.flagPayForPremiumPolicy === "yes") && <span style={{ color: "red" }}> *</span>}</TableCell>
                              <TableCell>Carrier Address</TableCell>
                              <TableCell>Insured/annuitant name{(noticeOfReplacement.flagDiscontinuingPremiumPayment === "yes" || noticeOfReplacement.flagPayForPremiumPolicy === "yes") && <span style={{ color: "red" }}> *</span>}</TableCell>
                              <TableCell>Policy number{(noticeOfReplacement.flagDiscontinuingPremiumPayment === "yes" || noticeOfReplacement.flagPayForPremiumPolicy === "yes") && <span style={{ color: "red" }}> *</span>}</TableCell>
                              <TableCell>Effective date</TableCell>
                              <TableCell>Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {noticeOfReplacement?.insurerPolicyDetailFieldArray?.map((row, i, arr) => {

                              return (
                                <TableRow key={i} className="replacementTableRow">
                                  <TableCell align="left" className="replacementTableCell mv_tbl_cell">
                                    <TextField
                                      type="text"
                                      className="not_required owner_replacement_not_required ma_textfield_two"
                                      disabled={(disableAllInputs === true || disableQuestions === true) ? true : false}
                                      placeholder="Carrier Name"
                                      variant="outlined"
                                      value={row.existingCarrierName}
                                      onChange={(e) => {
                                        let tempArr = arr
                                        tempArr = tempArr.map((element) => {
                                          if (element?.id == row?.id) {
                                            element.existingCarrierName = e.target.value
                                          }
                                          return element
                                        })
                                        SetNoticeOfReplacement({
                                          type: "insurerPolicyDetailFieldArray",
                                          value: tempArr,
                                        });
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className="replacementTableCell mv_tbl_cell">
                                    <TextField
                                      type="text"
                                      className="not_required owner_replacement_not_required ma_textfield_three"
                                      disabled={(disableAllInputs === true || disableQuestions === true) ? true : false}
                                      placeholder="Carrier Address"
                                      variant="outlined"
                                      value={row.existingCarrierAddress}
                                      onChange={(e) => {
                                        let tempArr = arr
                                        tempArr = tempArr.map((element) => {
                                          if (element?.id == row?.id) {
                                            element.existingCarrierAddress = e.target.value
                                          }
                                          return element
                                        })
                                        SetNoticeOfReplacement({
                                          type: "insurerPolicyDetailFieldArray",
                                          value: tempArr,
                                        });
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className="replacementTableCell mv_tbl_cell">
                                    <TextField
                                      disabled={(disableAllInputs === true || disableQuestions === true) ? true : false}
                                      className="not_required owner_replacement_not_required ma_textfield_one"
                                      type="text"
                                      placeholder="Insured/annuitant name"
                                      variant="outlined"
                                      value={row.nameOfInsuredOrAnnuitant}
                                      onChange={(e) => {
                                        let tempArr = arr
                                        tempArr = tempArr.map((element) => {
                                          if (element?.id == row?.id) {
                                            element.nameOfInsuredOrAnnuitant = e.target.value
                                          }
                                          return element
                                        })
                                        SetNoticeOfReplacement({
                                          type: "insurerPolicyDetailFieldArray",
                                          value: tempArr,
                                        });
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className="replacementTableCell mv_tbl_cell">
                                    <TextField
                                      type="text"
                                      className="not_required owner_replacement_not_required ma_textfield_four"
                                      disabled={(disableAllInputs === true || disableQuestions === true) ? true : false}
                                      placeholder="Policy number"
                                      variant="outlined"
                                      value={row.contractOrPolicyNumber}
                                      onChange={(e) => {
                                        let tempArr = arr
                                        tempArr = tempArr.map((element) => {
                                          if (element?.id == row?.id) {
                                            element.contractOrPolicyNumber = e.target.value
                                          }
                                          return element
                                        })
                                        SetNoticeOfReplacement({
                                          type: "insurerPolicyDetailFieldArray",
                                          value: tempArr,
                                        });
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell align="left" className="replacementTableCell mv_tbl_cell">
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                      <DatePicker
                                        disabled={(disableAllInputs === true || disableQuestions === true) ? true : false}
                                        className="notice_of_replace_date ma_textfield_five"
                                        id="date_field"
                                        // label="Date Of Birth"
                                        inputFormat="MM/dd/yyyy"
                                        renderInput={(params) => <TextField {...params} />}
                                        onChange={(e) => {
                                          let tempArr = arr
                                          tempArr = tempArr.map((element) => {
                                            if (element?.id == row?.id) {
                                              element.effectiveDate = e
                                            }
                                            return element
                                          })
                                          SetNoticeOfReplacement({
                                            type: "insurerPolicyDetailFieldArray",
                                            value: tempArr,
                                          });
                                        }}
                                        value={row.effectiveDate}
                                      />
                                    </LocalizationProvider>
                                  </TableCell>
                                  <TableCell align="left" className="replacementTableCell mv_tbl_cell">
                                    <TextField
                                      type="text"
                                      className="not_required owner_replacement_not_required_amt ma_textfield_six"
                                      disabled={(disableAllInputs === true || disableQuestions === true) ? true : false}
                                      placeholder="Amount"
                                      variant="outlined"
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                      value={row.amount && row.amount}
                                      onChange={(e) => {
                                        let tempArr = [...arr]
                                        e.target.value = e.target.value.replace(/[^0-9]/g, "");
                                        tempArr = tempArr.map((element) => {
                                          if (element?.id == row?.id) {
                                            if (e.target.value)
                                              element.amount = Number(e.target.value.replace(/,/g, '')).toLocaleString()
                                            else
                                              element.amount = ""
                                          }
                                          return element
                                        })
                                        SetNoticeOfReplacement({
                                          type: "insurerPolicyDetailFieldArray",
                                          value: tempArr,
                                        });
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            })
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  ) : null}
                </Box>
                <Box className="question_D_important_notice">
                  <Typography variant="span">
                    D. The existing policy or contract is being replace because{" "}
                    {(noticeOfReplacement.flagDiscontinuingPremiumPayment === "yes" ||
                      noticeOfReplacement.flagPayForPremiumPolicy === "yes")
                      && <span style={{ color: "red" }}>*</span>}
                  </Typography>
                  <TextField
                    variant="standard"
                    type="text"
                    className={(noticeOfReplacement.flagDiscontinuingPremiumPayment === "yes" ||
                      noticeOfReplacement.flagPayForPremiumPolicy === "yes")
                      ? "question_d_input_field" : "question_d_input_field not_required"}
                    label="Reason for replacement"
                    disabled={(disableAllInputs === true || disableQuestions === true) ? true : false}
                    value={noticeOfReplacement.reasonForReplacement}
                    onChange={(e) => {
                      CommonContextUpdate("reasonForReplacement", e.target.value);
                    }}
                  />
                </Box>
              </Box>
            ) : null}
            <Box
              className="previous_next_btn"
              style={{
                margin: "25px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                className="btn_prev"
                onClick={() => {
                  props.previousPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Previous
              </Button>
              <Button
                endIcon={<DoubleArrowIcon />}
                className="btn_next"
                onClick={() => {
                  props.nextPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Next - Suitability Analysis
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ReplacementForm;
