import React, { Fragment, useContext, useState, useEffect } from "react";
import {
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Grid,
  TextField,
  DialogActions,
  DialogContent,
} from "@mui/material";
import "./annuityApplication.css";
import { Context as UserLoaderContext } from "../../context/userLoaderContext";
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import {
  GetAnnuityApplicationAPI,
  GetSingleAnnuityApplicationAPI,
  CopyApplicationFormAPI,
  CopyApplicationFormV2API
} from "../../api/getAnnuityApplicationAPI";
import { Context as ApplicationFormContext } from "../../context/V3/newApplicationFormContext";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Popup from "../product/pdfCondfig/popup";
import { GetIaaAppListAPI } from "../../api/V3/iaaApplicationAPI";

const AnnuityApplication = (props) => {
  const navigate = useNavigate();

  const [applications, setApplications] = useState([]);
  const [defaultApplications, setDefaultApplications] = useState([]);
  const { SetFlagLoader, SetUserType } = useContext(UserLoaderContext);
  const { state } = useContext(UserLoaderContext);
  const [userType, setUserType] = useState();
  const [openCopyAlert, setOpenCopyAlert] = useState({
    flag: false,
    application_id: "",
    flag_new_application: true
  });
  useEffect(() => {
    SetFlagLoader(false);
  }, [applications]);
  useEffect(() => {
    SetFlagLoader(true);
  }, []);
  useEffect(() => {
    (async () => {
      SetFlagLoader(true);
      await GetIaaAppListAPI(
        (res) => {
          if (res.data.status === true) {
            let sortedData = res.data?.data?.sort((a, b) => new Date(b.insurance_updated_at) - new Date(a.insurance_updated_at));
            setApplications(sortedData);
            setDefaultApplications(sortedData);
            SetFlagLoader(false);
            console.log("Printing context user type", state);
            setUserType(state.userType);
          } else {
            SetFlagLoader(false);
          }
        },
        (err) => {
          if (err?.response?.status === 401) {
            window.location.reload();
          }
        }
      );
    })();
  }, []);

  const ViewApplication = (id, flag) => {
    navigate(`/myga_application?id=${id}&flag_new_application=${flag}`);
  };

  useEffect(() => {
    console.log("Printing user type in annuity application", userType);
  }, [userType]);

  const searchAnnuitant = (value) => {
    if (!value) {
      setApplications(defaultApplications);
    } else {
      var filteredData = defaultApplications.filter((row) => row?.annuitant_name?.toLowerCase()?.indexOf(value.toLowerCase()) > -1 || row?.application_submitted_by_fullname.toLowerCase().indexOf(value.toLowerCase()) > -1)
      setApplications(filteredData);
    }
  }

  const CopyApplicationPopUp = () => {
    return (
      <>
        <DialogContent className="edit_pdf_popup2">
          <>
            <Grid item xs={12}>
              <h4
                style={{
                  color: "#D9A22E",
                }}
              >
                Confirmation
              </h4>

            </Grid>
            <Grid item xs={12}>
              <WarningRoundedIcon sx={{ fontSize: "80px", color: "#d9a22e" }} />
            </Grid>
            <Grid item xs={12}>
              <span>Are you sure you want to copy the application?</span>
            </Grid>
            <Grid item xs={12}>
              <span className="hint_span">
                This action will create a new draft application based on the current one. Please confirm your selection.
              </span>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              SetFlagLoader(true);
              let obj = {
                application_id: openCopyAlert.application_id
              }

              if (openCopyAlert.flag_new_application) {

                CopyApplicationFormV2API(obj, (res) => {
                  if (res?.data?.status) {
                    let application_id = res?.data?.data[0]?.application_id
                    navigate(`/myga_application?id=${application_id}&flag_new_application=${openCopyAlert.flag_new_application}`);
                  } else {
                    setOpenCopyAlert({
                      flag: false,
                      application_id: "",
                      flag_new_application: true
                    });
                    SetFlagLoader(false);
                    return;
                  }
                }, (err) => {
                  setOpenCopyAlert({
                    flag: false,
                    application_id: "",
                    flag_new_application: true
                  });
                  console.log("copy err", err)
                  SetFlagLoader(false);
                })
              } else {
                CopyApplicationFormAPI(obj, (res) => {
                  if (res?.data?.status) {
                    let application_id = res?.data?.data[0]?.application_id
                    navigate(`/myga_application?id=${application_id}&flag_new_application=${openCopyAlert.flag_new_application}`);
                  } else {
                    setOpenCopyAlert({
                      flag: false,
                      application_id: "",
                      flag_new_application: true
                    });
                    SetFlagLoader(false);
                    return;
                  }
                }, (err) => {
                  setOpenCopyAlert({
                    flag: false,
                    application_id: "",
                    flag_new_application: true
                  });
                  console.log("copy err", err)
                  SetFlagLoader(false);
                })
              }

            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setOpenCopyAlert({
                flag: false,
                application_id: "",
                flag_new_application: true
              });
            }}
          >
            No
          </Button>
        </DialogActions>
      </>
    );
  };


  return (
    <Fragment>

      {openCopyAlert.flag && <Popup open={openCopyAlert.flag} data={CopyApplicationPopUp()} />}
      <Box className="annuity_application_page">
        <Box className="page_title_annuity_application page_title">
          <Typography variant="span">E-App History</Typography>
        </Box>
        <Box
          className="annuity_application_table_root"
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
          }}
        >
          <Grid container marginTop="20px" alignItems="end">
            <Grid item xs={6}>
              <TextField
                className="annuitySearchBarTextField"
                placeholder="Search..."
                inputProps={{
                  autoComplete: 'off'
                }}
                autoFocus={false}
                type="text"
                onChange={(e) => {
                  searchAnnuitant(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={6} textAlign="end">
              <Button
                onClick={() => {
                  let domain = window.location.host;
                  let protocol = window.location.protocol;
                  window.location.replace(`${protocol}//${domain}/myga_application`);
                }}
                style={{
                  textTransform: "capitalize",
                  background: "#D9A22E ",
                  color: "#fff",
                }}
              >
                New Application
              </Button>
            </Grid>
          </Grid>
          <TableContainer sx={{ maxHeight: 700, marginTop: "10px" }} >
            <Table className="annuity_application_table" stickyHeader>
              <TableHead className="annuity_application_table_head">
                <TableRow>
                  <TableCell>Action</TableCell>
                  <TableCell>Annuitant Name</TableCell>
                  {state.userType === "Admin" && (
                    <TableCell>Submitted By</TableCell>
                  )}
                  {state.userType === "Admin" && (
                    <TableCell>Agent Email</TableCell>
                  )}
                  <TableCell>Submitted Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Copy Application</TableCell>
                  <TableCell>SSN/TIN</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applications.length > 0 ?
                  applications.map((row, i) => {
                    return (
                      <TableRow
                        key={i}
                        className="annuity_application_table_data"
                      >
                        <TableCell className="annuity_application_view_button">
                          <Button
                            sx={{ minWidth: "84px" }}
                            onClick={() => { ViewApplication(row.applications_id, row.flag_new_application) }}
                          >
                            {(row?.application_status_name === "Submitted for Signature" && row.flag_new_application === true)
                              ? "View/Edit"
                              : "View"}
                          </Button>
                        </TableCell>
                        <TableCell>{row?.annuitant_name}</TableCell>
                        {state.userType === "Admin" && (
                          <TableCell>
                            {row?.application_submitted_by_fullname}
                          </TableCell>
                        )}
                        {state.userType === "Admin" && (
                          <TableCell>
                            {row?.application_submitted_by_email}
                          </TableCell>
                        )}
                        <TableCell>
                          {row?.insurance_updated_at ?
                            moment(row?.insurance_updated_at).format("MM-DD-YYYY")
                            :
                            moment(row?.insurance_created_at).format("MM-DD-YYYY")
                          }
                        </TableCell>
                        <TableCell>{row?.application_status_name}</TableCell>
                        <TableCell className="annuity_application_view_button">
                          {
                            row?.application_status_name === "Submitted for Signature" && row.flag_new_application === true
                              ?
                              <Button
                                sx={{ minWidth: "80px" }}
                                onClick={() => {
                                  // debugger;
                                  setOpenCopyAlert({
                                    flag: true,
                                    application_id: row.applications_id,
                                    flag_new_application: row.flag_new_application
                                  })
                                }}
                              >
                                Copy Application
                              </Button>
                              :
                              ""
                          }</TableCell>
                        <TableCell>{row?.ssn_num}</TableCell>
                      </TableRow>
                    );
                  })
                  :
                  <TableRow colspan={5}
                    className="annuity_application_table_data"
                  >
                    {state.flagLoader == false && <TableCell colSpan={state.userType === "Admin" ? 8 : 7} sx={{ textAlign: "center" }}>No Data Available</TableCell>}
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Fragment>
  );
};

export default AnnuityApplication;
