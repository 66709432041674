import React, { Fragment, useContext } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Context as ErrorValidationContext } from "../../../context/V2/errorValidationContext";

const ErrorValidationSubmitDialog = (props) => {
  const { SetPageIndex, SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );

  return (
    <Fragment>
      <Dialog
        open={true}
        onClose={() => {
          props.closeDialog();
        }}
        style={{ overflowX: "hidden" }}
      >
        <DialogTitle>
          <Typography style={{ color: "red" }} variant="span">
            Error: Below fields can't be empty
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TableContainer style={{ minHeight: "300px", maxHeight: "450px" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component={"th"}>Page</TableCell>
                  <TableCell component={"th"}>Section</TableCell>
                  <TableCell component={"th"}>Field</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.tableData.map((obj) => {
                  return (
                    <TableRow
                      onClick={() => {
                        // debugger;
                        if (obj.page === "Individual Annuity Application") {
                          SetPageIndex(1);
                          SetApplicationFormFlagSectionData({
                            type: "Individual Annuity Application",
                            section: "flagFirstPageFirstSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Annuity Application",
                            section: "flagFirstPageSecondSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Annuity Application",
                            section: "flagFirstPageThirdSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Annuity Application",
                            section: "flagFirstPageFourthSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Annuity Application",
                            section: "flagFirstPageFifthSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Annuity Application",
                            section: "flagFirstPageSixthSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Annuity Application",
                            section: "flagFirstPageSeventhSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Annuity Application",
                            section: "flagFirstPageEighthSection",
                            data: false,
                          });
                          switch (obj.section) {
                            case "1": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Annuity Application",
                                section: "flagFirstPageFirstSection",
                                data: true,
                              });
                              break;
                            }

                            case "2": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Annuity Application",
                                section: "flagFirstPageSecondSection",
                                data: true,
                              });
                              break;
                            }

                            case "3": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Annuity Application",
                                section: "flagFirstPageThirdSection",
                                data: true,
                              });
                              break;
                            }

                            case "4": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Annuity Application",
                                section: "flagFirstPageFourthSection",
                                data: true,
                              });
                              break;
                            }

                            case "5": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Annuity Application",
                                section: "flagFirstPageFifthSection",
                                data: true,
                              });
                              break;
                            }

                            case "6": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Annuity Application",
                                section: "flagFirstPageSixthSection",
                                data: true,
                              });
                              break;
                            }

                            case "7": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Annuity Application",
                                section: "flagFirstPageSeventhSection",
                                data: true,
                              });
                              break;
                            }

                            case "8": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Annuity Application",
                                section: "flagFirstPageEighthSection",
                                data: true,
                              });
                              break;
                            }
                          }
                          props.closeDialog();
                        } else if (obj.page === "Individual Fixed Index Annuity Application") {
                          SetPageIndex(1);
                          SetApplicationFormFlagSectionData({
                            type: "Individual Fixed Index Annuity Application",
                            section: "flagFirstPageFirstSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Fixed Index Annuity Application",
                            section: "flagFirstPageSecondSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Fixed Index Annuity Application",
                            section: "flagFirstPageThirdSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Fixed Index Annuity Application",
                            section: "flagFirstPageFourthSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Fixed Index Annuity Application",
                            section: "flagFirstPageFifthSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Fixed Index Annuity Application",
                            section: "flagFirstPageSixthSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Fixed Index Annuity Application",
                            section: "flagFirstPageSeventhSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Individual Fixed Index Annuity Application",
                            section: "flagFirstPageEighthSection",
                            data: false,
                          });
                          switch (obj.section) {
                            case "1": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Fixed Index Annuity Application",
                                section: "flagFirstPageFirstSection",
                                data: true,
                              });
                              break;
                            }

                            case "2": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Fixed Index Annuity Application",
                                section: "flagFirstPageSecondSection",
                                data: true,
                              });
                              break;
                            }

                            case "3": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Fixed Index Annuity Application",
                                section: "flagFirstPageThirdSection",
                                data: true,
                              });
                              break;
                            }

                            case "4": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Fixed Index Annuity Application",
                                section: "flagFirstPageFourthSection",
                                data: true,
                              });
                              break;
                            }

                            case "5": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Fixed Index Annuity Application",
                                section: "flagFirstPageFifthSection",
                                data: true,
                              });
                              break;
                            }

                            case "6": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Fixed Index Annuity Application",
                                section: "flagFirstPageSixthSection",
                                data: true,
                              });
                              break;
                            }

                            case "7": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Fixed Index Annuity Application",
                                section: "flagFirstPageSeventhSection",
                                data: true,
                              });
                              break;
                            }

                            case "8": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Fixed Index Annuity Application",
                                section: "flagFirstPageEighthSection",
                                data: true,
                              });
                              break;
                            }
                            case "9": {
                              SetApplicationFormFlagSectionData({
                                type: "Individual Fixed Index Annuity Application",
                                section: "flagFirstPageNinthSection",
                                data: true,
                              });
                              break;
                            }
                          }
                          props.closeDialog();
                        } else if (obj.page === "Suitability Analysis") {
                          SetPageIndex(3);

                          SetApplicationFormFlagSectionData({
                            type: "Suitability Analysis",
                            section: "flagSecondPageFirstSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Suitability Analysis",
                            section: "flagSecondPageSecondSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Suitability Analysis",
                            section: "flagSecondPageThirdSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Suitability Analysis",
                            section: "flagSecondPageFourthSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Suitability Analysis",
                            section: "flagSecondPageFifthSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Suitability Analysis",
                            section: "flagSecondPageSixthSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Suitability Analysis",
                            section: "flagSecondPageSeventhSection",
                            data: false,
                          });

                          switch (obj.section) {
                            case "1": {
                              SetApplicationFormFlagSectionData({
                                type: "Suitability Analysis",
                                section: "flagSecondPageFirstSection",
                                data: true,
                              });
                              break;
                            }

                            case "2": {
                              SetApplicationFormFlagSectionData({
                                type: "Suitability Analysis",
                                section: "flagSecondPageSecondSection",
                                data: true,
                              });
                              break;
                            }

                            case "3": {
                              SetApplicationFormFlagSectionData({
                                type: "Suitability Analysis",
                                section: "flagSecondPageThirdSection",
                                data: true,
                              });
                              break;
                            }

                            case "4": {
                              SetApplicationFormFlagSectionData({
                                type: "Suitability Analysis",
                                section: "flagSecondPageFourthSection",
                                data: true,
                              });
                              break;
                            }

                            case "5": {
                              SetApplicationFormFlagSectionData({
                                type: "Suitability Analysis",
                                section: "flagSecondPageFifthSection",
                                data: true,
                              });
                              break;
                            }

                            case "6": {
                              SetApplicationFormFlagSectionData({
                                type: "Suitability Analysis",
                                section: "flagSecondPageSixthSection",
                                data: true,
                              });
                              break;
                            }

                            case "7": {
                              SetApplicationFormFlagSectionData({
                                type: "Suitability Analysis",
                                section: "flagSecondPageSeventhSection",
                                data: true,
                              });
                              break;
                            }
                          }

                          props.closeDialog();
                        } else if (obj.page === "Notice of Replacement") {
                          SetPageIndex(2);

                          SetApplicationFormFlagSectionData({
                            type: "Notice of Replacement",
                            section: "flagReplacementPageFirstSection",
                            data: false,
                          });

                          SetApplicationFormFlagSectionData({
                            type: "Notice of Replacement",
                            section: "flagReplacementPageSecondSection",
                            data: false,
                          });

                          switch (obj.section) {
                            case "1": {
                              SetApplicationFormFlagSectionData({
                                type: "Notice of Replacement",
                                section: "flagReplacementPageFirstSection",
                                data: true,
                              });
                              break;
                            }

                            case "2": {
                              SetApplicationFormFlagSectionData({
                                type: "Notice of Replacement",
                                section: "flagReplacementPageSecondSection",
                                data: true,
                              });
                              break;
                            }
                          }
                          props.closeDialog();
                        } else if (obj.page === "Transfer or Exchange Request") {
                          SetPageIndex(4);

                          SetApplicationFormFlagSectionData({
                            type: "Transfer or Exchange Request",
                            section: "flagFourthPageFirstSection",
                            data: false,
                          });
                          SetApplicationFormFlagSectionData({
                            type: "Transfer or Exchange Request",
                            section: "flagFourthPageSecondSection",
                            data: false,
                          });
                          switch (obj.section) {
                            case "1": {
                              SetApplicationFormFlagSectionData({
                                type: "Transfer or Exchange Request",
                                section: "flagFourthPageFirstSection",
                                data: true,
                              });
                              break;
                            }

                            case "2": {
                              SetApplicationFormFlagSectionData({
                                type: "Transfer or Exchange Request",
                                section: "flagFourthPageSecondSection",
                                data: true,
                              });
                              break;
                            }
                            case "3": {
                              SetApplicationFormFlagSectionData({
                                type: "Transfer or Exchange Request",
                                section: "flagFourthPageThirdSection",
                                data: true,
                              });
                              break;
                            }
                          }
                          props.closeDialog();
                        } else if (obj.page === "Annuity Disclosure") {
                          SetPageIndex(6);
                          SetApplicationFormFlagSectionData({
                            type: "Annuity Disclosure",
                            section: "flagFirstPageFirstSection",
                            data: false,
                          });
                          props.closeDialog();
                        }
                      }}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell component={"td"}>{obj?.page}</TableCell>
                      <TableCell component={"td"}>{obj?.section}</TableCell>
                      <TableCell
                        style={{ textDecoration: "underline", color: "blue" }}
                        component={"td"}
                      >
                        {obj?.field}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              props.closeDialog();
            }}
            style={{ color: "#fff", textTransform: "capitalize" }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ErrorValidationSubmitDialog;
