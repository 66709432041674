import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import "./fixedIndexSiteMap.css";
import URLToSendApplicantDialog from "../../urlToSendApplicantDialog/urlToSendApplicantDialog";
import GeneratePublicURLAPI from "../../../api/generatePublicURL";
import { Context } from "../../../context/userLoaderContext";
import { Context as ApplicationFormContext } from "../../../context/V3/newApplicationFormContext";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { Context as ErrorValidationContext } from "../../../context/V3/errorValidationContext";
import { isValidEmail, isValidNPN, isValidWebsite, isValidZip, validatePhoneNumberLatest } from "../../../utils/constants";

const FixedIndexSiteMap = (props) => {
    const [flagUrlToSendApplicantDialog, setFlagUrlToSendApplicantDialog] =
        useState(false);
    const { SetFlagLoader } = useContext(Context);
    const [uniqueURLId, setUniqueURLId] = useState("");
    const [stopInterval, setStopInterval] = useState(true);
    const [replacementFormName, setReplacementFormName] = useState(null);

    const {
        individualFixedIndexAnnuityApplication,
        suitabilityAnalysis,
        transferOrExchangeRequest,
        noticeOfReplacement,
        // bankDetails,
        applicationId,
        annuityDisclosureForm,
        annuityDisclosureFormV2,
        harvestDisclosureForm,
        accountAllocationArray,
        rolloverTransferExchange,
        transferExchangeVersion,
        fiaDisclosureVersion,
        producerDisclosureForm,
        flagShowProducerDisclosure
    } = useContext(ApplicationFormContext).state;

    const [ownerSectionColor, setOwnerSectionColor] = useState();
    const [jointOwnerSectionColor, setJointOwnerSectionColor] = useState();
    const [annuitantSectionColor, setAnnuitantSectionColor] = useState();
    const [joitAnnuitantSectionColor, setJoitAnnuitantSectionColor] = useState();
    const [planRiderSectionColor, setPlanRiderSectionColor] = useState();
    const [initialAccountSectionColor, setInitialAccountSectionColor] = useState();
    const [beneficiariesSectionColor, setBeneficiariesSectionColor] = useState();
    const [premimumTaxQualificationSectionColor, setPremiumTaxQualificationSectionColor] = useState();
    const [producerDeclarationSectionColor, setProducerDeclarationSectionColor] = useState(0);
    const [safeguardPlusAnnuityPeriod, setSafeguardPlusAnnuityPeriod] = useState();

    const [personalInformationColor, setPersonalInformationColor] = useState();
    const [financialInformationColor, setFinancialInformationColor] = useState();
    const [financialSituationColor, setFinancialSituationColor] = useState();
    const [productPurchaseColor, setProductPurchaseColor] = useState();
    const [existingAccountInformationColor, setExistingAccountInformationColor] = useState();
    const [otherSectionColor, setOtherSectionColor] = useState();
    const [agentAcknowledgmentColor, setAgentAcknowledgmentColor] = useState();

    const [basicInformationColor, setBasicInformationColor] = useState();
    const [plansSectionColor, setPlansSectionColor] = useState();
    const [accountInformationColor, setAccountInformationColor] = useState();
    const [rolloverPlanColor, setRolloverPlanColor] = useState();
    const [signatureOptions, setSignatureOptions] = useState();
    const [witnessInformationColor, setWitnessInformationColor] = useState();

    const [importantNoticeColor, setImportantNoticeColor] = useState();
    const [brokerColor, setBrokerColor] = useState();

    // const [bankDetailsColor, setBankDetailsColor] = useState();

    const [annuityDisclosureColor, setAnnuityDisclosureColor] = useState();
    const [producerDisclosureSectionColor, setProducerDisclosureSectionColor] = useState({
        producerInfoSection: null,
        customerInfoSection: null,
        typeOfProductSection: null,
        annuityAuthorizedSection: null,
        paidForWorkSection: null
    })
    const { SetApplicationFormFlagSectionData, SetPageIndex } = useContext(
        ErrorValidationContext
    );

    let domain = window.location.host;
    let protocol = window.location.protocol;


    useEffect(() => {
        if (
            stopInterval === false &&
            applicationId !== null &&
            applicationId !== undefined &&
            applicationId !== ""
        ) {
            setStopInterval(true);
            SetFlagLoader(true);
            GeneratePublicURLAPI(
                applicationId,
                (res) => {
                    SetFlagLoader(false);

                    if (res.data.status === true) {
                        setUniqueURLId(res.data.data.unique_id_public_access);
                        setFlagUrlToSendApplicantDialog(true);
                    }
                },
                (err) => {
                    SetFlagLoader(false);
                }
            );
        }
    }, [applicationId, stopInterval]);

    const {
        ownerFullName,
        ownerSSN,
        ownerGender,
        ownerDOB,
        ownerStreetAddress,
        ownerZip,
        ownerCity,
        ownerState,
        ownerEmail,
        ownerRelationToAnnuitant,
        ownerPhone,

        jointOwnerFullName,
        jointOwnerSSN,
        jointOwnerGender,
        jointOwnerDOB,
        jointOwnerStreetAddress,
        jointOwnerZip,
        jointOwnerCity,
        jointOwnerState,
        jointOwnerEmail,
        // jointOwnerRelationToOwner,

        annuitantFullName,
        annuitantSSN,
        annuitantGender,
        annuitantDOB,
        annuitantStreetAddress,
        annuitantZip,
        annuitantCity,
        annuitantState,
        annuitantEmail,
        annuitantRelationToOwner,
        annuitantPhone,

        jointAnnuitantFullName,
        jointAnnuitantSSN,
        jointAnnuitantGender,
        jointAnnuitantDOB,
        jointAnnuitantStreetAddress,
        jointAnnuitantZip,
        jointAnnuitantCity,
        jointAnnuitantState,
        jointAnnuitantEmail,
        jointAnnuitantRelationToAnnuitant,


        planHarvest,
        ridersPlan,
        initialAccountAllocation,
        beneficiaries,
        flagNonQualifiedSelected,
        selectedNonQualifiedOptions,
        flagQualifiedSelected,
        selectedQualifiedOptions,
        selectedQualifiedOptionsGroup2,
        otherSelectedQualifiedOptions,
        stateToShowNoticeOfReplacement,
        flagQualifiedOrNonQualifiedSelected,
        amountPaidWithApplication,
        expectedPremiumField,
        flagHaveExistingLifeInsurance,
        reasonOfApplicationToChangeLifeInsurance,
        flagSafeguardPlusAnnuityPeriod
    } = individualFixedIndexAnnuityApplication;

    const {
        // ownerFullName,
        ownerCurrentAge,
        // ownerGender,
        ownerEmploymentStatus,
        ownerCurrentOccupation,
        ownerDependents,
        spouseFullName,
        spouseCurrentAge,
        spouseGender,
        spouseEmploymentStatus,
        spouseCurrentOccupation,
        spouseDependents,
        ownerAnnualHouseholdIncome,
        ownerSourceOfIncome,
        ownerIncomeTaxBracket,
        ownerTotalOutstandingDebt,
        ownerTotalAssets,
        ownerTotalLiquidAssets,
        flagOwnerIncomeCoverLivingAndMedicalExpenses,
        explainOwnerIncomeCoverLivingAndMedicalExpenses,
        flagOwnerExpectChangesInLivingExpenses,
        explainOwnerExpectChangesInLivingExpenses,
        flagOwnerAnticipateChangesInMedicalExpenses,
        explainOwnerAnticipateChangesInMedicalExpenses,
        flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
        explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
        flagOwnerHaveEmergencyFundForUnexpectedExpenses,
        explainOwnerHaveEmergencyFundForUnexpectedExpenses,
        ownerOtherInvestments,
        otherOwnerInvestmentField,
        ownerRiskTolerance,
        reasonForPurchasingProduct,
        otherRasonForPurchasingThisProduct,
        flagOwnerPlanToWithdrawFundInSurrenderPeriod,
        explainOwnerPlanToWithdrawFundInSurrenderPeriod,
        ownerAnticipateNeedingAccessToFunds,
        sourceOfFundsToPurchaseThisProduct,
        otherSourceOfFundsToPurchaseThisProduct,
        flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct,
        howLongHasTheLifeInsuranceBeenInForce,
        flagSurrenderChargeOnExistingProduct,
        currentSurrenderChargeOnExistingProduct,
        expirationOfCurrentSurrenderCharge,
        typeOfExistingProduct,
        interestRateForExistingProduct,
        flagTakingRequiredMinimumDistributionsFromExistingProduct,
        flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
        explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
        personsThatAssistedOwnerToPurchaseThisProduct,
        otherPersonsThatAssistedOwnerToPurchaseThisProduct,
        additionalRelaventInformationForSuitabilityAnalysis,
        agentAcknowledgment,
    } = suitabilityAnalysis;

    const {
        // ownerFullName,
        // ownerSSN,
        // ownerStreetAddress,
        // ownerZip,
        // ownerCity,
        // ownerState,
        annuitantOrInsured,
        currentInsuranceCompany,
        currentInsuranceCompanyPhone,
        currentInsuranceCompanyAccountNumber,
        currentInsuranceCompanyStreetAddress,
        currentInsuranceCompanyZip,
        currentInsuranceCompanyCity,
        currentInsuranceCompanyState,
        typeOfAccount,
        otherTypeOfAccount,
        ownerExistingAnnuity,
        plans,
        flagNonQualifiedOwnerWantWithdrawalToExchange,
        explainNonQualifiedOwnerWantWithdrawalToExchange,
        flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
        qualifiedTransferOrExchangeExistingAccountAllOrPartial,
        qualifiedTransferOrExchangeExistingAccountPartialAmount,
        qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
        qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField,
        qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
        qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField,
        qualifiedTypeOfTransfer,
        qualifiedQualifyingEventForDirectRolloverFunds,
        flagQualifiedOwnerReachedAge72OrMoreInThisYear,
        flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
        qualifiedInternalExchangeOfExistingAnnuityName,
        flagQualifiedOwnerWantRMDBeforeCompletingExchange,
        flagQualifiedOwnerWantToContinueExistingRMDSchedule,
        flagSignatureDigitalOrPhysical,
        witnessName,
        witnessEmail,
    } = transferOrExchangeRequest;

    const {
        flagDiscontinuingPremiumPayment,
        flagPayForPremiumPolicy,
        insurerPolicyDetailFieldArray,
        reasonForReplacement
    } = noticeOfReplacement;

    const { flagAnnuityClosure } = annuityDisclosureForm;
    const { flagAnnuityClosureV2 } = annuityDisclosureFormV2;
    const { flagHarvestClosure } = harvestDisclosureForm;

    const IndividualAnnuityApplicationSuccess = () => {
        if (
            ownerSectionColor === 3 &&
            (jointOwnerSectionColor === 1 || jointOwnerSectionColor === 3) &&
            (annuitantSectionColor === 1 || annuitantSectionColor === 3) &&
            planRiderSectionColor === 3 &&
            (beneficiariesSectionColor === 1 || beneficiariesSectionColor === 3) &&
            premimumTaxQualificationSectionColor === 3 &&
            safeguardPlusAnnuityPeriod === 3
        ) {
            return true;
        } else {
            return false;
        }
    };
    const producerDisclosureSuccess = () => {
        if (producerDisclosureSectionColor.producerInfoSection === 3 && producerDisclosureSectionColor.customerInfoSection === 3 && producerDisclosureSectionColor.typeOfProductSection === 3 && producerDisclosureSectionColor.annuityAuthorizedSection === 3 && producerDisclosureSectionColor.paidForWorkSection === 3) {
            return true
        } else {
            return false
        }
    }
    const [annuityDisclosureStyle, setAnnuityDisclosureStyle] = useState({
        top: "1095px",
    });

    useEffect(() => {
        if (ConditionToShowReplacementForm() === true) {
            if (transferExchangeVersion === 7) {
                setAnnuityDisclosureStyle({
                    top: "1180px",
                });
            } else {
                setAnnuityDisclosureStyle({
                    top: "1215px",
                });
            }
        } else {
            if (transferExchangeVersion === 7) {
                setAnnuityDisclosureStyle({
                    top: "1060px",
                });
            } else {
                setAnnuityDisclosureStyle({
                    top: "1095px",
                });
            }
        }
    }, [
        stateToShowNoticeOfReplacement,
        ownerState
    ]);
    const SuitabilityAnalysisSuccess = () => {
        if (
            personalInformationColor === 3 &&
            financialInformationColor === 3 &&
            financialSituationColor === 3 &&
            productPurchaseColor === 3 &&
            existingAccountInformationColor === 3 &&
            otherSectionColor === 3 &&
            agentAcknowledgmentColor === 3
        ) {
            return true;
        } else {
            return false;
        }
    };

    const NoticeOfReplacementSuccess = () => {
        if (importantNoticeColor === 3) {
            return true;
        } else {
            return false;
        }
    };

    const TransferExchangeRequestSuccess = () => {
        if (transferExchangeVersion === 7) {
            if (accountInformationColor === 3 && rolloverPlanColor === 3) {
                return true;
            } else {
                return false;
            }
        } else {
            if (basicInformationColor === 3 && plansSectionColor === 3 && signatureOptions === 3) {
                return true;
            } else {
                return false;
            }
        }
    };

    const AnnuityDisclosureSuccess = () => {
        if (annuityDisclosureColor === 3) {
            return true;
        } else {
            return false;
        }
    };

    const CommonPlansQuestionValidation = () => {
        if (qualifiedTransferOrExchangeExistingAccountAllOrPartial !== "" &&
            (qualifiedTransferOrExchangeExistingAccountAllOrPartial === "partials"
                ? qualifiedTransferOrExchangeExistingAccountPartialAmount !== ""
                : true) &&
            qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate !==
            "" &&
            (qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate ===
                "upon the maturity date of"
                ? qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
                ""
                : true) &&
            qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy !==
            "" &&
            (qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy ===
                "to existing policy/account"
                ? qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
                ""
                : true)) {
            return true
        } else {
            return false
        }
    }
    const producerDeclarationsArray = [
        {
            key: "A",
            producerState: "producerStateA",
            applicantState: "applicantStateA",
        },
        {
            key: "B",
            producerState: "producerStateB",
            applicantState: "applicantStateB",
        },
        {
            key: "C",
            producerState: "producerStateC",
            applicantState: "applicantStateC",
        },
        {
            key: "D",
            producerState: "producerStateD",
            applicantState: "applicantStateD",
        },
        {
            key: "E",
            producerState: "producerStateE",
            applicantState: "applicantStateE",
        },
        {
            key: "F",
            producerState: "producerStateF",
            applicantState: "applicantStateF",
        },
        {
            key: "G",
            producerState: "producerStateG",
            applicantState: "applicantStateG",
        }
    ]

    useEffect(() => {
        const re =
            /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        // Annuitant Checking Start
        const annuitantDate = moment(annuitantDOB);

        if (
            annuitantFullName !== "" &&
            annuitantSSN !== "" &&
            // annuitantGender !== "" &&
            // annuitantDate.isValid() === true &&
            annuitantStreetAddress !== "" &&
            annuitantZip !== "" &&
            annuitantCity !== "" &&
            annuitantState !== "" &&
            annuitantRelationToOwner !== "" &&
            annuitantEmail !== "" &&
            annuitantPhone !== ""
        ) {
            setAnnuitantSectionColor(3);
        } else if (
            annuitantFullName === "" ||
            annuitantSSN === "" ||
            annuitantGender === "" ||
            new Date(annuitantDOB).toDateString() === "Invalid Date" ||
            annuitantStreetAddress === "" ||
            annuitantZip === "" ||
            annuitantCity === "" ||
            annuitantState === "" ||
            annuitantRelationToOwner === "" ||
            annuitantPhone === "" ||
            re.test(annuitantEmail) === false
        ) {
            setAnnuitantSectionColor(0);
        }

        // Annuitant Checking End

        // Owner Checking Start

        if (
            ownerFullName === "" &&
            ownerSSN === "" &&
            ownerGender === "" &&
            (ownerDOB === "" || ownerDOB === null) &&
            ownerStreetAddress === "" &&
            ownerZip === "" &&
            ownerCity === "" &&
            ownerState === "" &&
            ownerRelationToAnnuitant === "" &&
            ownerEmail === "" &&
            ownerPhone === ""
        ) {
            setOwnerSectionColor(1);
        } else if (
            ownerFullName !== "" &&
            ownerSSN !== "" &&
            // ownerGender !== "" &&
            // new Date(ownerDOB).toDateString() !== "Invalid Date" &&
            ownerStreetAddress !== "" &&
            ownerZip !== "" &&
            ownerCity !== "" &&
            ownerState !== "" &&
            ownerRelationToAnnuitant !== "" &&
            ownerEmail !== "" &&
            ownerPhone !== ""
        ) {
            setOwnerSectionColor(3);
        } else if (
            ownerFullName === "" ||
            ownerSSN === "" ||
            ownerGender === "" ||
            new Date(ownerDOB).toDateString() === "Invalid Date" ||
            ownerStreetAddress === "" ||
            ownerZip === "" ||
            ownerCity === "" ||
            ownerState === "" ||
            ownerRelationToAnnuitant === "" ||
            re.test(ownerEmail) === false
        ) {
            setOwnerSectionColor(0);
        }

        // Owner Checking End

        // Joint Owner Checking Start

        if (
            jointOwnerFullName === "" &&
            jointOwnerSSN === "" &&
            jointOwnerGender === "" &&
            (jointOwnerDOB === "" || jointOwnerDOB === null) &&
            jointOwnerStreetAddress === "" &&
            jointOwnerZip === "" &&
            jointOwnerCity === "" &&
            jointOwnerState === "" &&
            // jointOwnerRelationToOwner === "" &&
            jointOwnerEmail === ""
        ) {
            setJointOwnerSectionColor(1);
        } else if (
            jointOwnerFullName !== "" &&
            jointOwnerSSN !== "" &&
            // jointOwnerGender !== "" &&
            // new Date(jointOwnerDOB).toDateString() !== "Invalid Date" &&
            jointOwnerStreetAddress !== "" &&
            jointOwnerZip !== "" &&
            jointOwnerCity !== "" &&
            jointOwnerState !== "" &&
            // jointOwnerRelationToOwner !== "" &&
            jointOwnerEmail !== ""
        ) {
            setJointOwnerSectionColor(3);
        } else if (
            jointOwnerFullName === "" ||
            jointOwnerSSN === "" ||
            jointOwnerGender === "" ||
            new Date(ownerDOB).toDateString() === "Invalid Date" ||
            jointOwnerStreetAddress === "" ||
            jointOwnerZip === "" ||
            jointOwnerCity === "" ||
            jointOwnerState === "" ||
            // jointOwnerRelationToOwner === "" ||
            re.test(jointOwnerEmail === "")
        ) {
            setJointOwnerSectionColor(0);
        }

        // Joint Owner Checking End


        // Joint Annuitant Checking start
        if (
            jointAnnuitantFullName === "" &&
            jointAnnuitantSSN === "" &&
            jointAnnuitantGender === "" &&
            (jointAnnuitantDOB === "" || jointAnnuitantDOB === null) &&
            jointAnnuitantStreetAddress === "" &&
            jointAnnuitantZip === "" &&
            jointAnnuitantCity === "" &&
            jointAnnuitantState === "" &&
            jointAnnuitantRelationToAnnuitant === "" &&
            jointAnnuitantEmail === ""
        ) {
            setJoitAnnuitantSectionColor(1);
        } else if (
            jointAnnuitantFullName !== "" &&
            jointAnnuitantSSN !== "" &&
            // jointAnnuitantGender !== "" &&
            // new Date(jointAnnuitantDOB).toDateString() !== "Invalid Date" &&
            jointAnnuitantStreetAddress !== "" &&
            jointAnnuitantZip !== "" &&
            jointAnnuitantCity !== "" &&
            jointAnnuitantState !== "" &&
            jointAnnuitantRelationToAnnuitant !== "" &&
            jointAnnuitantEmail !== ""
        ) {
            setJoitAnnuitantSectionColor(3);
        } else if (
            jointAnnuitantFullName === "" ||
            jointAnnuitantSSN === "" ||
            jointAnnuitantGender === "" ||
            new Date(ownerDOB).toDateString() === "Invalid Date" ||
            jointAnnuitantStreetAddress === "" ||
            jointAnnuitantZip === "" ||
            jointAnnuitantCity === "" ||
            jointAnnuitantState === "" ||
            jointAnnuitantRelationToAnnuitant === "" ||
            re.test(jointAnnuitantEmail === "")
        ) {
            setJoitAnnuitantSectionColor(0);
        }
        // Joint Annuitant Checking end


        // Plan-Rider Applied For Checking Start
        if (planHarvest?.length > 0 && ridersPlan?.length > 0) {
            setPlanRiderSectionColor(3);
        } else {
            setPlanRiderSectionColor(0);
        }

        // Plan-Rider Applied For Checking End


        // Initial Account Allocation(s) start

        let tempPercentage = 0;
        accountAllocationArray?.map((obj) => {
            if (individualFixedIndexAnnuityApplication.initialAccountAllocation[obj.state] != "" && individualFixedIndexAnnuityApplication.initialAccountAllocation[obj.state] != -1) {
                tempPercentage += parseFloat(individualFixedIndexAnnuityApplication.initialAccountAllocation[obj.state])
            }
        })
        if (tempPercentage == 100) {
            setInitialAccountSectionColor(3)
        } else {
            setInitialAccountSectionColor(0)
        }


        // Initial Account Allocation(s) end

        // Beneficiaries Checking Start

        let flagPrimaryValuesEmpty = [];
        let flagContingentValuesEmpty = [];
        let totalPrimarySum = 0;
        let totalContingentSum = 0;

        for (let i = 0; i < beneficiaries?.length; i++) {
            if (
                beneficiaries[i].primaryOrContingent !== "" &&
                beneficiaries[i].percentageField !== ""
            ) {
                if (beneficiaries[i].primaryOrContingent == "Primary") {
                    totalPrimarySum += parseFloat(beneficiaries[i].percentageField)
                    flagPrimaryValuesEmpty.push(true)
                } else if (beneficiaries[i].primaryOrContingent == "Contingent") {
                    totalContingentSum += parseFloat(beneficiaries[i].percentageField)
                    flagContingentValuesEmpty.push(true)
                }
            } else {
                flagPrimaryValuesEmpty.push(false)
                flagContingentValuesEmpty.push(false)
            }
        }

        if ((flagPrimaryValuesEmpty?.indexOf(true) > -1 && totalPrimarySum == 100) || flagContingentValuesEmpty?.indexOf(true) > -1 && totalContingentSum == 100) {
            setBeneficiariesSectionColor(3);
        } else {
            setBeneficiariesSectionColor(1);
        }

        // Beneficiaries Checking End

        // Premium and Tax Qualification Status Checking Start

        if ((expectedPremiumField !== "" || amountPaidWithApplication !== "") && ((selectedQualifiedOptionsGroup2?.length > 0 && selectedQualifiedOptionsGroup2 !== "other") || (selectedQualifiedOptionsGroup2 == "other" && otherSelectedQualifiedOptions?.length > 0))) {
            setPremiumTaxQualificationSectionColor(3);
        } else {
            setPremiumTaxQualificationSectionColor(0);
        }

        // Premium and Tax Qualification Status Checking End


        // Applicant and Insurance Producer Declarations Checking Start

        let tempProducerFlagEmpty = []
        producerDeclarationsArray.map((ele) => {
            if (individualFixedIndexAnnuityApplication[ele.producerState] !== "" && individualFixedIndexAnnuityApplication[ele.applicantState] !== "") {
                tempProducerFlagEmpty.push(true)
            } else {
                tempProducerFlagEmpty.push(false)
            }
        })

        if (tempProducerFlagEmpty?.indexOf(false) > -1) {
            setSafeguardPlusAnnuityPeriod(0)
        } else {
            setSafeguardPlusAnnuityPeriod(3)
        }

        // Applicant and Insurance Producer Declarations Checking End



    }, [individualFixedIndexAnnuityApplication]);

    useEffect(() => {
        // Owner Personal Information Checking Start

        if (
            suitabilityAnalysis.ownerFullName === "" ||
            ownerCurrentAge === "" ||
            suitabilityAnalysis.ownerGender === "" ||
            ownerEmploymentStatus === "" ||
            ownerCurrentOccupation === "" ||
            ownerDependents === ""
        ) {
            setPersonalInformationColor(0);
        } else if (
            (suitabilityAnalysis.ownerFullName !== "" ||
                ownerCurrentAge !== "" ||
                suitabilityAnalysis.ownerGender !== "" ||
                ownerEmploymentStatus !== "" ||
                ownerCurrentOccupation !== "" ||
                ownerDependents !== "") &&
            ((spouseFullName !== "" &&
                spouseCurrentAge !== "" &&
                spouseGender !== "" &&
                spouseCurrentOccupation !== "" &&
                spouseEmploymentStatus !== "" &&
                spouseDependents !== "") ||
                (spouseFullName === "" &&
                    spouseCurrentAge === "" &&
                    spouseGender === "" &&
                    spouseCurrentOccupation === "" &&
                    spouseEmploymentStatus === "" &&
                    spouseDependents === ""))
        ) {
            setPersonalInformationColor(3);
        } else {
            setPersonalInformationColor(0);
        }

        // Owner Personal Information Checking End

        // Financial Information Checking Start

        if (
            ownerAnnualHouseholdIncome === "" ||
            ownerSourceOfIncome === "" ||
            ownerIncomeTaxBracket === "" ||
            ownerTotalOutstandingDebt === "" ||
            ownerTotalAssets === "" ||
            ownerTotalLiquidAssets === ""
        ) {
            setFinancialInformationColor(0);
        } else {
            setFinancialInformationColor(3);
        }

        // Financial Information Checking End

        // Financial Situation Checking Start

        if (flagOwnerIncomeCoverLivingAndMedicalExpenses === "") {
            setFinancialSituationColor(0);
        } else if (
            flagOwnerIncomeCoverLivingAndMedicalExpenses === "no" &&
            explainOwnerIncomeCoverLivingAndMedicalExpenses === ""
        ) {
            setFinancialSituationColor(0);
        } else if (flagOwnerExpectChangesInLivingExpenses === "") {
            setFinancialSituationColor(0);
        } else if (
            flagOwnerExpectChangesInLivingExpenses === "yes" &&
            explainOwnerExpectChangesInLivingExpenses === ""
        ) {
            setFinancialSituationColor(0);
        } else if (flagOwnerAnticipateChangesInMedicalExpenses === "") {
            setFinancialSituationColor(0);
        } else if (
            flagOwnerAnticipateChangesInMedicalExpenses === "yes" &&
            explainOwnerAnticipateChangesInMedicalExpenses === ""
        ) {
            setFinancialSituationColor(0);
        } else if (
            flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
            ""
        ) {
            setFinancialSituationColor(0);
        } else if (
            flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
            "no" &&
            explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
            ""
        ) {
            setFinancialSituationColor(0);
        } else if (flagOwnerHaveEmergencyFundForUnexpectedExpenses === "") {
            setFinancialSituationColor(0);
        } else if (
            flagOwnerHaveEmergencyFundForUnexpectedExpenses === "no" &&
            explainOwnerHaveEmergencyFundForUnexpectedExpenses === ""
        ) {
            setFinancialSituationColor(0);
        } else if (ownerOtherInvestments.length === 0) {
            setFinancialSituationColor(0);
        } else if (ownerRiskTolerance === "") {
            setFinancialSituationColor(0);
        } else {
            setFinancialSituationColor(3);
        }

        // Financial Situation Checking End

        // Product Purchase Checking Start

        if (reasonForPurchasingProduct.length === 0) {
            setProductPurchaseColor(0);
        } else if (flagOwnerPlanToWithdrawFundInSurrenderPeriod === "") {
            setProductPurchaseColor(0);
        } else if (
            flagOwnerPlanToWithdrawFundInSurrenderPeriod === "yes" &&
            explainOwnerPlanToWithdrawFundInSurrenderPeriod === ""
        ) {
            setProductPurchaseColor(0);
        } else if (ownerAnticipateNeedingAccessToFunds === "") {
            setProductPurchaseColor(0);
        } else if (sourceOfFundsToPurchaseThisProduct.length === 0) {
            setProductPurchaseColor(0);
        } else {
            setProductPurchaseColor(3);
        }

        // Product Purchase Checking End

        // Existing Account Information Checking Start

        if (
            flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct === ""
        ) {
            setExistingAccountInformationColor(0);
        } else if (
            flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct ===
            "no"
        ) {
            setExistingAccountInformationColor(3);
        } else {
            if (howLongHasTheLifeInsuranceBeenInForce === "") {
                setExistingAccountInformationColor(0);
            } else if (flagSurrenderChargeOnExistingProduct === "") {
                setExistingAccountInformationColor(0);
            } else if (
                flagSurrenderChargeOnExistingProduct === "yes" &&
                currentSurrenderChargeOnExistingProduct === "" &&
                expirationOfCurrentSurrenderCharge === ""
            ) {
                setExistingAccountInformationColor(0);
            } else if (typeOfExistingProduct === "") {
                setExistingAccountInformationColor(0);
            } else if (interestRateForExistingProduct === "") {
                setExistingAccountInformationColor(0);
            } else if (
                flagTakingRequiredMinimumDistributionsFromExistingProduct === ""
            ) {
                setExistingAccountInformationColor(0);
            } else if (
                flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution === ""
            ) {
                setExistingAccountInformationColor(0);
            } else if (
                flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution ===
                "yes" &&
                explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution ===
                ""
            ) {
                setExistingAccountInformationColor(0);
            } else {
                setExistingAccountInformationColor(3);
            }
        }

        // Existing Account Information Checking End

        // Other Section Checking Start

        if (
            personsThatAssistedOwnerToPurchaseThisProduct.length === 0 &&
            additionalRelaventInformationForSuitabilityAnalysis === ""
        ) {
            setOtherSectionColor(1);
        } else {
            setOtherSectionColor(3);
        }

        // Other Section Checking End

        // Agent Acknowledgment Checking Start

        if (agentAcknowledgment !== "" && agentAcknowledgment !== null) {
            setAgentAcknowledgmentColor(3);
        } else {
            setAgentAcknowledgmentColor(0);
        }

        // Agent Acknowledgment Checking End
    }, [suitabilityAnalysis]);

    useEffect(() => {
        // Basic Information Checking Start

        if (
            transferOrExchangeRequest.ownerFullName === "" &&
            transferOrExchangeRequest.ownerSSN === "" &&
            transferOrExchangeRequest.ownerStreetAddress === "" &&
            transferOrExchangeRequest.ownerZip === "" &&
            transferOrExchangeRequest.ownerCity === "" &&
            transferOrExchangeRequest.ownerMaritalStatus === "" &&
            transferOrExchangeRequest.ownerState === "" &&
            // annuitantOrInsured === "" &&
            currentInsuranceCompany === "" &&
            currentInsuranceCompanyAccountNumber === "" &&
            currentInsuranceCompanyPhone === "" &&
            currentInsuranceCompanyStreetAddress === "" &&
            currentInsuranceCompanyCity === "" &&
            currentInsuranceCompanyZip === "" &&
            currentInsuranceCompanyState === "" &&
            typeOfAccount === "" &&
            ownerExistingAnnuity === ""
        ) {
            setBasicInformationColor(0);
        } else if (
            transferOrExchangeRequest.ownerFullName !== "" &&
            transferOrExchangeRequest.ownerSSN !== "" &&
            transferOrExchangeRequest.ownerStreetAddress !== "" &&
            transferOrExchangeRequest.ownerZip !== "" &&
            transferOrExchangeRequest.ownerCity !== "" &&
            transferOrExchangeRequest.ownerMaritalStatus !== "" &&
            transferOrExchangeRequest.ownerState !== "" &&
            currentInsuranceCompany !== "" &&
            currentInsuranceCompanyAccountNumber !== "" &&
            currentInsuranceCompanyPhone !== "" &&
            currentInsuranceCompanyStreetAddress !== "" &&
            currentInsuranceCompanyCity !== "" &&
            currentInsuranceCompanyZip !== "" &&
            currentInsuranceCompanyState !== "" &&
            typeOfAccount !== "" &&
            ownerExistingAnnuity !== ""
        ) {
            setBasicInformationColor(3);
        } else {
            setBasicInformationColor(0);
        }

        // Basic Information Checking End

        // Plans Section Checking Start

        if (plans === "") {
            setPlansSectionColor(0);
        } else {
            if (plans === "Non-Qualified Plans") {
                if (CommonPlansQuestionValidation() &&
                    flagNonQualifiedOwnerWantWithdrawalToExchange !== "" &&
                    (flagNonQualifiedOwnerWantWithdrawalToExchange === "yes" &&
                        explainNonQualifiedOwnerWantWithdrawalToExchange !== ""
                        ? true
                        : flagNonQualifiedOwnerWantWithdrawalToExchange === "no"
                            ? true
                            : false) &&
                    flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal !== ""
                ) {
                    setPlansSectionColor(3);
                } else {
                    setPlansSectionColor(0);
                }
            } else {
                if (CommonPlansQuestionValidation() &&
                    qualifiedTypeOfTransfer !== "" &&
                    qualifiedQualifyingEventForDirectRolloverFunds !== "" &&
                    flagQualifiedOwnerReachedAge72OrMoreInThisYear !== "" &&
                    flagQualifiedOwnerSatisfiedRMDFromDistributingPlan !== "" &&
                    (flagQualifiedOwnerReachedAge72OrMoreInThisYear === "yes" &&
                        flagQualifiedOwnerSatisfiedRMDFromDistributingPlan === "no"
                        ? qualifiedInternalExchangeOfExistingAnnuityName !== ""
                        : true) &&
                    flagQualifiedOwnerWantRMDBeforeCompletingExchange !== "" &&
                    flagQualifiedOwnerWantToContinueExistingRMDSchedule !== ""
                ) {
                    setPlansSectionColor(3);
                } else {
                    setPlansSectionColor(0);
                }
            }
        }

        // Plans Section Checking End

        // Sign section checking Start
        if (flagSignatureDigitalOrPhysical == "") {
            setSignatureOptions(0)
        } else {
            setSignatureOptions(3)
        }


        // Sign section checking End

        // Witness Information Checking Start
        if (
            witnessName !== "" &&
            witnessName !== undefined &&
            witnessName !== null &&
            witnessEmail !== null &&
            witnessEmail !== null &&
            witnessEmail !== ""
        ) {
            setWitnessInformationColor(3);
        } else {
            setWitnessInformationColor(0);
        }

        // Witness Information Checking End
    }, [transferOrExchangeRequest]);

    useEffect(() => {
        if (
            rolloverTransferExchange.transferV2Section1CompanyName == "" &&
            rolloverTransferExchange.transferV2Section1CompanyPhone == "" &&
            rolloverTransferExchange.transferV2Section1CompanyPolicyNumber == "" &&
            rolloverTransferExchange.transferV2Section1CompanyAddress == "" &&
            rolloverTransferExchange.transferV2Section1CompanyCity == "" &&
            rolloverTransferExchange.transferV2Section1CompanyState == "" &&
            rolloverTransferExchange.transferV2Section1CompanyZip == "" &&
            // rolloverTransferExchange.transferV2Section1OwnerName == "" &&
            // rolloverTransferExchange.transferV2Section1OwnerSsn == "" &&
            // rolloverTransferExchange.transferV2Section1JoinOwnerName == "" &&
            // rolloverTransferExchange.transferV2Section1JoinOwnerSsn == "" &&
            // rolloverTransferExchange.transferV2Section1AnnuitantName == "" &&
            // rolloverTransferExchange.transferV2Section1AnnuitantSsn == "" &&
            // rolloverTransferExchange.transferV2Section1JoinAnnuitantName == "" &&
            // rolloverTransferExchange.transferV2Section1JoinAnnuitantSsn == "" &&
            // rolloverTransferExchange.transferV2Section1BeneficiaryName == "" &&
            // rolloverTransferExchange.transferV2Section1BeneficiarySsn == "" &&
            rolloverTransferExchange.transferV2Section1InvestmentVehicle == "" &&
            rolloverTransferExchange.transferV2Section1ExistingCertificate == ""
        ) {
            setAccountInformationColor(0)
        } else if (
            rolloverTransferExchange.transferV2Section1CompanyName !== "" &&
            rolloverTransferExchange.transferV2Section1CompanyPhone !== "" &&
            rolloverTransferExchange.transferV2Section1CompanyPolicyNumber !== "" &&
            rolloverTransferExchange.transferV2Section1CompanyAddress !== "" &&
            rolloverTransferExchange.transferV2Section1CompanyCity !== "" &&
            rolloverTransferExchange.transferV2Section1CompanyState !== "" &&
            rolloverTransferExchange.transferV2Section1CompanyZip !== "" &&
            // rolloverTransferExchange.transferV2Section1OwnerName !== "" &&
            // rolloverTransferExchange.transferV2Section1OwnerSsn !== "" &&
            // rolloverTransferExchange.transferV2Section1JoinOwnerName !== "" &&
            // rolloverTransferExchange.transferV2Section1JoinOwnerSsn !== "" &&
            // rolloverTransferExchange.transferV2Section1AnnuitantName !== "" &&
            // rolloverTransferExchange.transferV2Section1AnnuitantSsn !== "" &&
            // rolloverTransferExchange.transferV2Section1JoinAnnuitantName !== "" &&
            // rolloverTransferExchange.transferV2Section1JoinAnnuitantSsn !== "" &&
            // rolloverTransferExchange.transferV2Section1BeneficiaryName !== "" &&
            // rolloverTransferExchange.transferV2Section1BeneficiarySsn !== "" &&
            rolloverTransferExchange.transferV2Section1InvestmentVehicle !== "" &&
            rolloverTransferExchange.transferV2Section1ExistingCertificate !== ""
        ) {
            setAccountInformationColor(3)
        }

        if (rolloverTransferExchange.transferV2Section2Plan == "") {
            setRolloverPlanColor(0)
        } else {
            if (rolloverTransferExchange.transferV2Section2Plan == "Non-Qualified Plans") {
                if (rolloverTransferExchange.transferV2Section2NonQualified1035Exchange == "") {
                    setRolloverPlanColor(0)
                } else {
                    if (rolloverTransferExchange.transferV2Section2NonQualified1035Exchange == "partial exchange" &&
                        rolloverTransferExchange.transferV2Section2NonQualified1035ExchangeOtherPercentage == "" &&
                        rolloverTransferExchange.transferV2Section2NonQualified1035ExchangeOtherAmount == ""
                    ) {
                        setRolloverPlanColor(0)
                    } else {
                        setRolloverPlanColor(3)
                    }
                }
            } else if (rolloverTransferExchange.transferV2Section2Plan == "Qualified Plans") {
                if (
                    rolloverTransferExchange.transferV2Section2QualifiedAgeRmdTransfer == "" &&
                    (rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount == "" ||
                        (rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount == "other" &&
                            rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccountOther == "")
                    ) &&
                    (rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer == ""
                        || (rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer == "partial amount" &&
                            rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferPercentage == "" &&
                            rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferAmount == ""
                        )
                    ) &&
                    rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferPartailPercentageAccount == "" &&
                    (rolloverTransferExchange.transferV2Section2QualifiedWithdrawFunds == "" ||
                        (rolloverTransferExchange.transferV2Section2QualifiedWithdrawFunds == "maturity date" &&
                            rolloverTransferExchange.transferV2Section2QualifiedWithdrawFundsOther == ""
                        )
                    ) &&
                    rolloverTransferExchange.transferV2Section2QualifiedFundsTransfered == ""
                ) {
                    setRolloverPlanColor(0)
                } else if (
                    rolloverTransferExchange.transferV2Section2QualifiedAgeRmdTransfer !== "" &&
                    rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount !== "" &&
                    rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer !== "" &&
                    rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferPartailPercentageAccount !== "" &&
                    rolloverTransferExchange.transferV2Section2QualifiedWithdrawFunds !== "" &&
                    rolloverTransferExchange.transferV2Section2QualifiedFundsTransfered !== ""
                ) {
                    if (
                        (rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount == "other" &&
                            rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccountOther == "") ||
                        (rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer == "partial amount" &&
                            rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferPercentage == "" &&
                            rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferAmount == ""
                        ) || (
                            rolloverTransferExchange.transferV2Section2QualifiedWithdrawFunds == "maturity date" &&
                            rolloverTransferExchange.transferV2Section2QualifiedWithdrawFundsOther == ""
                        )) {
                        setRolloverPlanColor(0)
                    } else {
                        setRolloverPlanColor(3)
                    }
                }
            }
        }

    }, [rolloverTransferExchange])

    useEffect(() => {
        const CheckInsuredPolicyFieldArray = () => {
            let temp = [];

            temp = insurerPolicyDetailFieldArray.map((v) => {
                if (
                    v.existingCarrierName === "" &&
                    v.contractOrPolicyNumber === "" &&
                    v.nameOfInsuredOrAnnuitant === ""
                ) {
                    return false;
                } else if (
                    v.existingCarrierName !== "" &&
                    v.contractOrPolicyNumber !== "" &&
                    v.nameOfInsuredOrAnnuitant !== ""
                ) {
                    return true;
                } else {
                    return false;
                }
            });

            if (temp.indexOf(true) > -1) {
                return true;
            } else {
                return false;
            }
        };

        if (
            flagDiscontinuingPremiumPayment === "" &&
            flagPayForPremiumPolicy === ""
        ) {
            setImportantNoticeColor(1);
        } else if (
            flagDiscontinuingPremiumPayment === "no" &&
            flagPayForPremiumPolicy === "no"
        ) {
            setImportantNoticeColor(3);
        } else if (
            (flagDiscontinuingPremiumPayment === "yes" ||
                flagPayForPremiumPolicy === "yes") &&
            CheckInsuredPolicyFieldArray() === true &&
            reasonForReplacement !== ""
        ) {
            setImportantNoticeColor(3);
        } else {
            setImportantNoticeColor(1);
        }
    }, [noticeOfReplacement]);

    useEffect(() => {
        if (fiaDisclosureVersion == 8) {
            if (flagHarvestClosure === "") {
                setAnnuityDisclosureColor(1);
            } else {
                setAnnuityDisclosureColor(3);
            }
        } else if (fiaDisclosureVersion == 10) {
            if (flagAnnuityClosureV2 === "") {
                setAnnuityDisclosureColor(1);
            } else {
                setAnnuityDisclosureColor(3);
            }
        } else if (fiaDisclosureVersion == 5) {
            if (flagAnnuityClosure === "") {
                setAnnuityDisclosureColor(1);
            } else {
                setAnnuityDisclosureColor(3);
            }
        }
    }, [annuityDisclosureForm, harvestDisclosureForm, annuityDisclosureFormV2]);

    useEffect(() => {

        // checking for producer info
        if (
            producerDisclosureForm.producerFirstName === "" &&
            producerDisclosureForm.producerLastName === "" &&
            producerDisclosureForm.producerBusinessName === "" &&
            // producerDisclosureForm.producerWebsite === "" &&
            producerDisclosureForm.producerBusinessMailingAddress === "" &&
            producerDisclosureForm.producerCity === "" &&
            producerDisclosureForm.producerState === "" &&
            producerDisclosureForm.producerZip === "" &&
            producerDisclosureForm.producerTelephone === "" &&
            producerDisclosureForm.producerEmail === "" &&
            producerDisclosureForm.producerNationalNumber === "" &&
            producerDisclosureForm.producerInStateOf === ""
        ) {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, producerInfoSection: 0 }));
        } else if (
            producerDisclosureForm.producerFirstName !== "" &&
            producerDisclosureForm.producerLastName !== "" &&
            producerDisclosureForm.producerBusinessName !== "" &&
            // producerDisclosureForm.producerWebsite !== "" &&
            producerDisclosureForm.producerBusinessMailingAddress !== "" &&
            producerDisclosureForm.producerCity !== "" &&
            producerDisclosureForm.producerState !== "" &&
            producerDisclosureForm.producerZip !== "" &&
            producerDisclosureForm.producerTelephone !== "" &&
            producerDisclosureForm.producerEmail !== "" &&
            producerDisclosureForm.producerNationalNumber !== "" &&
            producerDisclosureForm.producerInStateOf !== "" && isValidZip(producerDisclosureForm.producerZip) !== false
            // && isValidWebsite(producerDisclosureForm.producerWebsite) !== false
            && validatePhoneNumberLatest(producerDisclosureForm.producerTelephone) !== false && isValidEmail(producerDisclosureForm.producerEmail) !== false && isValidNPN(producerDisclosureForm.producerNationalNumber) !== false
        ) {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, producerInfoSection: 3 }));
        } else {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, producerInfoSection: 0 }));
        }
        // checking for producer info--end

        // checking for customer information
        if (
            producerDisclosureForm.producerCustomerFirstName === "" &&
            producerDisclosureForm.producerCustomerLastName === ""
        ) {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, customerInfoSection: 0 }));
        } else if (
            producerDisclosureForm.producerCustomerFirstName !== "" &&
            producerDisclosureForm.producerCustomerLastName !== ""
        ) {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, customerInfoSection: 3 }));
        } else {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, customerInfoSection: 0 }));
        }
        // checking for customer information --end

        //  checking for type of product
        if (
            producerDisclosureForm.producerOfferedProducts.length === 0 &&
            producerDisclosureForm.producerNonInsuranceProducts.length === 0
        ) {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, typeOfProductSection: 0 }));
        } else if (
            producerDisclosureForm.producerOfferedProducts.length !== 0 &&
            producerDisclosureForm.producerNonInsuranceProducts.length !== 0
        ) {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, typeOfProductSection: 3 }));
        } else {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, typeOfProductSection: 0 }));
        }
        // checking for type of product--end

        // checking for annuity authorized section
        if (
            producerDisclosureForm.producerAuthorizedToSell.length === 0
        ) {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, annuityAuthorizedSection: 0 }));
        } else if (
            producerDisclosureForm.producerAuthorizedToSell.length !== 0
        ) {
            if (producerDisclosureForm.producerAuthorizedToSell.indexOf("more insurer") < 0) {
                setProducerDisclosureSectionColor((prevState) => ({ ...prevState, annuityAuthorizedSection: 3 }));
            } else {
                if (producerDisclosureForm.producerAuthorizedToSellDescription !== "") {
                    setProducerDisclosureSectionColor((prevState) => ({ ...prevState, annuityAuthorizedSection: 3 }));
                } else {
                    setProducerDisclosureSectionColor((prevState) => ({ ...prevState, annuityAuthorizedSection: 0 }));
                }
            }
        } else {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, annuityAuthorizedSection: 0 }));
        }
        // checking for annuity authorized section --end

        // checking for paid for work section
        if (
            producerDisclosureForm.producerPaidForWork.length === 0
        ) {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, paidForWorkSection: 0 }));
        } else if (
            producerDisclosureForm.producerPaidForWork.length !== 0
        ) {
            if (producerDisclosureForm.producerPaidForWork.indexOf("commission") < 0 && producerDisclosureForm.producerPaidForWork.indexOf("other")) {
                setProducerDisclosureSectionColor((prevState) => ({ ...prevState, paidForWorkSection: 3 }));
            } else {
                // if (producerDisclosureForm.producerPaidForWork.includes("commission") && producerDisclosureForm.producerPaidForWorkCommissionDescription === "") {
                //     setProducerDisclosureSectionColor((prevState) => ({ ...prevState, paidForWorkSection: 0 }));
                // } else if (producerDisclosureForm.producerPaidForWork.includes("other") > 0 && producerDisclosureForm.producerPaidForWorkOtherDescription === "") {
                //     setProducerDisclosureSectionColor((prevState) => ({ ...prevState, paidForWorkSection: 0 }));
                // } 
                // else {
                // }
                setProducerDisclosureSectionColor((prevState) => ({ ...prevState, paidForWorkSection: 3 }));
            }
        } else {
            setProducerDisclosureSectionColor((prevState) => ({ ...prevState, paidForWorkSection: 0 }));
        }
        // checking for paid for work section --end



    }, [producerDisclosureForm])

    const [searchParams] = useSearchParams();

    const ConditionToShowReplacementForm = () => {
        if (ownerState?.length && ownerState !== "" && ownerState !== "Tennessee") {
            return true
        } else {
            if (annuitantState?.length && annuitantState !== "" && annuitantState !== "Tennessee") {
                return true
            } else {
                return false
            }
        }
    };

    return (
        <React.Fragment>
            {flagUrlToSendApplicantDialog === true && (
                <URLToSendApplicantDialog
                    value={`${protocol}//${domain}/application?public_url_id=${uniqueURLId}`}
                    closeURLToSendApplicantDialog={() => {
                        setFlagUrlToSendApplicantDialog(false);
                    }}
                />
            )}

            <div className="application_link_button">
                <div class="treeview">
                    <ul
                        style={
                            IndividualAnnuityApplicationSuccess() === true
                                ? {
                                    borderLeftColor: "#074a6e",
                                    position: "absolute",
                                    right: "25px",
                                }
                                : {
                                    borderLeftColor: "rgb(189, 189, 189)",
                                    position: "absolute",
                                    right: "25px",
                                }
                        }
                    >
                        <div
                            onClick={() => props.setPage(1)}
                            style={
                                IndividualAnnuityApplicationSuccess() === true &&
                                    props.pageIndex === 1
                                    ? { color: "#078a4e", fontWeight: "bold", whiteSpace: "nowrap" }
                                    : IndividualAnnuityApplicationSuccess() === true
                                        ? { color: "#074a6e", fontWeight: "bold", whiteSpace: "nowrap" }
                                        : props.pageIndex === 1
                                            ? { color: "#078a4e", whiteSpace: "nowrap" }
                                            : { color: "rgb(189, 189, 189)", whiteSpace: "nowrap" }
                            }
                            className={
                                props.pageIndex === 1
                                    ? "spn right_section_title current_page"
                                    : "spn right_section_title"
                            }
                        >
                            Individual Fixed Index Annuity Application
                        </div>
                    </ul>
                    <div className="individual_annuity_application_right_section">
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(1);
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFirstSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSeventhSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageEighthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageNinthSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        ownerSectionColor === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : ownerSectionColor === 3
                                                ? {
                                                    color: "#074a6e",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }
                                                : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Owner
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(1);
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSecondSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSeventhSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageEighthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageNinthSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        jointOwnerSectionColor === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : jointOwnerSectionColor === 1
                                                ? {
                                                    color: "#1976d2",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }
                                                : jointOwnerSectionColor === 3
                                                    ? {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                                    : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Joint Spousal Owner
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(1);
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageThirdSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSeventhSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageEighthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageNinthSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        annuitantSectionColor === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : annuitantSectionColor === 1
                                                ? {
                                                    color: "#1976d2",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }
                                                : annuitantSectionColor === 3
                                                    ? {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                                    : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Annuitant
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(1);
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFourthSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSeventhSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageEighthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageNinthSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        joitAnnuitantSectionColor === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : joitAnnuitantSectionColor === 1
                                                ? {
                                                    color: "#1976d2",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }
                                                : joitAnnuitantSectionColor === 3
                                                    ? {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                                    : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Joint Spousal Annuitant
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(1);
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFifthSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSeventhSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageEighthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageNinthSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        planRiderSectionColor === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Plan/Riders Applied For
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(1);
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSixthSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSeventhSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageEighthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageNinthSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        initialAccountSectionColor === 1
                                            ? {
                                                color: "#1976d2",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                            : initialAccountSectionColor === 3
                                                ? {
                                                    color: "#074a6e",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }
                                                : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Initial Account Allocation
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(1);
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSeventhSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageEighthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageNinthSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        beneficiariesSectionColor === 1
                                            ? {
                                                color: "#1976d2",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                            : beneficiariesSectionColor === 3
                                                ? {
                                                    color: "#074a6e",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }
                                                : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Beneficiaries
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(1);
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSeventhSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageEighthSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageNinthSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        premimumTaxQualificationSectionColor === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Premium and Tax Qualification Status
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(1);
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageSeventhSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageEighthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Individual Annuity Application",
                                            section: "flagFirstPageNinthSection",
                                            data: true,
                                        });
                                    }}
                                    style={
                                        safeguardPlusAnnuityPeriod === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Applicant and Insurance Producer
                                </span>
                            </div>
                        </li>
                    </div>

                    {/* Important Notice mapping */}

                    {ConditionToShowReplacementForm() === true &&
                        stateToShowNoticeOfReplacement !== "Tennessee" ? (
                        <ul
                            style={
                                NoticeOfReplacementSuccess() === true
                                    ? searchParams.has("public_url_id") === true
                                        ? {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: "380px",
                                        }
                                        : {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: "505px",
                                        }
                                    : searchParams.has("public_url_id") === true
                                        ? {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: "380px",
                                        }
                                        : {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: "505px",
                                        }
                            }
                        >
                            <div
                                style={
                                    NoticeOfReplacementSuccess() === true && props.pageIndex === 2
                                        ? {
                                            color: "#078a4e",
                                            fontWeight: "bold",
                                            marginTop: "-10px",
                                        }
                                        : NoticeOfReplacementSuccess() === true
                                            ? {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                marginTop: "-10px",
                                            }
                                            : props.pageIndex === 2
                                                ? { color: "#078a4e", marginTop: "-10px" }
                                                : { color: "rgb(189, 189, 189)", marginTop: "-10px" }
                                }
                                onClick={() => {

                                    props.setPage(2);

                                }}
                                className={
                                    props.pageIndex === 2
                                        ? "spn right_section_title current_page"
                                        : props.pageIndex === 5
                                            ? "spn right_section_title current_page "
                                            : "spn right_section_title"
                                }
                            >
                                Notice of Replacement of Life Insurance or Annuities{" "}
                            </div>
                        </ul>
                    ) : null}

                    {ConditionToShowReplacementForm() === true &&
                        stateToShowNoticeOfReplacement !== "" &&
                        stateToShowNoticeOfReplacement !== "Tennessee"
                        ? (
                            <div className="fia_notice_of_replacement_right_section">
                                <li>
                                    <div style={{ display: "flex" }}>
                                        <span className="spn middle_line">___</span>
                                        <span
                                            onClick={() => {
                                                SetPageIndex(2);
                                                SetApplicationFormFlagSectionData({
                                                    type: "Notice of Replacement",
                                                    section: "flagReplacementPageFirstSection",
                                                    data: true,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Notice of Replacement",
                                                    section: "flagReplacementPageSecondSection",
                                                    data: false,
                                                });
                                            }}
                                            style={
                                                importantNoticeColor === 1
                                                    ? { color: "rgb(189,189,189)", cursor: "pointer" }
                                                    : {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                            }
                                            className="spn application_form_section_name"
                                        >
                                            Important Notice: Replacement of Life Insurance or Annuities
                                        </span>
                                    </div>
                                </li>
                            </div>
                        ) : null}


                    {ConditionToShowReplacementForm() === true ? (
                        <ul
                            style={
                                SuitabilityAnalysisSuccess() === true
                                    ? searchParams.has("public_url_id") === true
                                        ? {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: "530px",
                                        }
                                        : {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: "630px",
                                        }
                                    : searchParams.has("public_url_id") === true
                                        ? {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: "530px",
                                        }
                                        : {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: "630px",
                                        }
                            }
                        >
                            <div
                                style={
                                    SuitabilityAnalysisSuccess() === true && props.pageIndex === 3
                                        ? { color: "#078a4e", fontWeight: "bold" }
                                        : SuitabilityAnalysisSuccess() === true
                                            ? { color: "#074a6e", fontWeight: "bold" }
                                            : props.pageIndex === 3
                                                ? { color: "#078a4e" }
                                                : { color: "rgb(189, 189, 189)" }
                                }
                                onClick={() => props.setPage(3)}
                                className={
                                    props.pageIndex === 3
                                        ? "spn right_section_title current_page"
                                        : "spn right_section_title"
                                }
                            >
                                Suitability Analysis
                            </div>
                        </ul>
                    ) : (
                        <ul
                            style={
                                SuitabilityAnalysisSuccess() === true
                                    ? searchParams.has("public_url_id") === true
                                        ? {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: "370px",
                                        }
                                        : {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: "510px",
                                        }
                                    : searchParams.has("public_url_id") === true
                                        ? {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: "370px",
                                        }
                                        : {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: "510px",
                                        }
                            }
                        >
                            <div
                                style={
                                    SuitabilityAnalysisSuccess() === true && props.pageIndex === 3
                                        ? { color: "#078a4e", fontWeight: "bold" }
                                        : SuitabilityAnalysisSuccess() === true
                                            ? { color: "#074a6e", fontWeight: "bold" }
                                            : props.pageIndex === 3
                                                ? { color: "#078a4e" }
                                                : { color: "rgb(189, 189, 189)" }
                                }
                                onClick={() => props.setPage(3)}
                                className={
                                    props.pageIndex === 3
                                        ? "spn right_section_title current_page"
                                        : "spn right_section_title"
                                }
                            >
                                Suitability Analysis
                            </div>
                        </ul>
                    )}
                    <div
                        className={
                            ConditionToShowReplacementForm() === true
                                ? "fia_suitability_analysis_right_section_exception"
                                : "suitability_analysis_right_section_fixed"
                        }
                    >
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(3);
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFirstSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSeventhSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        personalInformationColor === 3
                                            ? {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                            : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Personal Information
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(3);
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSecondSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSeventhSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        financialInformationColor === 3
                                            ? {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                            : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Financial Information of Owner
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(3);
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageThirdSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSeventhSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        financialSituationColor === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Financial Situation and Needs of Owner
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(3);
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFourthSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSeventhSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        productPurchaseColor === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    The Product Purchase
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(3);
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFifthSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSeventhSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        existingAccountInformationColor === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Existing Account Information
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(3);
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSixthSection",
                                            data: true,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSeventhSection",
                                            data: false,
                                        });
                                    }}
                                    style={
                                        otherSectionColor === 1
                                            ? {
                                                color: "#1976d2",
                                                cursor: "pointer",
                                                fontWeight: "bold",
                                            }
                                            : {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Other
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span className="spn middle_line">___</span>
                                <span
                                    onClick={() => {
                                        SetPageIndex(3);
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFirstSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSecondSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageThirdSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFourthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageFifthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSixthSection",
                                            data: false,
                                        });
                                        SetApplicationFormFlagSectionData({
                                            type: "Suitability Analysis",
                                            section: "flagSecondPageSeventhSection",
                                            data: true,
                                        });
                                    }}
                                    style={
                                        agentAcknowledgmentColor === 0
                                            ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            : {
                                                color: "#074a6e",
                                                fontWeight: "bold",
                                                cursor: "pointer",
                                            }
                                    }
                                    className="spn application_form_section_name"
                                >
                                    {" "}
                                    Agent's Acknowledgment
                                </span>
                            </div>
                        </li>
                    </div>

                    {ConditionToShowReplacementForm() === true ? (
                        <ul
                            style={
                                TransferExchangeRequestSuccess() === true
                                    ? searchParams.has("public_url_id") === true
                                        ? {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: "884px",
                                        }
                                        : {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: "1020px",
                                        }
                                    : searchParams.has("public_url_id") === true
                                        ? {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: "884px",
                                        }
                                        : {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: "1020px",
                                        }
                            }
                        >
                            <div
                                style={
                                    TransferExchangeRequestSuccess() === true &&
                                        props.pageIndex === 4
                                        ? { color: "#078a4e", fontWeight: "bold" }
                                        : TransferExchangeRequestSuccess() === true
                                            ? { color: "#074a6e", fontWeight: "bold" }
                                            : props.pageIndex === 4
                                                ? { color: "#078a4e" }
                                                : { color: "rgb(189, 189, 189)" }
                                }
                                onClick={() => props.setPage(4)}
                                className={
                                    props.pageIndex === 4
                                        ? "spn right_section_title current_page"
                                        : "spn right_section_title"
                                }
                            >
                                Transfer or Exchange Request
                            </div>
                        </ul>
                    ) : (
                        <ul
                            style={
                                TransferExchangeRequestSuccess() === true
                                    ? searchParams.has("public_url_id") === true
                                        ? {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: "704px",
                                        }
                                        : {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: "900px",
                                        }
                                    : searchParams.has("public_url_id") === true
                                        ? {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: "704px",
                                        }
                                        : {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: "900px",
                                        }
                            }
                        >
                            <div
                                style={
                                    TransferExchangeRequestSuccess() === true &&
                                        props.pageIndex === 4
                                        ? { color: "#078a4e", fontWeight: "bold" }
                                        : TransferExchangeRequestSuccess() === true
                                            ? { color: "#074a6e", fontWeight: "bold" }
                                            : props.pageIndex === 4
                                                ? { color: "#078a4e" }
                                                : { color: "rgb(189, 189, 189)" }
                                }
                                onClick={() => props.setPage(4)}
                                className={
                                    props.pageIndex === 4
                                        ? "spn right_section_title current_page"
                                        : "spn right_section_title"
                                }
                            >
                                Transfer or Exchange Request
                            </div>
                        </ul>
                    )}
                    {transferExchangeVersion !== 7 ? (
                        <div
                            className={
                                ConditionToShowReplacementForm() === true
                                    ? "fia_transfer_exchange_right_section"
                                    : "transfer_exchange_right_section_exception_fixed"
                            }
                        >
                            <li>
                                <div>
                                    <span className="spn middle_line">___</span>
                                    <span
                                        onClick={() => {
                                            SetPageIndex(4);
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageFirstSection",
                                                data: true,
                                            });
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageSecondSection",
                                                data: false,
                                            });
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageThirdSection",
                                                data: false,
                                            });
                                        }}
                                        style={
                                            basicInformationColor === 0
                                                ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                                : basicInformationColor === 1
                                                    ? {
                                                        color: "#1976d2",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                                    : {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                        }
                                        className="spn application_form_section_name"
                                    >
                                        {" "}
                                        Basic Information
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span className="spn middle_line">___</span>
                                    <span
                                        onClick={() => {
                                            SetPageIndex(4);
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageFirstSection",
                                                data: false,
                                            });
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageSecondSection",
                                                data: true,
                                            });
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageThirdSection",
                                                data: false,
                                            });
                                        }}
                                        style={
                                            plansSectionColor === 0
                                                ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                                : {
                                                    color: "#074a6e",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }
                                        }
                                        className="spn application_form_section_name"
                                    >
                                        {" "}
                                        Plans
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span className="spn middle_line">___</span>
                                    <span
                                        onClick={() => {
                                            SetPageIndex(4);
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageFirstSection",
                                                data: false,
                                            });
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageSecondSection",
                                                data: false,
                                            });
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageThirdSection",
                                                data: true,
                                            });
                                        }}
                                        style={
                                            signatureOptions === 0
                                                ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                                : {
                                                    color: "#074a6e",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }
                                        }
                                        className="spn application_form_section_name"
                                    >
                                        {" "}
                                        Signature
                                    </span>
                                </div>
                            </li>
                        </div>
                    ) :

                        <div
                            className={
                                ConditionToShowReplacementForm() === true
                                    ? "fia_transfer_exchange_right_section"
                                    : "transfer_exchange_right_section_exception_fixed"
                            }
                        >
                            <li>
                                <div>
                                    <span className="spn middle_line">___</span>
                                    <span
                                        onClick={() => {
                                            SetPageIndex(4);
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageFirstSection",
                                                data: true,
                                            });
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageSecondSection",
                                                data: false,
                                            });
                                        }}
                                        style={
                                            accountInformationColor === 0
                                                ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                                : accountInformationColor === 1
                                                    ? {
                                                        color: "#1976d2",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                                    : {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                        }
                                        className="spn application_form_section_name"
                                    >
                                        {" "}
                                        Basic Information
                                    </span>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <span className="spn middle_line">___</span>
                                    <span
                                        onClick={() => {
                                            SetPageIndex(4);
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageFirstSection",
                                                data: false,
                                            });
                                            SetApplicationFormFlagSectionData({
                                                type: "Transfer or Exchange Request",
                                                section: "flagFourthPageSecondSection",
                                                data: true,
                                            });
                                        }}
                                        style={
                                            rolloverPlanColor === 0
                                                ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                                : {
                                                    color: "#074a6e",
                                                    fontWeight: "bold",
                                                    cursor: "pointer",
                                                }
                                        }
                                        className="spn application_form_section_name"
                                    >
                                        {" "}
                                        Plans
                                    </span>
                                </div>
                            </li>
                        </div>


                    }
                    <ul
                        style={
                            AnnuityDisclosureSuccess() === true
                                ? searchParams.has("public_url_id") === true
                                    ? {
                                        borderLeftColor: "#074a6e",
                                        position: "absolute",
                                        right: "25px",
                                        top: annuityDisclosureStyle.top,
                                    }
                                    : {
                                        borderLeftColor: "#074a6e",
                                        position: "absolute",
                                        right: "25px",
                                        top: annuityDisclosureStyle.top,
                                    }
                                : searchParams.has("public_url_id") === true
                                    ? {
                                        borderLeftColor: "rgb(189, 189, 189)",
                                        position: "absolute",
                                        right: "25px",
                                        top: annuityDisclosureStyle.top,
                                    }
                                    : {
                                        borderLeftColor: "rgb(189, 189, 189)",
                                        position: "absolute",
                                        right: "25px",
                                        top: annuityDisclosureStyle.top,
                                    }
                        }
                    >
                        <div
                            style={
                                AnnuityDisclosureSuccess() === true && props.pageIndex === 6
                                    ? { color: "#078a4e", fontWeight: "bold" }
                                    : AnnuityDisclosureSuccess() === true
                                        ? { color: "#074a6e", fontWeight: "bold" }
                                        : props.pageIndex === 6
                                            ? { color: "#078a4e" }
                                            : { paddingBottom: "30px", color: "rgb(189, 189, 189)" }
                            }
                            onClick={() => props.setPage(6)}
                            className={
                                props.pageIndex === 6
                                    ? "spn right_section_title current_page"
                                    : "spn right_section_title"
                            }
                        >
                            Annuity Disclosure
                        </div>
                    </ul>
                    {/* producer disclosure */}
                    {flagShowProducerDisclosure &&
                        <>
                            <ul
                                style={
                                    producerDisclosureSuccess() === true
                                        ? {
                                            borderLeftColor: "#074a6e",
                                            position: "absolute",
                                            right: "25px",
                                            top: ConditionToShowReplacementForm() === true ? "1255px" : "1140px"
                                        }
                                        : {
                                            borderLeftColor: "rgb(189, 189, 189)",
                                            position: "absolute",
                                            right: "25px",
                                            top: ConditionToShowReplacementForm() === true ? "1255px" : "1140px"
                                        }
                                }
                            >
                                <div
                                    onClick={() => props.setPage(7)}
                                    style={
                                        producerDisclosureSuccess() === true &&
                                            props.pageIndex === 7
                                            ? { color: "#078a4e !important", fontWeight: "bold" }
                                            : producerDisclosureSuccess() === true
                                                ? { color: "#074a6e", fontWeight: "bold" }
                                                : props.pageIndex === 7
                                                    ? { color: "#078a4e" }
                                                    : { color: "rgb(189, 189, 189)" }
                                    }
                                    className={
                                        props.pageIndex === 7
                                            ? "spn right_section_title current_page"
                                            : "spn right_section_title"
                                    }
                                >
                                    Producer Disclosure
                                </div>
                            </ul>

                            <div
                                className={
                                    ConditionToShowReplacementForm() === true
                                        ? "fia_producer_disclosure_right_section_exception"
                                        : "fia_producer_disclosure_right_section"
                                }
                            >
                                <li>
                                    <div>
                                        <span className="spn middle_line">___</span>
                                        <span
                                            onClick={() => {
                                                SetPageIndex(7);
                                                window.scrollTo(0, 0)
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFirstSection",
                                                    data: true,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageSecondSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageThirdSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFourthSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFifthSection",
                                                    data: false,
                                                });
                                            }}
                                            style={
                                                producerDisclosureSectionColor.producerInfoSection === 3
                                                    ? {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                                    : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            }
                                            className="spn application_form_section_name"
                                        >
                                            {" "}
                                            Producer Information
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span className="spn middle_line">___</span>
                                        <span
                                            onClick={() => {
                                                SetPageIndex(7);
                                                window.scrollTo(0, 0)
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFirstSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageSecondSection",
                                                    data: true,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageThirdSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFourthSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFifthSection",
                                                    data: false,
                                                });
                                            }}
                                            style={
                                                producerDisclosureSectionColor.customerInfoSection === 3
                                                    ? {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                                    : { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                            }
                                            className="spn application_form_section_name"
                                        >
                                            {" "}
                                            Customer Information
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span className="spn middle_line">___</span>
                                        <span
                                            onClick={() => {
                                                SetPageIndex(7);
                                                window.scrollTo(0, 0)
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFirstSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Suitability Analysis",
                                                    section: "flagSixthPageSecondSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Suitability Analysis",
                                                    section: "flagSixthPageThirdSection",
                                                    data: true,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Suitability Analysis",
                                                    section: "flagSixthPageFourthSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Suitability Analysis",
                                                    section: "flagSixthPageFifthSection",
                                                    data: false,
                                                });
                                            }}
                                            style={
                                                producerDisclosureSectionColor.typeOfProductSection === 0
                                                    ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                                    : {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                            }
                                            className="spn application_form_section_name"
                                        >
                                            {" "}
                                            Type of product
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span className="spn middle_line">___</span>
                                        <span
                                            onClick={() => {
                                                SetPageIndex(7);
                                                window.scrollTo(0, 0)
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFirstSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageSecondSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageThirdSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFourthSection",
                                                    data: true,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFifthSection",
                                                    data: false,
                                                });
                                            }}
                                            style={
                                                producerDisclosureSectionColor.annuityAuthorizedSection === 0
                                                    ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                                    : {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                            }
                                            className="spn application_form_section_name"
                                        >
                                            {" "}
                                            Annuity authorized
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <span className="spn middle_line">___</span>
                                        <span
                                            onClick={() => {
                                                SetPageIndex(7);
                                                window.scrollTo(0, 0)
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFirstSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageSecondSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageThirdSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFourthSection",
                                                    data: false,
                                                });
                                                SetApplicationFormFlagSectionData({
                                                    type: "Producer Disclosure",
                                                    section: "flagSixthPageFifthSection",
                                                    data: true,
                                                });
                                            }}
                                            style={
                                                producerDisclosureSectionColor.paidForWorkSection === 0
                                                    ? { color: "rgb(189, 189, 189)", cursor: "pointer" }
                                                    : {
                                                        color: "#074a6e",
                                                        fontWeight: "bold",
                                                        cursor: "pointer",
                                                    }
                                            }
                                            className="spn application_form_section_name"
                                        >
                                            {" "}
                                            Paid for work
                                        </span>
                                    </div>
                                </li>
                            </div>
                        </>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};

export default FixedIndexSiteMap;
