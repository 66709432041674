import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  InputBase,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Snackbar,
  Alert,
  Autocomplete,
  FormControl,
  Link,
  Select,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useEffect, useState, useContext, Fragment } from "react";
import "./pdf_confing.css";
import PDF2Icon from "../../../assets/pdf_2.svg";
import Delete from "../../../assets/delete.svg";
import Edit from "../../../assets/edit.svg";
import FileUpload from "./fileupload";
import { Context as userLoaderContext } from "../../../context/userLoaderContext";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

import { styled, alpha } from "@mui/material/styles";

import Loader from "../../../components/loader/loader";
import { fromUnixTime } from "date-fns/esm";
import { AddResourceDataAPI, DeleteResourceAPI, GetCategoryGroupDataAPI, GetResourceCategoryListAPI, GetResourceConfigDataAPI, GetResourceTypeListAPI, RemoveFileAPI, UpdateResourceDataAPI } from "../../../api/resourceConfig";

const PdfConfig = (props) => {
  const { state } = useContext(userLoaderContext);
  const [loader, SetFlagLoader] = useState(false);
  const navigate = useNavigate();
  const [addCategoryName, setAddCategoryName] = useState();

  const [addPdfName, setAddPdfName] = useState();
  const [categoryName, setCategoryName] = useState();;
  const [getFileRes, setGetFileRes] = useState();
  const [saveEdit, setSaveEdit] = useState(0);
  const [editFeildPdfName, setEditFeildPdfName] = useState();
  const [flagCategory, setflagCategory] = useState(0);
  const [flagFileUploadededit, setFlagFileUploadededit] = useState(false);
  const [flagFileUploaded, setFlagFileUploaded] = useState(false);
  const [resourceUrlLink, setResourceUrlLink] = useState(null);
  const [editTypeName, setEditTypeName] = useState();
  const [ShowMsg, setShowMsg] = useState({
    status: false,
    error: false,
    msg: "",
  });
  const [buttonStatus, setButtonStatus] = useState(false);
  const [removedFileIds, setRemovedFileIds] = useState([]);

  // New States


  const [resourceListData, setResourceListData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [addEditPopupDetails, setAddEditPopupDetails] = useState({
    flagOpen: false,
    popupType: "",
    resource_type_id: "",
    resource_name: "",
    pdf_category_id: "",
    pdf_group_category_id: "",
    google_storage_id: "",
    resource_link: "",
    resource_sub_category_id: "",
  });
  const [typeOptions, setTypeOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const [flagGetCall, setFlagGetCall] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState("");


  console.log("resourceListData", resourceListData)

  useEffect(() => {
    if (state.userType !== "Admin") {
      navigate("/myga_application_history");
    }
  });
  useEffect(() => {
    if (addEditPopupDetails.flagOpen == true) {
      setShowMsg({
        status: false,
        error: false,
        msg: "",
      });
      setRemovedFileIds([])
    }
  }, [addEditPopupDetails.flagOpen])

  const AddResourcePopupOpen = (type) => {
    setAddEditPopupDetails(old => {
      let temp = { ...old }
      temp.flagOpen = true
      temp.popupType = type
      temp.resource_type_id = 4
      temp.resource_name = ""
      temp.pdf_category_id = ""
      temp.pdf_group_category_id = ""
      temp.google_storage_id = ""
      temp.google_storage_original_file_name = ""
      temp.resource_link = ""
      temp.resource_sub_category_id = ""
      return temp
    })
  }

  const EditResourcePopupOpen = (type, obj) => {
    setAddEditPopupDetails(old => {
      let temp = { ...old }
      temp.flagOpen = true
      temp.popupType = type
      temp.resource_type_id = obj.resource_type_id
      temp.resource_name = obj.resource_name
      temp.pdf_category_id = obj.pdf_category_id
      temp.pdf_group_category_id = obj.pdf_group_category_id
      temp.google_storage_id = obj.google_storage_id
      temp.google_storage_original_file_name = obj.google_storage_original_file_name
      temp.resource_link = obj.resource_link
      temp.resource_sub_category_id = obj.resource_sub_category_id
      return temp
    })
  }

  const DeleteResourcePopupOpen = (type, resourceName, resourceSubCategoryId) => {
    setAddEditPopupDetails(old => {
      let temp = { ...old }
      temp.flagOpen = true
      temp.popupType = type
      temp.resource_name = resourceName
      temp.resource_sub_category_id = resourceSubCategoryId
      return temp
    })

  }

  const UpdateResourceStateObject = (key, value) => {
    setAddEditPopupDetails(old => {
      let tempDetails = { ...old }
      tempDetails[key] = value
      return tempDetails
    })
  }

  const searchfunction = (searchedVal) => {
    var tempGetPdfData = JSON.parse(JSON.stringify(allData));
    if (searchedVal) {
      {
        var findData = tempGetPdfData.filter((r) => {
          if (
            r.resource_name.toLowerCase().indexOf(searchedVal.toLowerCase()) >
            -1 ||
            r.category_name.toLowerCase().indexOf(searchedVal.toLowerCase()) >
            -1
          ) {
            return r;
          }
        });
        setResourceListData(findData);
      }
    } else {
      setResourceListData(allData);
    }
  };

  console.log("removedFileIds", removedFileIds)
  const RemoveResourceIds = () => {
    let tempRemoveIds = [...removedFileIds]
    tempRemoveIds?.map((id) => {
      RemoveFileAPI(id,
        (res) => {
          console.log("res", res);
          setRemovedFileIds(old => {
            let temp = [...old]
            temp = temp.filter((gId) => gId != id)
            return temp
          })
          // SetFlagLoader(false)
        },
        (err) => {
          console.log("err", err);
          SetFlagLoader(false)
        }
      );
    })
  }

  const SaveResourceData = () => {

    if (addEditPopupDetails.popupType == "Add") {
      SetFlagLoader(true);
      let payload
      if (addEditPopupDetails.resource_type_id == 6) {
        payload = {
          resource_type_id: addEditPopupDetails.resource_type_id,
          resource_name: addEditPopupDetails.resource_name,
          pdf_category_id: addEditPopupDetails.pdf_category_id,
          pdf_group_category_id: addEditPopupDetails.pdf_group_category_id,
          resource_link: addEditPopupDetails.resource_link,
        }
      }
      if (addEditPopupDetails.resource_type_id == 5) {
        payload = {
          resource_type_id: addEditPopupDetails.resource_type_id,
          resource_name: addEditPopupDetails.resource_name,
          pdf_category_id: addEditPopupDetails.pdf_category_id,
          pdf_group_category_id: addEditPopupDetails.pdf_group_category_id
        }
      }
      if (addEditPopupDetails.resource_type_id == 4) {
        payload = {
          resource_type_id: addEditPopupDetails.resource_type_id,
          resource_name: addEditPopupDetails.resource_name,
          pdf_category_id: addEditPopupDetails.pdf_category_id,
          pdf_group_category_id: addEditPopupDetails.pdf_group_category_id,
          google_storage_id: addEditPopupDetails.google_storage_id
        }
      }
      AddResourceDataAPI(payload,
        (res) => {
          if (res.data.status === true) {
            setAddEditPopupDetails({
              flagOpen: false,
              popupType: "",
              resource_type_id: "",
              resource_name: "",
              pdf_category_id: "",
              pdf_group_category_id: "",
              google_storage_id: "",
              google_storage_original_file_name: "",
              resource_link: "",
              resource_sub_category_id: ""
            })
            setFlagGetCall(new Date().getTime())
            setShowMsg({
              status: false,
              error: false,
              msg: "",
            });
          } else {
            SetFlagLoader(false);
            setShowMsg({
              status: false,
              error: true,
              msg: res.data.error.message,
            });
          }
        },
        (err) => {
          SetFlagLoader(false);
          setShowMsg({
            status: false,
            error: true,
            msg: "somthing went wrong!",
          });
          // console.log("err", err);
        }
      );
    }

    if (addEditPopupDetails.popupType == "Edit") {
      if (removedFileIds?.length) {
        RemoveResourceIds()
      }
      SetFlagLoader(true);
      let payload
      if (addEditPopupDetails.resource_type_id == 6) {
        payload = {
          resource_sub_category_id: subCategoryId,
          resource_type_id: addEditPopupDetails.resource_type_id,
          resource_name: addEditPopupDetails.resource_name,
          pdf_category_id: addEditPopupDetails.pdf_category_id,
          pdf_group_category_id: addEditPopupDetails.pdf_group_category_id,
          resource_link: addEditPopupDetails.resource_link,
        }
      }
      if (addEditPopupDetails.resource_type_id == 5) {
        payload = {
          resource_sub_category_id: subCategoryId,
          resource_type_id: addEditPopupDetails.resource_type_id,
          resource_name: addEditPopupDetails.resource_name,
          pdf_category_id: addEditPopupDetails.pdf_category_id,
          pdf_group_category_id: addEditPopupDetails.pdf_group_category_id
        }
      }
      if (addEditPopupDetails.resource_type_id == 4) {
        payload = {
          resource_sub_category_id: subCategoryId,
          resource_type_id: addEditPopupDetails.resource_type_id,
          resource_name: addEditPopupDetails.resource_name,
          pdf_category_id: addEditPopupDetails.pdf_category_id,
          pdf_group_category_id: addEditPopupDetails.pdf_group_category_id,
          google_storage_id: addEditPopupDetails.google_storage_id
        }
      }

      UpdateResourceDataAPI(payload,
        (res) => {
          if (res.data.status === true) {
            setAddEditPopupDetails({
              flagOpen: false,
              popupType: "",
              resource_type_id: "",
              resource_name: "",
              pdf_category_id: "",
              pdf_group_category_id: "",
              google_storage_id: "",
              google_storage_original_file_name: "",
              resource_link: "",
              resource_sub_category_id: ""
            })
            setFlagGetCall(new Date().getTime())
            setShowMsg({
              status: false,
              error: false,
              msg: "",
            });
          } else {
            SetFlagLoader(false);
            setShowMsg({
              status: false,
              error: true,
              msg: res.data.error.message,
            });
          }
        },
        (err) => {
          SetFlagLoader(false);
          setShowMsg({
            status: false,
            error: true,
            msg: "somthing went wrong!",
          });
          // console.log("err", err);
        }
      );
    }
  }

  const handleDeleteYes = () => {

    SetFlagLoader(true);
    DeleteResourceAPI(addEditPopupDetails.resource_sub_category_id,
      (res) => {
        if (res.data.status === true) {
          SetFlagLoader(false);
          setFlagGetCall(new Date().getTime())
          setAddEditPopupDetails({
            flagOpen: false,
            popupType: "",
            resource_type_id: "",
            resource_name: "",
            pdf_category_id: "",
            pdf_group_category_id: "",
            google_storage_id: "",
            google_storage_original_file_name: "",
            resource_link: "",
            resource_sub_category_id: ""
          })
        }
      },
      (err) => {
        SetFlagLoader(false);
        // console.log("err", err);
      }
    );
  };

  useEffect(() => {
    SetFlagLoader(true);
    GetResourceConfigDataAPI(
      (res) => {
        console.log("get resource call", res);
        if (res?.data?.status) {
          setResourceListData(res?.data?.data)
          setAllData(res?.data?.data)
        }
        SetFlagLoader(false);
      },
      (err) => {
        SetFlagLoader(false);
      }
    );
  }, []);

  useEffect(() => {
    if (flagGetCall != false) {
      SetFlagLoader(true);
      GetResourceConfigDataAPI(
        (res) => {
          console.log("get resource call", res);
          if (res?.data?.status) {
            setResourceListData(res?.data?.data)
            setAllData(res?.data?.data)
          }
          SetFlagLoader(false);
        },
        (err) => {
          SetFlagLoader(false);
        }
      );
    }
  }, [flagGetCall]);

  useEffect(() => {
    if (addEditPopupDetails.resource_type_id) {
      GetResourceCategoryListAPI(
        (res) => {
          if (res.data.status === true) {
            setCategoryOptions(res.data.data);
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  }, [addEditPopupDetails.resource_type_id])
  useEffect(() => {
    if (addEditPopupDetails.pdf_category_id) {
      GetCategoryGroupDataAPI(addEditPopupDetails.pdf_category_id,
        (res) => {
          if (res.data.status === true) {
            console.log("grouplist", res)
            setGroupOptions(res.data.data);
          }
        },
        (err) => {
          console.log("err", err);
        }
      );
    }
  }, [addEditPopupDetails.pdf_category_id])

  useEffect(() => {
    // SetFlagLoader(true);
    GetResourceTypeListAPI(
      (res) => {
        if (res.data.status === true) {
          console.log("GetResourceTypeListAPI", res)
          // SetFlagLoader(false);
          setTypeOptions(res.data.data);
          UpdateResourceStateObject("resource_type_id", res.data.data[0].resource_type_id)
        }
      },
      (err) => {
        // SetFlagLoader(false);
      }
    );
  }, []);

  const disableBtn = () => {
    if (flagCategory === 1) {
      if (addCategoryName === "" || addCategoryName === undefined) {
        setButtonStatus(true);
      } else {
        setButtonStatus(false);
      }
    } else if (editTypeName === "Text") {
      if (editFeildPdfName === undefined || editFeildPdfName === "") {
        setButtonStatus(true);
      } else {
        setButtonStatus(false);
      }
    } else if (addEditPopupDetails.resource_type_id == 5) {
      if (
        categoryName === undefined ||
        categoryName === "" ||
        addPdfName === null ||
        addPdfName === "" ||
        addPdfName === undefined
      ) {
        setButtonStatus(true);
      } else {
        setButtonStatus(false);
      }
    } else if (editTypeName === "URL") {
      if (
        editFeildPdfName === undefined ||
        editFeildPdfName === "" ||
        resourceUrlLink === undefined ||
        resourceUrlLink === "" ||
        resourceUrlLink === null
      ) {
        setButtonStatus(true);
      } else {
        setButtonStatus(false);
      }
    } else if (addEditPopupDetails.resource_type_id == 6) {
      if (
        categoryName === undefined ||
        categoryName === "" ||
        resourceUrlLink === undefined ||
        resourceUrlLink === "" ||
        resourceUrlLink === null ||
        addPdfName === null ||
        addPdfName === "" ||
        addPdfName === undefined
      ) {
        setButtonStatus(true);
      } else {
        setButtonStatus(false);
      }
    } else if (editTypeName === "File upload") {
      if (
        editFeildPdfName === undefined ||
        editFeildPdfName === "" ||
        flagFileUploadededit === false ||
        flagFileUploadededit === undefined
      ) {
        setButtonStatus(true);
      } else {
        setButtonStatus(false);
      }
    } else if (addEditPopupDetails.resource_type_id == 4) {
      if (
        categoryName === undefined ||
        categoryName === "" ||
        addPdfName === null ||
        addPdfName === "" ||
        addPdfName === undefined ||
        flagFileUploaded === false ||
        flagFileUploaded === undefined
      ) {
        setButtonStatus(true);
      } else {
        setButtonStatus(false);
      }
    } else {
      setButtonStatus(false);
    }
  };

  function isUrlValid(userInput) {
    var res = userInput.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%.\+#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%\+.#?&//=]*)/g
    );
    if (res == null) return false;
    else return true;
  }

  return (
    <>
      {loader === true && <Loader />}

      <Dialog
        className="edit_pdf_popup1"
        open={addEditPopupDetails.flagOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="edit_pdf_popup2">
          <span
            style={{
              justifyContent: "end",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => {
              setAddEditPopupDetails({
                flagOpen: false,
                popupType: "",
                resource_type_id: "",
                resource_name: "",
                pdf_category_id: "",
                pdf_group_category_id: "",
                google_storage_id: "",
                google_storage_original_file_name: "",
                resource_link: "",
                resource_sub_category_id: ""
              })
            }}
          >
            <CloseIcon
              onClick={() => {
                setAddEditPopupDetails({
                  flagOpen: false,
                  popupType: "",
                  resource_type_id: "",
                  resource_name: "",
                  pdf_category_id: "",
                  pdf_group_category_id: "",
                  google_storage_id: "",
                  google_storage_original_file_name: "",
                  resource_link: "",
                  resource_sub_category_id: ""
                })
              }}
            />
          </span>
          <DialogContentText id="alert-dialog-description">
            {addEditPopupDetails?.popupType === "Edit" ? (
              <>
                <h5 style={{ color: "#D9A22E", margin: "-20px auto 40px auto" }}>
                  Edit Resources
                </h5>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {ShowMsg.error && (
                    <Alert className="editErr" severity="error">
                      {ShowMsg.msg}
                    </Alert>
                  )}
                  {ShowMsg.status && (
                    <Alert className="msgEdit" severity="success">
                      {ShowMsg.msg}
                    </Alert>
                  )}

                  <Autocomplete
                    id="disable-clearable"
                    className="autocomplete"
                    options={typeOptions}
                    getOptionLabel={(options) =>
                      typeof options === "string"
                        ? options
                        : options.resource_type_name
                    }
                    defaultValue={typeOptions?.filter((obj) => obj?.resource_type_id == addEditPopupDetails.resource_type_id)[0]}
                    sx={{ marginBottom: "7px" }}
                    onChange={(e, newValue) => {
                      setAddEditPopupDetails(old => {
                        let tempDetails = { ...old }
                        tempDetails.resource_type_id = newValue?.resource_type_id
                        return tempDetails
                      })
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Type" variant="standard" />
                    )}
                  />

                  <TextField
                    sx={{
                      marginBottom: "10px",
                    }}
                    label="Category"
                    type="text"
                    disabled
                    className="category_edit"
                    defaultValue={categoryOptions?.filter((obj) => obj?.pdf_category_id == addEditPopupDetails.pdf_category_id)[0]?.pdf_category_name}
                    variant="standard"
                  // value={editFeildCategory_name}
                  />


                  {groupOptions?.length !== 0 &&
                    <Autocomplete
                      id="disable-clearable"
                      className="autocomplete"
                      options={groupOptions}
                      getOptionLabel={(options) =>
                        options?.pdf_group_category_name !== undefined
                          ? options?.pdf_group_category_name
                          : "No Group"
                      }
                      defaultValue={groupOptions?.filter((obj) => obj?.pdf_group_category_id == addEditPopupDetails.pdf_group_category_id)[0]}
                      // Value={pdf_group_category_name}
                      sx={{ marginBottom: "7px" }}
                      onChange={(e, newValue) => {
                        setAddEditPopupDetails(old => {
                          let tempDetails = { ...old }
                          tempDetails.pdf_group_category_id = newValue?.pdf_group_category_id
                          return tempDetails
                        })
                        setShowMsg({
                          status: false,
                          error: false,
                          msg: "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Group"
                          variant="standard"
                        />
                      )}
                    />
                  }


                  <TextField
                    sx={{ marginBottom: "10px" }}
                    label="Resource Name"
                    inputProps={{ maxLength: 50 }}
                    type="text"
                    className="resourcesName"
                    variant="standard"
                    value={addEditPopupDetails.resource_name}
                    onChange={(e) => {
                      setAddEditPopupDetails(old => {
                        let tempDetails = { ...old }
                        tempDetails.resource_name = e.target.value
                        return tempDetails
                      })
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    }}
                  />

                  <Box
                    className="variablebox"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    {addEditPopupDetails.resource_type_id == 4 ? (
                      <Box className="pdficon_main">
                        <Box className="file_upload">
                          {
                            <FileUpload
                              sx={{ width: "23vw" }}
                              className="file_name"
                              type="file"
                              addEditPopupDetails={addEditPopupDetails}
                              setAddEditPopupDetails={setAddEditPopupDetails}
                              setRemovedFileIds={setRemovedFileIds}
                              setShowMsg={setShowMsg}
                            />
                          }
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          fontSize: "smaller",
                          marginRight: "20px",
                          width: "100%",
                        }}
                        className="url"
                      >
                        {addEditPopupDetails.resource_type_id == 6 && (
                          <TextField
                            sx={{ marginBottom: "10px" }}
                            label="Resources Link"
                            type="link"
                            variant="standard"
                            className="linktext"
                            defaultValue={addEditPopupDetails.resource_link}
                            onChange={(e) => {
                              if (isUrlValid(e.target.value)) {
                                setAddEditPopupDetails(old => {
                                  let tempDetails = { ...old }
                                  tempDetails.resource_link = e.target.value
                                  return tempDetails
                                })
                              } else {

                                return setShowMsg({
                                  status: false,
                                  error: true,
                                  msg: "Resource link is not valid",
                                });
                              }
                              setShowMsg({
                                status: false,
                                error: false,
                                msg: "",
                              });
                            }}
                          />
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            ) : addEditPopupDetails?.popupType === "Add" ? (
              <>
                <h5 style={{ color: "#D9A22E", margin: "-20px auto 40px auto" }}>
                  {" "}
                  Add Resource
                </h5>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {ShowMsg.error && (
                    <Alert className="errPdfConfig" severity="error">
                      {ShowMsg.msg}
                    </Alert>
                  )}
                  {ShowMsg.status && (
                    <Alert className="msgPdfConfig" severity="success">
                      {ShowMsg.msg}
                    </Alert>
                  )}

                  <Autocomplete
                    id="disable-clearable"
                    className="autocomplete"
                    options={typeOptions}
                    getOptionLabel={(options) =>
                      options?.resource_type_name !== undefined
                        ? options?.resource_type_name
                        : ""
                    }
                    defaultValue={typeOptions[0]}
                    sx={{ marginBottom: "7px" }}
                    onChange={(e, newValue) => {
                      setAddEditPopupDetails(old => {
                        let tempDetails = { ...old }
                        tempDetails.resource_type_id = newValue?.resource_type_id
                        return tempDetails
                      })
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Type" variant="standard" />
                    )}
                  />

                  <Autocomplete
                    id="disable-clearable"
                    className="autocomplete"
                    options={categoryOptions}
                    getOptionLabel={(options) =>
                      options?.pdf_category_name !== undefined
                        ? options?.pdf_category_name
                        : ""
                    }
                    sx={{ marginBottom: "7px" }}
                    onChange={(e, newValue) => {
                      setAddEditPopupDetails(old => {
                        let tempDetails = { ...old }
                        tempDetails.pdf_category_id = newValue?.pdf_category_id
                        return tempDetails
                      })
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        variant="standard"
                      />
                    )}
                  />
                  {groupOptions?.length !== 0 ? (
                    <>
                      <Autocomplete
                        id="disable-clearable"
                        className="autocomplete not_required"
                        options={groupOptions}
                        getOptionLabel={(options) =>
                          options?.pdf_group_category_name !== undefined
                            ? options?.pdf_group_category_name
                            : "No Group"
                        }
                        sx={{ marginBottom: "7px" }}
                        onChange={(e, newValue) => {
                          setAddEditPopupDetails(old => {
                            let tempDetails = { ...old }
                            tempDetails.pdf_group_category_id = newValue?.pdf_group_category_id
                            return tempDetails
                          })
                          setShowMsg({
                            status: false,
                            error: false,
                            msg: "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Group"
                            variant="standard"
                          />
                        )}
                      />
                    </>
                  ) : null}
                  <TextField
                    sx={{ marginBottom: "10px" }}
                    label="Resource Name"
                    type="text"
                    inputProps={{ maxLength: 50 }}
                    variant="standard"
                    onChange={(e) => {
                      setAddEditPopupDetails(old => {
                        let tempDetails = { ...old }
                        tempDetails.resource_name = e.target.value
                        return tempDetails
                      })
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });

                    }}
                  />
                  {addEditPopupDetails.resource_type_id == 6 && (
                    <TextField
                      sx={{ marginBottom: "10px", width: "max-width" }}
                      label="Resources Link"
                      type="link"
                      variant="standard"
                      onChange={(e) => {
                        if (isUrlValid(e.target.value)) {
                          setAddEditPopupDetails(old => {
                            let tempDetails = { ...old }
                            tempDetails.resource_link = e.target.value
                            return tempDetails
                          })
                        } else {
                          setShowMsg({
                            status: false,
                            error: true,
                            msg: "Url is not valid!",
                          });
                          // setButtonStatus(true);
                        }
                        // setFlagResource(true);
                        setShowMsg({
                          status: false,
                          error: false,
                          msg: "",
                        });

                        // disableBtn();
                      }}
                    />
                  )}
                  {addEditPopupDetails.resource_type_id == 4 && (
                    <FileUpload
                      className="file_name"
                      addEditPopupDetails={addEditPopupDetails}
                      setAddEditPopupDetails={setAddEditPopupDetails}
                      setRemovedFileIds={setRemovedFileIds}
                      setShowMsg={setShowMsg}
                      type="file"
                    />
                  )}
                </Box>
              </>
            ) : addEditPopupDetails?.popupType === "Delete" ? (
              <>
                <h5
                  style={{ color: "#D9A22E", margin: "-20px auto 40px auto" }}
                >
                  {" "}
                  Delete "{addEditPopupDetails.resource_name}" Resource
                </h5>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {" "}
                  <span>Are you sure you want to delete it?</span>
                </Box>
              </>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {addEditPopupDetails?.popupType === "Delete" ? (
            <>
              <Button onClick={handleDeleteYes}>Yes</Button>
              <Button
                onClick={() => {
                  setAddEditPopupDetails({
                    flagOpen: false,
                    popupType: "",
                    resource_type_id: "",
                    resource_name: "",
                    pdf_category_id: "",
                    pdf_group_category_id: "",
                    google_storage_id: "",
                    google_storage_original_file_name: "",
                    resource_link: "",
                    resource_sub_category_id: ""
                  })
                }}
              >
                No
              </Button>
            </>
          ) : (
            <Button onClick={() => { SaveResourceData() }}>Save</Button>
          )}
        </DialogActions>
      </Dialog>
      <Box className="page_title_annuity_application page_title">
        <Typography variant="span">Resources</Typography>
      </Box>
      <Box className="main_container_pdf_history">
        <Box className="btn-search-div" sx={{ marginTop: "20px" }}>
          <Box className="btns">
            <Button
              className="btn_New_Pdf"
              variant="contained"
              onClick={() => {
                navigate("/");
              }}
            >
              Back
            </Button>
            <Button
              className="btn_New_Pdf"
              variant="contained"
              onClick={() => {
                AddResourcePopupOpen("Add")
              }}
            >
              Add Resource
            </Button>
            <Button
              className="btn2"
              variant="contained"
              onClick={() => {
                SetFlagLoader(true);
                navigate("/category");
              }}
            >
              View Category
            </Button>
            <Button
              onClick={() => {
                navigate("/Group");
              }}
              className="btn3"
              variant="contained"
            >
              view Group
            </Button>
            {state.userType === "Admin" &&
              <>
                <Button
                  onClick={() => {
                    navigate("/resource_order");
                  }}
                  className="btn4"
                  variant="contained"
                >
                  Resources Order
                </Button>
              </>
            }

          </Box>
          <Box className="searchbar">
            {/* <InputAdornment>11</InputAdornment> */}
            <TextField
              className="Heellllooo"
              id="filled-hidden-label-small"
              variant="filled"
              size="small"
              placeholder="Search..."
              onChange={(e) => {
                searchfunction(e.target.value);
              }}
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Box>
        </Box>

        <Box className="Grid_box_Pdf_config">
          <TableContainer style={{ maxHeight: "70vh" }} >
            <Table sx={{ minWidth: 650 }} aria-label="simple table" className="resourceTable" stickyHeader>
              <TableHead>
                <TableRow className="resourceTableHeaderRow">
                  <TableCell>Category</TableCell>
                  <TableCell>Group</TableCell>
                  <TableCell>Resources Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>File</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {resourceListData?.length ?
                  resourceListData?.map((categoryRow, i) => {
                    return (
                      <>
                        {categoryRow?.pdf_category_resource_data?.length ?
                          categoryRow.pdf_category_resource_data.map((groupRow, j) => {
                            return (
                              groupRow.pdf_resource_flag && !groupRow.resource_hidden ?
                                <>
                                  <TableRow key={j} className="resourceTableRow">
                                    <TableCell align="left">{categoryRow.pdf_category_name}</TableCell>
                                    <TableCell align="left">{""}</TableCell>
                                    <TableCell align="left">{groupRow.resource_name}</TableCell>
                                    <TableCell align="left">{groupRow.resource_type_name}</TableCell>
                                    <TableCell align="center">
                                      {
                                        groupRow?.resource_type_name == "File upload" ?
                                          <a href={groupRow?.google_storage_file} target="_blank">
                                            <img src={PDF2Icon} width="30px" height="30px" />
                                          </a>
                                          : ""
                                      }
                                    </TableCell>
                                    <TableCell align="center">
                                      <img
                                        src={Edit}
                                        width="20px"
                                        height="20px"
                                        style={{
                                          marginRight: "7px",
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          let obj = {
                                            resource_type_id: groupRow.resource_type_id,
                                            resource_name: groupRow.resource_name,
                                            pdf_category_id: categoryRow.pdf_category_id,
                                            pdf_group_category_id: "",
                                            google_storage_id: groupRow.google_storage_id,
                                            google_storage_original_file_name: groupRow.google_storage_original_file_name,
                                            resource_link: groupRow.resource_link,
                                            resource_sub_category_id: groupRow.resource_sub_category_id
                                          }
                                          setSubCategoryId(groupRow.resource_sub_category_id)
                                          EditResourcePopupOpen("Edit", obj)
                                        }}
                                      />

                                      <img
                                        src={Delete}
                                        width="20px"
                                        height="20px"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { DeleteResourcePopupOpen("Delete", groupRow.resource_name, groupRow.resource_sub_category_id) }}
                                      />
                                    </TableCell>
                                  </TableRow>
                                </>
                                : !groupRow?.resource_hidden && groupRow?.pdf_group_resource_data?.map((resourceDetail, k) => {
                                  return (
                                    <TableRow key={k} className="resourceTableRow">
                                      <TableCell align="left">{categoryRow.pdf_category_name}</TableCell>
                                      <TableCell align="left">{groupRow.pdf_group_category_name}</TableCell>
                                      <TableCell align="left">{resourceDetail.resource_name}</TableCell>
                                      <TableCell align="left">{resourceDetail.resource_type_name}</TableCell>
                                      <TableCell align="center">{
                                        resourceDetail.resource_type_name == "File upload" ?
                                          <a href={resourceDetail.google_storage_file} target="_blank">
                                            <img src={PDF2Icon} width="30px" height="30px" />
                                          </a>
                                          : ""
                                      }</TableCell>
                                      <TableCell align="center">
                                        <img
                                          src={Edit}
                                          width="20px"
                                          height="20px"
                                          style={{
                                            marginRight: "7px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            let obj = {
                                              resource_type_id: resourceDetail.resource_type_id,
                                              resource_name: resourceDetail.resource_name,
                                              pdf_category_id: categoryRow.pdf_category_id,
                                              pdf_group_category_id: groupRow.pdf_group_category_id,
                                              google_storage_id: resourceDetail.google_storage_id,
                                              resource_link: resourceDetail.resource_link,
                                              resource_sub_category_id: resourceDetail.resource_sub_category_id
                                            }
                                            setSubCategoryId(resourceDetail.resource_sub_category_id)
                                            EditResourcePopupOpen("Edit", obj)
                                          }}
                                        />
                                        <img
                                          src={Delete}
                                          width="20px"
                                          height="20px"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => { DeleteResourcePopupOpen("Delete", resourceDetail.resource_name, resourceDetail.resource_sub_category_id) }}
                                        />
                                      </TableCell>
                                    </TableRow>
                                  )
                                })
                            )
                          })
                          : ""
                        }
                      </>
                    )
                  })
                  :
                  <TableRow className="resourceTableRow">
                    <TableCell align="center" colSpan={6}>No data available</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};
export default PdfConfig;
