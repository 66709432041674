import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Input,
  Snackbar,
  Alert,
  InputAdornment,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
  useLayoutEffect,
} from "react";
import Delete from "../../../assets/delete.svg";
import Edit from "../../../assets/edit.svg";
import { Context as userLoaderContext } from "../../../context/userLoaderContext";

const Popup = (props) => {
  return (
    <>
      <Dialog
        open={props.open}
      //   onClose={props.onClose}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ marginTop: "5px" }}
        >
          {props.data}
        </Grid>
      </Dialog>
    </>
    // <>
    //   <Dialog
    //     className="edit_pdf_popup1"
    //     open={true}
    //     aria-labelledby="alert-dialog-title"
    //     aria-describedby="alert-dialog-description"
    //   >
    //     <DialogContent className="edit_pdf_popup2">
    //       <>
    //         <h4 style={{ color: "#D9A22E", margin: "20px auto 40px auto" }}>
    //           Delete Category
    //         </h4>
    //         <Box sx={{ display: "flex", flexDirection: "column" }}>
    //           <span>Are you sure you want to delete it?</span>
    //         </Box>
    //       </>
    //     </DialogContent>
    //     <DialogActions>
    //       <Button>Yes</Button>
    //       <Button>No</Button>
    //     </DialogActions>
    //   </Dialog>
    // </>
  );
};

export default Popup;
