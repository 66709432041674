import React from 'react';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NestedMenuItem from "./NestedMenuItem";
import { styled } from "@mui/system";


export const DropdownMenuItem = styled(MenuItem)`
  display: flex;
  justify-content: space-between !important;

  & > svg {
    margin-left: 32px;
  }
`;

export const DropdownNestedMenuItem = styled(NestedMenuItem)`
  display: flex;
  justify-content: space-between !important;

  & > svg {
    margin-left: 32px;
  }
`;


const App = React.forwardRef(
    (props, ref) => {
        const { trigger,
            menu,
            keepOpenGlobal,
            controlledIsOpen,
            onControlledOpen,
            minWidth } = props;
        const [isInternalOpen, setInternalOpen] = React.useState(null)

        const isOpen = isInternalOpen;

        let anchorRef = React.useRef(null);
        if (ref) {
            anchorRef = ref;
        }

        const handleOpen = (event) => {
            event.stopPropagation();

            if (menu.length) {
                onControlledOpen
                    ? onControlledOpen(event.currentTarget)
                    : setInternalOpen(event.currentTarget);
            }
        };

        const handleClose = (event) => {
            event.stopPropagation();

            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }

            handleForceClose();
        };

        const handleForceClose = () => {
            onControlledOpen ? onControlledOpen(null) : setInternalOpen(null);
        };

        const renderMenu = (menuItem, index) => {
            const { keepOpenLocal, ...props } = menuItem.props;

            let extraProps = {};
            if (props.menu) {
                extraProps = {
                    parentMenuOpen: isOpen
                };
            }

            return React.createElement(menuItem.type, {
                ...props,
                key: index,
                ...extraProps,
                onClick: (event) => {
                    event.stopPropagation();

                    if (!keepOpenGlobal && !keepOpenLocal) {
                        handleClose(event);
                    }

                    if (menuItem.props.onClick) {
                        menuItem.props.onClick(event);
                    }
                },
                children: props.menu
                    ? React.Children.map(props.menu, renderMenu)
                    : props.children
            });
        };

        return (
            <>
                {React.cloneElement(trigger, {
                    onClick: isOpen ? handleForceClose : handleOpen,
                    ref: anchorRef
                })}

                <Menu
                    PaperProps={{ sx: { minWidth: minWidth ?? 0 } }}
                    anchorEl={isOpen}
                    open={!!isOpen}
                    onClose={handleClose}
                    className='move_option'
                >
                    {React.Children.map(menu, renderMenu)}
                </Menu>
            </>
        );
    }
);

export default App;