import React, { Fragment, useContext, useState } from 'react';
import { Typography, Dialog, Box, Button, Grid } from '@mui/material';
import PhoneVerifyImg from '../../assets/phoneVerifyImg.png'
import MuiPhoneNumber from 'material-ui-phone-number';
import './verifyPhoneDialog.css'
import { Context as userLoaderContext } from "../../context/userLoaderContext";
import { UpdateUserPhone } from '../../api/V3/iaaApplicationAPI';

const VerifyPhoneDialog = (props) => {
    const { setFlagVerifyPhonePopup, SendDataToServer } = props
    const { SetFlagLoader } = useContext(userLoaderContext);
    const { user } = useContext(userLoaderContext).state;

    const formatPhoneNumberForContext = (phoneNumber) => {
        if (!phoneNumber) {
            return ""
        }
        if (phoneNumber?.replace(/[^\d+]/g, '').length > 10) {
            let formatedPhone = phoneNumber.replace("-", " ")
            formatedPhone = "+" + formatedPhone
            return formatedPhone
        } else {
            let formatedPhone = phoneNumber?.replace(/[^\d+]| /g, '')
            formatedPhone = "+1 " + formatedPhone
            return formatedPhone
        }
    }

    const [verifyPhoneNumberValue, setVerifyPhoneNumberValue] = useState(formatPhoneNumberForContext(user?.phone))


    const GetPhoneNumberPayloadFormate = (phoneNumber) => {
        let gg = phoneNumber.split(" ");
        let ggCountryCode = gg[0];
        gg.shift();
        let ggPhone = gg.join();

        ggCountryCode = ggCountryCode.replace(/[^\d+]/g, '');
        ggPhone = ggPhone.replace(/[^\d+]/g, '');
        if (ggCountryCode.startsWith('+')) {
            ggCountryCode = ggCountryCode.substring(1);
        }

        // let countryCodeEndIndex = 1;
        // if (cleanNumber.length > 4) {
        //     if (!isNaN(cleanNumber[1]) && cleanNumber.length > 2) {
        //         countryCodeEndIndex = 2;
        //     }
        //     if (!isNaN(cleanNumber[2]) && cleanNumber.length > 3) {
        //         countryCodeEndIndex = 3;
        //     }
        // }

        // const countryCode = cleanNumber.substring(0, countryCodeEndIndex);
        // const restOfTheNumber = cleanNumber.substring(countryCodeEndIndex);

        return `${ggCountryCode}-${ggPhone}`;
    }

    return (
        <Fragment>
            <Dialog
                open={true}
                onClose={() => { setFlagVerifyPhonePopup(false) }}
            >
                <Grid container justifyContent={"center"}>
                    <Grid item xs={12} className='verify_popup_item verify_popup_title'>Confirm Phone Number</Grid>
                    <Grid item xs={12} className='verify_popup_item verify_popup_phone_img'>
                        <img src={PhoneVerifyImg} />
                    </Grid>
                    <Grid item xs={6.5} className='verify_popup_phone_label'>
                        Phone Number
                    </Grid>
                    <Grid item xs={6.5} className='verify_popup_phone_input'>
                        <MuiPhoneNumber
                            name="phone"
                            data-cy="user-phone"
                            defaultCountry={"us"}
                            value={verifyPhoneNumberValue}
                            className="input_field_width varify_popup_field_icon"
                            onChange={(value) => {
                                console.log("phoneWithFlag", value)
                                console.log("phoneWithFlag :", value.replace(/\D/g, '').length === 11)
                                setVerifyPhoneNumberValue(value)
                            }}
                        // countryCodeEditable={false}
                        />
                    </Grid>
                    <Grid item xs={12} className='verify_popup_item' sx={{ marginTop: "20px", marginBottom: "40px !important" }} >
                        DocuSign will call or sms on this phone number for verification.
                    </Grid>
                    <Grid item xs={6} className='verify_popup_item'>
                        <Grid container justifyContent={"center"}>
                            <Grid item xs={5} mr={2}>
                                <Button
                                    fullWidth
                                    variant='outlined'
                                    sx={{
                                        minWidth: "84px",
                                        margin: "0px !important",
                                        textTransform: "capitalize!important",
                                        background: "#fff !important",
                                        color: "#D9A22E !important",
                                        borderColor: "#D9A22E !important",
                                    }}
                                    onClick={() => { setFlagVerifyPhonePopup(false) }}
                                >
                                    Cancel
                                </Button>
                            </Grid>
                            <Grid item xs={5}>
                                <Button
                                    fullWidth
                                    sx={{
                                        minWidth: "84px",
                                        margin: "0px !important",
                                        textTransform: "capitalize!important",
                                        opacity: verifyPhoneNumberValue?.replace(/\D/g, '').length >= 11 ? 1 : 0.5,
                                    }}
                                    variant='contained'
                                    disabled={!(verifyPhoneNumberValue?.replace(/\D/g, '').length >= 11)}
                                    onClick={() => {
                                        SetFlagLoader(true)
                                        let obj = {
                                            phone: GetPhoneNumberPayloadFormate(verifyPhoneNumberValue)
                                        }
                                        UpdateUserPhone(obj, (res) => {
                                            if (res?.data?.status) {
                                                setFlagVerifyPhonePopup(false)
                                                SendDataToServer("submit", true)
                                            } else {
                                                SetFlagLoader(false)
                                                alert("error")
                                            }
                                        }, (err) => {
                                            SetFlagLoader(false)
                                            alert("error")
                                        })
                                    }}
                                >
                                    Confirm
                                </Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>

            </Dialog>
        </Fragment>
    )
}

export default VerifyPhoneDialog
