import { Box, Typography } from '@mui/material'
import React from 'react'
import FinancialRating from "../../assets/am_best_good_logo.png";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import './footer.css'
export const Footer = () => {
    return (
        <>
            <Box className="home_page_footer">
                <Box className="contact_us_section_footer">
                    <Box className="contact_detail_footer">
                        <Typography sx={{ marginBottom: 2 }} variant="span">
                            Contact Us
                        </Typography>
                        <Typography variant="span">
                            243 North Peters Road
                            <br />
                            Knoxville, TN 37923-4910
                            <br />
                            <a
                                className="footer_links_with_hover"
                                href="mailto:support@farmerslifeins.com"
                            >
                                support@farmerslifeins.com
                            </a>
                        </Typography>
                    </Box>
                </Box>

                <Box className="imp_link_section1_footer">
                    <ul>
                        <li>
                            <a
                                className="footer_links_with_hover"
                                href="https://farmerslifeins.com/"
                            >
                                Home
                            </a>{" "}
                        </li>
                        <li>
                            <a
                                className="footer_links_with_hover"
                                href="https://farmerslifeins.com/about/"
                            >
                                About
                            </a>
                        </li>
                        <li>
                            <a
                                className="footer_links_with_hover"
                                href="https://farmerslifeins.com/annuities/"
                            >
                                Annuties
                            </a>
                        </li>
                        <li>
                            <a
                                className="footer_links_with_hover"
                                href="https://farmerslifeins.com/products/"
                            >
                                Products
                            </a>{" "}
                        </li>
                        <li>
                            <a
                                className="footer_links_with_hover"
                                href="https://farmerslifeins.com/agents/"
                            >
                                Agents
                            </a>
                        </li>
                        <li>
                            <a
                                className="footer_links_with_hover"
                                href="https://farmerslifeins.com/contact/"
                            >
                                Contact
                            </a>
                        </li>
                    </ul>
                </Box>

                <Box className="financial_section_footer">
                    <img src={FinancialRating} alt="financial rating" />
                    <Typography className="financial_rating_desc" sx={{ width: "63%", textAlign: "center", color: "#f3e8dc" }} variant="span">
                        Farmers Life Insurance Company has been assigned a Financial Strength Rating of B++ (Good) from AM Best.

                        <a style={{
                            color: "white",
                            textDecoration: "none",
                            // fontWeight: "bold"
                        }}
                            href="https://news.ambest.com/presscontent.aspx?refnum=32852&amp;altsrc=2&amp;_ga=2.100183262.1161022748.1670871522-1859563721.1668715729"
                            target="_blank" rel="noopener">
                            &nbsp;Learn More <span style={{ verticalAlign: "middle" }}><KeyboardDoubleArrowRightIcon /></span>
                        </a>
                    </Typography>
                </Box>
                <Box className="copyright_info">
                    <Typography variant="span">
                        Copyright © 2022 Farmers Life Insurance Company. All rights
                        reserved. Policies issued by Farmers Life Insurance Company (FLIC),
                        Knoxville, TN. FLIC is responsible for financial obligations of
                        insurance products and guarantees are backed by the company’s
                        claims-paying ability.{" "}
                        <a
                            className="footer_links_with_hover"
                            href="https://farmerslifeins.com/privacy-policy/"
                        >
                            Privacy Policy
                        </a>{" "}
                        |{" "}
                        <a
                            className="footer_links_with_hover"
                            href="https://farmerslifeins.com/terms/"
                        >
                            Terms and Conditions
                        </a>
                    </Typography>
                </Box>
            </Box>
        </>
    )
}
