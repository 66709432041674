export function isValidEmail(email) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}
export function isValidZip(zip) {
    return /^[0-9]{5}(?:-[0-9]{4})?$/.test(zip);
}
export function isValidNPN(npn) {
    return /^\d{1,10}$/.test(npn);
}
export function isValidWebsite(website) {
    return /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/.test(website);
}
export function validatePhoneNumberLatest(input_str) { //phone number validation with country flag
    return input_str?.replace(/\D/g, '').length >= 11
}
export function validatePhoneNumber(input_str) { //without country code
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return re.test(input_str);
}
