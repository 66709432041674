import axiosInstance from "./axios";

const LogoutUserAPI = async (data, onSuccess, onError)=>{
    try{
        console.log("Printing data", data);
        const response = await axiosInstance.post("/api/user/logout", {}, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(response);
    }
    catch(err){
        onError && onError(err);
    }
}

export default LogoutUserAPI;