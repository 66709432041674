import React, { useContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom';
import { getResourceFileAPI } from '../../api/resourceConfig';
import { Context } from "../../context/userLoaderContext";


export const ViewResource = () => {
    const { state, SetFlagLoader } = useContext(Context);

    const [searchParams] = useSearchParams();
    const [fileUrl, setFileUrl] = useState("");

    useEffect(() => {
        SetFlagLoader(true)
        let id = searchParams.get("google_storage_id")
        getResourceFileAPI(id, (res) => {
            if (res?.data?.status) {
                setFileUrl(res?.data?.data[0]?.destination)
            }
            console.log("ressss", res)
            SetFlagLoader(false)
        }, (err) => {
            SetFlagLoader(false)
            console.log("ressss", err)
        })

    }, [])

    return (
        fileUrl?.length ?
            <iframe src={fileUrl} width="100%" style={{ height: "100vh" }}></iframe> : ""
    )
}

export default ViewResource