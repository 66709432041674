import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { BrowserRouter } from 'react-router-dom';
import { Provider as UserLoaderProvider } from './context/userLoaderContext';

ReactDOM.render(
    <Fragment>
        <BrowserRouter>
            <UserLoaderProvider>
                <App />
            </UserLoaderProvider>
        </BrowserRouter>
    </Fragment>, document.getElementById("root")
)