import { Box, Button, Divider, Menu, MenuItem, MenuList } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Logo from "../../assets/FLIC.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./header.css";
import { Context } from "../../context/userLoaderContext";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutUserAPI from "../../api/logoutUserAPI";

const Header = () => {
  const { state, SetFlagLoader } = useContext(Context);
  console.log(state?.user);
  const [flagUserOptions, setFlagUserOptions] = useState(false);
  const [flagHistoryOptions, setFlagHistoryOptions] = useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  }, []);

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    console.log("state", state);
    console.log("userType", state?.userType);
    console.log(window.location.pathname);
  }, [state?.userType, window.location.pathname]);

  const LogoutUser = () => {
    SetFlagLoader(true);
    LogoutUserAPI(
      {},
      (res) => {
        SetFlagLoader(false);
        console.log("Printing response of logout user api call", res);

        if (res.data.status === true) {
          window.location.replace("/login");
          // navigate("/login");
        } else {
          // alert("Something went wrong, Please try again later.!");
        }
      },
      (err) => {
        SetFlagLoader(false);
        // alert("Something went wrong, Please try again later.!");
      }
    );
  };
  return (
    <>
      <Box className="global_header">
        <Box className="company_logo">
          {location.pathname === "/login" || location.pathname === "/" ? (
            <a href="https://farmerslifeins.com/">
              <img src={Logo} alt="logo" />
            </a>
          ) : (
            <a href="/">
              <img src={Logo} alt="logo" />
            </a>
          )}
        </Box>
        {state?.user?.email === undefined ? (
          <Box className="header_right_section">
            <Link to="/register">Register</Link>
            <Link to="/login">Login</Link>
          </Box>
        ) : (
          <Box className="header_right_section">
            {width > 843 && state?.userType === "Admin" ? (
              <Fragment>
                <Button variant="outlined" className="my_bussiness_btn">
                  <a target="_blank" style={{ color: "#fff", padding: "5px 8px" }} href="https://farmerslifeins.verifiedagentusa.com/Account/Login">
                    My Business Portal
                  </a>
                </Button>
                <Link sx={{ color: "#000" }} to="/">
                  Resources
                </Link>
                <Button
                  onClick={() => {
                    setFlagHistoryOptions(!flagHistoryOptions);
                  }}
                  sx={{ textTransform: "capitalize", color: "#000" }}
                >
                  E-App History
                  <KeyboardArrowDownIcon />
                </Button>
                <Menu
                  open={flagHistoryOptions}
                  onClose={() => {
                    setFlagHistoryOptions(!flagHistoryOptions);
                  }}
                  id="history_options_menu"
                >
                  <MenuList>
                    <MenuItem onClick={() => { navigate("/myga_application_history"); setFlagHistoryOptions(false) }} sx={{ marginTop: "-10px", padding: "10px 10px 10px 10px" }}>
                      MYGA History
                    </MenuItem>
                    <MenuItem onClick={() => { navigate("/fia_application_history"); setFlagHistoryOptions(false) }} sx={{ marginTop: "-10px", padding: "10px 10px 10px 10px" }}>
                      FIA History
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Link sx={{ color: "#000" }} to="/agents">
                  Agents
                </Link>
                <Link sx={{ color: "#000" }} to="/history">
                  Calculator
                </Link>
              </Fragment>
            ) : (
              width > 843 && (
                <Fragment>

                  <Button variant="outlined" className="my_bussiness_btn">
                    <a target="_blank" style={{ color: "#fff", padding: "5px 8px" }} href="https://farmerslifeins.verifiedagentusa.com/Account/Login">
                      My Business Portal
                    </a>
                  </Button>
                  <Link
                    sx={{ color: "#000", fontSize: "14px" }}
                    to="/"
                  >
                    Forms & Resources
                  </Link>
                  <Button
                    onClick={() => {
                      setFlagHistoryOptions(!flagHistoryOptions);
                    }}
                    sx={{ textTransform: "capitalize", color: "#000" }}
                  >
                    E-App History
                    <KeyboardArrowDownIcon />
                  </Button>
                  <Menu
                    open={flagHistoryOptions}
                    onClose={() => {
                      setFlagHistoryOptions(!flagHistoryOptions);
                    }}
                    id="history_options_menu_agent"
                  >
                    <MenuList>
                      <MenuItem onClick={() => { navigate("/myga_application_history") }} sx={{ marginTop: "-10px", padding: "10px 10px 10px 10px" }}>
                        MYGA History
                      </MenuItem>
                      <MenuItem onClick={() => { navigate("/fia_application_history") }} sx={{ marginTop: "-10px", padding: "10px 10px 10px 10px" }}>
                        FIA History
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Fragment>
              )
            )}
            <Button
              onClick={() => {
                setFlagUserOptions(!flagUserOptions);
              }}
              sx={{ textTransform: "capitalize", color: "#000" }}
            >
              {state?.user?.first_name}{" "}
              {`${state?.user?.last_name.substr(0, 1)}`}{" "}
              <KeyboardArrowDownIcon />
            </Button>
            <Menu
              open={flagUserOptions}
              onClose={() => {
                setFlagUserOptions(!flagUserOptions);
              }}
              id="user_options_menu"
            >
              <MenuList>
                <MenuItem
                  sx={{ marginTop: "-10px", padding: "20px 20px 20px 10px" }}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    {state?.user?.first_name}
                  </span>
                  &nbsp;
                  <span style={{ textTransform: "capitalize" }}>
                    {state?.user?.last_name}
                  </span>
                </MenuItem>
                <Divider sx={{ border: "1px solid #ddd", width: "100%" }} />
                {width <= 843 && state?.userType === "Admin" ? (
                  <Fragment>
                    <MenuItem>
                      <a target="_blank" href="https://farmerslifeins.verifiedagentusa.com/Account/Login">
                        My Business Portal
                      </a>
                    </MenuItem>
                    <MenuItem>
                      <Link sx={{ color: "#000" }} to="/myga_application_history">
                        MYGA History
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link sx={{ color: "#000" }} to="/fia_application_history">
                        FIA History
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link sx={{ color: "#000" }} to="/pdf-config">
                        Resources
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link sx={{ color: "#000" }} to="/agents">
                        Agents
                      </Link>
                    </MenuItem>
                    <MenuItem>
                      <Link sx={{ color: "#000" }} to="/calculator-config">
                        Calculator
                      </Link>
                    </MenuItem>
                    <Divider sx={{ border: "1px solid #ddd", width: "100%" }} />
                  </Fragment>
                ) : (
                  width <= 843 && (
                    <Fragment>
                      <MenuItem>
                        <a target="_blank" href="https://farmerslifeins.verifiedagentusa.com/Account/Login">
                          My Business Portal
                        </a>
                      </MenuItem>
                      <MenuItem>
                        <Link sx={{ color: "#000" }} to="/">
                          Forms & Resources
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link sx={{ color: "#000" }} to="/myga_application_history">
                          MYGA History
                        </Link>
                      </MenuItem>
                      <MenuItem>
                        <Link sx={{ color: "#000" }} to="/fia_application_history">
                          FIA History
                        </Link>
                      </MenuItem>
                      <Divider
                        sx={{ border: "1px solid #ddd", width: "100%" }}
                      />
                    </Fragment>
                  )
                )}
                <MenuItem
                  onClick={LogoutUser}
                  sx={{
                    paddingLeft: "10px",
                    color: "#074a6e",
                    position: "relative",
                    top: "-5px",
                  }}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        )}
      </Box>
    </>
  );
};

export default Header;
