import Axios from "./axios";

export const YearListAPI = async (onSuccess, onError) => {
  try {
    // console.log("ID Data ",data);
    // debugger;
    const responce = await Axios.get("/api/user/year_list", {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    //   console.log("printing responce", responce)
    // debugger;
    onSuccess && onSuccess(responce);
    // debugger;
  } catch (err) {
    //    console.log("got an error",err);
    onError && onError(err);
  }
};

export const PutYearListAPI = async (data, onSuccess, onError) => {
  try {
    // console.log("ID Data ",data);
    // debugger;
    const responce = await Axios.put("/api/user/year_list", data, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    //   console.log("printing responce", responce)
    // debugger;
    onSuccess && onSuccess(responce);
    // debugger;
  } catch (err) {
    //    console.log("got an error",err);
    onError && onError(err);
  }
};
