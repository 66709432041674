import React from "react";
import {
  Box,
  Typography,
  Link,
  TextField,
  Button,
  Input,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  DialogTitle,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import Farmers_img from "../../assets/FarmersLifeHomepage.png";
import "./calculator.css";
import FinancialRating from "../../assets/financial_rating.png";
import { useState, useContext } from "react";
import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import { useEffect } from "react";
import { StateAPI } from "../../service/stateList.service";
import { YearListAPI } from "../../service/year.service";
import { SaveDataAPI } from "../../service/savedata.service";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import success_dialog from "../../assets/success_dialog.png";
//import download_icon from "../../assets/btn_icon.png";
import { useNavigate } from "react-router-dom";
import { HistoryAPI } from "../../service/calculator_history.service";
import { ViewCalcIdDataAPI } from "../../service/viewcalc_data_id.service";
import { DownloadPdfAPI } from "../../service/download_pdf.service";
import { Context } from "../../context/userLoaderContext";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import { Footer } from "../../components/footer/footer";

// const calc =  (a,b) =>
//     a+b

const Calculator = (props) => {
  // calc(3,4);

  const [periodValue, setPeriodValue] = useState(0);
  const [periodValueObj, setPeriodValueObj] = useState([{}]);
  const [interstRate, setInterstRate] = useState(0);
  const [interstRate1, setInterstRate1] = useState(0);
  const [year_list_id, setYear_list_id] = useState();
  const [state_id, setStateId] = useState();
  const [state_Name, setStateName] = useState();
  const [amount, setAmount] = useState();
  const [age, setAge] = useState([]);
  const [name, setName] = useState("");
  const [stateList, setStateList] = useState([{}]);
  const [amountText, setAmountText] = useState(null);
  const [surrenderCharge, setSurrenderCharge] = useState([[]]);
  const [surrender_charge_array, setSurrender_charge_array] = useState([]);
  const [final_value, setFinal_value] = useState();
  const [freeWithdrawalRider, setFreeWithdrawalRider] = useState(false);
  const [enhancedBenefitRider, setEnhancedBenefitRider] = useState(false);
  const [finalChartData, setFinalChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Loading...",
        data: [],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  });
  const [ShowMsg, setShowMsg] = useState({
    status: false,
    error: false,
    msg: "",
  });
  const [sum, setSum] = useState(0);
  const [msg, setMsg] = useState({});
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([{}]);
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const [checked, setChecked] = React.useState(true);
  const [prepardBy, setPrepardBy] = useState("");
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const { state, SetFlagLoader } = useContext(Context);
  console.log(state.user.first_name + " " + state.user.last_name);
  const queryParams = new URLSearchParams(window.location.search);
  const calculation_id = queryParams.get("calculation_id");
  const addCommas = (num) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num;
  };

  const downloadBase64File = (contentBase64, fileName) => {
    const linkSource = `data:application/pdf;base64,${contentBase64}`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = "_self";
    downloadLink.download = fileName;
    downloadLink.click();
  };

  useEffect(() => {
    var ff = surrender_charge_array;
    // debugger;
  }, [surrender_charge_array]);

  //  useEffect(()=>{
  //   debugger;
  //   if(freeWithdrawalRider && enhancedBenefitRider){
  //     setInterstRate(interstRate1-0.002);
  //   }else if(freeWithdrawalRider){
  //     setInterstRate(interstRate1-0.001);
  //   }
  //   else if(enhancedBenefitRider){
  //     setInterstRate(interstRate1-0.001);
  //   }else{
  //     setInterstRate(interstRate1);
  //   }
  //  },[freeWithdrawalRider,enhancedBenefitRider,year_list_id])
  const removeNonNumeric = (num) => num.toString().replace(/[^0-9]/g, "");

  useEffect(() => {
    SetFlagLoader(false);
    StateAPI(
      (res) => {
        if (res.data.status === true) {
          res.data.data.map((rr) => {
            rr["label"] = rr["state_name"];
            //setStateId(rr.state_id)
          });
          // let finalData = res.data.data;
          // // console.log("dlkfjhdslkfghdslfjghdsgfkjdfskjdgf",res.data.data)
          // var t1 = {
          //   state_id: "",
          //   value: "",
          //   state_name: "",
          //   label: "None",
          // };
          // var t2 = res.data.data;
          // var t3 = [{ ...t1 }, ...t2];
          // // var finalData = finalData1.unshift(FisTempdata);
          setStateList(res.data.data);
          // console.log("State list", t3);
        } else {
          console.log("res.data.status false");
        }
      },
      (err) => {
        console.log("err", err);
      }
    );

    YearListAPI((res) => {
      //console.log("123455678900123456576879879",res.data);
      if (res.data.status == true) {
        setPeriodValueObj(res.data.data);
      }
    });

    // if (calculation_id) {
    //   // debugger;
    //   ViewCalcIdDataAPI(calculation_id, (res) => {
    //     // console.log("7777777777777777",res.data.data);
    //     if (res.data.status === true) {
    //       var resData = res.data.data[0];
    //       setData(resData);
    //       console.log("resData", resData);
    //       setTimeout(() => {
    //         let getYearSList = resData.surrender_charge.map(
    //           (rr) => rr.surrender_charge_value
    //         );
    //         console.log(
    //           "getYearSList ::::::::::::::::::::::::::::::::::",
    //           getYearSList
    //         );
    //         // let findYearListID = periodValueObj.filter(rr=>rr.year_list_name==resData.year)
    //         // setYear_list_id(findYearListID[0]['year_list_id'])

    //         setName(resData.name);
    //         setAmountText(addCommas(resData.amount));
    //         setAmount(Number(resData.amount));
    //         setSurrender_charge_array(getYearSList);
    //         setPeriodValue(resData.year);
    //         setAge(resData.age);
    //         setStateName(resData.state);
    //         setFreeWithdrawalRider(resData.free_withdrawal_rider);
    //         setEnhancedBenefitRider(resData.enhanced_benefit_rider);
    //         let tempInterestRate1 = resData.interest_rate;
    //         setInterstRate1(tempInterestRate1);

    //         if (
    //           resData.enhanced_benefit_rider === true &&
    //           resData.free_withdrawal_rider === true
    //         ) {
    //           tempInterestRate1 -= 0.002;
    //         } else if (
    //           resData.enhanced_benefit_rider === true ||
    //           resData.free_withdrawal_rider === true
    //         ) {
    //           tempInterestRate1 -= 0.001;
    //         }
    //         setInterstRate(tempInterestRate1);

    //         // setSurrenderCharge()
    //       }, 1000);
    //     }
    //   });
    // }
  }, []);

  useEffect(() => {
    setPeriodValue(0);
    setStateName("");
    setYear_list_id();
    setStateId();
    setAmount();
    setInterstRate(0);
    setAmount(0);
    setAge([]);
    setName("");
    setPrepardBy("");
    setEnhancedBenefitRider(false);
    setFreeWithdrawalRider(false);
    setFinal_value();
    setAmountText(null);
    setSurrenderCharge([[]]);
    setSurrender_charge_array([]);
  }, [open]);
  useEffect(() => {
    let globalTemp = amount;
    let FinalArr = [];
    let chartDataArr = [];
    let chartlabelDataArr = [];
    // debugger;
    let tempInterestRate = data.interest_rate
      ? data.interest_rate
      : interstRate1;

    if (freeWithdrawalRider && enhancedBenefitRider) {
      tempInterestRate -= 0.002;
    } else if (freeWithdrawalRider) {
      tempInterestRate -= 0.001;
    } else if (enhancedBenefitRider) {
      tempInterestRate -= 0.001;
    }
    setInterstRate(tempInterestRate);

    let sumff = 0;
    for (let i = 0; i < periodValue; i++) {
      if (i == 0) {
        // FinalArr.push([interstRate,0,globalTemp,globalTemp])
        chartlabelDataArr.push("year " + i);
        chartDataArr.push(globalTemp);
        setY(chartDataArr);
      }
      // else
      {
        chartlabelDataArr.push("year " + (i + 1));
        setX(chartlabelDataArr);

        let t1 = (1 + tempInterestRate / 365) ** 365;
        let temp_account_value = globalTemp * (t1);
        let temp_earning = temp_account_value - globalTemp;
        console.log("interest Rate, final interest rate ", tempInterestRate, t1);
        //  console.log("surrender_charge_array[i] :: ",surrender_charge_array[i])

        //  console.log("temp_account_value",temp_account_value);
        // // console.log("(temp_account_value*surrender_charge_array[i])",(temp_account_value*surrender_charge_array[i]));
        let sc1 =
          temp_account_value - temp_account_value * surrender_charge_array[i];
        globalTemp = temp_account_value;
        // console.log("SC1 ::::::::::::: ",sc1)
        setFinal_value(temp_account_value.toFixed(0));
        sumff += parseInt(temp_earning?.toFixed(0));

        FinalArr.push([
          tempInterestRate,
          temp_earning?.toFixed(0),
          temp_account_value?.toFixed(0),
          Number(sc1)?.toFixed(0),
        ]);
        console.log("FinalArrrrrrrrrrrrrrrrrrrrrrr", FinalArr);
        chartDataArr.push(temp_account_value);
      }
    }
    //  const options = {}
    // chartDataArr.unshift(amount)
    setFinalChartData({
      labels: chartlabelDataArr,
      datasets: [
        {
          label: "Amount Year Graph",
          data: chartDataArr,
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
      options: {
        scales: {
          y: {
            ticks: {
              callback: (value, index, ticks) => {
                //console.log(value);
                return `$${addCommas(value)}`;
              },
            },
          },
        },
      },
    });
    setSurrenderCharge(FinalArr);

    setSum(sumff);
  }, [amount, periodValue, freeWithdrawalRider, enhancedBenefitRider]);

  return (
    <>
      {msg.status && (
        <Dialog
          open={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <img src={success_dialog} />
            <DialogContentText id="alert-dialog-description">
              <h4>Calculated Data Saved Successfully!</h4>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Box className="img_div">
        <img src={Farmers_img} className="img" alt="img" />
        <span class="img_text1">Farmers Safeguard Plus®</span>
        <span class="img_text2">Multi-Year Guaranteed Annuity (MYGA)</span>
      </Box>

      <Box className="btn_div1">
        {/* <Link to="/history"> */}
        <Button
          variant="contained"
          onClick={() => {
            navigate("/history");
          }}
        >
          Back
        </Button>
        {/* </Link> */}
      </Box>
      <Box className="main_container">
        <Box className="first_div">Calculator</Box>

        <Box className="third_box">
          {/* <button className="btn_btn">
            {" "} */}
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            className="download_btn"
            onClick={() => {
              SetFlagLoader(true);
              const d = new Date();
              if (!calculation_id) {
                if (
                  final_value !== NaN &&
                  final_value !== 0 &&
                  age > 0 &&
                  amount != null &&
                  amount > 0 &&
                  name != null &&
                  year_list_id != null
                ) {
                  // let url = "https://docs.google.com/spreadsheets/d/1bEZ38C5lZQiTe5TwQnRb7C0XKN4aCHthBQMflhpW6ro/export?format=pdf"
                  // window.open(url, '_blank', 'noopener,noreferrer')
                  // return ;
                  DownloadPdfAPI(
                    prepardBy === ""
                      ? {
                        client: name,
                        preparedBy:
                          state.user.first_name + " " + state.user.last_name,
                        age: Number(age),
                        state: state_Name,
                        Graph: { x, y },
                        amount: amount,
                        period: periodValue,
                        interest_rate: interstRate,
                        pdf_array: surrenderCharge,
                        prepared: moment(d).format("DD/MM/YYYY h:mm"),
                        surrender_Charge: surrender_charge_array,
                        free_withdrawal_rider: freeWithdrawalRider,
                        enhanced_benefit_rider: enhancedBenefitRider,
                      }
                      : {
                        client: name,
                        agent:
                          state.user.first_name + " " + state.user.last_name,
                        age: Number(age),
                        state: state_Name,
                        Graph: { x, y },
                        amount: amount,
                        preparedBy: prepardBy,
                        period: periodValue,
                        interest_rate: interstRate,
                        pdf_array: surrenderCharge,
                        prepared: moment(d).format("DD/MM/YYYY h:mm"),
                        surrender_Charge: surrender_charge_array,
                        free_withdrawal_rider: freeWithdrawalRider,
                        enhanced_benefit_rider: enhancedBenefitRider,
                      },
                    (res) => {
                      SetFlagLoader(false);
                      console.log(res);
                      console.log(res.data);
                      // let url =
                      //   "https://docs.google.com/spreadsheets/d/1bEZ38C5lZQiTe5TwQnRb7C0XKN4aCHthBQMflhpW6ro/export?format=pdf";
                      // window.open(url, "_blank", "noopener,noreferrer");
                      if (res.data.status)
                        downloadBase64File(
                          res.data.data,
                          "Farmers_Safeguard_Plus_calculator.pdf"
                        );
                      // else alert("Something Went Wrong!");
                      else {
                        return setShowMsg({
                          status: false,
                          error: true,
                          msg: "Something Went Wrong!",
                        });
                      }
                    },
                    (err) => { }
                  );
                } else if (name != null) {
                  SetFlagLoader(false);
                  // alert(
                  //   "Client Name, Client Age,State, Amount and Period Value can't be empty"
                  // );
                  return setShowMsg({
                    status: false,
                    error: true,
                    msg: "Client Name, Client Age,State, Amount and Period Value can't be empty",
                  });
                }
              } else {
                DownloadPdfAPI(
                  {
                    client: name,
                    agent: state.user.first_name + " " + state.user.last_name,
                    age: Number(age),
                    state: state_Name,
                    Graph: { x, y },
                    amount: amount,
                    prepardBy: prepardBy,
                    period: periodValue,
                    interest_rate: interstRate,
                    pdf_array: surrenderCharge,
                    prepared: moment(d).format("DD/MM/YYYY h:mm"),
                    surrender_Charge: surrender_charge_array,
                    free_withdrawal_rider: freeWithdrawalRider,
                    enhanced_benefit_rider: enhancedBenefitRider,
                  },
                  (res) => {
                    SetFlagLoader(false);
                    let url =
                      "https://docs.google.com/spreadsheets/d/1bEZ38C5lZQiTe5TwQnRb7C0XKN4aCHthBQMflhpW6ro/export?format=pdf";
                    window.open(url, "_blank", "noopener,noreferrer");
                  },
                  (err) => { }
                );
              }
            }}
            style={{ borderColor: "2px solid red" }}
          >
            Download
          </Button>
          {/* /  </button> */}
        </Box>

        <Box className="first_part">
          <Box className="first_box">
            {ShowMsg.error && (
              <Alert className="errCalculator" severity="error">
                {ShowMsg.msg}
              </Alert>
            )}
            {ShowMsg.status && (
              <Alert className="msgCalculator" severity="success">
                {ShowMsg.msg}
              </Alert>
            )}
            <Box className="first">
              <Box className="first_box1">
                <div className="Client_name">
                  Client name
                  <span style={{ color: "red" }}>*</span>
                </div>
                <Box className="input1">
                  <TextField
                    id="standard-basic"
                    placeholder="Client Name"
                    value={name}
                    disabled={calculation_id !== null ? true : false}
                    variant="standard"
                    onChange={(e) => {
                      setName(e.target.value);
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    }}
                  />
                </Box>
              </Box>

              <Box className="first_box2">
                <div className="Prepared_By">Prepared By</div>
                <Box className="input1">
                  <TextField
                    id="standard-basic"
                    placeholder="Prepared By"
                    value={prepardBy}
                    disabled={calculation_id !== null ? true : false}
                    variant="standard"
                    onChange={(e) => {
                      setPrepardBy(e.target.value);
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box className="age_state">
              <Box className="first_box3">
                <div className="Client_age">
                  Client age<span style={{ color: "red" }}>*</span>
                </div>
                <Box className="input11">
                  <TextField
                    type="number"
                    id="standard-basic"
                    placeholder="Client age"
                    value={age}
                    disabled={calculation_id !== null ? true : false}
                    variant="standard"
                    onChange={(e) => {
                      if (e.target.value > 0 && e.target.value <= 100) {
                        setAge(e.target.value);
                      } else {
                        // alert("Age should be a number between 1 and 100");
                        return setShowMsg({
                          status: false,
                          error: true,
                          msg: "Age should be a number between 1 and 100",
                        });
                      }
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    }}
                  />
                </Box>
              </Box>
              <Box className="first_box4">
                <Box className="input2">
                  <FormControl fullWidth>
                    <Autocomplete
                      id="combo-box-demo"
                      options={stateList}
                      // options={stateList.map((i) => {
                      //   i.state_Name !== "None"
                      //     ? stateList.push("None")
                      //     : stateList;
                      // })
                      // }
                      value={stateList.find((s) => s.state_name == state_Name)}
                      disabled={calculation_id !== null ? true : false}
                      onChange={(e, v) => {
                        console.log("params.............", v.state_name);
                        setStateId(v.state_id);
                        setStateName(v.state_name);
                        setShowMsg({
                          status: false,
                          error: false,
                          msg: "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State"
                          variant="standard"
                        />
                      )}
                    />
                  </FormControl>
                </Box>
              </Box>
            </Box>

            <Box className="input3">
              <FormControl
                fullWidth
                sx={{ m: 1 }}
                variant="standard"
                className="form_control"

              // label="Amount"
              >
                <InputLabel htmlFor="standard-adornment-amount">
                  Amount
                </InputLabel>
                <Input
                  type="text"
                  id="standard-adornment-amount"
                  value={amountText ? amountText : ""}
                  disabled={calculation_id !== null ? true : false}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/,/gi, "");
                    setAmount(e.target.value);
                    e.target.value = removeNonNumeric(e.target.value);
                    setAmountText(addCommas(e.target.value));
                    setShowMsg({
                      status: false,
                      error: false,
                      msg: "",
                    });
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <Box className="input4">
              <div className="period_text">
                Period<span style={{ color: "red" }}>*</span>
              </div>
              <div className="btn_div">
                {periodValueObj?.map((index, i) => {
                  return (
                    <>
                      <button
                        className="btn_for_Calaculator"
                        disabled={calculation_id !== null ? true : false}
                        // value={data[0]?.state}
                        onClick={() => {
                          console.log("222222222222222222222", periodValueObj);
                          console.log(
                            "yyyyyyyyyyyyyyyyyyy",
                            index.year_list_id
                          );
                          if (amount !== null) {
                            let getYearSList = index.surrender_charge.map(
                              (index) => index.surrender_charge_data
                            );
                            setYear_list_id(index.year_list_id);
                            setSurrender_charge_array(getYearSList);
                            setPeriodValue(index.year_list_name);
                            setInterstRate1(index.interest_rate);
                            if (!interstRate)
                              setInterstRate(index.interest_rate);
                            // setInterstRate(interstRate1)
                          }
                        }}
                        style={
                          periodValue === index.year_list_name
                            ? {
                              backgroundColor: "rgb(217, 162, 46)",
                              color: "white",
                            }
                            : { backgroundColor: "rgb(241, 241, 241)" }
                        }
                      >
                        {index.year_list_name} Year
                      </button>
                    </>
                  );
                })}
              </div>
            </Box>
          </Box>
          <Box className="rightbox">
            <Box className="rightfirstcomponent">
              <Box className="rider_header">
                <span>Rider</span>
              </Box>
              <Box className="check_box">
                <FormGroup className="fm1">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setFreeWithdrawalRider(e.target.checked);
                        }}
                        disabled={calculation_id !== null ? true : false}
                        checked={freeWithdrawalRider ? true : false}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="5% Free Withdrawal Rider"
                  />
                </FormGroup>
                <FormGroup className="fm2">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => {
                          setEnhancedBenefitRider(e.target.checked);
                        }}
                        disabled={calculation_id !== null ? true : false}
                        checked={enhancedBenefitRider ? true : false}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Enhanced Benefit Rider"
                  />
                </FormGroup>
              </Box>
            </Box>
            <Box className="second_box">
              <span className="test">
                <b>Annual Interest Rate: {(interstRate * 100).toFixed(2)}%</b>
              </span>
              <Box>
                <span>
                  <b>Amount After {periodValue} Years</b>
                </span>
                <span className="secBox">
                  <h2 style={{ color: "#D9A22E" }}>
                    $
                    {final_value !== NaN && final_value !== undefined
                      ? addCommas(final_value)
                      : 0}
                  </h2>
                </span>
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider
          component="div"
          role="presentation"
          style={{
            width: "90%",
            color: "rgb(217, 162, 46) !important",
            margin: "0 auto !important",
          }}
        ></Divider>

        <div className="chart">
          <Line
            options={finalChartData.options}
            data={finalChartData}
            style={{ width: "50% !important", height: "50% !important" }}
          />
        </div>

        <Box className="con_main_box">
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            className="con_main"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
              >
                <Grid
                  item
                  className="grid_item1 item box_height"
                  justifyContent="center"
                  xs={8}
                  sm={4}
                  md={8}
                >
                  Guaranteed
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ height: "36px", margin: "5px" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={3}
                  sm={4}
                  md={3}
                  className="grid_item2 item box_height"
                >
                  <Grid container>
                    <Grid item className="itemtextcenter " xs={2} sm={3} md={3}>
                      Year
                    </Grid>
                    <Grid item className="itemtextcenter" xs={8} sm={6} md={6}>
                      Surrender Charge
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} sm={3} md={3}>
                      Age
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  className="item grid_item3 box_height"
                  xs={8}
                  sm={6}
                  md={8}
                >
                  <Grid container justifyContent="space-evenly">
                    <Grid item className="itemtextcenter" xs={2} md={3}>
                      Interest
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={3}>
                      Earnings
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={3}>
                      Account Value
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={3}>
                      Surrender Value
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {surrenderCharge.map((data, i) => {
              console.log(
                "surrender_charge_array:1111111111111:    ",
                surrender_charge_array,
                surrenderCharge
              );
              console.log("surrender_charge   I ", data);
              console.log(
                "surrender_charge_array:88:::    ",
                surrender_charge_array[i]
              );

              //   console.log(surrenderCharge.length)
              if (surrenderCharge.length > 1) {
                return (
                  <>
                    <Grid item style={{ height: "36px", margin: "5px" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={3}
                          sm={4}
                          md={3}
                          className="color grid_item2 item box_height"
                        >
                          <Grid container>
                            <Grid
                              item
                              className="itemtextcenter "
                              xs={2}
                              sm={3}
                              md={3}
                            >
                              {i + 1}
                            </Grid>
                            <Grid
                              item
                              className="itemtextcenter"
                              xs={8}
                              sm={6}
                              md={6}
                            >
                              {Number(
                                (surrender_charge_array[i] * 100).toFixed(2)
                              )}
                              %
                            </Grid>
                            <Grid
                              item
                              className="itemtextcenter"
                              xs={2}
                              sm={3}
                              md={3}
                            >
                              {Number(age) + i + 1}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          className="color item grid_item3 box_height"
                          xs={8}
                          sm={6}
                          md={8}
                        >
                          <Grid container justifyContent="space-evenly">
                            <Grid item className="itemtextcenter" xs={2} md={3}>
                              {(data[0] * 100).toFixed(2)}%
                            </Grid>
                            <Grid item className="itemtextcenter" xs={2} md={3}>
                              ${addCommas(data[1])}
                            </Grid>
                            <Grid item className="itemtextcenter" xs={2} md={3}>
                              ${addCommas(data[2])}
                            </Grid>
                            <Grid item className="itemtextcenter" xs={2} md={3}>
                              ${addCommas(data[3])}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              }
            })}

            <Grid item style={{ height: "36px", margin: "5px" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  className="color item grid_item3 box_height"
                  xs={11}
                  sm={10}
                  md={11}
                >
                  <Grid container justifyContent="space-evenly">
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      <b>Total / Ending</b>
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      {""}
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      {""}
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      <b>${addCommas(sum)}</b>
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      <b>
                        $
                        {surrenderCharge[surrenderCharge.length - 1] !==
                          undefined
                          ? addCommas(
                            surrenderCharge[surrenderCharge.length - 1]?.[2]
                          )
                          : 0}
                      </b>
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      <b>
                        $
                        {surrenderCharge[surrenderCharge.length - 1] !==
                          undefined
                          ? addCommas(
                            surrenderCharge[surrenderCharge.length - 1]?.[2]
                          )
                          : 0}
                      </b>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {console.log(
              "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@",
              freeWithdrawalRider
            )}
            {console.log(
              "@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@rrrrrrrrrrrrrr",
              enhancedBenefitRider
            )}
            <Button
              variant="contained"
              className="savebtn"
              sx={{
                display: calculation_id !== null ? "none !important" : "block",
              }}
              //  style={calculation_id !== null ?{display: "none !important"}:{opacity:"1"} }
              onClick={() => {
                SetFlagLoader(true);
                if (
                  age > 0 &&
                  name !== "" &&
                  amount > 0 &&
                  amount != null &&
                  periodValue !== 0
                ) {
                  SaveDataAPI(
                    {
                      name: name,
                      final_amount: final_value,
                      amount: Number(amount),
                      prepardBy:
                        prepardBy !== ""
                          ? prepardBy
                          : state.user.first_name + " " + state.user.last_name,
                      year_list_id: year_list_id,
                      state_id: state_id,
                      age: Number(age),
                      free_withdrawal_rider: freeWithdrawalRider,
                      enhanced_benefit_rider: enhancedBenefitRider,
                    },
                    (res) => {
                      if (res.data.status == true) {
                        SetFlagLoader(false);
                        setMsg(res.data);
                        setOpen(true);
                      }
                    },
                    (err) => {
                      console.log("err", err);
                      SetFlagLoader(false);
                      return setShowMsg({
                        status: false,
                        error: true,
                        msg: err.message,
                      });
                    }
                  );
                } else {
                  SetFlagLoader(false);
                  // alert("Age, Name, State & amount is must");
                  window.scrollTo(0, 500);
                  return setShowMsg({
                    status: false,
                    error: true,
                    msg: "Age, Name, State, period & amount are mandetory",
                  });
                }
              }}
            >
              Save
            </Button>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default Calculator;
