import axios from "../../service/axios";

const SubmitSaveDraftApplication = async (data, onSuccess, onError) => {
    try {
        console.log("Printing data to be sent to server for submitting app", data);

        let path = data.publicURL == true ? `/api/application?unique_id_public_access=${data.publicURL}` : "/api/application";

        const response = await axios.post(path, data.data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(response);
        // debugger;
    }
    catch (err) {
        onError && onError(err);
    }
}

export default SubmitSaveDraftApplication;