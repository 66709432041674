import React, { Fragment, useState, useContext, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import IndividualAnnuityApplication from "../../../components/V2/individualAnnuityApplication/individualAnnuityApplication";
import SuitabilityAnalysis from "../../../components/V2/suitabilityAnalysis/suitabilityAnalysis";
import FormSiteMap from "../../../components/V2/individualAnnuityApplication/formSiteMap";
import { ApplicationStatusId } from "../../../api/applicationStatusIdAndIdAPI";
import TransferExchangeRequest from "../../../components/V2/transferOrExchangeRequest/transferExchangeRequest";
import { Context } from "../../../context/V2/newApplicationFormContext";
import SuccessDdialog from "../../../components/successDialog/successDialog";
import SubmitSaveDraftApplication from "../../../api/V2/submitSaveDraftApplicationAPI";
import { Context as UserLoaderContext } from "../../../context/userLoaderContext";
import { GetSingleAnnuityApplicationAPI } from "../../../api/getAnnuityApplicationAPI";
import ErrorValidationSubmitDialog from "../../../components/V2/errorValidationSubmitDialog/errorValidationSubmitDialog";
import { useNavigate, useLocation } from "react-router-dom";
import { Context as ErrorValidationContext } from "../../../context/V2/errorValidationContext";
import AnnuityDisclosureForm from "../../../components/V2/annuityDisclosureForm/annuityDisclosureForm";
import SomethingWentWrongDialog from "../../../components/somethingWentWrong/somethingWentWrong";
import ReplacementForm from "../../../components/V2/replacementForm/replacementForm";

const ApplicationForm = () => {
  // const [pageIndex, setPageIndex] = useState(1);

  console.log(
    "useContext -------------------------------------------- state",
    Context
  );
  const { state, transferOrExchangeRequestFlag } = useContext(Context);
  const {
    individualAnnuityApplication,
    suitabilityAnalysis,
    transferOrExchangeRequest,
    // bankDetails,
    noticeOfReplacement,
    annuityDisclosureForm,
  } = state;

  const location = useLocation();
  const [statusFalse, setStatusFalse] = useState(false);
  const [applicationEditable, setApplicationEditable] = useState(false);

  const { SetPageIndex, SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );

  const {
    pageIndex,

    flagFirstPageFirstSection,
    flagFirstPageSecondSection,
    flagFirstPageThirdSection,
    flagFirstPageFourthSection,
    flagFirstPageFifthSection,
    flagFirstPageSixthSection,
    flagFirstPageSeventhSection,
    flagFirstPageEighthSection,

    flagSecondPageFirstSection,
    flagSecondPageSecondSection,
    flagSecondPageThirdSection,
    flagSecondPageFourthSection,
    flagSecondPageFifthSection,
    flagSecondPageSixthSection,

    flagFourthPageFirstSection,
    // flagFourthPageSecondSection
  } = useContext(ErrorValidationContext).state;

  const [flagSuccessDialog, setFlagSuccessDialog] = useState(false);

  const { SetFlagLoader } = useContext(UserLoaderContext);
  const [submitApplicationStatusId, setSubmitApplicationStatusId] = useState(
    {}
  );
  const [saveAsDraftApplicationStatusId, setSaveAsDraftApplicationStatusId] =
    useState({});
  const navigate = useNavigate();
  const {
    SetWholeIndividualAnnuityApplication,
    SetWholeSuitabilityAnalysis,
    SetWholeTransferExchangeRequest,
    // SetWholeBankDetails,
    SetDisableAllInputs,
    disableAllInputs,
    SetApplicationId,
    SetWholeAnnuityDisclosureForm,
    SetWholeNoticeOfReplacement,
  } = useContext(Context);
  const [applicationId, setApplicationId] = useState(null);
  const [flagErrorValidtionDialog, setFlagErrorValidationDialog] =
    useState(false);
  const [applicationStatusName, setApplicationStatusName] = useState("");
  const [beneficiariesPercentageTotal, setBeneficiariesPercentageTotal] =
    useState(0);

  const [errorFieldsArray, setErrorFieldsArray] = useState([]);
  const [searchParams] = new URLSearchParams();
  const { flagRequestYield } = noticeOfReplacement;

  const ReturnBeneficiariesPrimary = (data) => {
    // debugger;
    let temp = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i]?.beneficiaries_flag_primary == 1) {
        temp.push({
          id: data[i]?.beneficiaries_id,
          // primaryField: data[i]?.beneficiaries_data[i]?.value,
          primaryField: data[i]?.beneficiaries_value,
          percentageField:
            data[i]?.beneficiaries_percentage == -1
              ? ""
              : data[i]?.beneficiaries_percentage,
          ssnField: data[i]?.beneficiaries_ssn_tin_no,
          genderField: data[i]?.beneficiaries_gender,
          relationshipField: data[i]?.beneficiaries_relationship,
        });
      }
    }

    if (temp.length === 0) {
      temp = [
        {
          id: 1,
          primaryField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
        {
          id: 2,
          primaryField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
        {
          id: 3,
          primaryField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
      ];
    } else if (temp.length === 1) {
      temp.push({
        id: Date.now(),
        primaryField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
      temp.push({
        id: Date.now(),
        primaryField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
    } else if (temp.length === 2) {
      temp.push({
        id: Date.now(),
        primaryField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
    }

    console.log("beneficiries primary set whole individual application", temp);
    return temp;
  };

  const ReturnBeneficiariesContigent = (data) => {
    // debugger;
    let temp = [];
    data.map((v) => {
      if (v?.beneficiaries_flag_primary === 0) {
        temp.push({
          id: v?.beneficiaries_id,
          contigentField: v?.beneficiaries_value,
          percentageField:
            v?.beneficiaries_percentage === -1
              ? ""
              : v?.beneficiaries_percentage,
          ssnField: v?.beneficiaries_ssn_tin_no,
          genderField: v?.beneficiaries_gender,
          relationshipField: v?.beneficiaries_relationship,
        });
      }
    });

    if (temp.length === 0) {
      temp = [
        {
          id: 1,
          contigentField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
        {
          id: 2,
          contigentField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
        {
          id: 3,
          contigentField: "",
          percentageField: "",
          ssnField: "",
          genderField: "",
          relationshipField: "",
        },
      ];
    } else if (temp.length === 1) {
      temp.push({
        id: Date.now(),
        contigentField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
      temp.push({
        id: Date.now(),
        contigentField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
    } else if (temp.length === 2) {
      temp.push({
        id: Date.now(),
        contigentField: "",
        percentageField: "",
        ssnField: "",
        genderField: "",
        relationshipField: "",
      });
    }

    console.log(
      "beneficiries contigent set whole individual application",
      temp
    );
    return temp;
  };

  const ReturnReplacementQuestionFieldArray = (data) => {
    console.log(
      "Printing data for replacement question data array that i got from server",
      data
    );

    let temp = [];
    data.map((v) => {
      temp.push({
        id: v?.replacement_question_contact_data_id,
        companyNameOfPolicyField: v?.replacement_question_contact_data_name,
        policyNumberField: v?.replacement_question_contact_data_contact_number,
      });
    });

    if (temp.length === 0) {
      temp = [
        {
          id: -1,
          companyNameOfPolicyField: "",
          policyNumberField: "",
        },
        {
          id: -2,
          companyNameOfPolicyField: "",
          policyNumberField: "",
        },
        {
          id: -3,
          companyNameOfPolicyField: "",
          policyNumberField: "",
        },
      ];
    } else if (temp.length === 1) {
      temp.push({
        id: -1,
        companyNameOfPolicyField: "",
        policyNumberField: "",
      });
      temp.push({
        id: -2,
        companyNameOfPolicyField: "",
        policyNumberField: "",
      });
    } else if (temp.length === 2) {
      temp.push({
        id: -1,
        companyNameOfPolicyField: "",
        policyNumberField: "",
      });
    }

    return temp;
  };

  const ReturnOwnerOtherInvestments = (data) => {
    let temp = data?.split(", ");
    temp = temp.filter((v) => {
      if (
        v === "Stocks" ||
        v === "Bonds" ||
        v === "Mutual Funds" ||
        v === "Personal Business" ||
        v === "Options" ||
        v === "Real Estate" ||
        v === "Partnerships"
      ) {
        return v;
      }
    });

    return temp;
  };

  const ReturnTempInsurerPolicyDetailFieldArray = (data) => {
    if (data === undefined) {
      return [
        {
          id: 1,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
        {
          id: 2,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
        {
          id: 3,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
        {
          id: 4,
          existingCarrierName: "",
          existingCarrierAddress: "",
          contractOrPolicyNumber: "",
          nameOfInsuredOrAnnuitant: "",
          amount: "",
          effectiveDate: ""
        },
      ];
    }

    let temp = [];
    data.map((v) => {
      temp.push({
        id: v?.existing_policy_id,
        existingCarrierName: v.existing_policy_existing_carrier_name,
        existingCarrierAddress: v.existing_policy_existing_carrier_address,
        contractOrPolicyNumber: v.existing_policy_policy_number,
        nameOfInsuredOrAnnuitant: v.existing_policy_name_of_annuitant,
        amount: v.existing_policy_face_amount,
        effectiveDate: v.existing_policy_effective_date
      });
    });
    return temp;
  };

  const ReturnOwnerOtherInvestmentsOtherField = (data) => {
    // debugger;
    let temp = data?.split(", ");
    temp = temp.filter((v) => {
      if (
        v !== "Stocks" ||
        v !== "Bonds" ||
        v !== "Mutual Funds" ||
        v !== "Personal Business" ||
        v !== "Options" ||
        v !== "Real Estate" ||
        v !== "Partnerships"
      ) {
        return v;
      }
    });

    return temp;
  };

  const ReturnReasonForPurchasingProduct = (data) => {
    let temp = data?.split(", ");
    temp = temp.filter((v) => {
      if (
        v === "Asset accumulation" ||
        v === "Tax deferred growth" ||
        v === "Immediate income" ||
        v === "Future retirement income" ||
        v === "Guaranteed interest rate" ||
        v === "Transfer to heirs" ||
        v === "Safety of principal"
      ) {
        return v;
      }
    });

    return temp;
  };

  const ReturnOtherReasonForPurchasingProduct = (data) => {
    let temp = data?.split(", ");
    temp = temp.filter((v) => {
      if (
        v !== "Asset accumulation" ||
        v !== "Tax deferred growth" ||
        v !== "Immediate income" ||
        v !== "Future retirement income" ||
        v !== "Guaranteed interest rate" ||
        v !== "Transfer to heirs" ||
        v !== "Safety of principal"
      ) {
        return v;
      }
    });

    return temp;
  };

  const ReturnSourceOfFundsToPurchaseProduct = (data) => {
    let temp = data?.split(", ");
    temp = temp.filter((v) => {
      if (
        v === "CD/Savings/Checking" ||
        v === "Inheritance" ||
        v === "Rollover/transfer from qualified account" ||
        v === "Liquidation of assets" ||
        v === "Death benefit proceeds" ||
        v === "Qualified plan distribution" ||
        v === "Cash value from existing annuity" ||
        v === "Current income"
      ) {
        return v;
      }
    });

    return temp;
  };

  const ReturnOtherSourceOfFundsToPurchaseProduct = (data) => {
    let temp = data?.split(", ");
    temp = temp.filter((v) => {
      if (
        v !== "CD/Savings/Checking" ||
        v !== "Inheritance" ||
        v !== "Rollover/transfer from qualified account" ||
        v !== "Liquidation of assets" ||
        v !== "Death benefit proceeds" ||
        v !== "Qualified plan distribution" ||
        v !== "Cash value from existing annuity" ||
        v !== "Current income"
      ) {
        return v;
      }
    });

    return temp;
  };

  const ReturnPersonsAssistedToPurchaseProduct = (data) => {
    let temp = data?.split(", ");
    temp = temp.filter((v) => {
      if (
        v === "None" ||
        v === "Accountant" ||
        v === "Attorney" ||
        v === "Family Member" ||
        v === "Financial Planner" ||
        v === "Other (please specify)"
      ) {
        return v;
      }
    });

    return temp;
  };

  const ReturnOtherPersonAssistedToPurchaseProduct = (data) => {
    let temp = data?.split(", ");
    temp = temp.filter((v) => {
      if (
        v !== "None" ||
        v !== "Accountant" ||
        v !== "Attorney" ||
        v !== "Family Member" ||
        v !== "Financial Planner" ||
        v !== "Other (please specify)"
      ) {
        return v;
      }
    });
  };

  // const ReturnTransferExchangeRequestPlan = (data1, data2) => {
  //     if (data1.value === "" && data2.value === "") {
  //         return "";
  //     } else if (data1.value === "" && data2.value !== "") {
  //         return "Qualified Plans";
  //     } else if (data1.value !== "" && data2.value === "") {
  //         return "Non-Qualified Plans";
  //     }
  // };

  const OtherFieldSetupFinSituation6 = (matchString, data) => {
    let i;
    let other;
    let temp = data;
    for (i = 0; i < temp.length; i++) {
      if (temp[i] === "Stocks") {
        continue;
      } else if (temp[i] === "Bonds") {
        continue;
      } else if (temp[i] === "Mutual Funds") {
        continue;
      } else if (temp[i] === "Personal Business") {
        continue;
      } else if (temp[i] === "Options") {
        continue;
      } else if (temp[i] === "Real Estate") {
        continue;
      } else if (temp[i] === "Partnerships") {
        continue;
      } else if (data[i] === "") {
        continue;
      } else if (data[i] === matchString) {
        continue;
      } else {
        other = true;
        break;
      }
    }
    other === true && temp.push(matchString);
    return temp;
  };

  const ExtraValueFinSituaton6 = (data) => {
    let i;
    let value = "";
    for (i = 0; i < data.length; i++) {
      if (data[i] === "Stocks") {
        continue;
      } else if (data[i] === "Bonds") {
        continue;
      } else if (data[i] === "Mutual Funds") {
        continue;
      } else if (data[i] === "Personal Business") {
        continue;
      } else if (data[i] === "Options") {
        continue;
      } else if (data[i] === "Real Estate") {
        continue;
      } else if (data[i] === "Partnerships") {
        continue;
      } else if (data[i] === "") {
        continue;
      } else {
        value = data[i];
        break;
      }
    }

    return value;
  };

  const ReasonForPurchasingProduct = (matchString, data) => {
    let i;
    let other;
    let temp = data;
    for (i = 0; i < temp.length; i++) {
      if (temp[i] === "Asset accumulation") {
        continue;
      } else if (temp[i] === "Tax deferred growth") {
        continue;
      } else if (temp[i] === "Immediate income") {
        continue;
      } else if (temp[i] === "Future retirement income") {
        continue;
      } else if (temp[i] === "Guaranteed interest rate") {
        continue;
      } else if (temp[i] === "Transfer to heirs") {
        continue;
      } else if (temp[i] === "Safety of principal") {
        continue;
      } else if (data[i] === "") {
        continue;
      } else if (data[i] === matchString) {
        continue;
      } else {
        other = true;
        break;
      }
    }
    other === true && temp.push(matchString);
    return temp;
  };

  const OtherReasonForPurchasingProduct = (data) => {
    let i;
    let value = "";
    for (i = 0; i < data.length; i++) {
      if (data[i] === "Asset accumulation") {
        continue;
      } else if (data[i] === "Tax deferred growth") {
        continue;
      } else if (data[i] === "Immediate income") {
        continue;
      } else if (data[i] === "Future retirement income") {
        continue;
      } else if (data[i] === "Guaranteed interest rate") {
        continue;
      } else if (data[i] === "Transfer to heirs") {
        continue;
      } else if (data[i] === "Safety of principal") {
        continue;
      } else if (data[i] === "") {
        continue;
      } else {
        value = data[i];
        break;
      }
    }

    return value;
  };

  const SourceOfFundsToPurchaseProduct = (matchString, data) => {
    let i;
    let other;
    let temp = data;
    for (i = 0; i < temp.length; i++) {
      if (temp[i] === "CD/Savings/Checking") {
        continue;
      } else if (temp[i] === "Inheritance") {
        continue;
      } else if (temp[i] === "Rollover/transfer from qualified account") {
        continue;
      } else if (temp[i] === "Liquidation of assets") {
        continue;
      } else if (temp[i] === "Death benefit proceeds") {
        continue;
      } else if (temp[i] === "Qualified plan distribution") {
        continue;
      } else if (temp[i] === "Cash value from existing annuity") {
        continue;
      } else if (temp[i] === "Current income") {
        continue;
      } else if (data[i] === "") {
        continue;
      } else if (data[i] === matchString) {
        continue;
      } else {
        other = true;
        break;
      }
    }
    other === true && temp.push(matchString);
    return temp;
  };

  const OtherSourceOfFundsToPurchaseProduct = (data) => {
    let i;
    let value = "";
    for (i = 0; i < data.length; i++) {
      if (data[i] === "CD/Savings/Checking") {
        continue;
      } else if (data[i] === "Inheritance") {
        continue;
      } else if (data[i] === "Rollover/transfer from qualified account") {
        continue;
      } else if (data[i] === "Liquidation of assets") {
        continue;
      } else if (data[i] === "Death benefit proceeds") {
        continue;
      } else if (data[i] === "Qualified plan distribution") {
        continue;
      } else if (data[i] === "Cash value from existing annuity") {
        continue;
      } else if (data[i] === "Current income") {
        continue;
      } else if (data[i] === "") {
        continue;
      } else {
        value = data[i];
        break;
      }
    }

    return value;
  };

  const PersonsAssistedToPurchaseProduct = (matchString, data) => {
    let i;
    let other;
    let temp = data;
    for (i = 0; i < temp.length; i++) {
      if (temp[i] === "None") {
        continue;
      } else if (temp[i] === "Accountant") {
        continue;
      } else if (temp[i] === "Attorney") {
        continue;
      } else if (temp[i] === "Family Member") {
        continue;
      } else if (temp[i] === "Financial Planner") {
        continue;
      } else if (data[i] === "") {
        continue;
      } else if (data[i] === matchString) {
        continue;
      } else {
        other = true;
        break;
      }
    }
    other === true && temp.push(matchString);
    return temp;
  };

  const OtherPersonsAssistedToPurchaseProduct = (data) => {
    let i;
    let value = "";
    for (i = 0; i < data.length; i++) {
      if (data[i] === "None") {
        continue;
      } else if (data[i] === "Accountant") {
        continue;
      } else if (data[i] === "Attorney") {
        continue;
      } else if (data[i] === "Family Member") {
        continue;
      } else if (data[i] === "Financial Planner") {
        continue;
      } else if (data[i] === "" || data[i] === "Other (please specify)") {
        continue;
      } else {
        value = data[i];
        break;
      }
    }

    return value;
  };

  const ReturnTransferExchangeRequestPlan = (
    nonQualifiedSection,
    qualifiedSection
  ) => {
    console.log("Printing nonqualified section", nonQualifiedSection?.value);
    console.log("Printing qulified section", qualifiedSection?.value);

    if (nonQualifiedSection?.value.length === 1) {
      if (nonQualifiedSection?.value[0] === "") {
        if (qualifiedSection?.value.length === 1) {
          if (qualifiedSection?.value[0] === "") {
            return "";
          }
        } else {
          return "Qualified Plans";
        }
      }
    } else {
      return "Non-Qualified Plans";
    }
  };

  const ReturnTypeOfAccountTransferExchangeRequest = (data) => {
    if (data === "Annuity") {
      return "Annuity";
    } else if (data === "Life Insurance") {
      return "Life Insurance";
    } else if (data === "Bank/Savings Account") {
      return "Bank/Savings Account";
    } else if (data === "") {
      return "";
    } else {
      return "Other";
    }
  };

  const ReturnOtherTypeOfAccountForTransferExchangeRequest = (data) => {
    if (data === "Annuity") {
      return "";
    } else if (data === "Life Insurance") {
      return "";
    } else if (data === "Bank/Savings Account") {
      return "";
    } else {
      return data;
    }
  };

  const SetDataToContext = (data) => {
    console.log("Printing data got from api call", data);
    // let gg = data;
    try {
      SetWholeIndividualAnnuityApplication({
        annuitantFullName: data?.annuity_full_name,
        annuitantSSN: data?.annuity_ssn_tin,
        annuitantGender: data?.annuity_gender?.value[0],
        annuitantDOB: data?.annuity_birthdate,
        annuitantStreetAddress: data?.annuity_address,
        annuitantZip: data?.annuity_zip,
        annuitantCity: data?.annuity_city,
        annuitantState: data?.annuity_state,
        annuitantPhone: data?.annuity_phone,
        annuitantEmail: data?.annuity_email,
        ownerFullName: data?.owner_full_name,
        ownerSSN: data?.owner_ssn_tin,
        ownerGender: data?.owner_gender?.value[0],
        ownerDOB: data?.owner_birthdate,
        ownerStreetAddress: data?.owner_address,
        ownerZip: data?.owner_zip,
        ownerCity: data?.owner_city,
        ownerState: data?.owner_state,
        ownerEmail: data?.owner_email,
        ownerRelationToAnnuitant: data?.owner_relationship_annuitant,
        jointOwnerFullName: data?.joint_owner_full_name,
        jointOwnerSSN: data?.joint_owner_ssn_tin,
        jointOwnerGender: data?.joint_owner_gender?.value[0],
        jointOwnerDOB: data?.joint_owner_birthdate,
        jointOwnerStreetAddress: data?.joint_owner_address,
        jointOwnerZip: data?.joint_owner_zip,
        jointOwnerCity: data?.joint_owner_city,
        jointOwnerState: data?.joint_owner_state,
        jointOwnerEmail: data?.joint_owner_email,
        jointOwnerRelationToAnnuitant: data?.joint_owner_relationship_annuitant,
        planAppliedFor: data?.plan_riders_applied_for?.value,
        beneficiariesPrimary: ReturnBeneficiariesPrimary(data?.beneficiaries),
        beneficiariesContigent: ReturnBeneficiariesContigent(data?.beneficiaries),
        selectedNonQualifiedOptions: data?.premium_tax_non_qualified?.value[0] !== "" ? data?.premium_tax_non_qualified?.value : data?.premium_tax_qualified?.value,
        // selectedQualifiedOptions: data?.premium_tax_qualified?.value[0],
        selectedQualifiedOptionsGroup2:
          data?.premium_tax_qualified_plan?.value[0] !== "" ? data?.premium_tax_qualified_plan?.value[0] : data?.premium_tax_non_qualified_plan?.value[0],
        flagQualifiedOrNonQualifiedSelected: data?.premium_tax_qualified?.value[0] !== "" ? "Qualified" : data?.premium_tax_non_qualified?.value === ""
          ? ""
          : "Non - Qualified",
        otherSelectedQualifiedOptions: data?.premium_tax_qualified?.extra_value_value,
        stateToShowNoticeOfReplacement: data?.state_to_show_notice_of_replacement,
        amountPaidWithApplication: data?.premium_tax_paid_amount,
        expectedPremiumField: data?.premium_tax_expected_amount,
        flagHaveExistingLifeInsurance: data?.owner_replacement_question_1?.value[0],
        reasonOfApplicationToChangeLifeInsurance: data?.owner_replacement_question_2?.value[0],
        applicationReplacementFieldsArray: ReturnReplacementQuestionFieldArray(
          data?.replacement_question_contact_data
        ),
      });

      console.log("Printing data for updating context", data);

      SetWholeSuitabilityAnalysis({
        ownerFullName: data?.suitability_analysis_owner_full_name,
        ownerCurrentAge: data?.suitability_analysis_owner_age,
        ownerGender: data?.suitability_analysis_owner_gender?.value[0],
        ownerEmploymentStatus:
          data?.suitability_analysis_owner_employment_status?.value[0],
        ownerCurrentOccupation: data?.suitability_analysis_owner_ocuoation,
        ownerDependents: data?.suitability_analysis_owner_dependents,
        spouseFullName: data?.suitability_analysis_supouse_full_name,
        spouseCurrentAge: data?.suitability_analysis_supouse_age,
        spouseGender: data?.suitability_analysis_supouse_gender?.value[0],
        spouseEmploymentStatus:
          data?.suitability_analysis_supouse_employment_status?.value[0],
        spouseCurrentOccupation: data?.suitability_analysis_supouse_ocuoation,
        spouseDependents: data?.suitability_analysis_supouse_dependents,
        ownerAnnualHouseholdIncome:
          data?.owner_financial_info_annual_household_income,
        ownerSourceOfIncome: data?.owner_financial_info_income_source,
        ownerIncomeTaxBracket: data?.owner_financial_info_income_tax_bracket,
        ownerTotalOutstandingDebt:
          data?.owner_financial_info_total_outstanding_debt,
        ownerTotalAssets: data?.owner_financial_info_total_assets,
        ownerTotalLiquidAssets: data?.owner_financial_info_total_liquid_assets,
        flagOwnerIncomeCoverLivingAndMedicalExpenses:
          data?.owner_fin_situation_needs_question_1?.value[0],
        explainOwnerIncomeCoverLivingAndMedicalExpenses:
          data?.owner_fin_situation_needs_question_1.extra_value_value,
        flagOwnerExpectChangesInLivingExpenses:
          data?.owner_fin_situation_needs_question_2?.value[0],
        explainOwnerExpectChangesInLivingExpenses:
          data?.owner_fin_situation_needs_question_2.extra_value_value,
        flagOwnerAnticipateChangesInMedicalExpenses:
          data?.owner_fin_situation_needs_question_3?.value[0],
        explainOwnerAnticipateChangesInMedicalExpenses:
          data?.owner_fin_situation_needs_question_3.extra_value_value,
        flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod:
          data?.owner_fin_situation_needs_question_4?.value[0],
        explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod:
          data?.owner_fin_situation_needs_question_4.extra_value_value,
        flagOwnerHaveEmergencyFundForUnexpectedExpenses:
          data?.owner_fin_situation_needs_question_5?.value[0],
        explainOwnerHaveEmergencyFundForUnexpectedExpenses:
          data?.owner_fin_situation_needs_question_5.extra_value_value,
        ownerOtherInvestments:
          data?.owner_fin_situation_needs_question_6?.value.length === 0
            ? ""
            : OtherFieldSetupFinSituation6(
              "Other",
              data?.owner_fin_situation_needs_question_6?.value
            ),
        // otherOwnerInvestmentField:
        //     data?.owner_fin_situation_needs_question_6.value.length === 0
        //         ? ""
        //         : ExtraValueFinSituaton6(data?.owner_fin_situation_needs_question_6.value),
        otherOwnerInvestmentField:
          data?.owner_fin_situation_needs_question_6?.value.length === 0
            ? ""
            : data?.owner_fin_situation_needs_question_6.extra_value_value,
        ownerRiskTolerance:
          data?.owner_fin_situation_needs_question_7?.value[0],
        reasonForPurchasingProduct:
          data?.product_purchase_question_1?.value.length === 0
            ? ""
            : ReasonForPurchasingProduct(
              "Other",
              data?.product_purchase_question_1?.value
            ),
        otherRasonForPurchasingThisProduct:
          data?.product_purchase_question_1?.value.length === 0
            ? ""
            : data?.product_purchase_question_1.extra_value_value,
        flagOwnerPlanToWithdrawFundInSurrenderPeriod:
          data?.product_purchase_question_2?.value[0],
        explainOwnerPlanToWithdrawFundInSurrenderPeriod:
          data?.product_purchase_question_2.extra_value_value,
        ownerAnticipateNeedingAccessToFunds:
          data?.product_purchase_question_3?.value[0],
        sourceOfFundsToPurchaseThisProduct:
          data?.product_purchase_question_4?.value.length === 0
            ? ""
            : SourceOfFundsToPurchaseProduct(
              "Other",
              data?.product_purchase_question_4?.value
            ),
        otherSourceOfFundsToPurchaseThisProduct:
          data?.product_purchase_question_4?.value.length === 0
            ? ""
            : data?.product_purchase_question_4.extra_value_value,
        flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct:
          data?.exist_acc_info_question_1?.value[0],
        howLongHasTheLifeInsuranceBeenInForce: data?.exist_acc_info_question_2,
        flagSurrenderChargeOnExistingProduct:
          data?.exist_acc_info_question_3?.value[0],
        currentSurrenderChargeOnExistingProduct:
          data?.exist_acc_info_question_4,
        expirationOfCurrentSurrenderCharge: data?.exist_acc_info_question_5,
        typeOfExistingProduct: data?.exist_acc_info_question_6?.value[0],
        interestRateForExistingProduct: data?.exist_acc_info_question_7,
        flagTakingRequiredMinimumDistributionsFromExistingProduct:
          data?.exist_acc_info_question_8?.value[0],
        flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution:
          data?.exist_acc_info_question_9?.value[0],
        explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution:
          data?.exist_acc_info_question_9.extra_value_value,
        personsThatAssistedOwnerToPurchaseThisProduct:
          data?.suitability_analysis_other_question_1?.value.length === 0
            ? ""
            : PersonsAssistedToPurchaseProduct(
              "Other (please specify)",
              data?.suitability_analysis_other_question_1?.value
            ),
        otherPersonsThatAssistedOwnerToPurchaseThisProduct:
          data?.suitability_analysis_other_question_1?.value.length === 0
            ? ""
            : data?.suitability_analysis_other_question_1.extra_value_value,
        additionalRelaventInformationForSuitabilityAnalysis:
          data?.suitability_analysis_other_question_2,
        agentAcknowledgment: data?.suitability_analysis_agent_acknowledgment
          ? data?.suitability_analysis_agent_acknowledgment?.value[0]
          : "",
      });

      console.log("Printing data? for updating context for transfer", data);
      SetWholeTransferExchangeRequest({
        ownerFullName: data?.trans_exchange_req_owner_name,
        ownerSSN: data?.trans_exchange_req_owner_text_id_or_ssn,
        ownerStreetAddress: data?.trans_exchange_req_owner_address,
        ownerZip: data?.trans_exchange_req_owner_zip,
        ownerCity: data?.trans_exchange_req_owner_city,
        ownerState: data?.trans_exchange_req_owner_state,
        ownerMaritalStatus: data?.trans_exchange_req_owner_marital_status,
        annuitantOrInsured: data?.trans_exchange_req_owner_annuitant_insured,
        currentInsuranceCompany:
          data?.trans_exchange_req_current_insurance_company,
        currentInsuranceCompanyPhone:
          data?.trans_exchange_req_current_insurance_company_phone,
        currentInsuranceCompanyAccountNumber:
          data?.trans_exchange_req_current_insurance_company_acc_no,
        currentInsuranceCompanyStreetAddress:
          data?.trans_exchange_req_current_insurance_company_address,
        currentInsuranceCompanyZip:
          data?.trans_exchange_req_current_insurance_company_zip,
        currentInsuranceCompanyCity:
          data?.trans_exchange_req_current_insurance_company_city,
        currentInsuranceCompanyState:
          data?.trans_exchange_req_current_insurance_company_state,
        typeOfAccount: ReturnTypeOfAccountTransferExchangeRequest(
          data?.trans_exchange_req_current_insurance_company_acc_type?.value[0]
        ),
        otherTypeOfAccount:
          data?.trans_exchange_req_current_insurance_company_acc_type
            ?.extra_value_value,
        ownerExistingAnnuity:
          data?.trans_exchange_req_existing_annuity?.value[0],
        plans: ReturnTransferExchangeRequestPlan(
          data?.trans_exchange_req_non_qualified_plans,
          data?.trans_exchange_req_qualified_plans
        ),

        // nonQualifiedTransferOrExchangeExistingAccountAllOrPartial:
        // ReturnTransferExchangeRequestPlan(data?.trans_exchange_req_non_qualified_plans, data?.trans_exchange_req_qualified_plans) === "Non-Qualified Plans" ? data?.trans_exchange_req_non_qualified_plans.value[0].indexOf("All") === 0 ? "All" : data?.trans_exchange_req_non_qualified_plans.value[0].indexOf("Partial") === 0 ? "Partial" : "" : "",
        nonQualifiedTransferOrExchangeExistingAccountAllOrPartial:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Non-Qualified Plans"
            ? data?.trans_exchange_req_non_qualified_plans?.value[0] === "All"
              ? "All"
              : "Partial"
            : "",

        // nonQualifiedTransferOrExchangeExistingAccountPartialAmount:
        // ReturnTransferExchangeRequestPlan(data?.trans_exchange_req_non_qualified_plans, data?.trans_exchange_req_qualified_plans) === "Non-Qualified Plans" ? data?.trans_exchange_req_non_qualified_plans.value[0].indexOf("All") === 0 ? "" : data?.trans_exchange_req_non_qualified_plans.value[0].indexOf("Partial") === 0 ? data?.trans_exchange_req_non_qualified_plans.value[0].split("Partial ")[1] : "" : "" ,
        nonQualifiedTransferOrExchangeExistingAccountPartialAmount:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Non-Qualified Plans"
            ? data?.trans_exchange_req_non_qualified_plans?.extra_value_value.split(
              ","
            )[0]
            : "",

        nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Non-Qualified Plans"
            ? data?.trans_exchange_req_non_qualified_plans?.value[1] ===
              "Immediately"
              ? "Immediately"
              : "Upon the maturity date of"
            : "",

        nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Non-Qualified Plans"
            ? data?.trans_exchange_req_non_qualified_plans?.extra_value_value.split(
              ","
            )[1]
            : "",

        nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Non-Qualified Plans"
            ? data?.trans_exchange_req_non_qualified_plans?.value[2] ===
              "To New Policy/Account"
              ? "To New Policy/Account"
              : "To Existing Policy/Account"
            : "",

        nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Non-Qualified Plans"
            ? data?.trans_exchange_req_non_qualified_plans?.extra_value_value.split(
              ","
            )[2]
            : "",

        flagNonQualifiedOwnerWantWithdrawalToExchange:
          data?.trans_exchange_req_transfer_farmers_annulty_question_a.value[0],

        explainNonQualifiedOwnerWantWithdrawalToExchange:
          data?.trans_exchange_req_transfer_farmers_annulty_question_a
            ?.extra_value_value,

        flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal:
          data?.trans_exchange_req_transfer_farmers_annulty_question_b
            ?.value[0],

        qualifiedTransferOrExchangeExistingAccountAllOrPartial:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Qualified Plans"
            ? data?.trans_exchange_req_qualified_plans?.value[0] === "All"
              ? "All"
              : "Partial"
            : "",

        // qualifiedTransferOrExchangeExistingAccountPartialAmount:
        // ReturnTransferExchangeRequestPlan(data?.trans_exchange_req_non_qualified_plans, data?.trans_exchange_req_qualified_plans) === "Qualified Plans" ? data?.trans_exchange_req_qualified_plans.extra_value_value.split(",")[0].indexOf("All") === 0 ? "" : data?.trans_exchange_req_qualified_plans.value[0].indexOf("Partial") === 0 ? data?.trans_exchange_req_qualified_plans.value[0].split("Partial ")[1] : "" : "" ,
        qualifiedTransferOrExchangeExistingAccountPartialAmount:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Qualified Plans"
            ? data?.trans_exchange_req_qualified_plans?.extra_value_value.split(
              ","
            )[0]
            : "",

        qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Qualified Plans"
            ? data?.trans_exchange_req_qualified_plans?.value[1] ===
              "Immediately"
              ? "Immediately"
              : "Upon the maturity date of"
            : "",

        qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Qualified Plans"
            ? data?.trans_exchange_req_qualified_plans?.extra_value_value.split(
              ","
            )[1]
            : "",

        qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Qualified Plans"
            ? data?.trans_exchange_req_qualified_plans?.value[2] ===
              "To New Policy/Account"
              ? "To New Policy/Account"
              : "To Existing Policy/Account"
            : "",

        qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField:
          ReturnTransferExchangeRequestPlan(
            data?.trans_exchange_req_non_qualified_plans,
            data?.trans_exchange_req_qualified_plans
          ) === "Qualified Plans"
            ? data?.trans_exchange_req_qualified_plans?.extra_value_value.split(
              ","
            )[2]
            : "",

        qualifiedTypeOfTransfer:
          data?.trans_exchange_req_transfer_rollover?.value[0],

        qualifiedQualifyingEventForDirectRolloverFunds:
          data?.trans_exchange_req_direct_rollover_funds?.value[0],

        flagQualifiedOwnerReachedAge72OrMoreInThisYear:
          data?.trans_exchange_req_transfer_rmd_info_question_a?.value[0],
        flagQualifiedOwnerSatisfiedRMDFromDistributingPlan:
          data?.trans_exchange_req_transfer_rmd_info_question_b?.value[0],
        qualifiedInternalExchangeOfExistingAnnuityName:
          data?.trans_exchange_req_transfer_rmd_info_question_1,
        flagQualifiedOwnerWantRMDBeforeCompletingExchange:
          data?.trans_exchange_req_transfer_rmd_info_question_c?.value[0],
        flagQualifiedOwnerWantToContinueExistingRMDSchedule:
          data?.trans_exchange_req_transfer_rmd_info_question_d?.value[0],
        flagSignatureDigitalOrPhysical: data?.trans_exchange_req_signature_digital_or_physical?.value[0] ? data?.trans_exchange_req_signature_digital_or_physical?.value[0] : ""
        // witnessEmail: data?.witness_email,
        // witnessName: data?.witness_name
      });

      SetWholeAnnuityDisclosureForm({
        flagAnnuityClosure: data?.safegaurd_plus_annuity_disclosure?.value[0],
      });

      console.log("Printing data? toi update dthe cdata? in contex", data);

      SetWholeNoticeOfReplacement({
        flagDiscontinuingPremiumPayment:
          data?.notice_of_replacement_question1_a?.value[0],
        flagPayForPremiumPolicy:
          data?.notice_of_replacement_question1_b?.value[0],
        insurerPolicyDetailFieldArray: ReturnTempInsurerPolicyDetailFieldArray(
          data?.existing_policy
        ),
        reasonForReplacement: data?.notice_of_replacement_question1_d,
      });

      SetWholeAnnuityDisclosureForm({
        flagAnnuityClosure: data?.safegaurd_plus_annuity_disclosure?.value[0],
      });

      SetFlagLoader(false);
    } catch (e) {
      console.log(e);
      let gg = e.message;
      debugger;
    }
  };

  // const alertUser = (e) => {
  //   e.preventDefault();
  //   e.returnValue = "";
  // };

  // useEffect(() => {
  //   window.addEventListener("beforeunload", alertUser);
  //   return () => {
  //     window.removeEventListener("beforeunload", alertUser);
  //   };
  // }, [])


  useEffect(() => {
    SetFlagLoader(true);

    let searchQueryExist = window.location.search === "" ? false : true;

    if (window.location.search.includes("public_url_id") === false) {
      if (searchQueryExist === true) {
        let searchId = window.location.search?.substr(4);
        console.log(window.location.search);
        setApplicationId(searchId);
        // debugger;
        SetFlagLoader(true);
        GetSingleAnnuityApplicationAPI(
          {
            type: "applications_id",
            id: searchId,
          },
          (res) => {
            // let gg = res;
            // debugger;
            console.log("Printing response", res);
            setApplicationStatusName(res.data.data[0].application_status_name);
            console.log(
              "Printing appliction status name",
              res.data.data[0].application_status_name
            );
            console.log(
              "Printinging application id for the useffect",
              res.data.data[0].applications_id
            );
            // debugger;
            SetApplicationId(res.data.data[0].applications_id);
            if (res.data.data[0].application_status_name !== "Draft") {
              console.log(
                "Printing inside first useEffect for checking application status name draft or not",
                res.data.data[0].application_status_name
              );
              console.log(" ");
              if (applicationEditable === true) {
                SetDisableAllInputs(false);
              } else {
                console.log("It is coming in else opart of if condition");
                // debugger;
                SetDisableAllInputs(true);
              }
            } else {
              SetDisableAllInputs(false);
            }

            if (res.data.status === true) {
              // debugger;
              SetDataToContext(res.data.data[0].form_data);
            }
          },
          (err) => {
            // if (err.response.status === 401) {
            //   window.location.reload();
            // }
            SetFlagLoader(false);
          }
        );
      }

      ApplicationStatusId(
        {},
        (res) => {
          console.log("Printing rssponse", res);
          SetFlagLoader(!true);

          if (res.data.status === true) {
            res.data.data.map((v) => {
              if (v.name === "Submitted for Signature") {
                setSubmitApplicationStatusId(v);
              } else {
                setSaveAsDraftApplicationStatusId(v);
              }
            });
          }
        },
        (err) => {
          SetFlagLoader(!true);
          console.log("Printing error", err);
          if (err.response.status === 401) {
            window.location.reload();
          }
          // alert("Something went wrong, Please try again later.!");
        }
      );
    } else {
      GetSingleAnnuityApplicationAPI(
        {
          type: "unique_id_public_access",
          id: window.location.search.substr(15),
        },
        (res) => {
          console.log("Printing response public url id", res);
          // debugger;

          if (res.data.status === true) {
            SetDataToContext(res.data.data[0].form_data);
            setApplicationId(res.data.data[0].applications_id);
            setApplicationStatusName(res.data.data[0].application_status_name);
            if (res.data.data[0].application_status_name !== "Draft") {
              if (applicationEditable === true) {
                SetDisableAllInputs(false);
              } else {
                // debugger;
                SetDisableAllInputs(true);
              }
            }
          }
        },
        (err) => {
          console.log("Got error", err);
          SetFlagLoader(false);
          console.log("Printign navigate", navigate);
          navigate("/login", { replace: true });
        }
      );
    }
  }, []);

  useEffect(() => {
    console.log("Printing disable all input value", disableAllInputs);
    console.log(
      "Printing id includes in search or not",
      location.search.includes("id")
    );
    console.log("Printing application status name", applicationStatusName);
    console.log("Printing value of application editable", applicationEditable);
    if (location?.search?.includes("id") === true) {
      if (
        applicationStatusName !== "Draft" &&
        applicationStatusName !== "" &&
        applicationStatusName !== null
      ) {
        if (applicationEditable === true) {
          SetDisableAllInputs(false);
        } else {
          console.log("I am wrong, it comes in else part");
          // debugger;
          SetDisableAllInputs(true);
        }
      }
    }
  }, [applicationEditable, applicationStatusName]);

  // const { routingNumber, accountNumber, bankName, bankAddress } = bankDetails;

  const {
    flagDiscontinuingPremiumPayment,
    flagPayForPremiumPolicy,
    insurerPolicyDetailFieldArray,
    reasonForReplacement,
    brokerName,
    brokerEmail,
  } = noticeOfReplacement;

  const { flagAnnuityClosure } = annuityDisclosureForm;

  const {
    annuitantOrInsured,
    currentInsuranceCompany,
    currentInsuranceCompanyPhone,
    currentInsuranceCompanyAccountNumber,
    currentInsuranceCompanyStreetAddress,
    currentInsuranceCompanyZip,
    currentInsuranceCompanyCity,
    currentInsuranceCompanyState,
    typeOfAccount,
    otherTypeOfAccount,
    ownerExistingAnnuity,
    plans,
    nonQualifiedTransferOrExchangeExistingAccountAllOrPartial,
    nonQualifiedTransferOrExchangeExistingAccountPartialAmount,
    nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
    nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField,
    nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
    nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField,
    flagNonQualifiedOwnerWantWithdrawalToExchange,
    explainNonQualifiedOwnerWantWithdrawalToExchange,
    flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
    flagSignatureDigitalOrPhysical,
    qualifiedTransferOrExchangeExistingAccountAllOrPartial,
    qualifiedTransferOrExchangeExistingAccountPartialAmount,
    qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
    qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField,
    qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
    qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField,
    qualifiedTypeOfTransfer,
    qualifiedQualifyingEventForDirectRolloverFunds,
    flagQualifiedOwnerReachedAge72OrMoreInThisYear,
    flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
    qualifiedInternalExchangeOfExistingAnnuityName,
    flagQualifiedOwnerWantRMDBeforeCompletingExchange,
    flagQualifiedOwnerWantToContinueExistingRMDSchedule,
  } = transferOrExchangeRequest;

  console.log("flagSignatureDigitalOrPhysical::", flagSignatureDigitalOrPhysical)
  const {
    // ownerFullName,
    ownerCurrentAge,
    // ownerGender,
    ownerEmploymentStatus,
    ownerCurrentOccupation,
    ownerDependents,
    spouseFullName,
    spouseCurrentAge,
    spouseGender,
    spouseEmploymentStatus,
    spouseCurrentOccupation,
    spouseDependents,
    ownerAnnualHouseholdIncome,
    ownerSourceOfIncome,
    ownerIncomeTaxBracket,
    ownerTotalOutstandingDebt,
    ownerTotalAssets,
    ownerTotalLiquidAssets,
    flagOwnerIncomeCoverLivingAndMedicalExpenses,
    explainOwnerIncomeCoverLivingAndMedicalExpenses,
    flagOwnerExpectChangesInLivingExpenses,
    explainOwnerExpectChangesInLivingExpenses,
    flagOwnerAnticipateChangesInMedicalExpenses,
    explainOwnerAnticipateChangesInMedicalExpenses,
    flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
    explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
    flagOwnerHaveEmergencyFundForUnexpectedExpenses,
    explainOwnerHaveEmergencyFundForUnexpectedExpenses,
    ownerOtherInvestments,
    otherOwnerInvestmentField,
    ownerRiskTolerance,
    reasonForPurchasingProduct,
    otherRasonForPurchasingThisProduct,
    flagOwnerPlanToWithdrawFundInSurrenderPeriod,
    explainOwnerPlanToWithdrawFundInSurrenderPeriod,
    ownerAnticipateNeedingAccessToFunds,
    sourceOfFundsToPurchaseThisProduct,
    otherSourceOfFundsToPurchaseThisProduct,
    flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct,
    howLongHasTheLifeInsuranceBeenInForce,
    flagSurrenderChargeOnExistingProduct,
    currentSurrenderChargeOnExistingProduct,
    expirationOfCurrentSurrenderCharge,
    typeOfExistingProduct,
    interestRateForExistingProduct,
    flagTakingRequiredMinimumDistributionsFromExistingProduct,
    flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
    explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
    personsThatAssistedOwnerToPurchaseThisProduct,
    otherPersonsThatAssistedOwnerToPurchaseThisProduct,
    additionalRelaventInformationForSuitabilityAnalysis,
    agentAcknowledgment,
  } = suitabilityAnalysis;

  const {
    annuitantFullName,
    annuitantSSN,
    annuitantGender,
    annuitantDOB,
    annuitantStreetAddress,
    annuitantZip,
    annuitantCity,
    annuitantState,
    annuitantPhone,
    annuitantEmail,
    ownerFullName,
    ownerSSN,
    ownerGender,
    ownerDOB,
    ownerStreetAddress,
    ownerZip,
    ownerCity,
    ownerState,
    ownerEmail,
    ownerRelationToAnnuitant,
    jointOwnerFullName,
    jointOwnerSSN,
    jointOwnerGender,
    jointOwnerDOB,
    jointOwnerStreetAddress,
    jointOwnerZip,
    jointOwnerCity,
    jointOwnerState,
    jointOwnerEmail,
    jointOwnerRelationToAnnuitant,
    planAppliedFor,
    beneficiariesPrimary,
    beneficiariesContigent,
    selectedNonQualifiedOptions,
    // selectedQualifiedOptions,
    otherSelectedQualifiedOptions,
    selectedQualifiedOptionsGroup2,
    stateToShowNoticeOfReplacement,
    flagQualifiedOrNonQualifiedSelected,
    amountPaidWithApplication,
    expectedPremiumField,
    flagHaveExistingLifeInsurance,
    reasonOfApplicationToChangeLifeInsurance,
    applicationReplacementFieldsArray,
  } = individualAnnuityApplication;

  const tempBeneficiaries = () => {
    console.log("beneficiariesPrimary", beneficiariesPrimary);
    console.log("beneficiariesContigent", beneficiariesContigent);
    // debugger;
    let temp = [];
    beneficiariesPrimary.map((obj) => {
      temp.push({
        beneficiaries_flag_primary: "1",
        beneficiaries_value: obj?.primaryField,
        beneficiaries_percentage: obj?.percentageField,
        beneficiaries_ssn_tin_no: obj?.ssnField,
        beneficiaries_gender: obj?.genderField,
        beneficiaries_id: obj?.id,
        beneficiaries_relationship: obj?.relationshipField,
      });
    });

    console.log(temp);
    // debugger;

    beneficiariesContigent.map((obj) => {
      temp.push({
        beneficiaries_flag_primary: "0",
        beneficiaries_value: obj?.contigentField,
        beneficiaries_percentage: obj?.percentageField,
        beneficiaries_ssn_tin_no: obj?.ssnField,
        beneficiaries_gender: obj?.genderField,
        beneficiaries_id: obj?.id,
        beneficiaries_relationship: obj?.relationshipField,
      });
    });

    console.log(temp);
    // debugger;

    return temp;
  };

  const tempInsurerPolicyDetailFieldArray = () => {

    let temp = [];
    insurerPolicyDetailFieldArray.map((v, i) => {
      if (window.location.search.includes("id") === false) {
        temp.push({
          existing_policy_existing_carrier_name: v.existingCarrierName,
          existing_policy_existing_carrier_address: v.existingCarrierAddress,
          existing_policy_policy_number: v.contractOrPolicyNumber,
          existing_policy_name_of_annuitant: v.nameOfInsuredOrAnnuitant,
          existing_policy_r_or_f: "",
          existing_policy_face_amount: v.amount,
          existing_policy_effective_date: new Date(v.effectiveDate).toDateString() === "Invalid Date"
            ? ""
            : `${new Date(v.effectiveDate).getMonth() + 1}/${new Date(
              v.effectiveDate
            ).getDate()}/${new Date(v.effectiveDate).getFullYear()}`
        });
      } else {
        temp.push({
          existing_policy_id: v.id,
          existing_policy_existing_carrier_name: v.existingCarrierName,
          existing_policy_existing_carrier_address: v.existingCarrierAddress,
          existing_policy_policy_number: v.contractOrPolicyNumber,
          existing_policy_name_of_annuitant: v.nameOfInsuredOrAnnuitant,
          existing_policy_r_or_f: v.replacedOrFinancing,
          existing_policy_face_amount: v.amount,
          existing_policy_effective_date:
            new Date(v.effectiveDate).toDateString() === "Invalid Date"
              ? ""
              : `${new Date(v.effectiveDate).getMonth() + 1}/${new Date(
                v.effectiveDate
              ).getDate()}/${new Date(v.effectiveDate).getFullYear()}`
        });
      }
    });
    return temp;
  };

  const replaceMentQuestionDataArray = () => {
    // debugger;
    let temp = [];
    applicationReplacementFieldsArray.map((obj) => {
      if (obj?.id < 0) {
        temp.push({
          replacement_question_contact_data_name: obj?.companyNameOfPolicyField,
          replacement_question_contact_data_contact_number:
            obj?.policyNumberField,
        });
      } else {
        temp.push({
          replacement_question_contact_data_id: obj?.id,
          replacement_question_contact_data_name: obj?.companyNameOfPolicyField,
          replacement_question_contact_data_contact_number:
            obj?.policyNumberField,
        });
      }
    });

    console.log(temp);

    return temp;
  };
  function isValidEmail(email) {
    return /^([a-zA-Z0-9\._]+)@([a-zA-Z)-9])+.([a-z]+)(.[a-z]+)?$/.test(email);
  }

  function isValidZip(zip) {
    return /^[0-9]{5}(?:-[0-9]{4})?$/.test(zip);
  }
  function validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return re.test(input_str);
  }

  const OwnerValidation = () => {
    const { individualAnnuityApplication } = state;
    // debugger;
    const {
      ownerFullName,
      ownerSSN,
      ownerGender,
      ownerDOB,
      ownerStreetAddress,
      ownerZip,
      ownerCity,
      ownerState,
      ownerEmail,
      ownerRelationToAnnuitant,
    } = individualAnnuityApplication;

    let flagValidEmail = isValidEmail(ownerEmail);
    if (
      ownerFullName === "" &&
      ownerSSN === "" &&
      ownerGender === "" &&
      ownerDOB === "" &&
      ownerStreetAddress === "" &&
      ownerZip === "" &&
      ownerCity === "" &&
      ownerState === "" &&
      ownerEmail === "" &&
      // isValidEmail(ownerEmail) === false &&
      ownerRelationToAnnuitant === ""
    ) {
      return true;
    } else if (
      ownerFullName !== "" &&
      ownerSSN !== "" &&
      ownerGender !== "" &&
      ((ownerGender === "Not Applicable") || (ownerGender !== "Not Applicable" && ownerDOB !== "")) &&
      ownerStreetAddress !== "" &&
      ownerZip !== "" &&
      isValidZip(ownerZip) === true &&
      ownerCity !== "" &&
      ownerState !== "" &&
      ownerEmail !== "" &&
      isValidEmail(ownerEmail) === true &&
      ownerRelationToAnnuitant !== ""
    ) {
      return true;
    }
    //  else if (isValidEmail(jointOwnerEmail) === false) {
    //   return true;
    // }
    else {
      return false;
    }
  };

  const JointOwnerValidation = () => {
    const { individualAnnuityApplication } = state;
    let temp = [];
    let returnObj = {};
    // debugger;
    const {
      jointOwnerFullName,
      jointOwnerSSN,
      jointOwnerGender,
      jointOwnerDOB,
      jointOwnerStreetAddress,
      jointOwnerZip,
      jointOwnerCity,
      jointOwnerState,
      jointOwnerEmail,
      jointOwnerRelationToAnnuitant,
    } = individualAnnuityApplication;
    if (
      jointOwnerFullName === "" &&
      jointOwnerSSN === "" &&
      jointOwnerGender === "" &&
      jointOwnerDOB === "" &&
      jointOwnerStreetAddress === "" &&
      jointOwnerZip === "" &&
      jointOwnerCity === "" &&
      jointOwnerState === "" &&
      jointOwnerEmail === "" &&
      isValidEmail(jointOwnerEmail) === false &&
      jointOwnerRelationToAnnuitant === ""
    ) {
      return true;
    } else if (
      jointOwnerFullName !== "" &&
      jointOwnerSSN !== "" &&
      jointOwnerGender !== "" &&
      ((jointOwnerGender === "Not Applicable") || (jointOwnerGender !== "Not Applicable" && jointOwnerDOB !== "")) &&
      jointOwnerStreetAddress !== "" &&
      jointOwnerZip !== "" &&
      jointOwnerCity !== "" &&
      jointOwnerState !== "" &&
      jointOwnerEmail !== "" &&
      // isValidEmail(ownerEmail) === true &&
      jointOwnerRelationToAnnuitant !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const ReplacementQuestionDataArrayValidation = () => {
    let temp = applicationReplacementFieldsArray;

    temp = applicationReplacementFieldsArray.filter((v) => {
      if (v.companyNameOfPolicyField === "" || v.policyNumberField === "") {
        return false;
      } else {
        return true;
      }
    });

    if (temp.length === 0) {
      return false;
    } else {
      return true;
    }
  };

  const SpouseValidation = () => {
    if (
      spouseFullName === "" &&
      spouseGender === "" &&
      spouseEmploymentStatus === "" &&
      spouseCurrentOccupation === "" &&
      spouseCurrentAge === "" &&
      spouseDependents === ""
    ) {
      return true;
    } else if (
      spouseFullName !== "" &&
      spouseGender !== "" &&
      spouseEmploymentStatus !== "" &&
      spouseCurrentOccupation !== "" &&
      spouseCurrentAge !== "" &&
      spouseDependents !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const CheckInsuredPolicyFieldArray = () => {
    let temp = [];
    temp = insurerPolicyDetailFieldArray.map((v) => {
      if (
        v.existingCarrierName === "" &&
        v.contractOrPolicyNumber === "" &&
        v.nameOfInsuredOrAnnuitant === ""
      ) {
        return false;
      } else if (
        v.existingCarrierName !== "" &&
        v.contractOrPolicyNumber !== "" &&
        v.nameOfInsuredOrAnnuitant !== ""
      ) {
        return true;
      } else {
        return false;
      }
    });

    if (temp.indexOf(true) > -1) {
      return true;
    } else {
      return false;
    }
  };

  const BasicInformationValidation = () => {
    const {
      annuitantOrInsured,
      currentInsuranceCompany,
      currentInsuranceCompanyPhone,
      currentInsuranceCompanyAccountNumber,
      currentInsuranceCompanyStreetAddress,
      currentInsuranceCompanyZip,
      currentInsuranceCompanyCity,
      currentInsuranceCompanyState,
      typeOfAccount,
      otherTypeOfAccount,
      ownerExistingAnnuity,
    } = transferOrExchangeRequest;

    if (
      transferOrExchangeRequest?.ownerFullName !== "" &&
      transferOrExchangeRequest?.ownerSSN !== "" &&
      transferOrExchangeRequest?.ownerStreetAddress !== "" &&
      transferOrExchangeRequest?.ownerZip !== "" &&
      // isValidZip(transferOrExchangeRequest?.ownerZip) === true &&
      transferOrExchangeRequest?.ownerCity !== "" &&
      transferOrExchangeRequest?.ownerState !== "" &&
      transferOrExchangeRequest?.ownerMaritalStatus !== "" &&
      // annuitantOrInsured !== "" &&
      currentInsuranceCompany !== "" &&
      currentInsuranceCompanyPhone !== "" &&
      currentInsuranceCompanyAccountNumber !== "" &&
      currentInsuranceCompanyState !== "" &&
      currentInsuranceCompanyStreetAddress !== "" &&
      currentInsuranceCompanyCity !== "" &&
      currentInsuranceCompanyPhone !== "" &&
      currentInsuranceCompanyZip !== "" &&
      // isValidZip(currentInsuranceCompanyZip) === true &&
      typeOfAccount !== "" &&
      (typeOfAccount === "Other" && otherTypeOfAccount !== ""
        ? true
        : typeOfAccount !== "Other"
          ? true
          : false) &&
      ownerExistingAnnuity !== ""
    ) {
      return "filled";
    } else if (
      transferOrExchangeRequest?.ownerFullName === "" &&
      transferOrExchangeRequest?.ownerSSN === "" &&
      transferOrExchangeRequest?.ownerStreetAddress === "" &&
      transferOrExchangeRequest?.ownerZip === "" &&
      // isValidZip(transferOrExchangeRequest?.ownerZip) === true &&
      transferOrExchangeRequest?.ownerCity === "" &&
      transferOrExchangeRequest?.ownerState === "" &&
      transferOrExchangeRequest?.ownerMaritalStatus === "" &&
      // annuitantOrInsured === "" &&
      currentInsuranceCompany === "" &&
      currentInsuranceCompanyPhone === "" &&
      currentInsuranceCompanyAccountNumber === "" &&
      currentInsuranceCompanyState === "" &&
      currentInsuranceCompanyStreetAddress === "" &&
      currentInsuranceCompanyCity === "" &&
      currentInsuranceCompanyPhone === "" &&
      currentInsuranceCompanyZip === "" &&
      // isValidZip(currentInsuranceCompanyZip) === true &&
      typeOfAccount === "" &&
      ownerExistingAnnuity === ""
    ) {
      return "blank";
    } else if (
      transferOrExchangeRequest?.ownerFullName !== "" ||
      transferOrExchangeRequest?.ownerSSN !== "" ||
      transferOrExchangeRequest?.ownerStreetAddress !== "" ||
      transferOrExchangeRequest?.ownerZip !== "" ||
      // isValidZip(transferOrExchangeRequest?.ownerZip) === true ||
      transferOrExchangeRequest?.ownerCity !== "" ||
      transferOrExchangeRequest?.ownerState !== "" ||
      transferOrExchangeRequest?.ownerMaritalStatus !== "" ||
      // annuitantOrInsured !== "" ||
      currentInsuranceCompany !== "" ||
      currentInsuranceCompanyPhone !== "" ||
      currentInsuranceCompanyAccountNumber !== "" ||
      currentInsuranceCompanyState !== "" ||
      currentInsuranceCompanyStreetAddress !== "" ||
      currentInsuranceCompanyCity !== "" ||
      currentInsuranceCompanyPhone !== "" ||
      currentInsuranceCompanyZip !== "" ||
      // isValidZip(currentInsuranceCompanyZip) === true ||
      typeOfAccount !== "" ||
      ownerExistingAnnuity !== ""
    ) {
      return "partially"
    }
    // else {
    //   return false;
    // }
  };

  const PlansValidation = () => {
    const {
      plans,
      nonQualifiedTransferOrExchangeExistingAccountAllOrPartial,
      nonQualifiedTransferOrExchangeExistingAccountPartialAmount,
      nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
      nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField,
      nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
      nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField,
      flagNonQualifiedOwnerWantWithdrawalToExchange,
      explainNonQualifiedOwnerWantWithdrawalToExchange,
      flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
      qualifiedTransferOrExchangeExistingAccountAllOrPartial,
      qualifiedTransferOrExchangeExistingAccountPartialAmount,
      qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate,
      qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField,
      qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy,
      qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField,
      qualifiedTypeOfTransfer,
      qualifiedQualifyingEventForDirectRolloverFunds,
      flagQualifiedOwnerReachedAge72OrMoreInThisYear,
      flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
      qualifiedInternalExchangeOfExistingAnnuityName,
      flagQualifiedOwnerWantRMDBeforeCompletingExchange,
      flagQualifiedOwnerWantToContinueExistingRMDSchedule,
    } = transferOrExchangeRequest;

    if (plans === "") {
      return "blank";
    } else {
      if (plans === "Non-Qualified Plans") {
        if (
          nonQualifiedTransferOrExchangeExistingAccountAllOrPartial !== "" &&
          (nonQualifiedTransferOrExchangeExistingAccountAllOrPartial ===
            "Partial"
            ? nonQualifiedTransferOrExchangeExistingAccountPartialAmount !== ""
            : true) &&
          nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate !==
          "" &&
          (nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate ===
            "Upon the maturity date of"
            ? nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
            ""
            : true) &&
          nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy !==
          "" &&
          (nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy ===
            "To Existing Policy/Account"
            ? nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
            ""
            : true) &&
          flagNonQualifiedOwnerWantWithdrawalToExchange !== "" &&
          (flagNonQualifiedOwnerWantWithdrawalToExchange === "Yes" &&
            explainNonQualifiedOwnerWantWithdrawalToExchange !== ""
            ? true
            : flagNonQualifiedOwnerWantWithdrawalToExchange === "No"
              ? true
              : false) &&
          flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal !== ""
        ) {
          return "filled";
        } else {
          return "partially";
        }
      } else {
        if (
          qualifiedTransferOrExchangeExistingAccountAllOrPartial !== "" &&
          (qualifiedTransferOrExchangeExistingAccountAllOrPartial === "Partial"
            ? qualifiedTransferOrExchangeExistingAccountPartialAmount !== ""
            : true) &&
          qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate !==
          "" &&
          (qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate ===
            "Upon the maturity date of"
            ? qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField !==
            ""
            : true) &&
          qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy !==
          "" &&
          (qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy ===
            "To Existing Policy/Account"
            ? qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField !==
            ""
            : true) &&
          qualifiedTypeOfTransfer !== "" &&
          qualifiedQualifyingEventForDirectRolloverFunds !== "" &&
          flagQualifiedOwnerReachedAge72OrMoreInThisYear !== "" &&
          flagQualifiedOwnerSatisfiedRMDFromDistributingPlan !== "" &&
          (flagQualifiedOwnerReachedAge72OrMoreInThisYear === "Yes" &&
            flagQualifiedOwnerSatisfiedRMDFromDistributingPlan === "No"
            ? qualifiedInternalExchangeOfExistingAnnuityName !== ""
            : true)
          //   &&
          // flagQualifiedOwnerWantRMDBeforeCompletingExchange !== "" &&
          // flagQualifiedOwnerWantToContinueExistingRMDSchedule !== ""
        ) {
          return "filled";
        } else {
          return "partially";
        }
      }
    }
  };

  const ConditionToShowReplacementForm = () => {
    if (reasonOfApplicationToChangeLifeInsurance == "Yes" && stateToShowNoticeOfReplacement) {
      return true
    } else {
      return false
    }
  };


  const Validation = () => {
    // whole annuitant, owner - joint owner (one field is filled then all else zero), plans applied for
    const { individualAnnuityApplication } = state;
    // debugger;
    // whole annuitant
    const {
      annuitantFullName,
      annuitantSSN,
      annuitantGender,
      annuitantDOB,
      annuitantStreetAddress,
      annuitantZip,
      annuitantCity,
      annuitantState,
      annuitantPhone,
      annuitantEmail,
    } = individualAnnuityApplication;

    // plans applied for
    const { planAppliedFor } = individualAnnuityApplication;

    console.log(
      annuitantFullName === "" ||
      annuitantSSN === "" ||
      annuitantGender === "" ||
      annuitantDOB === "" ||
      annuitantStreetAddress === "" ||
      annuitantZip === "" ||
      annuitantCity === "" ||
      annuitantState === "" ||
      annuitantPhone === "" ||
      annuitantEmail === "" ||
      // isValidEmail(annuitantEmail) === false ||
      OwnerValidation() === false ||
      JointOwnerValidation() === false ||
      planAppliedFor === ""
    );

    let temp = [];
    let returnObj = {};

    if (annuitantFullName === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Annuitant full name",
      });
    }
    if (annuitantSSN === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "SSN/TIN",
      });
    }
    if (annuitantGender === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Gender",
      });
    }
    if (annuitantDOB === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Date of Birth",
      });
    }
    if (annuitantStreetAddress === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Street Address",
      });
    }
    if (annuitantZip === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Zip",
      });
    }
    if (annuitantCity === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "City",
      });
    }
    if (annuitantState === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "State",
      });
    }
    if (annuitantPhone === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Phone",
      });
    }
    if (annuitantEmail === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Email",
      });
    }
    if (
      isValidEmail(annuitantEmail) === false &&
      annuitantEmail.length > 0 &&
      annuitantEmail !== ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Email Id is not valid",
      });
    }
    if (isValidZip(annuitantZip) === false && annuitantZip.length > 0) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "ZIP number only contains 5 digits",
      });
    }
    if (validatePhoneNumber(annuitantPhone) === false) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "1",
        field: "Phone Number is not valid",
      });
    }

    if (OwnerValidation() === false) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "2",
        field: "All fields are required",
      });
    }
    if (isValidZip(ownerZip) === false && ownerZip.length > 0) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "2",
        field: "ZIP number only contains 5 digits",
      });
    }
    if (
      isValidEmail(ownerEmail) === false &&
      ownerEmail.length > 0 &&
      ownerEmail !== ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "2",
        field: "Email Id is not valid",
      });
    }
    if (JointOwnerValidation() === false) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "3",
        field: "All fields are required",
      });
    }
    if (
      isValidEmail(jointOwnerEmail) === false &&
      jointOwnerEmail.length >= 0 &&
      jointOwnerEmail !== ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "3",
        field: "Email Id is not valid",
      });
    }
    if (isValidZip(jointOwnerZip) === false && jointOwnerZip.length > 0) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "3",
        field: "ZIP number only contains 5 digits",
      });
    }
    // 10th january 4th section validation

    if (planAppliedFor.length < 1) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "4",
        field: "Please select atleast one annuity plan",
      });
    } else if (planAppliedFor.length === 1) {
      if (
        (planAppliedFor.includes("Safeguard Plus 3") ||
          planAppliedFor.includes("Safeguard Plus 5") ||
          planAppliedFor.includes("Safeguard Plus 7") ||
          planAppliedFor.includes("Safeguard Plus 10")) === false
      ) {
        returnObj["status"] = false;
        temp.push({
          page: "Individual Annuity Application",
          section: "4",
          field: "Please select atleast one annuity plan",
        });
      }
    } else {
    }

    const tempBeneficiariesf1 = () => {
      let count = 0;
      let qq;
      let result = beneficiariesPrimary.map((index, i) => {
        console.log("!!!!", i, index);

        if (
          index.primaryField === "" &&
          index.relationshipField === "" &&
          index.ssnField === "" &&
          index.percentageField === "" &&
          index.genderField === ""
        ) {
          return count++, true;
        } else if (
          index.primaryField !== "" &&
          index.relationshipField !== "" &&
          index.ssnField !== "" &&
          index.percentageField !== "" &&
          index.genderField !== ""
        ) {
          return true;
        } else {
          return false;
        }
      });
      if (count === 6) {
        qq = true;
      } else {
        qq = result.includes(false);
      }
      console.log("11111", result, qq);
      return qq;
    };
    const tempbeneficiariesContigentf2 = () => {
      // debugger;
      let qq;
      let result = beneficiariesContigent.map((index, i) => {
        console.log("!!!!", i, index);
        if (
          index.contigentField === "" &&
          index.relationshipField === "" &&
          index.ssnField === "" &&
          index.percentageField === "" &&
          index.genderField === ""
        ) {
          return true;
        } else if (
          index.contigentField !== "" &&
          index.relationshipField !== "" &&
          index.ssnField !== "" &&
          index.percentageField !== "" &&
          index.genderField !== ""
        ) {
          return true;
        } else {
          return false;
        }
      });

      qq = result.includes(false);

      console.log("11111", result, qq);
      return qq;
    };

    const isContingentPercentage = () => {
      let qq;
      let result = beneficiariesContigent.map((index, i) => {

        if (index.percentageField === "") {
          return true;
        } else {
          return false;
        }
      });

      qq = result.indexOf(false) > -1 ? true : false;

      console.log("11111", result, qq);
      return qq;
    };

    const tempBeneficiariespersentage = () => {
      let temp2 = beneficiariesPrimary.reduce((obj, t1) => {
        let tt1 = t1.percentageField !== "" ? parseInt(t1.percentageField) : 0;
        return obj + tt1;
      }, 0);
      console.log("22222", temp2);

      return temp2 === 100 ? true : false;
    };

    const tempbeneficiariesContigentpersentage = () => {
      let temp3;
      // if (tempBeneficiariespersentage()) {
      {
        console.log("beneficiariesContigent", beneficiariesContigent);
        temp3 = beneficiariesContigent.reduce((obj, t1) => {
          let tt2 =
            t1.percentageField !== "" ? parseInt(t1.percentageField) : 0;
          return obj + tt2;
        }, 0);
        console.log("22222", temp3);
      }
      return temp3 === 100 ? true : false;
    };

    // console.log("@@@@", tempBeneficiariesf1.includes(true));

    if (tempBeneficiariesf1()) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "5",
        field: "Primary: All feilds are mandatory of this section",
      });
    } else if (tempBeneficiariespersentage() === false) {
      // debugger;
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "5",
        field:
          "Primary  Beneficiaries Percentage should not be more than or less than 100%",
      });
    }

    // if (tempbeneficiariesContigentf2()) {
    //   returnObj["status"] = false;
    //   // debugger;
    //   temp.push({
    //     page: "Individual Annuity Application",
    //     section: "5",
    //     field: " Contigent:  All feilds are mandatory of this section",
    //   });
    // }
    if (
      isContingentPercentage() === true &&
      tempbeneficiariesContigentpersentage() === false
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "5",
        field:
          "Contigent Beneficiaries Percentage should not be more than or less than 100%",
      });
    }

    // console.log("@@@", tempbeneficiariesContigentpersentage() === false);
    // if (
    //   tempbeneficiariesContigentf2() === true &&
    //   tempbeneficiariesContigentpersentage() === false
    // ) {
    //   // debugger;
    //   returnObj["status"] = false;
    //   temp.push({
    //     page: "Individual Annuity Application",
    //     section: "5",
    //     field:
    //       "Contigent Beneficiaries Percentage should not be more than or less than 100%",
    //   });
    // }

    // if (amountPaidWithApplication === "")
    if (
      (individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected !== "" &&
        individualAnnuityApplication.selectedNonQualifiedOptions.length > 0 &&
        individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("Cash with application") > -1 &&
        individualAnnuityApplication.selectedQualifiedOptionsGroup2 !== "" &&
        amountPaidWithApplication === "")
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "6",
        field: "Amount Paid With Application",
      });
    }

    if (
      (individualAnnuityApplication.flagQualifiedOrNonQualifiedSelected !== "" &&
        individualAnnuityApplication.selectedNonQualifiedOptions.length > 0 &&
        (individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("Transfer") > -1 ||
          individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("Rollover") > -1 ||
          individualAnnuityApplication.selectedNonQualifiedOptions.indexOf("1035 exchange") > -1
        )
        &&
        individualAnnuityApplication.selectedQualifiedOptionsGroup2 !== "" &&
        expectedPremiumField === "")
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "6",
        field: "expected Premium Field",
      });
    }

    if (flagQualifiedOrNonQualifiedSelected === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "6",
        field: "Select atleast One checkBox out of two",
      });
    }
    // debugger;
    if (
      flagQualifiedOrNonQualifiedSelected !== "" &&
      selectedNonQualifiedOptions?.length == 0
    ) {
      debugger;
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "6",
        field: "Select atleast one tax qualification status",
      });
    }
    if (
      flagQualifiedOrNonQualifiedSelected !== "" &&
      (selectedNonQualifiedOptions?.length > 0 && selectedQualifiedOptionsGroup2 === "")
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "6",
        field: "Select atleast one Check type of plan",
      });
    }

    if (flagHaveExistingLifeInsurance === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "7",
        field: "Question 1",
      });
    }

    if (reasonOfApplicationToChangeLifeInsurance === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "7",
        field: "Question 2",
      });
    }

    if (
      reasonOfApplicationToChangeLifeInsurance === "Yes" &&
      ReplacementQuestionDataArrayValidation() === false
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Individual Annuity Application",
        section: "7",
        field:
          "Atleast one row should be filled for Company Name of Policy Replaced and Policy or Contract Number",
      });
    }
    if (
      suitabilityAnalysis.ownerFullName === "" ||
      ownerCurrentAge === "" ||
      suitabilityAnalysis.ownerGender === "" ||
      ownerEmploymentStatus === "" ||
      ownerCurrentOccupation === "" ||
      ownerDependents === ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "1",
        field: "All information must be filled",
      });
    }

    if (
      ownerAnnualHouseholdIncome === "" ||
      ownerSourceOfIncome === "" ||
      ownerTotalAssets === "" ||
      ownerTotalLiquidAssets === "" ||
      ownerTotalOutstandingDebt === "" ||
      ownerIncomeTaxBracket === ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "2",
        field: "All fields of section 2 must be filled",
      });
    }
    if (
      flagOwnerIncomeCoverLivingAndMedicalExpenses === "" ||
      flagOwnerExpectChangesInLivingExpenses === "" ||
      flagOwnerAnticipateChangesInMedicalExpenses === "" ||
      flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
      "" ||
      flagOwnerHaveEmergencyFundForUnexpectedExpenses === "" ||
      ownerOtherInvestments.length === 0 ||
      ownerRiskTolerance === ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "3",
        field: "All fields of section 3 must be filled",
      });
    }
    if (
      (flagOwnerIncomeCoverLivingAndMedicalExpenses === "No" &&
        explainOwnerIncomeCoverLivingAndMedicalExpenses === "") ||
      (flagOwnerExpectChangesInLivingExpenses === "Yes" &&
        explainOwnerExpectChangesInLivingExpenses === "") ||
      (flagOwnerAnticipateChangesInMedicalExpenses === "Yes" &&
        explainOwnerAnticipateChangesInMedicalExpenses === "") ||
      (flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
        "No" &&
        explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod ===
        "") ||
      (flagOwnerHaveEmergencyFundForUnexpectedExpenses === "No" &&
        explainOwnerHaveEmergencyFundForUnexpectedExpenses === "")
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "3",
        field: "Please fill all the fields of explain for section 3",
      });
    }
    if (
      reasonForPurchasingProduct.length === 0 ||
      flagOwnerPlanToWithdrawFundInSurrenderPeriod === "" ||
      (flagOwnerPlanToWithdrawFundInSurrenderPeriod === "Yes" &&
        explainOwnerPlanToWithdrawFundInSurrenderPeriod === "") ||
      ownerAnticipateNeedingAccessToFunds === "" ||
      sourceOfFundsToPurchaseThisProduct.length === 0
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "4",
        field: "Please fill all the fields",
      });
    }
    if (
      flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct === ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "5",
        field: "Please fill all the fields",
      });
    }
    if (
      flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct ===
      "Yes (complete the rest of section 5)" &&
      (howLongHasTheLifeInsuranceBeenInForce === "" ||
        flagSurrenderChargeOnExistingProduct === "" ||
        typeOfExistingProduct === "" ||
        interestRateForExistingProduct === "" ||
        flagTakingRequiredMinimumDistributionsFromExistingProduct === "" ||
        flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution === "")
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "5",
        field: "Please fill all the fields",
      });
    }
    if (
      flagSurrenderChargeOnExistingProduct === "Yes" &&
      (currentSurrenderChargeOnExistingProduct === "" ||
        expirationOfCurrentSurrenderCharge === "") &&
      flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct ===
      "Yes (complete the rest of section 5)"
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "5",
        field:
          "What is current charge and when it will expire fields are required",
      });
    }
    if (
      flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution ===
      "Yes" &&
      explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution === ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "5",
        field: "For question 7, please describe",
      });
    }
    if (agentAcknowledgment === "") {
      returnObj["status"] = false;
      temp.push({
        page: "Suitability Analysis",
        section: "7",
        field: "fill this section",
      });
    }

    if (ConditionToShowReplacementForm() === true) {
      if (
        flagDiscontinuingPremiumPayment === "" ||
        flagPayForPremiumPolicy === ""
      ) {
        returnObj["status"] = false;
        temp.push({
          page: "Notice of Replacement",
          section: "1",
          field: "Please fill all fields",
        });
      }
      if (
        (flagDiscontinuingPremiumPayment === "Yes" ||
          flagPayForPremiumPolicy === "Yes") &&
        (CheckInsuredPolicyFieldArray() === false)
      ) {
        returnObj["status"] = false;
        temp.push({
          page: "Notice of Replacement",
          section: "1",
          field: "Question C is required ",
        });
      }
      if (
        (flagDiscontinuingPremiumPayment === "Yes" ||
          flagPayForPremiumPolicy === "Yes") &&
        (reasonForReplacement == "")
      ) {
        returnObj["status"] = false;
        temp.push({
          page: "Notice of Replacement",
          section: "1",
          field: "Question D is required ",
        });
      }
    }

    if (
      (BasicInformationValidation() == "blank" && PlansValidation() == "blank" && flagSignatureDigitalOrPhysical == "")
      ||
      (BasicInformationValidation() == "filled" && PlansValidation() == "filled" && flagSignatureDigitalOrPhysical !== "")
    ) {
      // return true
    } else {
      if ((BasicInformationValidation() === "filled" || flagSignatureDigitalOrPhysical !== "") &&
        (PlansValidation() == "blank" || PlansValidation() == "partially")) {
        returnObj["status"] = false;
        temp.push({
          page: "Transfer or Exchange Request",
          section: "2",
          field: "Please fill all fields",
        });
      }
      if ((PlansValidation() === "filled" || flagSignatureDigitalOrPhysical !== "") &&
        (BasicInformationValidation() == "blank" || BasicInformationValidation() == "partially")) {
        returnObj["status"] = false;
        temp.push({
          page: "Transfer or Exchange Request",
          section: "1",
          field: "Please fill all fields",
        });
      }
      if (
        (BasicInformationValidation() === "partially" && PlansValidation() == "partially") ||
        (BasicInformationValidation() === "blank" && PlansValidation() == "partially") ||
        (BasicInformationValidation() === "partially" && PlansValidation() == "blank")
      ) {
        // debugger;
        returnObj["status"] = false;
        temp.push({
          page: "Transfer or Exchange Request",
          section: "1",
          field: "Please fill all fields",
        });
        temp.push({
          page: "Transfer or Exchange Request",
          section: "2",
          field: "Please fill all fields",
        });
      }
      if (flagSignatureDigitalOrPhysical === "") {
        temp.push({
          page: "Transfer or Exchange Request",
          section: "3",
          field: "Please fill all fields",
        });
      }

    }
    if (
      isValidZip(transferOrExchangeRequest?.ownerZip) === false &&
      transferOrExchangeRequest?.ownerZip.length > 0 &&
      transferOrExchangeRequest?.ownerZip !== ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Transfer or Exchange Request",
        section: "1",
        field: "ZIP number should sontains 5 digits",
      });
    }
    if (
      isValidZip(currentInsuranceCompanyZip) === false &&
      currentInsuranceCompanyZip.length > 0 &&
      currentInsuranceCompanyZip !== ""
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Transfer or Exchange Request",
        section: "1",
        field: "ZIP number should sontains 5 digits",
      });
    }

    if (
      validatePhoneNumber(currentInsuranceCompanyPhone) === false &&
      currentInsuranceCompanyPhone !== "" &&
      currentInsuranceCompanyPhone.length > 0
    ) {
      returnObj["status"] = false;
      temp.push({
        page: "Transfer or Exchange Request",
        section: "1",
        field: "Phone Number is not valid",
      });
    }

    if (flagAnnuityClosure == "") {
      returnObj["status"] = false;
      temp.push({
        page: "Annuity Disclosure",
        section: "6",
        field: "fill this section",
      });
    }

    returnObj["data"] = temp;
    console.log("Printing returnobj", returnObj);
    return returnObj;
  };

  const FilteredArray = (data, replace) => {
    let temp = data;
    temp = temp.filter((v) => {
      if (v !== "Other") {
        return v;
      }
    });

    console.log(data, replace, temp);
    return temp.join(",");
  };

  useEffect(() => {
    if (
      stateToShowNoticeOfReplacement !== "Tennessee" &&
      stateToShowNoticeOfReplacement !== ""
    ) {
      if (flagQualifiedOrNonQualifiedSelected === "Non - Qualified") {
        if (
          selectedNonQualifiedOptions?.length == 1 &&
          selectedNonQualifiedOptions.indexOf("Cash with application") > -1 &&
          selectedQualifiedOptionsGroup2 !== ""
        ) {
          SetWholeNoticeOfReplacement({
            flagDiscontinuingPremiumPayment: "",
            flagPayForPremiumPolicy: "",
            insurerPolicyDetailFieldArray: [
              {
                id: 1,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 2,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 3,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 4,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
            ],
            reasonForReplacement: ""
          });
        }
      } else if (flagQualifiedOrNonQualifiedSelected === "Qualified") {
        if (
          selectedNonQualifiedOptions?.length == 1 &&
          selectedNonQualifiedOptions.indexOf("Cash with application") > -1 &&
          selectedQualifiedOptionsGroup2 !== ""
        ) {
          SetWholeNoticeOfReplacement({
            flagDiscontinuingPremiumPayment: "",
            flagPayForPremiumPolicy: "",
            insurerPolicyDetailFieldArray: [
              {
                id: 1,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 2,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 3,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
              {
                id: 4,
                insurerName: "",
                contractOrPolicyNumber: "",
                nameOfInsuredOrAnnuitant: "",
                replacedOrFinancing: "",
                effectiveDate: ""
              },
            ],
            reasonForReplacement: ""
          });
        }
      }
    }
  }, [
    stateToShowNoticeOfReplacement,
    flagQualifiedOrNonQualifiedSelected,
    selectedNonQualifiedOptions,
    selectedQualifiedOptionsGroup2,
    // selectedQualifiedOptions,
  ]);

  useEffect(() => {
    if (state.transferOrExchangeRequestFlag === false) {
      SetWholeTransferExchangeRequest({
        ownerFullName: "",
        ownerSSN: "",
        ownerStreetAddress: "",
        ownerZip: "",
        ownerCity: "",
        ownerState: "",
        ownerMaritalStatus: "",
        annuitantOrInsured: "",
        currentInsuranceCompany: "",
        currentInsuranceCompanyPhone: "",
        currentInsuranceCompanyAccountNumber: "",
        currentInsuranceCompanyStreetAddress: "",
        currentInsuranceCompanyZip: "",
        currentInsuranceCompanyCity: "",
        currentInsuranceCompanyState: "",
        typeOfAccount: "",
        otherTypeOfAccount: "",
        ownerExistingAnnuity: "",
        plans: "",
        nonQualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
        nonQualifiedTransferOrExchangeExistingAccountPartialAmount: "",
        nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate:
          "",
        nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
        nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy:
          "",
        nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField:
          "",
        flagNonQualifiedOwnerWantWithdrawalToExchange: "",
        explainNonQualifiedOwnerWantWithdrawalToExchange: "",
        flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal: "",
        flagSignatureDigitalOrPhysical: "",
        qualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
        qualifiedTransferOrExchangeExistingAccountPartialAmount: "",
        qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate:
          "",
        qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
        qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy:
          "",
        qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField:
          "",
        qualifiedTypeOfTransfer: "",
        qualifiedQualifyingEventForDirectRolloverFunds: "",
        flagQualifiedOwnerReachedAge72OrMoreInThisYear: "",
        flagQualifiedOwnerSatisfiedRMDFromDistributingPlan: "",
        qualifiedInternalExchangeOfExistingAnnuityName: "",
        flagQualifiedOwnerWantRMDBeforeCompletingExchange: "",
        flagQualifiedOwnerWantToContinueExistingRMDSchedule: "",
      });
    }
  }, [state.transferOrExchangeRequestFlag]);
  // debugger;

  const data = {
    annuity_full_name: annuitantFullName,
    annuity_ssn_tin: annuitantSSN,
    annuity_birthdate:
      new Date(annuitantDOB).toDateString() === "Invalid Date"
        ? ""
        : `${new Date(annuitantDOB).getMonth() + 1}/${new Date(
          annuitantDOB
        ).getDate()}/${new Date(annuitantDOB).getFullYear()}`,
    annuity_address: annuitantStreetAddress,
    annuity_city: annuitantCity,
    annuity_state: annuitantState,
    annuity_zip: annuitantZip,
    annuity_phone: annuitantPhone,
    annuity_email: annuitantEmail,
    annuity_gender: annuitantGender,
    owner_full_name: ownerFullName,
    joint_owner_full_name: jointOwnerFullName,
    owner_address: ownerStreetAddress,
    joint_owner_address: jointOwnerStreetAddress,
    owner_city: ownerCity,
    owner_state: ownerState,
    owner_zip: ownerZip,
    joint_owner_city: jointOwnerCity,
    joint_owner_state: jointOwnerState,
    joint_owner_zip: jointOwnerZip,
    owner_email: ownerEmail,
    joint_owner_email: jointOwnerEmail,
    owner_ssn_tin: ownerSSN,
    joint_owner_ssn_tin: jointOwnerSSN,
    owner_relationship_annuitant: ownerRelationToAnnuitant,
    joint_owner_relationship_annuitant: jointOwnerRelationToAnnuitant,
    owner_birthdate:
      new Date(ownerDOB).toDateString() === "Invalid Date"
        ? ""
        : `${new Date(ownerDOB).getMonth() + 1}/${new Date(
          ownerDOB
        ).getDate()}/${new Date(ownerDOB).getFullYear()}`,
    joint_owner_birthdate:
      new Date(jointOwnerDOB).toDateString() === "Invalid Date"
        ? ""
        : `${new Date(jointOwnerDOB).getMonth() + 1}/${new Date(
          jointOwnerDOB
        ).getDate()}/${new Date(jointOwnerDOB).getFullYear()}`,
    owner_gender: ownerGender,
    joint_owner_gender: jointOwnerGender,
    plan_riders_applied_for: {
      value: planAppliedFor.join(","),
      extra_value: "",
    },
    premium_tax_non_qualified: {
      value: flagQualifiedOrNonQualifiedSelected == "Non - Qualified" ? selectedNonQualifiedOptions?.join(",") : "",
      extra_value: ""
    },
    premium_tax_qualified: {
      value: flagQualifiedOrNonQualifiedSelected == "Qualified" ? selectedNonQualifiedOptions?.join(",") : "",
      extra_value: "",
    },
    premium_tax_qualified_plan: {
      value: flagQualifiedOrNonQualifiedSelected == "Qualified" ? selectedQualifiedOptionsGroup2 : "",
      extra_value: flagQualifiedOrNonQualifiedSelected == "Qualified" ? otherSelectedQualifiedOptions : "",
    },
    premium_tax_non_qualified_plan: {
      value: flagQualifiedOrNonQualifiedSelected == "Non - Qualified" ? selectedQualifiedOptionsGroup2 : "",
      extra_value: flagQualifiedOrNonQualifiedSelected == "Non - Qualified" ? otherSelectedQualifiedOptions : "",
    },
    state_to_show_notice_of_replacement: stateToShowNoticeOfReplacement,
    premium_tax_paid_amount: amountPaidWithApplication.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    ),
    applicationReplacementFieldsArray: applicationReplacementFieldsArray,
    premium_tax_expected_amount: expectedPremiumField.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    ),
    owner_replacement_question_1: flagHaveExistingLifeInsurance,
    owner_replacement_question_2: reasonOfApplicationToChangeLifeInsurance,
    annuity_application_completed_city: "",
    annuity_application_completed_state: "",
    annuity_application_completed_day: "",
    annuity_application_completed_month: "",
    annuity_application_completed_year: "",
    annuity_application_completed_owner_sign: "",
    annuity_application_completed_joint_owner_sign: "",
    agents_report_question: "",
    agents_report_agent_name: "",
    agents_report_agent_number: "",
    agents_report_agent_sign: "",
    agents_report_agent_license_no: "",
    annuity_owner_sign: "",
    annuity_joint_owner_sign: "",
    annuity_owner_name: "",
    annuity_joint_owner_name: "",
    annuity_sign_of_agent: "",
    annuity_name_of_agent: "",
    annuity_application_date: "",
    suitability_analysis_owner_full_name: suitabilityAnalysis?.ownerFullName,
    suitability_analysis_owner_age: ownerCurrentAge,
    suitability_analysis_owner_gender: suitabilityAnalysis?.ownerGender,
    suitability_analysis_owner_employment_status: ownerEmploymentStatus,
    suitability_analysis_owner_ocuoation: ownerCurrentOccupation,
    suitability_analysis_owner_dependents: ownerDependents,
    suitability_analysis_supouse_employment_status: spouseEmploymentStatus,
    suitability_analysis_supouse_full_name: spouseFullName,
    suitability_analysis_supouse_age: spouseCurrentAge,
    suitability_analysis_supouse_gender: spouseGender,
    suitability_analysis_supouse_ocuoation: spouseCurrentOccupation,
    suitability_analysis_supouse_dependents: spouseDependents,
    owner_financial_info_annual_household_income:
      ownerAnnualHouseholdIncome.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    owner_financial_info_income_source: ownerSourceOfIncome,
    owner_financial_info_income_tax_bracket: ownerIncomeTaxBracket,
    owner_financial_info_total_assets: ownerTotalAssets.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    ),
    owner_financial_info_total_liquid_assets: ownerTotalLiquidAssets.replace(
      /\B(?=(\d{3})+(?!\d))/g,
      ","
    ),
    owner_financial_info_total_outstanding_debt:
      ownerTotalOutstandingDebt.replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    owner_fin_situation_needs_question_1: {
      value: flagOwnerIncomeCoverLivingAndMedicalExpenses,
      extra_value: explainOwnerIncomeCoverLivingAndMedicalExpenses,
    },
    owner_fin_situation_needs_question_2: {
      value: flagOwnerExpectChangesInLivingExpenses,
      extra_value: explainOwnerExpectChangesInLivingExpenses,
    },
    owner_fin_situation_needs_question_3: {
      value: flagOwnerAnticipateChangesInMedicalExpenses,
      extra_value: explainOwnerAnticipateChangesInMedicalExpenses,
    },
    owner_fin_situation_needs_question_4: {
      value:
        flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
      extra_value:
        explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod,
    },
    owner_fin_situation_needs_question_5: {
      value: flagOwnerHaveEmergencyFundForUnexpectedExpenses,
      extra_value: explainOwnerHaveEmergencyFundForUnexpectedExpenses,
    },
    // owner_fin_situation_needs_question_6:
    //     ownerOtherInvestments.length > 0
    //         ? ownerOtherInvestments.indexOf("Other") < 0
    //             ? ownerOtherInvestments?.join(",")
    //             : FilteredArray(ownerOtherInvestments, otherOwnerInvestmentField)
    //         : "",
    owner_fin_situation_needs_question_6:
      ownerOtherInvestments.length > 0
        ? {
          value: ownerOtherInvestments?.join(","),
          extra_value: otherOwnerInvestmentField,
        }
        : "",
    owner_fin_situation_needs_question_7: ownerRiskTolerance,
    // product_purchase_question_1:
    //     reasonForPurchasingProduct.length > 0
    //         ? reasonForPurchasingProduct.indexOf("Other") < 0
    //             ? reasonForPurchasingProduct.join(",")
    //             : FilteredArray(
    //                 reasonForPurchasingProduct,
    //                 otherRasonForPurchasingThisProduct
    //             )
    //         : "",
    product_purchase_question_1: {
      value:
        reasonForPurchasingProduct.length > 0
          ? reasonForPurchasingProduct.join(",")
          : "",
      extra_value: otherRasonForPurchasingThisProduct,
    },
    product_purchase_question_2: {
      value: flagOwnerPlanToWithdrawFundInSurrenderPeriod,
      extra_value: explainOwnerPlanToWithdrawFundInSurrenderPeriod,
    },
    product_purchase_question_3: ownerAnticipateNeedingAccessToFunds,
    product_purchase_question_4:
      sourceOfFundsToPurchaseThisProduct.length > 0
        ? {
          value: sourceOfFundsToPurchaseThisProduct.join(","),
          extra_value: otherSourceOfFundsToPurchaseThisProduct,
        }
        : "",
    exist_acc_info_question_1:
      flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct,
    exist_acc_info_question_2: howLongHasTheLifeInsuranceBeenInForce,
    exist_acc_info_question_3: flagSurrenderChargeOnExistingProduct,
    exist_acc_info_question_4: currentSurrenderChargeOnExistingProduct,
    exist_acc_info_question_5: expirationOfCurrentSurrenderCharge,
    exist_acc_info_question_6: typeOfExistingProduct,
    exist_acc_info_question_7: interestRateForExistingProduct,
    exist_acc_info_question_8:
      flagTakingRequiredMinimumDistributionsFromExistingProduct,
    exist_acc_info_question_9: {
      value: flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
      extra_value:
        explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution,
    },
    suitability_analysis_other_question_1:
      personsThatAssistedOwnerToPurchaseThisProduct.length > 0
        ? {
          value: personsThatAssistedOwnerToPurchaseThisProduct.join(","),
          extra_value: otherPersonsThatAssistedOwnerToPurchaseThisProduct,
        }
        : "",
    suitability_analysis_other_question_2:
      additionalRelaventInformationForSuitabilityAnalysis,
    suitability_analysis_owner_ack_question: "",
    suitability_analysis_owner_name: "",
    suitability_analysis_owner_sign: "",
    suitability_analysis_owner_sign_date: "",
    suitability_analysis_joint_owner_name: "",
    suitability_analysis_joint_owner_sign: "",
    suitability_analysis_joint_owner_sign_date: "",
    suitability_analysis_agent_ack_use_sales_material: "",
    suitability_analysis_agent_acknowledgment:
      suitabilityAnalysis?.agentAcknowledgment,
    suitability_analysis_agent_ack_sign: "",
    suitability_analysis_agent_ack_sign_date: "",
    trans_exchange_req_owner_name: transferOrExchangeRequest.ownerFullName,
    trans_exchange_req_owner_address:
      transferOrExchangeRequest.ownerStreetAddress,
    trans_exchange_req_owner_city: transferOrExchangeRequest.ownerCity,
    trans_exchange_req_owner_state: transferOrExchangeRequest.ownerState,
    trans_exchange_req_owner_marital_status: transferOrExchangeRequest.ownerMaritalStatus,
    trans_exchange_req_owner_zip: transferOrExchangeRequest.ownerZip,
    trans_exchange_req_owner_annuitant_insured: annuitantOrInsured,
    trans_exchange_req_owner_text_id_or_ssn: transferOrExchangeRequest.ownerSSN,
    trans_exchange_req_current_insurance_company: currentInsuranceCompany,
    trans_exchange_req_current_insurance_company_phone:
      currentInsuranceCompanyPhone,
    trans_exchange_req_current_insurance_company_acc_no:
      currentInsuranceCompanyAccountNumber,
    trans_exchange_req_current_insurance_company_address:
      currentInsuranceCompanyStreetAddress,
    trans_exchange_req_current_insurance_company_city:
      currentInsuranceCompanyCity,
    trans_exchange_req_current_insurance_company_state:
      currentInsuranceCompanyState,
    trans_exchange_req_current_insurance_company_zip:
      currentInsuranceCompanyZip,
    // trans_exchange_req_current_insurance_company_acc_type:
    //     typeOfAccount === "Other" ? otherTypeOfAccount : typeOfAccount,
    trans_exchange_req_current_insurance_company_acc_type: {
      value: typeOfAccount,
      extra_value: otherTypeOfAccount,
    },
    // trans_exchange_req_non_qualified_plans:
    //     plans === "Non-Qualified Plans"
    //         ? `${nonQualifiedTransferOrExchangeExistingAccountAllOrPartial === "All"
    //             ? "All"
    //             : `${nonQualifiedTransferOrExchangeExistingAccountAllOrPartial} ${nonQualifiedTransferOrExchangeExistingAccountPartialAmount}`
    //         },${nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate ===
    //             "Immediately"
    //             ? "Immediately"
    //             : `${nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate} ${nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField}`
    //         },${nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy ===
    //             "To New Policy/Account"
    //             ? "To New Policy/Account"
    //             : `${nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy} ${nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField}`
    //         }`
    //         : "",
    trans_exchange_req_non_qualified_plans:
      plans === "Non-Qualified Plans"
        ? {
          value: `${nonQualifiedTransferOrExchangeExistingAccountAllOrPartial},${nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate},${nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy}`,
          extra_value: `${nonQualifiedTransferOrExchangeExistingAccountPartialAmount},${nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField},${nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField}`,
        }
        : "",
    trans_exchange_req_qualified_plans:
      plans === "Qualified Plans"
        ? {
          value: `${qualifiedTransferOrExchangeExistingAccountAllOrPartial},${qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate},${qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy}`,
          extra_value: `${qualifiedTransferOrExchangeExistingAccountPartialAmount},${qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField},${qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField}`,
        }
        : "",
    trans_exchange_req_transfer_rollover: qualifiedTypeOfTransfer,
    trans_exchange_req_direct_rollover_funds:
      qualifiedQualifyingEventForDirectRolloverFunds,
    trans_exchange_req_transfer_rmd_info_question_a:
      flagQualifiedOwnerReachedAge72OrMoreInThisYear,
    trans_exchange_req_transfer_rmd_info_question_b:
      flagQualifiedOwnerSatisfiedRMDFromDistributingPlan,
    trans_exchange_req_transfer_rmd_info_question_1:
      qualifiedInternalExchangeOfExistingAnnuityName,
    trans_exchange_req_transfer_rmd_info_question_c:
      flagQualifiedOwnerWantRMDBeforeCompletingExchange,
    trans_exchange_req_transfer_rmd_info_question_d:
      flagQualifiedOwnerWantToContinueExistingRMDSchedule,
    trans_exchange_req_transfer_farmers_annulty_question_a: {
      value: flagNonQualifiedOwnerWantWithdrawalToExchange,
      extra_value: explainNonQualifiedOwnerWantWithdrawalToExchange,
    },
    trans_exchange_req_transfer_farmers_annulty_question_b:
      flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal,
    trans_exchange_req_signature_digital_or_physical: flagSignatureDigitalOrPhysical,
    trans_exchange_req_final_owner_name: "",
    trans_exchange_req_final_owner_sign: "",
    trans_exchange_req_final_owner_sign_date: "",
    trans_exchange_req_final_joint_owner_name: "",
    trans_exchange_req_final_owner_joint_sign: "",
    trans_exchange_req_final_joint_owner_sign_date: "",
    trans_exchange_req_final_witness_name: "",
    trans_exchange_req_final_witness_sign: "",
    trans_exchange_req_final_witness_sign_date: "",
    trans_exchange_req_contract_number: "",
    trans_exchange_req_auth_sign: "",
    trans_exchange_req_auth_sign_date: "",
    // bank_details_bank_name: bankName,
    // bank_details_bank_address: bankAddress,
    // bank_details_bank_account_no: accountNumber,
    // bank_details_bank_routing_number: routingNumber,
    notice_reagard_replacement_owner_name: "",
    notice_reagard_replacement_owner_sign: "",
    notice_reagard_replacement_owner_sign_date: "",
    notice_reagard_replacement_agent_name: "",
    notice_reagard_replacement_agent_sign: "",
    notice_reagard_replacement_agent_sign_date: "",
    beneficiaries: tempBeneficiaries(),
    replacement_question_contact_data: replaceMentQuestionDataArray(),
    trans_exchange_req_existing_annuity: ownerExistingAnnuity,

    notice_of_replacement_question1_a: flagDiscontinuingPremiumPayment,
    notice_of_replacement_question1_b: flagPayForPremiumPolicy,
    existing_policy: tempInsurerPolicyDetailFieldArray(),
    notice_of_replacement_question1_d: reasonForReplacement,
    // flag_request_yield: flagRequestYield,
    // notice_of_replacement_question2_broker_name: brokerName,
    // notice_of_replacement_question2_broker_email: brokerEmail,
    safegaurd_plus_annuity_disclosure: flagAnnuityClosure,
    witness_name: transferOrExchangeRequest.witnessName,
    witness_email: transferOrExchangeRequest.witnessEmail,
  };

  const SendDataToServer = (type, redirect) => {
    SetFlagLoader(true);
    // debugger;
    console.log("Data To be Submit", data);
    let temp = { ...data };
    if (window.location.search.includes("public_url_id") === true) {
      temp["application_status_id"] = type === "draft" ? 1 : 2;
    } else {
      temp["application_status_id"] =
        type === "draft"
          ? saveAsDraftApplicationStatusId.id
          : submitApplicationStatusId.id;
    }

    if (applicationId !== null) {
      temp["application_id"] = applicationId;
    }

    let url = new URLSearchParams(window.location.search);

    console.log(temp);
    SubmitSaveDraftApplication(
      {
        data: temp,
        publicURL:
          window.location.search.includes("public_url_id") === true
            ? url.get("public_url_id")
            : false,
      },
      (res) => {
        SetFlagLoader(false);
        console.log("Printing response of save draft or submit api call", res);
        let domain = window.location.host;
        let protocol = window.location.protocol;

        if (res.data.status === true || res.data?.statu === true) {
          console.log("Status is true");
          if (type !== "draft") {
            setFlagSuccessDialog(true);
          } else {
            // debugger;
            SetApplicationId(res.data.data.application_id);
            if (redirect === true) {
              window.location.replace(
                `${protocol}//${domain}/annuity-applications`
              );
            } else {
              console.log(res.data);
              // debugger;
              let data = res.data;
              // debugger;
              return data;
            }
          }
        } else {
          setStatusFalse(true);
        }
      },
      (err) => {
        SetFlagLoader(false);
        console.log(
          "Got error while submitting/saving draft of application",
          err
        );
        // alert("Something went wrong, Please try again later.!");
      }
    );

    // return true;
  };

  return (
    <Fragment>
      {flagSuccessDialog === true && (
        <SuccessDdialog
          firstText="Application Submitted Successfully!"
          secondText="DocuSign request to sign the envelop will be sent to applicant in 5 minutes."
          redirect="/myga_application_history"
          closeSuccessDialog={() => {
            setFlagSuccessDialog(false);
            let domain = window.location.host;
            let protocol = window.location.protocol;
            window.location.replace(
              `${protocol}//${domain}/annuity-applications`
            );
          }}
        />
      )}
      {statusFalse === true && <SomethingWentWrongDialog />}
      {flagErrorValidtionDialog.status === true && (
        <ErrorValidationSubmitDialog
          tableData={flagErrorValidtionDialog.tableData}
          closeDialog={() => {
            setFlagErrorValidationDialog(false);
          }}
        />
      )}
      <Box className="new_application_form">
        <Box className="page_title">
          <Typography
            variant=""
            style={{ marginBottom: "0px" }}
            paragraph={true}
          >
            Application Form
          </Typography>
        </Box>
        <Box className="application_form_main">
          {applicationStatusName === "Submitted for Signature" && (
            <Button
              className="editable_button"
              sx={{
                position: "absolute",
                top: "calc(100px + 55px + 25px)",
                right: "25%",
                color: "#fff",
                background: "#074a6e",
                minWidth: "160px",
                textTransform: "capitalize",
                zInex: 1,
              }}
              onClick={() => {
                if (applicationEditable === true) {
                  window.location.reload();
                }
                if (applicationEditable === false) {
                  SetPageIndex(1);
                }
                setApplicationEditable(!applicationEditable);
              }}
            >
              {applicationEditable === true
                ? "View Application"
                : "Edit Application"}
            </Button>
          )}
          {pageIndex === 1 && (
            <IndividualAnnuityApplication
              changePageIndex={(pagenumber) => {
                SetPageIndex(pageIndex + pagenumber);
              }}
              beneficiariesPercentageTotal={(value) => {
                setBeneficiariesPercentageTotal(value);
              }}
              errorFields={errorFieldsArray}
            />
          )}

          {pageIndex === 3 && (
            <SuitabilityAnalysis
              changePageIndex={(pagenumber) => {
                SetPageIndex(pageIndex - pagenumber);
              }}
              nextPage={() => {
                SetPageIndex(pageIndex + 1);
              }}
              eftPage={() => {
                SetPageIndex(pageIndex + 3);
              }}
              errorFields={errorFieldsArray}
            />
          )}

          {pageIndex === 2 && (
            <ReplacementForm
              previousPage={() => {
                SetPageIndex(pageIndex - 1);
              }}
              nextPage={() => {
                SetPageIndex(pageIndex + 1);
              }}
              errorFields={errorFieldsArray}
            />
          )}
          {pageIndex === 4 && (
            <div className="individual_form">
              <TransferExchangeRequest
                previousPage={() => {
                  SetPageIndex(pageIndex - 1);
                }}
                nextPage={() => {
                  SetPageIndex(pageIndex + 2);
                }}
                errorFields={errorFieldsArray}
              />
            </div>
          )}
          {pageIndex === 6 && (
            <AnnuityDisclosureForm
              suitabilityAnalysisPage={() => {
                SetPageIndex(pageIndex - 3);
              }}
              previousPage={() => {
                SetPageIndex(pageIndex - 2);
              }}
              errorFields={errorFieldsArray}
            />
          )}
          <div className="formsitemap">
            <FormSiteMap
              pageIndex={pageIndex}
              setPage={(dataid) => {
                SetPageIndex(dataid);
              }}
              applicationId={applicationId}
              saveAsDraft={async (onGetData) => {
                let res = await SendDataToServer("draft", false);
                console.log(
                  "Printing response of the save as draft api call",
                  res
                );
                // debugger;
              }}
            />
          </div>
        </Box>
        {(applicationEditable === true
          ? true
          : applicationStatusName !== "Submitted for Signature"
            ? true
            : false) && (
            <Box
              className="btn_group"
              style={{
                margin: "10px 0",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => {
                  // debugger;
                  console.log("finally", state);
                  const res = Validation();
                  console.log("Printing submit click validation", res);

                  if (res.status === false && res.data.length > 0) {
                    setFlagErrorValidationDialog({
                      status: true,
                      tableData: res.data,
                    });
                  } else {
                    SendDataToServer("submit", true);
                  }
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                {applicationEditable === true &&
                  applicationStatusName === "Submitted for Signature"
                  ? "Re-Submit"
                  : "Submit"}
              </Button>
              {applicationEditable === true ? null : (
                <Button
                  onClick={() => {
                    SendDataToServer("draft", true);
                    console.log("saved as draft", true);
                  }}
                  style={{
                    background: "#074a6e",
                    color: "white",
                    textTransform: "capitalize",
                    margin: "0 10px",
                  }}
                >
                  Save As Draft
                </Button>
              )}
            </Box>
          )}
      </Box>
    </Fragment>
  );
};

export default ApplicationForm;
