import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Grid, Menu, MenuItem, Button, Typography } from "@mui/material";

import ArrowRight from "@mui/icons-material/ArrowRight";
import Dropdown, { DropdownMenuItem, DropdownNestedMenuItem } from "./ResourceDropdown/Dropdown";

import "./resourceItem.css"
const Container = styled("div")`
  border: 1px solid lightgrey;
  margin-bottom: 8px;
  border-radius: 2px;
  padding: 8px;
  background: ${props => (props.isDragging ? "#d9a22e" : "#fcf6eb")};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 1px 2px 0 rgba(0, 0, 0, 0.19);
`;

const TaskList = styled("div")`
  padding: 8px;
  flex-grow: 1;
  min-height: 100px;
  transition: background-color ease 0.2s;
  background-color: ${props =>
        props.isDraggingOver ? "#55A3FB" : "white"};
`;

const Resource = ({ resourceDetails, pdfCategoryId, PdfGroupCategoryId, index, setDraggedItemDetails, MoveResourceOut, resourceListData, MoveResourceInGroup }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [menuItemList, setMenuItemList] = useState([])

    useEffect(() => {
        if (menuItemList?.length == 0) {
            let tempList = []
            resourceListData?.map((category, i) => {
                let categoryList = {}
                categoryList["category_name"] = category.pdf_category_name
                categoryList["category_id"] = category.pdf_category_id
                if (category?.pdf_category_resource_data?.length) {
                    let groupListArray = []
                    category.pdf_category_resource_data?.map((group) => {
                        let groupList = {}
                        if (group.pdf_resource_flag == false) {
                            groupList["group_id"] = group.pdf_group_category_id
                            groupList["group_name"] = group.pdf_group_category_name
                            groupListArray.push(groupList)
                        }
                        categoryList["group_list"] = groupListArray
                    })
                }
                tempList.push(categoryList)
            })
            setMenuItemList(tempList)
        }
    }, [resourceListData])

    console.log("menuItems", menuItemList)

    return (
        <Draggable key={resourceDetails?.resource_sub_category_id} draggableId={"resource-drag-" + pdfCategoryId + "-" + PdfGroupCategoryId + "-" + resourceDetails?.resource_sub_category_id?.toString()} index={index} type="subtask">

            {(provided, snapshot) => (
                <Container
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                    isDragging={snapshot.isDragging}
                >
                    <Grid container alignItems="center">
                        <Grid item xs={3} sm={2} md={2} lg={1.5} xl={1.5}>
                            <Dropdown
                                trigger={<DragIndicatorIcon />}
                                menu={
                                    PdfGroupCategoryId == 'null' ?
                                        [
                                            <DropdownNestedMenuItem
                                                label="Move to group"
                                                rightIcon={<ArrowRight />}
                                                menu={
                                                    menuItemList?.map((category) => {
                                                        return (
                                                            category?.group_list?.length ?
                                                                <DropdownNestedMenuItem
                                                                    label={category?.category_name}
                                                                    rightIcon={<ArrowRight />}
                                                                    menu={
                                                                        category.group_list?.map((group) => {
                                                                            return <DropdownMenuItem
                                                                                onClick={() => {
                                                                                    let params = {
                                                                                        category_id: category?.category_id,
                                                                                        group_id: group?.group_id,
                                                                                        resource_id: resourceDetails?.resource_sub_category_id,
                                                                                    }
                                                                                    MoveResourceInGroup(params)
                                                                                }}
                                                                            >{group?.group_name}</DropdownMenuItem>
                                                                        })
                                                                    }
                                                                />
                                                                :
                                                                <DropdownMenuItem>{category?.category_name}</DropdownMenuItem>
                                                        )

                                                    })
                                                }
                                            />
                                        ]
                                        :
                                        [
                                            <DropdownNestedMenuItem
                                                label="Move to category"
                                                rightIcon={<ArrowRight />}
                                                menu={
                                                    menuItemList?.map((category) => (
                                                        <DropdownMenuItem
                                                            onClick={() => {
                                                                let params = {
                                                                    category_id: parseInt(category?.category_id),
                                                                    group_id: parseInt(PdfGroupCategoryId),
                                                                    resource_id: parseInt(resourceDetails?.resource_sub_category_id),
                                                                }
                                                                MoveResourceOut(params)
                                                            }}
                                                        >{category?.category_name}</DropdownMenuItem>
                                                    ))
                                                }
                                            />
                                        ]
                                }
                            />
                            {/* <DragIndicatorIcon onClick={handleClick} /> */}
                        </Grid>
                        <Grid item xs={9} sm={10} md={10} lg={10.5} xl={10.5}>{resourceDetails.resource_name}</Grid>
                    </Grid>
                </Container>
            )}
        </Draggable>
    );
};

export default Resource;

