import axiosInstance from './axios';

export const ResetPasswordAPI = async (data, onSuccess, onError)=>{
    try{
        const res = await axiosInstance.post("/api/user/resetPassword", {
            email: data.email
        }, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(res);
    }
    catch(err){
        onError && onError(err);
    }
};

export const UpdatePasswordAPI = async (data, onSuccess, onError)=>{
    try{
        console.log("Printing data for update password", data, JSON.stringify(data));
        const res = await axiosInstance.post("/api/user/update-password", JSON.stringify(data), {
            headers: {
                "Content-Type": "application/json; charset=utf-8"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(res);
    }
    catch(err){
        onError && onError(err);
    }
}