import createDataContext from "../createDataContext";

// Reducer
const NewApplicationFormReducer = (state, action) => {
    switch (action.form) {
        case "individualAnnuityApplication": {
            return {
                ...state,
                individualAnnuityApplication: {
                    ...state.individualAnnuityApplication,
                    [action.type]: action.payload
                }
            }
        }


        case "individualFixedIndexAnnuityApplication": {
            return {
                ...state,
                individualFixedIndexAnnuityApplication: {
                    ...state.individualFixedIndexAnnuityApplication,
                    [action.type]: action.payload
                }
            }
        }

        case "individualFixedIndexAnnuityApplicationWhole": {
            return {
                ...state,
                individualFixedIndexAnnuityApplication: action.payload
            }
        }

        case "suitabilityAnalysis": {
            return {
                ...state,
                suitabilityAnalysis: {
                    ...state.suitabilityAnalysis,
                    [action.type]: action.payload
                }
            }
        }

        case "transferOrExchangeRequest": {
            return {
                ...state,
                transferOrExchangeRequest: {
                    ...state.transferOrExchangeRequest,
                    [action.type]: action.payload
                }
            }
        }

        case "rolloverTransferExchange": {
            return {
                ...state,
                rolloverTransferExchange: {
                    ...state.rolloverTransferExchange,
                    [action.type]: action.payload
                }
            }
        }

        case "noticeOfReplacement": {
            return {
                ...state,
                noticeOfReplacement: {
                    ...state.noticeOfReplacement,
                    [action.type]: action.payload
                }
            }
        }

        case "individualAnnuityApplicationWhole": {
            return {
                ...state,
                individualAnnuityApplication: action.payload
            }
        }

        case "suitabilityAnalysisWhole": {
            return {
                ...state,
                suitabilityAnalysis: action.payload
            }
        }

        case "noticeOfReplacementWhole": {
            return {
                ...state, noticeOfReplacement: action.payload
            }
        }

        case "transferExchangeRequestWhole": {

            return {
                ...state,
                transferOrExchangeRequest: action.payload
            }
        }

        case "rolloverTransferExchangeRequestWhole": {

            return {
                ...state,
                rolloverTransferExchange: action.payload
            }
        }

        case "annuityDisclosureFormWhole": {
            return {
                ...state,
                annuityDisclosureForm: action.payload
            }
        }

        case "annuityDisclosureFormWholeV2": {
            return {
                ...state,
                annuityDisclosureFormV2: action.payload
            }
        }

        case "annuityDisclosureForm": {
            return {
                ...state,
                annuityDisclosureForm: {
                    [action.type]: action.payload
                }
            }
        }

        case "annuityDisclosureFormV2": {
            return {
                ...state,
                annuityDisclosureFormV2: {
                    ...state.annuityDisclosureFormV2,
                    [action.type]: action.payload
                }
            }
        }

        case "producerDisclosureForm": {
            return {
                ...state,
                producerDisclosureForm: {
                    ...state.producerDisclosureForm,
                    [action.type]: action.payload
                }
            }
        }
        case "producerDisclosureFormWhole": {
            return {
                ...state,
                producerDisclosureForm: action.payload
            }
        }
        case "harvestDisclosureForm": {
            return {
                ...state,
                harvestDisclosureForm: {
                    [action.type]: action.payload
                }
            }
        }

        case "harvestDisclosureFormWhole": {
            return {
                ...state,
                harvestDisclosureForm: action.payload
            }
        }

        case "disableAllInputs": {
            return {
                ...state,
                disableAllInputs: action.payload
            }
        }

        case "transferExchangeVersion": {
            return {
                ...state,
                transferExchangeVersion: action.payload
            }
        }

        case "flagShowProducerDisclosure": {
            return {
                ...state,
                flagShowProducerDisclosure: action.payload
            }
        }

        case "fiaDisclosureVersion": {
            return {
                ...state,
                fiaDisclosureVersion: action.payload
            }
        }

        case "disclosureFormVersion": {
            return {
                ...state,
                disclosureFormVersion: action.payload
            }
        }

        case "applicationId": {
            return {
                ...state,
                applicationId: action.payload
            }
        }

        case "transferOrExchangeRequestFlag": {

            return {
                ...state,
                transferOrExchangeRequestFlag: action.payload
            }
        }

        default:
            return state;
    }
}

// Function for Individual Annuity Application
const SetIndividualAnnuityApplication = dispatch => (data) => {
    dispatch({
        type: data.type,
        payload: data.value,
        form: "individualAnnuityApplication"
    })
}
const SetProducerDisclosureForm = dispatch => (data) => {
    dispatch({
        type: data.type,
        payload: data.value,
        form: "producerDisclosureForm"
    })
}
const SetWholeProducerDisclosureForm = dispatch => (data) => {
    dispatch({
        form: 'producerDisclosureFormWhole',
        payload: data
    })
}
const SetIndividualFixedIndexAnnuityApplication = dispatch => (data) => {
    dispatch({
        type: data.type,
        payload: data.value,
        form: "individualFixedIndexAnnuityApplication"
    })
}

// Function for Suitability Analysis
const SetSuitabilityAnalysis = dispatch => (data) => {
    dispatch({
        type: data.type,
        payload: data.value,
        form: "suitabilityAnalysis"
    })
}

//Function for Notice of Replacement
const SetNoticeOfReplacement = dispatch => (data) => {
    dispatch({
        type: data.type,
        payload: data.value,
        form: "noticeOfReplacement"
    })
}

// Function for Transfer or Exchange Request
const SetTransferOrExchangeRequest = dispatch => (data) => {
    dispatch({
        type: data.type,
        payload: data.value,
        form: "transferOrExchangeRequest"
    })
}
const SetRolloverTransferExchangeRequest = dispatch => (data) => {
    dispatch({
        type: data.type,
        payload: data.value,
        form: "rolloverTransferExchange"
    })
}

//  Function for Annuity disclosure form
const SetAnnuityDisclosureForm = dispatch => (data) => {
    dispatch({
        type: data.type,
        payload: data.value,
        form: "annuityDisclosureForm"
    })
}

const SetAnnuityDisclosureFormV2 = dispatch => (data) => {
    dispatch({
        type: data.type,
        payload: data.value,
        form: "annuityDisclosureFormV2"
    })
}

const SetHarvestDisclosureForm = dispatch => (data) => {
    dispatch({
        type: data.type,
        payload: data.value,
        form: "harvestDisclosureForm"
    })
}

const SetWholeIndividualAnnuityApplication = dispatch => (data) => {
    dispatch({
        form: 'individualAnnuityApplicationWhole',
        payload: data
    })
}

const SetWholeSuitabilityAnalysis = dispatch => (data) => {
    dispatch({
        form: 'suitabilityAnalysisWhole',
        payload: data
    })
}

const SetWholeNoticeOfReplacement = dispatch => (data) => {
    dispatch({
        form: 'noticeOfReplacementWhole',
        payload: data
    })
}

const SetWholeTransferExchangeRequest = dispatch => (data) => {
    dispatch({
        form: 'transferExchangeRequestWhole',
        payload: data
    })
}

const SetWholeRolloverTransferExchangeRequest = dispatch => (data) => {
    dispatch({
        form: 'rolloverTransferExchangeRequestWhole',
        payload: data
    })
}

const SetWholeAnnuityDisclosureForm = dispatch => (data) => {
    dispatch({
        form: "annuityDisclosureFormWhole",
        payload: data,
    })
}

const SetWholeAnnuityDisclosureFormV2 = dispatch => (data) => {
    dispatch({
        form: "annuityDisclosureFormWholeV2",
        payload: data,
    })
}

const SetWholeIndividualFixedIndexAnnuityApplication = dispatch => (data) => {
    dispatch({
        form: 'individualFixedIndexAnnuityApplicationWhole',
        payload: data
    })
}

const SetWholeHarvestDisclosureForm = dispatch => (data) => {
    dispatch({
        form: "harvestDisclosureFormWhole",
        payload: data,
    })
}

const SetDisableAllInputs = dispatch => (data) => {
    dispatch({
        form: "disableAllInputs",
        payload: data
    })
}

const SetTransferExchangeVersion = dispatch => (data) => {
    dispatch({
        payload: data,
        form: "transferExchangeVersion"
    })
}
const SetFlagShowProducerDisclosure = dispatch => (data) => {
    dispatch({
        payload: data,
        form: "flagShowProducerDisclosure"
    })
}

const SetFiaDisclosureVersion = dispatch => (data) => {
    dispatch({
        payload: data,
        form: "fiaDisclosureVersion"
    })
}

const SetDisclosureFormVersion = dispatch => (data) => {
    dispatch({
        payload: data,
        form: "disclosureFormVersion"
    })
}

const SetApplicationId = dispatch => (data) => {
    dispatch({
        form: "applicationId",
        payload: data
    })
}
const updateTransferOrExchangeRequestFlag = dispatch => (data) => {
    dispatch({
        form: "transferOrExchangeRequestFlag",
        payload: data
    })
}
const transferOrExchangeRequestFlag = dispatch => (data) => {
    dispatch({
        form: "transferOrExchangeRequestFlag",
        payload: data
    })
}

// Export Context and Provider
export const { Context, Provider } = createDataContext(
    NewApplicationFormReducer,
    {
        SetIndividualAnnuityApplication, SetSuitabilityAnalysis, SetTransferOrExchangeRequest,
        SetRolloverTransferExchangeRequest, SetNoticeOfReplacement, SetTransferExchangeVersion, SetFlagShowProducerDisclosure,
        SetAnnuityDisclosureForm, SetAnnuityDisclosureFormV2, SetHarvestDisclosureForm, SetFiaDisclosureVersion, SetDisclosureFormVersion,
        SetWholeIndividualAnnuityApplication, SetWholeSuitabilityAnalysis, SetWholeNoticeOfReplacement,
        SetWholeTransferExchangeRequest, SetWholeRolloverTransferExchangeRequest,// SetWholeBankDetails,
        updateTransferOrExchangeRequestFlag,
        SetWholeAnnuityDisclosureForm, SetWholeAnnuityDisclosureFormV2, SetProducerDisclosureForm, SetWholeHarvestDisclosureForm, SetDisableAllInputs, SetApplicationId, transferOrExchangeRequestFlag,
        SetIndividualFixedIndexAnnuityApplication,
        SetWholeIndividualFixedIndexAnnuityApplication,
        SetWholeProducerDisclosureForm
    },
    {
        individualAnnuityApplication: {
            annuitantFullName: "",
            annuitantSSN: "",
            annuitantGender: "",
            annuitantDOB: "",
            annuitantStreetAddress: "",
            annuitantZip: "",
            annuitantCity: "",
            annuitantState: "",
            annuitantPhone: "",
            annuitantEmail: "",
            ownerFullName: "",
            ownerSSN: "",
            ownerGender: "",
            ownerDOB: "",
            ownerStreetAddress: "",
            ownerZip: "",
            ownerCity: "",
            ownerState: "",
            ownerEmail: "",
            ownerRelationToAnnuitant: "",
            ownerPhone: "",
            jointOwnerFullName: "",
            jointOwnerSSN: "",
            jointOwnerGender: "",
            jointOwnerDOB: "",
            jointOwnerStreetAddress: "",
            jointOwnerZip: "",
            jointOwnerCity: "",
            jointOwnerState: "",
            jointOwnerEmail: "",
            jointOwnerRelationToAnnuitant: "",
            jointOwnerPhone: "",
            annuitantPlan: "",
            planRiders: [],
            beneficiariesPrimary: [
                {
                    id: 1,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 2,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 3,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 4,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 5,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 6,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                }
            ],
            beneficiariesContigent: [
                {
                    id: 1,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 2,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 3,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 4,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 5,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 6,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                }

            ],
            // flagNonQualifiedSelected: false,
            selectedNonQualifiedOptions: [],
            // flagQualifiedSelected: false,
            // selectedQualifiedOptions: "",
            selectedQualifiedOptionsGroup2: "",
            otherSelectedQualifiedOptions: "",
            stateToShowNoticeOfReplacement: "",
            flagQualifiedOrNonQualifiedSelected: "",
            amountPaidWithApplication: "",
            expectedPremiumField: "",
            flagHaveExistingLifeInsurance: "",
            reasonOfApplicationToChangeLifeInsurance: "",
            applicationReplacementFieldsArray: [
                {
                    id: 1,
                    companyNameOfPolicyField: "",
                    policyNumberField: ""
                },
                {
                    id: 2,
                    companyNameOfPolicyField: "",
                    policyNumberField: ""
                },
                {
                    id: 3,
                    companyNameOfPolicyField: "",
                    policyNumberField: ""
                },
                {
                    id: 4,
                    companyNameOfPolicyField: "",
                    policyNumberField: ""
                }
            ],
            flagSafeguardPlusAnnuityPeriod: ""
        },

        suitabilityAnalysis: {
            ownerFullName: "",
            ownerCurrentAge: "",
            ownerGender: "",
            ownerEmploymentStatus: "",
            ownerCurrentOccupation: "",
            ownerDependents: "",
            spouseFullName: "",
            spouseCurrentAge: "",
            spouseGender: "",
            spouseEmploymentStatus: "",
            spouseCurrentOccupation: "",
            spouseDependents: "",
            ownerAnnualHouseholdIncome: "",
            ownerSourceOfIncome: "",
            ownerIncomeTaxBracket: "",
            ownerTotalOutstandingDebt: "",
            ownerTotalAssets: "",
            ownerTotalLiquidAssets: "",
            flagOwnerIncomeCoverLivingAndMedicalExpenses: "",
            explainOwnerIncomeCoverLivingAndMedicalExpenses: "",
            flagOwnerExpectChangesInLivingExpenses: "",
            explainOwnerExpectChangesInLivingExpenses: "",
            flagOwnerAnticipateChangesInMedicalExpenses: "",
            explainOwnerAnticipateChangesInMedicalExpenses: "",
            flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod: "",
            explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod: "",
            flagOwnerHaveEmergencyFundForUnexpectedExpenses: "",
            explainOwnerHaveEmergencyFundForUnexpectedExpenses: "",
            ownerOtherInvestments: [],
            otherOwnerInvestmentField: "",
            ownerRiskTolerance: "",
            reasonForPurchasingProduct: [],
            otherRasonForPurchasingThisProduct: "",
            flagOwnerPlanToWithdrawFundInSurrenderPeriod: "",
            explainOwnerPlanToWithdrawFundInSurrenderPeriod: "",
            ownerAnticipateNeedingAccessToFunds: "",
            sourceOfFundsToPurchaseThisProduct: [],
            otherSourceOfFundsToPurchaseThisProduct: "",
            flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct: "",
            howLongHasTheLifeInsuranceBeenInForce: "",
            flagSurrenderChargeOnExistingProduct: "",
            currentSurrenderChargeOnExistingProduct: "",
            expirationOfCurrentSurrenderCharge: "",
            typeOfExistingProduct: "",
            interestRateForExistingProduct: "",
            flagTakingRequiredMinimumDistributionsFromExistingProduct: "",
            flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution: "",
            explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution: "",
            personsThatAssistedOwnerToPurchaseThisProduct: [],
            otherPersonsThatAssistedOwnerToPurchaseThisProduct: "",
            additionalRelaventInformationForSuitabilityAnalysis: "",
            agentAcknowledgment: ""
        },

        noticeOfReplacement: {
            flagDiscontinuingPremiumPayment: "",
            flagPayForPremiumPolicy: "",
            insurerPolicyDetailFieldArray: [
                {
                    id: 1,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
                {
                    id: 2,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
                {
                    id: 3,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
                {
                    id: 4,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
            ],
            reasonForReplacement: ""
        },

        transferOrExchangeRequest: {
            ownerFullName: "",
            ownerSSN: "",
            ownerStreetAddress: "",
            ownerZip: "",
            ownerCity: "",
            ownerState: "",
            annuitantOrInsured: "",
            ownerMaritalStatus: "",
            currentInsuranceCompany: "",
            currentInsuranceCompanyPhone: "",
            currentInsuranceCompanyAccountNumber: "",
            currentInsuranceCompanyStreetAddress: "",
            currentInsuranceCompanyZip: "",
            currentInsuranceCompanyCity: "",
            currentInsuranceCompanyState: "",
            typeOfAccount: "",
            otherTypeOfAccount: "",
            ownerExistingAnnuity: "",
            plans: "",

            flagNonQualifiedOwnerWantWithdrawalToExchange: "",
            explainNonQualifiedOwnerWantWithdrawalToExchange: "",
            flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal: "",

            qualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
            qualifiedTransferOrExchangeExistingAccountPartialAmount: "",
            qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate: "",
            qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
            qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy: "",
            qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField: "",

            qualifiedTypeOfTransfer: "",
            qualifiedQualifyingEventForDirectRolloverFunds: "",
            flagQualifiedOwnerReachedAge72OrMoreInThisYear: "",
            flagQualifiedOwnerSatisfiedRMDFromDistributingPlan: "",
            qualifiedInternalExchangeOfExistingAnnuityName: "",
            flagQualifiedOwnerWantRMDBeforeCompletingExchange: "",
            flagQualifiedOwnerWantToContinueExistingRMDSchedule: "",
            flagSignatureDigitalOrPhysical: "",
            // witnessName: "",
            // witnessEmail: ""
        },

        annuityDisclosureForm: {
            flagAnnuityClosure: "",
        },

        annuityDisclosureFormV2: {
            flagAnnuityClosureV2: "",
            flagAnnuityClosureBackupWithout: "no",
        },

        harvestDisclosureForm: {
            flagHarvestClosure: "",
        },

        producerDisclosureForm: {
            producerFirstName: "",
            producerLastName: "",
            producerBusinessName: "",
            producerWebsite: "",
            producerBusinessMailingAddress: "",
            producerCity: "",
            producerState: "",
            producerZip: "",
            producerTelephone: "",
            producerEmail: "",
            producerNationalNumber: "",
            producerInStateOf: "",
            producerCustomerFirstName: "",
            producerCustomerLastName: "",
            producerOfferedProducts: [],
            producerNonInsuranceProducts: [],
            producerAuthorizedToSell: [],
            producerAuthorizedToSellDescription: "",
            producerPaidForWork: [],
            producerPaidForWorkCommissionDescription: "",
            producerPaidForWorkOtherDescription: ""
            // producerFixedAnnuities: "",
            // producerVariableAnnuities: "",
            // producerLifeInsurance: "",
            // producerMutualFunds: "",
            // producerStocks: "",
            // producerCertificatesOfDeposits: "",
            // producerAuthorisedToSellOneInsurer: "",
            // producerAuthorisedToSellMoreInsurer: "",
            // producerAuthorisedToSellAnnuitiesFrom: "",
            // // producerAuthorisedToSellAnnuitiesFromDescription: "",
            // producerCommission: "",
            // producerCommissionDescription: "",
            // producerFees: "",
            // producerOther: "",
            // producerOtherDescription: ""
        },

        disableAllInputs: false,
        applicationId: "",
        transferOrExchangeRequestFlag: false,

        rolloverTransferExchange: {
            transferV2Section1CompanyName: "",
            transferV2Section1CompanyPhone: "",
            transferV2Section1CompanyPolicyNumber: "",
            transferV2Section1CompanyAddress: "",
            transferV2Section1CompanyCity: "",
            transferV2Section1CompanyState: "",
            transferV2Section1CompanyZip: "",
            transferV2Section1OwnerName: "",
            transferV2Section1OwnerSsn: "",
            transferV2Section1JoinOwnerName: "",
            transferV2Section1JoinOwnerSsn: "",
            transferV2Section1AnnuitantName: "",
            transferV2Section1AnnuitantSsn: "",
            transferV2Section1JoinAnnuitantName: "",
            transferV2Section1JoinAnnuitantSsn: "",
            transferV2Section1BeneficiaryName: "",
            transferV2Section1BeneficiarySsn: "",
            transferV2Section1InvestmentVehicle: "",
            transferV2Section1InvestmentVehicleOther: "",
            transferV2Section1ExistingCertificate: "",
            transferV2Section2Plan: "",
            transferV2Section2NonQualified1035Exchange: "",
            transferV2Section2NonQualified1035ExchangeOtherPercentage: "",
            transferV2Section2NonQualified1035ExchangeOtherAmount: "",
            transferV2Section2QualifiedAgeRmdTransfer: "",
            transferV2Section2QualifiedTypeOfAccount: "",
            transferV2Section2QualifiedTypeOfAccountOther: "",
            transferV2Section2QualifiedLiquidateTransfer: "",
            transferV2Section2QualifiedLiquidateTransferPercentage: "",
            transferV2Section2QualifiedLiquidateTransferAmount: "",
            transferV2Section2QualifiedWithdrawFunds: "",
            transferV2Section2QualifiedWithdrawFundsOther: "",
            transferV2Section2QualifiedFundsTransfered: "",
        },

        // For FIA

        individualFixedIndexAnnuityApplication: {
            ownerFullName: "",
            ownerSSN: "",
            ownerGender: "",
            ownerDOB: "",
            ownerStreetAddress: "",
            ownerZip: "",
            ownerCity: "",
            ownerState: "",
            ownerEmail: "",
            ownerRelationToAnnuitant: "",

            jointOwnerFullName: "",
            jointOwnerSSN: "",
            jointOwnerGender: "",
            jointOwnerDOB: "",
            jointOwnerStreetAddress: "",
            jointOwnerZip: "",
            jointOwnerCity: "",
            jointOwnerState: "",
            jointOwnerEmail: "",
            // jointOwnerRelationToOwner: "",

            annuitantFullName: "",
            annuitantSSN: "",
            annuitantGender: "",
            annuitantDOB: "",
            annuitantStreetAddress: "",
            annuitantZip: "",
            annuitantCity: "",
            annuitantState: "",
            annuitantEmail: "",
            annuitantRelationToOwner: "",
            annuitantPhone: "",

            jointAnnuitantFullName: "",
            jointAnnuitantSSN: "",
            jointAnnuitantGender: "",
            jointAnnuitantDOB: "",
            jointAnnuitantStreetAddress: "",
            jointAnnuitantZip: "",
            jointAnnuitantCity: "",
            jointAnnuitantState: "",
            jointAnnuitantEmail: "",
            jointAnnuitantRelationToAnnuitant: "",
            jointAnnuitantPhone: "",

            planHarvest: "",
            ridersPlan: "",

            initialAccountAllocation: {
                fixedStrategy: "",
                pointWithCap: "",
                pointWithEnhancedCap: "",
                pointWithParticipationRate: "",
                pointWithEnhancedParticipationRate: "",
                dividendGrowersParticipationRate: "",
                dividendGrowersEnhancedParticipationRate: "",
                nasdaqParticipationRate: "",
                nasdaqEnhancedParticipationRate: "",
                bloombergGlobalParticipationRate: "",
                bloombergGlobalEnhancedParticipationRate: "",
            },

            beneficiaries: [
                {
                    id: 1,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                },
                {
                    id: 2,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                },
                {
                    id: 3,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                },
                {
                    id: 4,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                }, {
                    id: 5,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                },
                {
                    id: 6,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                },
            ],

            // flagNonQualifiedSelected: false,
            selectedNonQualifiedOptions: [],
            // flagQualifiedSelected: false,
            // selectedQualifiedOptions: "",
            selectedQualifiedOptionsGroup2: "",
            otherSelectedQualifiedOptions: "",
            stateToShowNoticeOfReplacement: "",
            flagQualifiedOrNonQualifiedSelected: "",
            amountPaidWithApplication: "",
            expectedPremiumField: "",
            flagHaveExistingLifeInsurance: "",
            reasonOfApplicationToChangeLifeInsurance: "",
            flagSafeguardPlusAnnuityPeriod: "",

            producerStateA: "",
            applicantStateA: "",
            producerStateB: "",
            applicantStateB: "",
            producerStateC: "",
            applicantStateC: "",
            producerStateD: "",
            applicantStateD: "",
            producerStateE: "",
            applicantStateE: "",
            producerStateF: "",
            applicantStateF: "",
            producerStateG: "",
            applicantStateG: "",

        },

        accountAllocationArray: [
            {
                key: 1,
                state: "fixedStrategy",
                label: "Fixed Strategy",
            },
            {
                key: 2,
                state: "pointWithCap",
                label: "S&P 500 Annual Point-to Point with Cap"
            },
            {
                key: 3,
                state: "pointWithEnhancedCap",
                label: "S&P 500 Annual Point-to-Point with Enhanced Cap"
            },
            {
                key: 4,
                state: "pointWithParticipationRate",
                label: "S&P 500 Annual Point-to-Point with Participation Rate"
            },
            {
                key: 5,
                state: "pointWithEnhancedParticipationRate",
                label: "S&P 500 Annual Point-to-Point with Enhanced Participation Rate"
            },
            {
                key: 6,
                state: "dividendGrowersParticipationRate",
                label: "S&P Dividend Growers Vol Adjust RC2 7.5% Annual Point-to-Point Participation Rate"
            },
            {
                key: 7,
                state: "dividendGrowersEnhancedParticipationRate",
                label: "S&P Dividend Growers Vol Adjust RC2 7.5% Annual Point-to-Point Enhanced Participation Rate"
            },
            {
                key: 8,
                state: "nasdaqParticipationRate",
                label: "Nasdaq-100 7% Target Volatility Index Annual Point-to-Point Participation Rate"
            },
            {
                key: 9,
                state: "nasdaqEnhancedParticipationRate",
                label: "Nasdaq-100 7% Target Volatility Index Annual Point-to-Point Enhanced Participation Rate"
            },
            {
                key: 10,
                state: "bloombergGlobalParticipationRate",
                label: "Bloomberg Global Momentum Diversified Leaders 5% Volatility Target ER Index Annual Point-to-Point Participation Rate"
            },
            {
                key: 11,
                state: "bloombergGlobalEnhancedParticipationRate",
                label: "Bloomberg Global Momentum Diversified Leaders 5% Volatility Target ER Index Annual Point-to-Point Enhanced Participation Rate"
            }
        ],

        // Fia Disclosure new version
        fiaDisclosureVersion: 10,

        // Myga Disclosure new version
        disclosureFormVersion: 9,

        // Transfer Exchange new version
        transferExchangeVersion: 7,

        //Flag to show producer disclosure
        flagShowProducerDisclosure: true,
    })
