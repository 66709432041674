import {
  Grid,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  TextField,
  Input,
  Snackbar,
  Alert,
  InputAdornment,
  Typography,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useContext,
  Fragment,
  useLayoutEffect,
} from "react";
import "../pdfCondfig/category.css";
import { useNavigate } from "react-router-dom";
import Delete from "../../../assets/delete.svg";
import Edit from "../../../assets/edit.svg";

import { Context as userLoaderContext } from "../../../context/userLoaderContext";

import Popup from "./popup";
import { CreateResourceCategoryAPI, DeleteResourceCaetgoryAPI, GetResourceCategoryListAPI, UpdateCategoryNameAPI } from "../../../api/resourceConfig";

const Category = (props) => {
  const { state } = useContext(userLoaderContext);
  const { SetFlagLoader } = useContext(userLoaderContext);

  const [categoryList, setCategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState();
  const navigate = useNavigate();
  const [flagEditPopup, setFlagEditPopup] = useState({
    flag: false,
    pdf_category_id: "",
    pdf_category_name: "",
  });
  const [flagAddPopup, setFlagAddPopup] = useState(false);
  const [flagDeletePopup, setFlagDeletePopup] = useState({
    flag: false,
    pdf_category_id: "",
    pdf_category_name: "",
  });
  const [ShowMsg, setShowMsg] = useState({
    status: false,
    error: false,
    msg: "",
  });
  useEffect(() => {
    SetFlagLoader(true);
  }, []);

  useEffect(() => {
    GetResourceCategoryListAPI(
      (res) => {
        if (res.data.status === true) {
          SetFlagLoader(false);
          setCategoryList(res.data.data);
        } else {
          SetFlagLoader(false);
        }
      },
      (err) => {
        SetFlagLoader(false);
        console.log("err", err);
      }
    );
  }, []);

  const openEditHandle = () => {
    return (
      <>
        <DialogContent className="edit_pdf_popup2">
          <>
            <h4
              style={{
                color: "#D9A22E",
                margin: "20px auto 40px auto",
                // minWidth: "550px",
                minWidth: "400px",
              }}
            >
              Edit Category
            </h4>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ShowMsg.error && (
                <Alert className="errCategory" severity="error">
                  {ShowMsg.msg}
                </Alert>
              )}
              {ShowMsg.status && (
                <Alert className="msgCategory" severity="success">
                  {ShowMsg.msg}
                </Alert>
              )}
            </Box>

            <TextField
              label="Category"
              type="text"
              defaultValue={flagEditPopup.pdf_category_name}
              value={flagEditPopup.pdf_category_name}
              variant="standard"
              sx={{ width: "70%" }}
              onChange={(e) => {
                setFlagEditPopup(old => {
                  let temp = { ...old }
                  temp.pdf_category_name = e.target.value
                  return temp
                })
                setShowMsg({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (!flagEditPopup.pdf_category_name) {
                return setShowMsg({
                  status: false,
                  error: true,
                  msg: "Please fill category feild.",
                });
              } else {
                SetFlagLoader(true);
                UpdateCategoryNameAPI(
                  {
                    pdf_category_id: flagEditPopup.pdf_category_id,
                    pdf_category_name: flagEditPopup.pdf_category_name,
                  },
                  (res) => {
                    console.log("11", res);
                    if (res?.data?.status) {
                      SetFlagLoader(false);
                      setCategoryList(old => {
                        let temp = [...old]
                        temp.map((obj) => {
                          if (obj.pdf_category_id == flagEditPopup.pdf_category_id) {
                            obj.pdf_category_name = flagEditPopup.pdf_category_name
                          }
                        })
                        return temp
                      });
                      setFlagEditPopup({
                        flag: false,
                        pdf_category_id: "",
                        pdf_category_name: "",
                      });
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    }
                    // setFlagDeleteUpdated(false);
                  },
                  (err) => {
                    SetFlagLoader(false);
                    setShowMsg({
                      status: false,
                      error: true,
                      msg: err.data.message,
                    });
                  }
                );
              }
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setFlagEditPopup({
                flag: false,
                pdf_category_id: "",
                pdf_category_name: "",
              });
              setShowMsg({
                status: false,
                error: false,
                msg: "",
              });
            }}
          >
            No
          </Button>
        </DialogActions>
      </>
    );
  };
  const openDeleteHandle = () => {
    return (
      <>
        <DialogContent className="edit_pdf_popup2">
          <>
            <h4
              style={{
                color: "#D9A22E",
                margin: "20px auto 40px auto",
                minWidth: "400px",
              }}
            >
              Delete Category
            </h4>
            <span>Are you sure you want to delete {flagDeletePopup.pdf_category_name}?</span>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              SetFlagLoader(true);
              DeleteResourceCaetgoryAPI(flagDeletePopup.pdf_category_id,
                (res) => {
                  if (res?.data.status) {
                    setCategoryList(old => {
                      let tempList = [...old]
                      tempList = tempList.filter(element => element.pdf_category_id != flagDeletePopup.pdf_category_id)
                      return tempList
                    });
                  }
                  SetFlagLoader(false);
                  setFlagDeletePopup({
                    flag: false,
                    pdf_category_id: "",
                    pdf_category_name: ""
                  });
                },
                (err) => {
                  console.log("11", err);
                }
              );
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setFlagDeletePopup({
                flag: false,
                pdf_category_id: "",
                pdf_category_name: ""
              });
            }}
          >
            No
          </Button>
        </DialogActions >
      </>
    );
  };

  const AddCategoryHandle = () => {
    return (
      <>
        <DialogContent className="edit_pdf_popup2">
          <>
            <h4
              style={{
                color: "#D9A22E",
                margin: "20px auto 40px auto",
                minWidth: "400px",
              }}
            >
              Add Category
            </h4>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {ShowMsg.error && (
                <Alert className="errCategory" severity="error">
                  {ShowMsg.msg}
                </Alert>
              )}
              {ShowMsg.status && (
                <Alert className="msgCategory" severity="success">
                  {ShowMsg.msg}
                </Alert>
              )}
            </Box>
            <TextField
              label="Category"
              type="text"
              variant="standard"
              sx={{ width: "70%", marginTop: "10px" }}
              onChange={(e) => {
                setCategoryName(e.target.value);
                setShowMsg({
                  status: false,
                  error: false,
                  msg: "",
                });
              }}
            />
          </>
        </DialogContent>
        <DialogActions>
          <Button
            // debugger
            onClick={() => {
              if (!categoryName) {
                return setShowMsg({
                  status: false,
                  error: true,
                  msg: "Please fill all the fields.",
                });
              } else {
                SetFlagLoader(true);
                let payload = {
                  category_name: categoryName,
                  prev_pdf_category_id: categoryList?.length ? categoryList[categoryList.length - 1]?.pdf_category_id : 0,
                }
                CreateResourceCategoryAPI(
                  payload,
                  (res) => {
                    if (res?.data?.status) {
                      SetFlagLoader(false);
                      setCategoryList(old => {
                        let tempList = [...old]
                        tempList.push(res?.data?.data[0])
                        return tempList
                      });
                      setFlagAddPopup(false);
                      setShowMsg({
                        status: false,
                        error: false,
                        msg: "",
                      });
                    } else {
                      SetFlagLoader(false);
                      // setFlagAddPopup(false);
                      setShowMsg({
                        status: false,
                        error: true,
                        msg: "Something went wrong!",
                      });
                    }
                  },
                  (err) => {
                    console.log("11", err);
                    // SetFlagLoader(false);
                    setShowMsg({
                      status: false,
                      error: true,
                      msg: err.data.message,
                    });
                  }
                );
              }
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setFlagAddPopup(false);
              setShowMsg({
                status: false,
                error: false,
                msg: "",
              });
              setCategoryName("");
            }}
          >
            No
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <>
      {flagEditPopup.flag && <Popup open={flagEditPopup.flag} data={openEditHandle()} />}
      {flagDeletePopup.flag && <Popup open={flagDeletePopup.flag} data={openDeleteHandle()} />}
      {flagAddPopup && <Popup open={flagAddPopup} data={AddCategoryHandle()} />}
      <Box className="page_title_annuity_application page_title">
        <Typography variant="span">Category</Typography>
      </Box>
      <Box className="main_container_history">
        {/* <Box className="first_div">Category</Box> */}
        <Box className="btn-search-div" sx={{ marginTop: "20px" }}>
          <Box className="btns_category">
            <Button
              onClick={() => {
                navigate("/pdf-config");
              }}
              className="btn1"
              variant="contained"
            >
              Back
            </Button>
            <Button
              onClick={() => {
                setFlagAddPopup(true);
              }}
              className="btn2"
              variant="contained"
            >
              New Category
            </Button>
          </Box>
        </Box>

        <Box className="Grid_box_category">
          <Grid
            xs={12}
            sm={12}
            md={12}
            container
            direction="column"
            justifyContent="space-evenly"
            className="Grid_main"
          >
            <Grid item className="item1 grid_item head box_height" xs={12}>
              <Grid
                container
                justifyContent="space-around"
                xs={12}
                sx={{ height: "30px" }}
              >
                <Grid item xs={8} sm={10}>
                  Category
                </Grid>
                <Grid item xs={4} sm={2}></Grid>
              </Grid>
            </Grid>

            {categoryList.map((singleCategory, index) => {
              return (
                <Grid
                  item
                  className="item1 grid_item  box_height"
                  xs={12}
                  sm={10}
                >
                  <Grid container justifyContent="space-around" xs={12}>
                    <Grid item xs={8}>
                      {singleCategory.pdf_category_name}
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={Edit}
                          onClick={() => {
                            setFlagEditPopup({
                              flag: true,
                              pdf_category_id: singleCategory.pdf_category_id,
                              pdf_category_name: singleCategory.pdf_category_name,
                            });
                          }}
                          width="20px"
                          height="20px"
                          style={{
                            marginRight: "7px",
                            cursor: "pointer",
                          }}
                        />

                        <img
                          src={Delete}
                          width="20px"
                          height="20px"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // setFlagDeleteUpdated(false);
                            setFlagDeletePopup({
                              flag: true,
                              pdf_category_id: singleCategory.pdf_category_id,
                              pdf_category_name: singleCategory.pdf_category_name,
                            });
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Category;