import Axios from "./axios";

export const GetResourceDataAPI = async (onSuccess, onError) => {
    try {
        const responce = await Axios.get("/api/resources/get-resource-config-data", {
            headers: {
                "Content-Type": "application/json",
            },
            withCredentials: true,
        });
        onSuccess && onSuccess(responce);
    } catch (err) {
        onError && onError(err);
    }
};