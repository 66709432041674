import React from "react";
import {
  Box,
  Typography,
  Link,
  TextField,
  Button,
  Input,
  InputAdornment,
  Grid,
  FormControl,
  InputLabel,
  DialogTitle,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  Dialog,
  DialogContentText,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import Farmers_img from "../../assets/FarmersLifeHomepage.png";
import "./calculator_view.css";
import FinancialRating from "../../assets/financial_rating.png";
import { useState, useContext } from "react";
import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";
import { useEffect } from "react";
import { StateAPI } from "../../service/stateList.service";
import { YearListAPI } from "../../service/year.service";
import { SaveDataAPI } from "../../service/savedata.service";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import success_dialog from "../../assets/success_dialog.png";
//import download_icon from "../../assets/btn_icon.png";
import { useNavigate } from "react-router-dom";
import { HistoryAPI } from "../../service/calculator_history.service";
import { ViewCalcIdDataAPI } from "../../service/viewcalc_data_id.service";
import { DownloadPdfAPI } from "../../service/download_pdf.service";
import { Context } from "../../context/userLoaderContext";
import moment from "moment";
import DownloadIcon from "@mui/icons-material/Download";
import { Footer } from "../../components/footer/footer";

const View_Calculation = (props) => {
  // debugger;
  const [periodValue, setPeriodValue] = useState(0);
  const [periodValueObj, setPeriodValueObj] = useState([{}]);
  const [interstRate, setInterstRate] = useState(0);
  const [interstRate1, setInterstRate1] = useState(0);
  const [year_list_id, setYear_list_id] = useState();
  const [state_id, setStateId] = useState();
  const [stateName, setStateName] = useState();
  const [amount, setAmount] = useState();
  const [age, setAge] = useState([]);
  const [name, setName] = useState("");
  const [stateList, setStateList] = useState([{}]);
  const [amountText, setAmountText] = useState(null);
  const [surrenderCharge, setSurrenderCharge] = useState([[]]);
  const [surrender_charge_array, setSurrender_charge_array] = useState([]);
  const [final_value, setFinal_value] = useState();
  const [freeWithdrawalRider, setFreeWithdrawalRider] = useState(false);
  const [enhancedBenefitRider, setEnhancedBenefitRider] = useState(false);
  const [finalChartData, setFinalChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Loading...",
        data: [],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
    ],
  });
  const [surrender_charge_change_flag, setSurrender_charge_change_flag] =
    useState();
  const [interest_rate_change_flag, setInterest_rate_change_flag] = useState();
  const [ShowMsg, setShowMsg] = useState({
    status: false,
    error: false,
    msg: "",
  });
  const [sum, setSum] = useState(0);
  const [msg, setMsg] = useState({});
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [data, setData] = useState([{}]);
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);
  const { state, SetFlagLoader } = useContext(Context);
  console.log(state.user.first_name + " " + state.user.last_name);
  const queryParams = new URLSearchParams(window.location.search);
  const calculation_id = queryParams.get("calculation_id");
  const addCommas = (num) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return num;
  };
  const [searchParams] = new URLSearchParams();
  const downloadBase64File = (contentBase64, fileName) => {
    const linkSource = `data:application/pdf;base64,${contentBase64}`;
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);

    downloadLink.href = linkSource;
    downloadLink.target = "_self";
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const [preparedBy, setPrepredBy] = useState("");
  const [created_at, setCreatedAt] = useState("");

  // useEffect(() => {
  //   var ff = surrender_charge_array;
  //   // debugger;
  // }, [surrender_charge_array]);

  useEffect(() => {
    SetFlagLoader(false);
    // StateAPI(
    //   (res) => {
    //     if (res.data.status === true) {
    //       res.data.data.map((rr) => {
    //         rr["label"] = rr["state_name"];
    //         //setStateId(rr.state_id)
    //       });
    //       // console.log("dlkfjhdslkfghdslfjghdsgfkjdfskjdgf",res.data.data)
    //       setStateList(res.data.data);
    //       console.log("State list", res.data.data);
    //     } else {
    //       console.log("res.data.status false");
    //     }
    //   },
    //   (err) => {
    //     console.log("err", err);
    //   }
    // );

    // YearListAPI((res) => {
    //   //console.log("123455678900123456576879879",res.data);
    //   if (res.data.status == true) {
    //     setPeriodValueObj(res.data.data);
    //   }
    // });
    // debugger;

    let url = new URLSearchParams(window.location.search);
    let getCalc_id = url.get("calculation_id");
    if (getCalc_id) {
      ViewCalcIdDataAPI(getCalc_id, (res) => {
        if (res.data.status === true) {
          var resData = res.data.data[0];
          setData(resData);
          setName(resData.name);
          setAmountText(addCommas(resData.amount));
          setAmount(Number(resData.amount));
          setSurrender_charge_array(
            resData.surrender_charge.map((rr) => {
              return rr.surrender_charge_value;
            })
          );
          setCreatedAt(resData.date);
          if (resData.prepared_By === "") {
            setPrepredBy(resData.agent_name);
          } else {
            setPrepredBy(resData.prepared_By);
          }
          setPeriodValue(resData.year);
          setAge(resData.age);
          setStateName(resData.state);
          setFreeWithdrawalRider(resData.free_withdrawal_rider);
          setEnhancedBenefitRider(resData.enhanced_benefit_rider);
          let tempInterestRate1 = resData.interest_rate;
          setInterstRate1(tempInterestRate1);
          setInterest_rate_change_flag(resData.interest_rate_change_flag);
          setSurrender_charge_change_flag(resData.surrender_charge_change_flag);
          console.log("resData", resData);
          // setTimeout(() => {
          // let getYearSList = resData.surrender_charge.map(
          //   (rr) => rr.surrender_charge_value
          // );
          // console.log(
          //   "getYearSList ::::::::::::::::::::::::::::::::::",
          //   getYearSList
          // );
          // let findYearListID = periodValueObj.filter(rr=>rr.year_list_name==resData.year)
          // setYear_list_id(findYearListID[0]['year_list_id'])

          //   setName(resData.name);
          //   setAmountText(addCommas(resData.amount));
          //   setAmount(Number(resData.amount));
          //   setSurrender_charge_array(getYearSList);
          //   setPeriodValue(resData.year);
          //   setAge(resData.age);
          //   setStateName(resData.state);
          //   setFreeWithdrawalRider(resData.free_withdrawal_rider);
          //   setEnhancedBenefitRider(resData.enhanced_benefit_rider);
          //   let tempInterestRate1 = resData.interest_rate;
          //   setInterstRate1(tempInterestRate1);

          //   if (
          //     resData.enhanced_benefit_rider === true &&
          //     resData.free_withdrawal_rider === true
          //   ) {
          //     tempInterestRate1 -= 0.002;
          //   } else if (
          //     resData.enhanced_benefit_rider === true ||
          //     resData.free_withdrawal_rider === true
          //   ) {
          //     tempInterestRate1 -= 0.001;
          //   }
          //   setInterstRate(tempInterestRate1);

          //   // setSurrenderCharge()
          // }, 1000);
        }
      });
    }
  }, []);

  // useEffect(() => {
  //   setPeriodValue(0);
  //   setStateName("");
  //   setYear_list_id();
  //   setStateId();
  //   setAmount();
  //   setInterstRate(0);
  //   setAmount(0);
  //   setAge([]);
  //   setName("");

  //   setEnhancedBenefitRider(false);
  //   setFreeWithdrawalRider(false);
  //   setFinal_value();
  //   setAmountText(null);
  //   setSurrenderCharge([[]]);
  //   setSurrender_charge_array([]);
  // }, [open]);
  useEffect(() => {
    let globalTemp = amount;
    let FinalArr = [];
    let chartDataArr = [];
    let chartlabelDataArr = [];
    // debugger;
    let tempInterestRate = data.interest_rate
      ? data.interest_rate
      : interstRate1;

    if (freeWithdrawalRider && enhancedBenefitRider) {
      tempInterestRate -= 0.002;
    } else if (freeWithdrawalRider) {
      tempInterestRate -= 0.001;
    } else if (enhancedBenefitRider) {
      tempInterestRate -= 0.001;
    }
    setInterstRate(tempInterestRate);

    let sumff = 0;
    for (let i = 0; i < periodValue; i++) {
      if (i == 0) {
        // FinalArr.push([interstRate,0,globalTemp,globalTemp])
        chartlabelDataArr.push("year " + i);
        chartDataArr.push(globalTemp);
        setY(chartDataArr);
      }
      // else
      {
        chartlabelDataArr.push("year " + (i + 1));
        setX(chartlabelDataArr);
        console.log("interstRate :: ", tempInterestRate);
        let t1 = 1 + tempInterestRate;
        let temp_account_value = globalTemp * t1;
        let temp_earning = temp_account_value - globalTemp;
        //  console.log("surrender_charge_array[i] :: ",surrender_charge_array[i])

        //  console.log("temp_account_value",temp_account_value);
        console.log(
          "(temp_account_value*surrender_charge_array[i])",
          temp_account_value * surrender_charge_array[i]
        );
        let sc1 =
          temp_account_value - temp_account_value * surrender_charge_array[i];
        globalTemp = temp_account_value;
        console.log("SC1 ::::::::::::: ", sc1);
        setFinal_value(temp_account_value.toFixed(0));
        sumff += parseInt(temp_earning?.toFixed(0));

        FinalArr.push([
          tempInterestRate,
          temp_earning?.toFixed(0),
          temp_account_value?.toFixed(0),
          Number(sc1)?.toFixed(0),
        ]);
        console.log("FinalArrrrrrrrrrrrrrrrrrrrrrr", FinalArr);
        chartDataArr.push(temp_account_value);
      }
    }
    //  const options = {}
    // chartDataArr.unshift(amount)
    setFinalChartData({
      labels: chartlabelDataArr,
      datasets: [
        {
          label: "Amount Year Graph",
          data: chartDataArr,
          fill: true,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
        },
      ],
      options: {
        scales: {
          y: {
            ticks: {
              callback: (value, index, ticks) => {
                //console.log(value);
                return `$${addCommas(value)}`;
              },
            },
          },
        },
      },
    });
    setSurrenderCharge(FinalArr);

    setSum(sumff);
  }, [amount, periodValue, freeWithdrawalRider, enhancedBenefitRider]);

  return (
    <>
      <Box className="img_div">
        <img src={Farmers_img} className="img" alt="img" />
        <span class="img_text1">Farmers Safeguard Plus®</span>
        <span class="img_text2">Multi-Year Guaranteed Annuity (MYGA)</span>
      </Box>
      <Box className="btn_div1_view">
        {/* <Link to="/history"> */}
        <Button
          variant="contained"
          onClick={() => {
            navigate("/history");
          }}
        >
          Back
        </Button>
        {/* </Link> */}
      </Box>
      <Box className="main_container main_container_view">
        <Box className="first_div">Calculator View</Box>

        <Box className="third_box">
          {/* <button className="btn_btn">
            {" "} */}
          <Button
            variant="contained"
            startIcon={<DownloadIcon />}
            className="download_btn"
            onClick={() => {
              SetFlagLoader(true);
              const d = new Date();
              if (!calculation_id) {
                if (
                  final_value !== NaN &&
                  final_value !== 0 &&
                  age > 0 &&
                  amount != null &&
                  name != null &&
                  year_list_id != null
                ) {
                  // let url = "https://docs.google.com/spreadsheets/d/1bEZ38C5lZQiTe5TwQnRb7C0XKN4aCHthBQMflhpW6ro/export?format=pdf"
                  // window.open(url, '_blank', 'noopener,noreferrer')
                  // return ;
                  DownloadPdfAPI(
                    {
                      client: name,
                      // agent: state.user.first_name + " " + state.user.last_name,
                      preparedBy:
                        preparedBy !== ""
                          ? preparedBy
                          : state.user.first_name + " " + state.user.last_name,
                      age: Number(age),
                      state: stateName,
                      Graph: { x, y },
                      amount: amount,
                      period: periodValue,
                      interest_rate: interstRate,
                      pdf_array: surrenderCharge,
                      prepared: moment(d).format("DD/MM/YYYY h:mm"),
                      surrender_Charge: surrender_charge_array,
                      free_withdrawal_rider: freeWithdrawalRider,
                      enhanced_benefit_rider: enhancedBenefitRider,
                    },
                    (res) => {
                      SetFlagLoader(false);
                      console.log(res);
                      console.log(res.data);
                      // let url =
                      //   "https://docs.google.com/spreadsheets/d/1bEZ38C5lZQiTe5TwQnRb7C0XKN4aCHthBQMflhpW6ro/export?format=pdf";
                      // window.open(url, "_blank", "noopener,noreferrer");
                      if (res.data.status)
                        downloadBase64File(
                          res.data.data,
                          "Farmers_Safeguard_Plus_calculator.pdf"
                        );
                      // else alert("Something Went Wrong!");
                      return setShowMsg({
                        status: false,
                        error: true,
                        msg: "Something Went Wrong!",
                      });
                    },
                    (err) => { }
                  );
                }
                //  else if (name != null) {
                //   SetFlagLoader(false);
                //   // alert(
                //   //   "Client Name, Client Age,State, Amount and Period Value can't be empty"
                //   // );
                //   return setShowMsg({
                //     status: false,
                //     error: true,
                //     msg: "Client Name, Client Age,State, Amount and Period Value can't be empty",
                //   });
                // }
              } else {
                DownloadPdfAPI(
                  {
                    client: name,
                    // agent: state.user.first_name + " " + state.user.last_name,
                    preparedBy:
                      preparedBy !== ""
                        ? preparedBy
                        : state.user.first_name + " " + state.user.last_name,
                    age: Number(age),
                    state: stateName,
                    Graph: { x, y },
                    amount: amount,
                    period: periodValue,
                    interest_rate: interstRate,
                    pdf_array: surrenderCharge,
                    prepared: moment(d).format("DD/MM/YYYY h:mm"),
                    surrender_Charge: surrender_charge_array,
                    free_withdrawal_rider: freeWithdrawalRider,
                    enhanced_benefit_rider: enhancedBenefitRider,
                  },
                  (res) => {
                    SetFlagLoader(false);
                    let url =
                      "https://docs.google.com/spreadsheets/d/1bEZ38C5lZQiTe5TwQnRb7C0XKN4aCHthBQMflhpW6ro/export?format=pdf";
                    window.open(url, "_blank", "noopener,noreferrer");
                  },
                  (err) => { }
                );
              }
            }}
            style={{ borderColor: "2px solid red" }}
          >
            Download
          </Button>
          {/* /  </button> */}
        </Box>

        <Box className="first_part">
          <Box className="first_box_view">
            <Box>
              <Box className="name">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Client Name:</div>
                  {/* // <div className="second">:</div> */}
                  <div className="third">{name}</div>
                </Box>
              </Box>

              <Box className="age">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Client Age:</div>
                  {/* // <div className="second">:</div> */}
                  <div className="third">{age}</div>
                </Box>
              </Box>

              <Box className="amount">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Amount:</div>
                  {/* // <div className="second">:</div> */}
                  <div className="third">${addCommas(amount)}</div>
                </Box>
              </Box>

              <Box className="period">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Period:</div>
                  {/* // <div className="second">:</div> */}
                  <div className="third">{periodValue}</div>
                </Box>
              </Box>
              <Box className="state state_view">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">State:</div>
                  {/* // <div className="second">:</div> */}
                  <div className="third">{stateName}</div>
                </Box>
              </Box>
              <Box className="rider">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Rider:</div>
                  {/* // <div className="second">:</div> */}
                  <div className="third third_rider">
                    {freeWithdrawalRider === true &&
                      enhancedBenefitRider === false
                      ? "Free Withdrawal Rider"
                      : enhancedBenefitRider === true &&
                        freeWithdrawalRider === false
                        ? "Enhanced Benefit Rider"
                        : enhancedBenefitRider === true &&
                          freeWithdrawalRider === true
                          ? "Free Withdrawal Rider,Enhanced Benefit Rider"
                          : "None"}
                  </div>
                </Box>
              </Box>
              <Box className="state">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Created At:</div>
                  {/* // <div className="second">:</div> */}
                  <div className="third">
                    {moment(created_at).format("MM-DD-YYYY")}
                  </div>
                </Box>
              </Box>
              <Box className="state">
                <Box sx={{ display: "flex" }}>
                  <div className="first_view">Prepared By:</div>
                  {/* // <div className="second">:</div> */}
                  <div className="third">{preparedBy}</div>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="rightbox rightbox_view">
            <Box className="second_box second_box_view">
              <span className="test">
                <b>Annual Interest Rate: {(interstRate * 100).toFixed(2)}%</b>
              </span>
              <Box>
                <span>
                  <b>Amount After {periodValue} Years</b>
                </span>
                <span className="secBox">
                  <h2 style={{ color: "#D9A22E" }}>
                    $
                    {final_value !== NaN && final_value !== undefined
                      ? addCommas(final_value)
                      : 0}
                  </h2>
                </span>
              </Box>
            </Box>
            {(surrender_charge_change_flag === true ||
              interest_rate_change_flag === true) && (
                <Box
                  className="note"
                  style={{ color: "red", textAlign: "justify" }}
                >
                  <span>
                    <b>Note: </b>The interest rate or surrender charges have
                    changed since this calculator was run. You may need to create
                    a new calculator to get the latest data.
                  </span>
                </Box>
              )}
          </Box>
        </Box>

        <Divider
          component="div"
          role="presentation"
          style={{
            width: "90%",
            color: "rgb(217, 162, 46) !important",
            margin: "0 auto !important",
          }}
        ></Divider>

        <div className="chart">
          <Line
            options={finalChartData.options}
            data={finalChartData}
            style={{ width: "50% !important", height: "50% !important" }}
          />
        </div>

        <Box className="con_main_box">
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            className="con_main"
          >
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="right"
                alignItems="center"
              >
                <Grid
                  item
                  className="grid_item1 item box_height"
                  justifyContent="center"
                  xs={8}
                  sm={4}
                  md={8}
                >
                  Guaranteed
                </Grid>
              </Grid>
            </Grid>

            <Grid item style={{ height: "36px", margin: "5px" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={3}
                  sm={4}
                  md={3}
                  className="grid_item2 item box_height"
                >
                  <Grid container>
                    <Grid item className="itemtextcenter " xs={2} sm={3} md={3}>
                      Year
                    </Grid>
                    <Grid item className="itemtextcenter" xs={8} sm={6} md={6}>
                      Surrender Charge
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} sm={3} md={3}>
                      Age
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  className="item grid_item3 box_height"
                  xs={8}
                  sm={6}
                  md={8}
                >
                  <Grid container justifyContent="space-evenly">
                    <Grid item className="itemtextcenter" xs={2} md={3}>
                      Interest
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={3}>
                      Earnings
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={3}>
                      Account Value
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={3}>
                      Surrender Value
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {surrenderCharge.map((data, i) => {
              console.log(
                "surrender_charge_array:1111111111111:    ",
                surrender_charge_array,
                surrenderCharge,
                data,
                i
              );
              console.log("surrender_charge   I ", data);
              console.log(
                "surrender_charge_array:88:::    ",
                surrender_charge_array[i]
              );

              //   console.log(surrenderCharge.length)
              if (surrenderCharge.length > 1) {
                return (
                  <>
                    <Grid item style={{ height: "36px", margin: "5px" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={3}
                          sm={4}
                          md={3}
                          className="color grid_item2 item box_height"
                        >
                          <Grid container>
                            <Grid
                              item
                              className="itemtextcenter "
                              xs={2}
                              sm={3}
                              md={3}
                            >
                              {i + 1}
                            </Grid>
                            <Grid
                              item
                              className="itemtextcenter"
                              xs={8}
                              sm={6}
                              md={6}
                            >
                              {Number(
                                (surrender_charge_array[i] * 100).toFixed(2)
                              )}
                              %
                            </Grid>
                            <Grid
                              item
                              className="itemtextcenter"
                              xs={2}
                              sm={3}
                              md={3}
                            >
                              {Number(age) + i + 1}
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          className="color item grid_item3 box_height"
                          xs={8}
                          sm={6}
                          md={8}
                        >
                          <Grid container justifyContent="space-evenly">
                            <Grid item className="itemtextcenter" xs={2} md={3}>
                              {(data[0] * 100).toFixed(2)}%
                            </Grid>
                            <Grid item className="itemtextcenter" xs={2} md={3}>
                              ${addCommas(data[1])}
                            </Grid>
                            <Grid item className="itemtextcenter" xs={2} md={3}>
                              ${addCommas(data[2])}
                            </Grid>
                            <Grid item className="itemtextcenter" xs={2} md={3}>
                              ${addCommas(data[3])}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                );
              }
            })}

            <Grid item style={{ height: "36px", margin: "5px" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  className="color item grid_item3 box_height"
                  xs={11}
                  sm={10}
                  md={11}
                >
                  <Grid container justifyContent="space-evenly">
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      <b>Total / Ending</b>
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      {""}
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      {""}
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      <b>${addCommas(sum)}</b>
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      <b>
                        $
                        {surrenderCharge[surrenderCharge.length - 1] !==
                          undefined
                          ? addCommas(
                            surrenderCharge[surrenderCharge.length - 1]?.[2]
                          )
                          : 0}
                      </b>
                    </Grid>
                    <Grid item className="itemtextcenter" xs={2} md={2}>
                      <b>
                        $
                        {surrenderCharge[surrenderCharge.length - 1] !==
                          undefined
                          ? addCommas(
                            surrenderCharge[surrenderCharge.length - 1]?.[2]
                          )
                          : 0}
                      </b>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default View_Calculation;
