import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Divider,
  RadioGroup,
  Radio,
  FormControlLabel,
  Select,
  MenuItem,
  Input,
  InputAdornment,
  Button,
  FormControl,
  InputLabel,
  imageListClasses,
  Autocomplete,
  Link,
  FormGroup,
  Checkbox,
} from "@mui/material";
import { Context } from "../../../context/V3/newApplicationFormContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import "./rolloverTransferExchangeRequest.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Context as ErrorValidationContext } from "../../../context/V3/errorValidationContext";

const RolloverTransferExchangeRequest = (props) => {
  const {
    SetTransferOrExchangeRequest,
    SetWholeTransferExchangeRequest,
    SetWholeRolloverTransferExchangeRequest,
    updateTransferOrExchangeRequestFlag,
    SetDisableAllInputs,
    SetRolloverTransferExchangeRequest
  } = useContext(Context);
  const {
    individualAnnuityApplication,
    disableAllInputs,
    rolloverTransferExchange
  } = useContext(Context).state;

  useEffect(() => {
    if (flagFill === true) {
      SetDisableAllInputs(true);
    }
  }, []);
  const { SetApplicationFormFlagSectionData } = useContext(
    ErrorValidationContext
  );
  const {
    flagFourthPageFirstSection,
    flagFourthPageSecondSection,
    flagFourthPageThirdSection,
  } = useContext(ErrorValidationContext).state;

  // const [flagThirdPageFirstSection, setFlagFirstSection] = useState(true);
  // const [flagThirdPageSecondSection, setFlagSecondSection] = useState(false);
  const [flagFill, setFlagFill] = useState(false);
  const [flagDelete, setFlagDelete] = useState(false);

  const [backupData, setBackupData] = useState({});
  const [transferExchangeValidationError, setTransferExchangeValidationError] = useState({
    flagZipValid: false,
    flagPhoneValid: false,
  });

  const [requiredFields, setRequiredFields] = useState({
    owner: false,
    jointOwner: false,
    annuitant: false,
    jointAnnuitant: false,
    beneficiary: false,
  });

  const StatesName = [
    "Alabama",
    "Alaska",
    // "American Samoa",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "District Of Columbia",
    // "Federated States Of Micronesia",
    "Florida",
    "Georgia",
    // "Guam",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    // "Marshall Islands",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    // "Northern Mariana Islands",
    "Ohio",
    "Oklahoma",
    "Oregon",
    // "Palau",
    "Pennsylvania",
    // "Puerto Rico",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    // "Virgin Islands",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ];

  useEffect(() => {

    let tempRequiredFields = { ...requiredFields }
    if (rolloverTransferExchange.transferV2Section1OwnerName?.length || rolloverTransferExchange.transferV2Section1OwnerSsn?.length) {
      tempRequiredFields.owner = true
    } else {
      tempRequiredFields.owner = false
    }
    if (rolloverTransferExchange.transferV2Section1JoinOwnerName?.length || rolloverTransferExchange.transferV2Section1JoinOwnerSsn?.length) {
      tempRequiredFields.jointOwner = true
    } else {
      tempRequiredFields.jointOwner = false
    }
    if (rolloverTransferExchange.transferV2Section1AnnuitantName?.length || rolloverTransferExchange.transferV2Section1AnnuitantSsn?.length) {
      tempRequiredFields.annuitant = true
    } else {
      tempRequiredFields.annuitant = false
    }
    if (rolloverTransferExchange.transferV2Section1JoinAnnuitantName?.length || rolloverTransferExchange.transferV2Section1JoinAnnuitantSsn?.length) {
      tempRequiredFields.jointAnnuitant = true
    } else {
      tempRequiredFields.jointAnnuitant = false
    }
    if (rolloverTransferExchange.transferV2Section1BeneficiaryName?.length || rolloverTransferExchange.transferV2Section1BeneficiarySsn?.length) {
      tempRequiredFields.beneficiary = true
    } else {
      tempRequiredFields.beneficiary = false
    }
    setRequiredFields(tempRequiredFields)

  }, [rolloverTransferExchange.transferV2Section1OwnerName,
  rolloverTransferExchange.transferV2Section1OwnerSsn,
  rolloverTransferExchange.transferV2Section1JoinOwnerName,
  rolloverTransferExchange.transferV2Section1JoinOwnerSsn,
  rolloverTransferExchange.transferV2Section1AnnuitantName,
  rolloverTransferExchange.transferV2Section1AnnuitantSsn,
  rolloverTransferExchange.transferV2Section1JoinAnnuitantName,
  rolloverTransferExchange.transferV2Section1JoinAnnuitantSsn,
  rolloverTransferExchange.transferV2Section1BeneficiaryName,
  rolloverTransferExchange.transferV2Section1BeneficiarySsn])

  useEffect(() => {
    // debugger;
    if (flagDelete) {
      SetWholeRolloverTransferExchangeRequest({
        transferV2Section1CompanyName: "",
        transferV2Section1CompanyPhone: "",
        transferV2Section1CompanyPolicyNumber: "",
        transferV2Section1CompanyAddress: "",
        transferV2Section1CompanyCity: "",
        transferV2Section1CompanyState: "",
        transferV2Section1CompanyZip: "",
        transferV2Section1OwnerName: "",
        transferV2Section1OwnerSsn: "",
        transferV2Section1JoinOwnerName: "",
        transferV2Section1JoinOwnerSsn: "",
        transferV2Section1AnnuitantName: "",
        transferV2Section1AnnuitantSsn: "",
        transferV2Section1JoinAnnuitantName: "",
        transferV2Section1JoinAnnuitantSsn: "",
        transferV2Section1BeneficiaryName: "",
        transferV2Section1BeneficiarySsn: "",
        transferV2Section1InvestmentVehicle: "",
        transferV2Section1InvestmentVehicleOther: "",
        transferV2Section1ExistingCertificate: "",
        transferV2Section2Plan: "",
        transferV2Section2NonQualified1035Exchange: "",
        transferV2Section2NonQualified1035ExchangeOtherPercentage: "",
        transferV2Section2NonQualified1035ExchangeOtherAmount: "",
        transferV2Section2QualifiedAgeRmdTransfer: "",
        transferV2Section2QualifiedTypeOfAccount: "",
        transferV2Section2QualifiedTypeOfAccountOther: "",
        transferV2Section2QualifiedLiquidateTransfer: "",
        transferV2Section2QualifiedLiquidateTransferPercentage: "",
        transferV2Section2QualifiedLiquidateTransferAmount: "",
        transferV2Section2QualifiedWithdrawFunds: "",
        transferV2Section2QualifiedWithdrawFundsOther: "",
        transferV2Section2QualifiedFundsTransfered: ""
      });

      setFlagDelete(false);
    }
  }, [flagDelete]);


  useEffect(() => {
    // intiial data saving for backup
    setBackupData({ ...rolloverTransferExchange });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let inputArray = document.querySelectorAll("input");
    if (disableAllInputs === true) {
      for (let i = 0; i < inputArray.length; i++) {
        console.log(
          "Printig all input for dsiable in transfer exchange request",
          inputArray[i]
        );
        inputArray[i].disabled = true;
      }
    }
  }, [
    disableAllInputs,
    flagFourthPageFirstSection,
    flagFourthPageSecondSection,
  ]);

  const CommonContextUpdate = (type, value) => {
    SetRolloverTransferExchangeRequest({
      type: type,
      value: value,
    });
  };

  const onChangeSSN = (fieldName, e) => {
    let targetLength = e.target.value.length;
    let contextLength = rolloverTransferExchange[fieldName].length;

    if (targetLength > contextLength) {
      if (targetLength === 3 || targetLength === 6) {
        let val = e.target.value + "-";
        CommonContextUpdate(fieldName, val);
      } else if (e.target.value.length <= 11) {
        CommonContextUpdate(fieldName, e.target.value);
      }
    } else {
      if (targetLength === 7 || targetLength === 4) {
        CommonContextUpdate(fieldName, e.target.value.slice(0, -1));
      } else {
        CommonContextUpdate(fieldName, e.target.value);
      }
    }
  }

  const onBlurSSN = (fieldName, e) => {
    var regx = /^\d{3}-?\d{2}-?\d{4}$|^XXX-XX-XXXX$/;
    if (!regx.test(e.target.value)) {
      CommonContextUpdate(fieldName, "");
    }
  }

  useEffect(() => {
    let qualifiedArray = [
      "transferV2Section2QualifiedAgeRmdTransfer",
      "transferV2Section2QualifiedTypeOfAccount",
      "transferV2Section2QualifiedTypeOfAccountOther",
      "transferV2Section2QualifiedLiquidateTransfer",
      "transferV2Section2QualifiedLiquidateTransferPercentage",
      "transferV2Section2QualifiedLiquidateTransferAmount",
      "transferV2Section2QualifiedWithdrawFunds",
      "transferV2Section2QualifiedWithdrawFundsOther",
      "transferV2Section2QualifiedFundsTransfered",
    ]

    let nonQualifiedArray = [
      "transferV2Section2NonQualified1035Exchange",
      "transferV2Section2NonQualified1035ExchangeOtherPercentage",
      "transferV2Section2NonQualified1035ExchangeOtherAmount",
    ]
    if (rolloverTransferExchange?.transferV2Section2Plan == "Qualified Plans") {
      for (let i = 0; i < nonQualifiedArray.length; i++) {
        CommonContextUpdate(nonQualifiedArray[i], "")

      }

    } else if (rolloverTransferExchange?.transferV2Section2Plan == "Non-Qualified Plans") {
      for (let i = 0; i < qualifiedArray.length; i++) {
        CommonContextUpdate(qualifiedArray[i], "")

      }
    }
  }, [rolloverTransferExchange.transferV2Section2Plan])


  return (
    <Fragment>
      <Box className="transfer_exchange_request_page_root">
        <Box className="application_form">
          <Box className="application_form_card">
            <Box className="application_form_head">
              <Box>
                <Typography variant="span">
                  Transfer or Exchange Request
                </Typography>
              </Box>
              <Typography
                variant="text"
                className="btn_want"
                onClick={() => {
                  setFlagFill(!flagFill);
                  updateTransferOrExchangeRequestFlag(!flagFill);
                }}
              >
                {flagFill === false
                  ? "Fill this form"
                  : "Do not fill this form"}
              </Typography>
              <Box className="application_form_head_bottom_line">
                <Divider />
              </Box>
            </Box>
            <Box
              className="section_head_root"
              onClick={() => {
                // debugger;
                // setFlagFirstSection(!flagThirdPageFirstSection)
                SetApplicationFormFlagSectionData({
                  type: "Transfer or Exchange Request",
                  section: "flagFourthPageFirstSection",
                  data: !flagFourthPageFirstSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">1. Existing Contract or Account Information</Typography>
              </Box>
              <Box className="section_open_close">
                {flagFourthPageFirstSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFourthPageFirstSection === true ? (
              <Box className="basic_information" style={{ width: "80%" }}>
                <TextField
                  type="text"
                  disabled={disableAllInputs === true || flagFill === false ? true : false}
                  variant="standard"
                  label="Name of transferring company"
                  className="Owner_Full_Name req_fields"
                  value={rolloverTransferExchange.transferV2Section1CompanyName}
                  onChange={(e) => {
                    CommonContextUpdate("transferV2Section1CompanyName", e.target.value);
                  }}
                  style={{ width: "48%", margin: "10px 1%" }}
                />
                <TextField
                  type="tel"
                  variant="standard"
                  label="Phone"
                  error={transferExchangeValidationError?.flagPhoneValid ? true : false}
                  helperText={transferExchangeValidationError?.flagPhoneValid ? "Invalid phone number" : ""}
                  onBlur={() => {
                    let temp = { ...transferExchangeValidationError }
                    if (rolloverTransferExchange.transferV2Section1CompanyPhone?.length) {
                      temp.flagPhoneValid = !(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(rolloverTransferExchange.transferV2Section1CompanyPhone))
                      setTransferExchangeValidationError(temp)
                    } else {
                      temp.flagPhoneValid = false
                      setTransferExchangeValidationError(temp)
                    }
                  }}
                  className="Phone req_fields"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={rolloverTransferExchange.transferV2Section1CompanyPhone}
                  onChange={(e) => {
                    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                    if (onlyNums.length && onlyNums.length < 10) {
                      let temp = { ...transferExchangeValidationError }
                      temp.flagPhoneValid = true
                      setTransferExchangeValidationError(temp)
                    }
                    if (onlyNums.length < 10) {
                      CommonContextUpdate("transferV2Section1CompanyPhone", onlyNums);
                    } else if (onlyNums.length === 10) {
                      let temp = { ...transferExchangeValidationError }
                      temp.flagPhoneValid = false
                      setTransferExchangeValidationError(temp)
                      const number = onlyNums.replace(
                        /(\d{3})(\d{3})(\d{4})/,
                        "($1) $2-$3"
                      );
                      CommonContextUpdate("transferV2Section1CompanyPhone", number);
                    }
                  }}
                  style={{ width: "48%", margin: "10px 1%" }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Policy Number"
                  className="Street_Address req_fields"
                  value={rolloverTransferExchange.transferV2Section1CompanyPolicyNumber}
                  disabled={disableAllInputs === true || flagFill === false ? true : false
                  }
                  onChange={(e) => {
                    CommonContextUpdate("transferV2Section1CompanyPolicyNumber", e.target.value);
                  }}
                  style={{ width: "48%", margin: "10px 1%" }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Street Address"
                  className="Street_Address req_fields"
                  value={rolloverTransferExchange.transferV2Section1CompanyAddress}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    CommonContextUpdate("transferV2Section1CompanyAddress", e.target.value);
                  }}
                  style={{ width: "48%", margin: "10px 1%" }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="City"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={rolloverTransferExchange.transferV2Section1CompanyCity}
                  onChange={(e) => {
                    CommonContextUpdate("transferV2Section1CompanyCity", e.target.value);
                  }}
                  style={{ width: "48%", margin: "10px 1%" }}
                  className="req_fields"
                />
                <FormControl
                  variant="standard"
                  style={{ width: "48%", margin: "10px 1%" }}
                >
                  <InputLabel id="demo-simple-select-label" disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }>
                    State
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </InputLabel>
                  <Select
                    className="state_dropdown"
                    onChange={(e) => {
                      console.log(e.target.value);
                      CommonContextUpdate("transferV2Section1CompanyState", e.target.value);
                    }}
                    value={rolloverTransferExchange.transferV2Section1CompanyState}
                    disabled={disableAllInputs === true || flagFill === false
                      ? true : false
                    }
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {StatesName.map((stateName) => {
                      return <MenuItem value={stateName}>{stateName}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <TextField
                  type="number"
                  variant="standard"
                  error={transferExchangeValidationError?.flagZipValid ? true : false}
                  helperText={transferExchangeValidationError?.flagZipValid ? "ZIP number only contains 5 digits" : ""}
                  onBlur={() => {
                    let temp = { ...transferExchangeValidationError }
                    if (rolloverTransferExchange.transferV2Section1CompanyZip?.length) {
                      temp.flagZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(rolloverTransferExchange.transferV2Section1CompanyZip))
                      setTransferExchangeValidationError(temp)
                    } else {
                      temp.flagZipValid = false
                      setTransferExchangeValidationError(temp)
                    }
                  }}
                  label="ZIP"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={rolloverTransferExchange.transferV2Section1CompanyZip}
                  onChange={(e) => {
                    let temp = { ...transferExchangeValidationError }
                    if (e.target.value?.length) {
                      temp.flagZipValid = !(/^[0-9]{5}(?:-[0-9]{4})?$/.test(e.target.value))
                      setTransferExchangeValidationError(temp)
                    } else {
                      temp.flagZipValid = false
                      setTransferExchangeValidationError(temp)
                    }
                    CommonContextUpdate("transferV2Section1CompanyZip", e.target.value);
                  }}
                  style={{ width: "48%", margin: "10px 1%" }}
                  className="req_fields"
                />
                <br />
                <TextField
                  type="text"
                  variant="standard"
                  label="Name of owner"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={rolloverTransferExchange.transferV2Section1OwnerName}
                  onChange={(e) => {
                    CommonContextUpdate("transferV2Section1OwnerName", e.target.value);
                  }}
                  style={{ width: "58%", margin: "10px 1%" }}
                  className={requiredFields.owner ? "" : "not_required"}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Tax Id or SSN"
                  className={requiredFields.owner ? "Owner_Tax_Id_or_SSN" : "Owner_Tax_Id_or_SSN not_required"}
                  value={rolloverTransferExchange.transferV2Section1OwnerSsn}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onChange={(e) => { onChangeSSN("transferV2Section1OwnerSsn", e) }}
                  onBlur={(e) => { onBlurSSN("transferV2Section1OwnerSsn", e) }}
                  style={{ width: "38%", margin: "10px 1%" }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Name of joint owner"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={rolloverTransferExchange.transferV2Section1JoinOwnerName}
                  onChange={(e) => {
                    CommonContextUpdate("transferV2Section1JoinOwnerName", e.target.value);
                  }}
                  style={{ width: "58%", margin: "10px 1%" }}
                  className={requiredFields.jointOwner ? "" : "not_required"}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Tax Id or SSN"
                  className={requiredFields.jointOwner ? "Owner_Tax_Id_or_SSN" : "Owner_Tax_Id_or_SSN not_required"}
                  value={rolloverTransferExchange.transferV2Section1JoinOwnerSsn}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onChange={(e) => { onChangeSSN("transferV2Section1JoinOwnerSsn", e) }}
                  onBlur={(e) => { onBlurSSN("transferV2Section1JoinOwnerSsn", e) }}
                  style={{ width: "38%", margin: "10px 1%" }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Name of annuitant"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={rolloverTransferExchange.transferV2Section1AnnuitantName}
                  onChange={(e) => {
                    CommonContextUpdate("transferV2Section1AnnuitantName", e.target.value);
                  }}
                  style={{ width: "58%", margin: "10px 1%" }}
                  className={requiredFields.annuitant ? "" : "not_required"}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Tax Id or SSN"
                  className={requiredFields.annuitant ? "Owner_Tax_Id_or_SSN" : "Owner_Tax_Id_or_SSN not_required"}
                  value={rolloverTransferExchange.transferV2Section1AnnuitantSsn}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onChange={(e) => { onChangeSSN("transferV2Section1AnnuitantSsn", e) }}
                  onBlur={(e) => { onBlurSSN("transferV2Section1AnnuitantSsn", e) }}
                  style={{ width: "38%", margin: "10px 1%" }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Name of joint annuitant"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={rolloverTransferExchange.transferV2Section1JoinAnnuitantName}
                  onChange={(e) => {
                    CommonContextUpdate("transferV2Section1JoinAnnuitantName", e.target.value);
                  }}
                  style={{ width: "58%", margin: "10px 1%" }}
                  className={requiredFields.jointAnnuitant ? "" : "not_required"}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Tax Id or SSN"
                  className={requiredFields.jointAnnuitant ? "Owner_Tax_Id_or_SSN" : "Owner_Tax_Id_or_SSN not_required"}
                  value={rolloverTransferExchange.transferV2Section1JoinAnnuitantSsn}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onChange={(e) => { onChangeSSN("transferV2Section1JoinAnnuitantSsn", e) }}
                  onBlur={(e) => { onBlurSSN("transferV2Section1JoinAnnuitantSsn", e) }}
                  style={{ width: "38%", margin: "10px 1%" }}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Name of beneficiary"
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  value={rolloverTransferExchange.transferV2Section1BeneficiaryName}
                  onChange={(e) => {
                    CommonContextUpdate("transferV2Section1BeneficiaryName", e.target.value);
                  }}
                  style={{ width: "58%", margin: "10px 1%" }}
                  className={requiredFields.beneficiary ? "" : "not_required"}
                />
                <TextField
                  type="text"
                  variant="standard"
                  label="Tax Id or SSN"
                  className={requiredFields.beneficiary ? "Owner_Tax_Id_or_SSN" : "Owner_Tax_Id_or_SSN not_required"}
                  value={rolloverTransferExchange.transferV2Section1BeneficiarySsn}
                  disabled={
                    disableAllInputs === true || flagFill === false
                      ? true
                      : false
                  }
                  onChange={(e) => { onChangeSSN("transferV2Section1BeneficiarySsn", e) }}
                  onBlur={(e) => { onBlurSSN("transferV2Section1BeneficiarySsn", e) }}
                  style={{ width: "38%", margin: "10px 1%" }}
                />
                <Box className="type_of_account" style={{ margin: "15px 0" }}>
                  <Typography variant="span">
                    Current Investment Vehicle:<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RadioGroup
                    name="type_of_account_transfer"
                    className="radio_group_shift_right"
                    value={rolloverTransferExchange.transferV2Section1InvestmentVehicle}
                    onClick={(e) => {
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate("transferV2Section1InvestmentVehicleOther", "");
                        CommonContextUpdate("transferV2Section1InvestmentVehicle", "");
                        return;
                      }
                      if (e.target.value != rolloverTransferExchange.transferV2Section1InvestmentVehicle) {
                        if (e.target.value !== "other" && rolloverTransferExchange.transferV2Section1InvestmentVehicleOther !== "") {
                          CommonContextUpdate("transferV2Section1InvestmentVehicleOther", "");
                        }
                        CommonContextUpdate("transferV2Section1InvestmentVehicle", e.target.value);
                      } else {
                        CommonContextUpdate("transferV2Section1InvestmentVehicleOther", "");
                        CommonContextUpdate("transferV2Section1InvestmentVehicle", "");
                      }
                    }}
                    row={true}
                  >
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"certificate of deposit"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Certificate of Deposit"
                    />
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"mutual fund"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Mutual Fund"
                    />
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"annuity"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Annuity (Qualified only)"
                    />
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"other"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Other"
                    />
                    <TextField
                      variant="standard"
                      type="text"
                      value={rolloverTransferExchange.transferV2Section1InvestmentVehicleOther}
                      className={rolloverTransferExchange.transferV2Section1InvestmentVehicle !== "other" ? "" : "re_fields"}
                      placeholder={rolloverTransferExchange.transferV2Section1InvestmentVehicle !== "other"
                        ? ""
                        : "This field is required"
                      }
                      disabled={
                        rolloverTransferExchange.transferV2Section1InvestmentVehicle !== "other"
                          || disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      onChange={(e) => { CommonContextUpdate("transferV2Section1InvestmentVehicleOther", e.target.value) }}
                    />
                  </RadioGroup>
                </Box>
                <Box className="existing_annuity_status">
                  <Typography variant="span">
                    My existing annuity/life contract/certificate is:<span style={{ color: "red" }}>*</span>
                  </Typography>
                  <RadioGroup
                    name="plan_applied_for"
                    className="radio_group_shift_right"
                    value={rolloverTransferExchange.transferV2Section1ExistingCertificate}
                    onClick={(e) => {
                      if (!e.target.checked) {
                        return;
                      }
                      if (!e.target.value) {
                        CommonContextUpdate("transferV2Section1ExistingCertificate", "");
                        return;
                      }
                      if (e.target.value != rolloverTransferExchange.transferV2Section1ExistingCertificate) {
                        if (e.target.value !== "other" && rolloverTransferExchange.transferV2Section1ExistingCertificate !== "") {
                          CommonContextUpdate("transferV2Section1ExistingCertificate", "");
                        }
                        CommonContextUpdate("transferV2Section1ExistingCertificate", e.target.value);
                      } else {
                        CommonContextUpdate("transferV2Section1ExistingCertificate", "");
                      }
                    }}
                    row={true}
                  >
                    <FormControlLabel
                      disabled={disableAllInputs === true || flagFill === false ? true : false}
                      control={
                        <Radio
                          value={"enclosed"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Enclosed"
                    />
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"not required transaction"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Not required to process this transaction"
                    />
                    <FormControlLabel
                      disabled={
                        disableAllInputs === true || flagFill === false
                          ? true
                          : false
                      }
                      control={
                        <Radio
                          value={"lost or destroyed"}
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                        />
                      }
                      label="Lost or Destroyed"
                    />
                  </RadioGroup>
                </Box>
              </Box>
            ) : null}

            <Box
              className="section_head_root"
              onClick={() => {
                SetApplicationFormFlagSectionData({
                  type: "Transfer or Exchange Request",
                  section: "flagFourthPageSecondSection",
                  data: !flagFourthPageSecondSection,
                });
              }}
            >
              <Box className="section_open_close">
                <Typography variant="span">2. Plans</Typography>
              </Box>
              <Box className="section_open_close">
                {flagFourthPageSecondSection === true ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Box>
            </Box>
            {flagFourthPageSecondSection === true ? (
              <Box className="plans_transfer_request" style={{ width: "80%" }}>
                <FormControl fullWidth>
                  <Autocomplete
                    options={["Non-Qualified Plans", "Qualified Plans"]}
                    disabled={
                      disableAllInputs === true || flagFill === false
                        ? true
                        : false
                    }
                    value={rolloverTransferExchange?.transferV2Section2Plan}
                    onChange={(e, value, reason) => {
                      CommonContextUpdate("transferV2Section2Plan", value === null ? "" : value);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          variant="standard"
                          label="Select Plan"
                          className="req_fields"
                          {...params}
                        />
                      );
                    }}
                  />
                </FormControl>
                {/* Non-Qualified plans details */}
                {rolloverTransferExchange.transferV2Section2Plan === "Non-Qualified Plans" && (
                  <Box
                    className="non_qualified_plans"
                    style={{ margin: "10px 0px 5px 0px" }}
                  >
                    <Box
                      className="internal_exchange_of_existing_farmers_annuity"
                      style={{ margin: "10px 0" }}
                    >
                      <Box className="question_a">
                        <Typography variant="span">
                          A. 1035 Exchange <span style={{ color: "red" }}>*</span>
                        </Typography>
                        <RadioGroup
                          name="question_a"
                          className="radio_group_shift_right"
                          value={rolloverTransferExchange.transferV2Section2NonQualified1035Exchange}
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate("transferV2Section2NonQualified1035Exchange", "");
                              return;
                            }
                            if (
                              e.target.value != rolloverTransferExchange.transferV2Section2NonQualified1035Exchange
                            ) {
                              if (e.target.value == "complete exchange") {
                                CommonContextUpdate("transferV2Section2NonQualified1035ExchangeOtherAmount", "");
                                CommonContextUpdate("transferV2Section2NonQualified1035ExchangeOtherPercentage", "");
                              }
                              CommonContextUpdate("transferV2Section2NonQualified1035Exchange", e.target.value);
                            } else {
                              CommonContextUpdate("transferV2Section2NonQualified1035ExchangeOtherAmount", "");
                              CommonContextUpdate("transferV2Section2NonQualified1035ExchangeOtherPercentage", "");
                              CommonContextUpdate("transferV2Section2NonQualified1035Exchange", "");
                            }
                          }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true || flagFill === false ? true : false}
                            control={
                              <Radio
                                value={"complete exchange"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Complete Exchange"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true || flagFill === false ? true : false}
                            control={
                              <Radio
                                value={"partial exchange"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Partial Exchange"
                          />
                          {rolloverTransferExchange.transferV2Section2NonQualified1035Exchange == "partial exchange" &&
                            <>
                              <Input
                                variant="standard"
                                type="text"
                                value={rolloverTransferExchange.transferV2Section2NonQualified1035ExchangeOtherAmount?.replace(
                                  /\B(?=(\d{3})+(?!\d))/g, ",")}
                                disabled={disableAllInputs === true || flagFill === false ||
                                  rolloverTransferExchange.transferV2Section2NonQualified1035Exchange === "complete exchange"
                                  || rolloverTransferExchange.transferV2Section2NonQualified1035Exchange === ""
                                  ? true
                                  : false
                                }
                                onChange={(e) => {
                                  if (isNaN(e.target.value.replaceAll(",", "")) === false)
                                    CommonContextUpdate("transferV2Section2NonQualified1035ExchangeOtherAmount", e.target.value.replaceAll(",", ""));
                                  CommonContextUpdate("transferV2Section2NonQualified1035ExchangeOtherPercentage", "");
                                }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    $
                                  </InputAdornment>
                                }
                                sx={{ width: "100px", marginRight: "20px", marginLeft: "10px" }}
                              />

                              <Typography
                                style={{ margin: "12px 15px 0 -5px" }}
                                variant="span"
                                className="non_qualified_or"
                              >
                                OR
                              </Typography>

                              <Input
                                variant="standard"
                                type="text"
                                value={rolloverTransferExchange.transferV2Section2NonQualified1035ExchangeOtherPercentage}
                                disabled={disableAllInputs === true || flagFill === false ||
                                  rolloverTransferExchange.transferV2Section2NonQualified1035Exchange === "complete exchange"
                                  || rolloverTransferExchange.transferV2Section2NonQualified1035Exchange === ""
                                  ? true
                                  : false
                                }
                                onChange={(e) => {
                                  if (Number(e.target.value) > 100) {
                                    return
                                  }
                                  CommonContextUpdate("transferV2Section2NonQualified1035ExchangeOtherAmount", "");
                                  CommonContextUpdate("transferV2Section2NonQualified1035ExchangeOtherPercentage", e.target.value);
                                }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                }
                                sx={{ width: "100px" }}
                              />
                            </>
                          }

                        </RadioGroup>
                      </Box>
                    </Box>
                  </Box>
                )}

                {/* Qualified Plans Details */}
                {rolloverTransferExchange.transferV2Section2Plan === "Qualified Plans" && (
                  <Box className="qualified_plans" style={{ margin: "10px 0" }}>

                    <Box className="question_b" style={{ margin: "10px 0" }}>
                      <Typography variant="span">
                        B. Transfer/Rollover<span style={{ color: "red" }}>*</span>
                      </Typography>

                      <RadioGroup
                        name="question_a"
                        className="radio_group_shift_right"
                        value={rolloverTransferExchange.transferV2Section2QualifiedAgeRmdTransfer}
                        onClick={(e) => {
                          if (!e.target.checked) {
                            return;
                          }
                          if (!e.target.value) {
                            CommonContextUpdate("transferV2Section2QualifiedAgeRmdTransfer", "");
                            return;
                          }
                          if (e.target.value != rolloverTransferExchange.transferV2Section2QualifiedAgeRmdTransfer) {
                            CommonContextUpdate("transferV2Section2QualifiedAgeRmdTransfer", e.target.value);
                          } else {
                            CommonContextUpdate("transferV2Section2QualifiedAgeRmdTransfer", "");
                          }
                        }}
                        row={true}
                      >
                        <FormControlLabel
                          disabled={disableAllInputs === true || flagFill === false ? true : false}
                          control={
                            <Radio
                              value={"yes"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="If you are of required age, please check the box if you have processed your RMD prior to this transfer"
                        />
                      </RadioGroup>
                      <Box className="question_b" style={{ margin: "10px 0" }}>
                        <Typography variant="span">
                          Type of account funds coming from:<span style={{ color: "red" }}>*</span>
                        </Typography>
                        <RadioGroup
                          name="question_b"
                          className="radio_group_shift_right"
                          value={
                            rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount
                          }
                          onClick={(e) => {
                            if (!e.target.checked) {
                              return;
                            }
                            if (!e.target.value) {
                              CommonContextUpdate("transferV2Section2QualifiedTypeOfAccount", "");
                              return;
                            }
                            if (e.target.value != rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount) {
                              if (e.target.value != "other") {
                                CommonContextUpdate("transferV2Section2QualifiedTypeOfAccountOther", "")
                              }
                              CommonContextUpdate("transferV2Section2QualifiedTypeOfAccount", e.target.value);
                            } else {
                              CommonContextUpdate("transferV2Section2QualifiedTypeOfAccount", "");
                              CommonContextUpdate("transferV2Section2QualifiedTypeOfAccountOther", "")
                            }
                          }}
                          row={true}
                        >
                          <FormControlLabel
                            disabled={disableAllInputs === true || flagFill === false ? true : false}
                            control={
                              <Radio
                                value={"tsa 403b"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="TSA 403(b)"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true || flagFill === false ? true : false}
                            control={
                              <Radio
                                value={"ira"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Traditional IRA"
                          />
                          <FormControlLabel
                            disabled={disableAllInputs === true || flagFill === false ? true : false}
                            control={
                              <Radio
                                value={"roth ira"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Roth IRA"
                          />
                          <FormControlLabel
                            disabled={
                              disableAllInputs === true || flagFill === false
                                ? true
                                : false
                            }
                            control={
                              <Radio
                                value={"other"}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checkedIcon={<CheckBoxIcon />}
                              />
                            }
                            label="Other"
                          />
                          <TextField
                            variant="standard"
                            type="text"
                            value={rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccountOther}
                            className={
                              rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount !== "other" ? ""
                                : "re_fields"
                            }
                            placeholder={
                              rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount !== "other" ? ""
                                : "This field is required"
                            }
                            disabled={
                              rolloverTransferExchange.transferV2Section2QualifiedTypeOfAccount !== "other" || disableAllInputs === true
                                ? true
                                : false
                            }
                            onChange={(e) => { CommonContextUpdate("transferV2Section2QualifiedTypeOfAccountOther", e.target.value) }}
                          />
                        </RadioGroup>
                      </Box>
                    </Box>

                    <Box
                      className="all_or_partial"
                      style={{
                        margin: "0px 0px 0px 0px",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <RadioGroup
                        name="all_partial"
                        className="radio_group_shift_right"
                        value={
                          rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer
                        }
                        onClick={(e) => {
                          if (!e.target.checked) {
                            return;
                          }
                          if (!e.target.value) {
                            CommonContextUpdate("transferV2Section2QualifiedLiquidateTransfer", "");
                            return;
                          }
                          if (e.target.value != rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer) {
                            if (e.target.value == "all") {
                              CommonContextUpdate("transferV2Section2QualifiedLiquidateTransferAmount", "")
                              CommonContextUpdate("transferV2Section2QualifiedLiquidateTransferPercentage", "")
                            }
                            CommonContextUpdate("transferV2Section2QualifiedLiquidateTransfer", e.target.value);
                          } else {
                            CommonContextUpdate("transferV2Section2QualifiedLiquidateTransfer", "");
                            CommonContextUpdate("transferV2Section2QualifiedLiquidateTransferAmount", "")
                            CommonContextUpdate("transferV2Section2QualifiedLiquidateTransferPercentage", "")
                          }
                        }}
                        row={true}
                      >
                        <FormControlLabel
                          disabled={disableAllInputs === true || flagFill === false ? true : false}
                          control={
                            <Radio
                              value={"all"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="All"
                        />
                        <Typography
                          style={{ margin: "12px 15px 0 -5px" }}
                          variant="span"
                          className="non_qualified_or"
                        >
                          OR
                        </Typography>
                        <FormControlLabel
                          disabled={disableAllInputs === true || flagFill === false ? true : false}
                          control={
                            <Radio
                              value={"partial"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Partial"
                        />
                        {rolloverTransferExchange?.transferV2Section2QualifiedLiquidateTransfer == "partial" &&
                          <>
                            <Input
                              variant="standard"
                              type="text"
                              value={rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferAmount.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              disabled={disableAllInputs === true || flagFill === false ||
                                rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer !== "partial"
                                ? true
                                : false
                              }
                              onChange={(e) => {
                                if (isNaN(e.target.value.replaceAll(",", "")) === false)
                                  CommonContextUpdate("transferV2Section2QualifiedLiquidateTransferAmount", e.target.value.replaceAll(",", ""));
                                CommonContextUpdate("transferV2Section2QualifiedLiquidateTransferPercentage", "")
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              }
                              style={{ width: "100px", marginRight: "10px" }}
                            />
                            <Typography
                              style={{ margin: "12px 15px 0 -5px" }}
                              variant="span"
                              className="non_qualified_or"
                            >
                              OR
                            </Typography>
                            <Input
                              variant="standard"
                              type="text"
                              value={rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransferPercentage}
                              disabled={disableAllInputs === true || flagFill === false ||
                                rolloverTransferExchange.transferV2Section2QualifiedLiquidateTransfer !== "partial"
                                ? true
                                : false
                              }
                              onChange={(e) => {
                                if (e.target.value > 100) {
                                  return;
                                }
                                CommonContextUpdate("transferV2Section2QualifiedLiquidateTransferPercentage", e.target.value);
                                CommonContextUpdate("transferV2Section2QualifiedLiquidateTransferAmount", "");
                              }}
                              startAdornment={
                                <InputAdornment position="start">
                                  %
                                </InputAdornment>
                              }
                              style={{ width: "100px" }}
                            />
                          </>
                        }
                      </RadioGroup>
                    </Box>

                    <Box className="question_b" style={{ margin: "10px 0" }}>
                      <Typography variant="span">
                        Please withdraw such funds:<span style={{ color: "red" }}>*</span>
                      </Typography>
                      <RadioGroup
                        name="all_partial"
                        className="radio_group_shift_right"
                        value={
                          rolloverTransferExchange.transferV2Section2QualifiedWithdrawFunds
                        }
                        onClick={(e) => {
                          if (!e.target.checked) {
                            return;
                          }
                          if (!e.target.value) {
                            CommonContextUpdate("transferV2Section2QualifiedWithdrawFunds", "");
                            return;
                          }
                          if (e.target.value != rolloverTransferExchange.transferV2Section2QualifiedWithdrawFunds) {
                            if (e.target.value === "immediately") {
                              CommonContextUpdate("transferV2Section2QualifiedWithdrawFundsOther", "");
                            }
                            CommonContextUpdate("transferV2Section2QualifiedWithdrawFunds", e.target.value);
                          } else {
                            CommonContextUpdate("transferV2Section2QualifiedWithdrawFunds", "");
                            CommonContextUpdate("transferV2Section2QualifiedWithdrawFundsOther", "");
                          }
                        }}
                        row={true}
                      >
                        <FormControlLabel
                          disabled={disableAllInputs === true || flagFill === false ? true : false}
                          control={
                            <Radio
                              value={"immediately"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Immediately"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true || flagFill === false ? true : false}
                          control={
                            <Radio
                              value={"maturity date"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Upon the maturity date of"
                        />
                        <Input
                          variant="standard"
                          type="text"
                          value={rolloverTransferExchange.transferV2Section2QualifiedWithdrawFundsOther}
                          disabled={
                            disableAllInputs === true || flagFill === false ||
                              rolloverTransferExchange.transferV2Section2QualifiedWithdrawFunds !== "maturity date"
                              ? true
                              : false
                          }
                          onChange={(e) => {
                            CommonContextUpdate("transferV2Section2QualifiedWithdrawFundsOther", e.target.value);
                          }}
                        />
                      </RadioGroup>
                    </Box>
                    <Box className="immediately_maturity_date">
                      <Typography variant="span">
                        Funds are to be transferred to a:
                      </Typography>
                      <RadioGroup
                        name="all_partial"
                        className="radio_group_shift_right"
                        value={rolloverTransferExchange.transferV2Section2QualifiedFundsTransfered}
                        onClick={(e) => {
                          if (!e.target.checked) {
                            return;
                          }
                          if (!e.target.value) {
                            CommonContextUpdate("transferV2Section2QualifiedFundsTransfered", "");
                            return;
                          }
                          if (e.target.value != rolloverTransferExchange.transferV2Section2QualifiedFundsTransfered) {
                            CommonContextUpdate("transferV2Section2QualifiedFundsTransfered", e.target.value);
                          } else {
                            CommonContextUpdate("transferV2Section2QualifiedFundsTransfered", "");
                          }
                        }}
                        row={true}
                      >
                        <FormControlLabel
                          disabled={disableAllInputs === true || flagFill === false ? true : false}
                          control={
                            <Radio
                              value={"ira"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Traditional IRA"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true || flagFill === false ? true : false}
                          control={
                            <Radio
                              value={"roth ira"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Roth IRA"
                        />
                        <FormControlLabel
                          disabled={disableAllInputs === true || flagFill === false ? true : false}
                          control={
                            <Radio
                              value={"non qualified annuity"}
                              icon={<CheckBoxOutlineBlankIcon />}
                              checkedIcon={<CheckBoxIcon />}
                            />
                          }
                          label="Non- Qualified Annuity"
                        />
                      </RadioGroup>
                    </Box>
                  </Box>
                )}
              </Box>
            ) : null}

            <Box
              className="previous_next_btn"
              style={{
                margin: "25px 0",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                startIcon={<KeyboardDoubleArrowLeftIcon />}
                className="btn_prev"
                onClick={() => {
                  props.previousPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Previous
              </Button>
              <Button
                endIcon={<DoubleArrowIcon />}
                className="btn_next"
                onClick={() => {
                  props.nextPage();
                }}
                style={{
                  background: "#074a6e",
                  color: "white",
                  textTransform: "capitalize",
                  margin: "0 10px",
                }}
              >
                Next - Safeguard Plus® Annuity Disclosure
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default RolloverTransferExchangeRequest;
