import Axios from "./axios";

const RegisterUserAPI = async (data, onSuccess, onError) => {
    try {
        console.log("Printing data params", data);
        const response = await Axios.post("/api/user/register", data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}

const LoginUserAPI = async (data, onSuccess, onError) => {
    try {
        console.log("Printing params data", data);
        const defaultHeaders = {
            "Content-Type": "application/json"
        };
        const response = await Axios.post("/api/user/login", data, {
            headers: defaultHeaders,
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
};

const UserDetailAPI = async (onSuccess, onError) => {
    try {
        // console.log("Printing params data", data);
        const response = await Axios.get("/api/user/me", {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        console.log("Printing params data res", response);
        onSuccess && onSuccess(response);
    }
    catch (err) {
        console.log("got an error in UserDetailAPI", err);
        onError && onError(err);
    }
};

export { LoginUserAPI, RegisterUserAPI, UserDetailAPI };