import React from "react";
import styled from "@emotion/styled";
import Group from "./Group";
import { Droppable, Draggable } from "react-beautiful-dnd";
import Resource from "./Resource";
import { Grid } from "@mui/material";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

const Container = styled("div")`
  margin: 8px;
  border-radius: 2px;
  border: 1px solid #d9a22e;
  display: flex;
  flex-direction: column;
  width: 340px;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;
const Title = styled("h4")`
  padding-bottom: 4px;
  ${'' /* background-color:#d9a22e; */}
  color:white;
`;

const GroupList = styled("div")`
  padding: 8px;
  flex-grow: 1;
  min-height: 100px;
  transition: background-color ease 0.2s;
  background-color: ${props =>
        props.isDraggingOver ? "#55A3FB" : "white"};
`;

const Category = ({ category, index, dragItem, setDraggedItemDetails, MoveResourceOut, resourceListData, MoveResourceInGroup }) => {
    return (
        // <Draggable key={category?.pdf_category_id} draggableId={"category-drag-" + category?.pdf_category_id?.toString()} index={index} type="category">
        <Draggable
            key={category?.pdf_category_id}
            draggableId={"category-drag-" + category?.pdf_category_id?.toString() + "-" + category?.pdf_category_seq_num?.toString()}
            index={index}
            type="category"
        >
            {provided => (
                <Container
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Grid container sx={{ paddingLeft: "8px", backgroundColor: "#d9a22e" }} alignItems="center">
                        <Grid item><DragIndicatorIcon /></Grid>
                        <Grid item><Title>{category?.pdf_category_name}</Title></Grid>
                    </Grid>

                    {/* <Title>{category?.pdf_category_name}</Title> */}
                    <Droppable droppableId={"resource-drop-" + category?.pdf_category_id?.toString()} type="resource">
                        {(provided, snapshot) => (
                            <>
                                <GroupList
                                    isDraggingOver={snapshot.isDraggingOver}
                                    ref={provided.innerRef}
                                    id={index}
                                    {...provided.droppableProps}
                                >
                                    {category?.pdf_category_resource_data?.length ?
                                        category?.pdf_category_resource_data.map((group, groupIndex) => (
                                            group?.pdf_resource_flag
                                                ?
                                                <Resource
                                                    key={group?.resource_sub_category_id}
                                                    pdfCategoryId={category?.pdf_category_id?.toString()}
                                                    PdfGroupCategoryId={"null"}
                                                    resourceDetails={group}
                                                    id={groupIndex}
                                                    index={groupIndex}
                                                    setDraggedItemDetails={setDraggedItemDetails}
                                                    MoveResourceOut={MoveResourceOut}
                                                    MoveResourceInGroup={MoveResourceInGroup}
                                                    resourceListData={resourceListData}

                                                />
                                                :
                                                <Group
                                                    key={group}
                                                    GroupRow={group}
                                                    pdfCategoryId={category?.pdf_category_id?.toString()}
                                                    id={groupIndex}
                                                    index={groupIndex}
                                                    dragItem={dragItem}
                                                    setDraggedItemDetails={setDraggedItemDetails}
                                                    MoveResourceOut={MoveResourceOut}
                                                    MoveResourceInGroup={MoveResourceInGroup}
                                                    resourceListData={resourceListData}

                                                />

                                        ))
                                        : ""
                                    }
                                    {provided.placeholder}
                                </GroupList>
                            </>
                        )}
                    </Droppable>
                </Container>
            )}
        </Draggable>
    );
};

export default Category;
