import createDataContext from './createDataContext';

const UserLoaderReducer = (state, action)=>{
    // console.log("@@@@@@@",userType)
    switch(action.type){
        case "changeUser": {
            return {...state, user: action.payload}
        }

        case "changeFlagLoader": {
            return {...state, flagLoader: action.payload}
        }

        case "changeUserType": {
            return {...state, userType: action.payload}
        }

        default:{
            return state;
        }
    }
};

const SetUser = dispatch => (data)=>{
    console.log("Printing data for setUser", data);
    dispatch({
        type: "changeUser",
        payload: data
    })
};

const SetFlagLoader = dispatch => (data)=>{
    console.log("Pritning data for loader", data);
    console.log("Updating value of loader", data)
    dispatch({
        type: "changeFlagLoader",
        payload: data
    })
};

const SetUserType = dispatch => (data)=>{
    console.log("Printing user type before updating it", data);
    // debugger;
    dispatch({
        type: "changeUserType",
        payload: data
    })
}

export const { Context, Provider } = createDataContext(
    UserLoaderReducer,
    { SetUser, SetFlagLoader, SetUserType },
    { user: {}, flagLoader: false, userType: "" }
)