import axios from "../axios";

const SubmitSaveDraftIaaApplication = async (data, onSuccess, onError) => {
    try {
        const response = await axios.post("/api/application_v2/add-new-iaa-application", data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}
const GetIaaAppListAPI = async (onSuccess, onError) => {
    try {
        const response = await axios.get("/api/application_v2/get-iaa-application-list", {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}
const GetSingleIaaDataAPI = async (data, onSuccess, onError) => {
    try {
        const response = await axios.get("/api/application_v2/get-iaa-application-data?applications_id=" + data.applicationId + "&flag_new_application=" + data.flag, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}
const UpdateUserPhone = async (data, onSuccess, onError) => {
    try {
        const response = await axios.put("/api/user/update-user-phone-number", data, {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
        onSuccess && onSuccess(response);
    }
    catch (err) {
        onError && onError(err);
    }
}


export { SubmitSaveDraftIaaApplication, GetSingleIaaDataAPI, GetIaaAppListAPI, UpdateUserPhone }