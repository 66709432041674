import { Dialog, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import "./style.css";

const SomethingWentWrongDialog = ()=>{
    const [open, setOpen] = useState(true);
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={()=>{
                    setOpen(false);
                }}
            >
                <Typography>Something Went Wrong. Please try again later.!</Typography>
                <Button onClick={()=>{setOpen(false)}}>Ok</Button>
            </Dialog>
        </React.Fragment>
    )
};

export default SomethingWentWrongDialog;