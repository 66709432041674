import React, { useContext, useEffect, useState } from 'react';
import { Box, Button, FormControl, Grid, InputLabel, Menu, MenuItem, MenuList, Select, Typography } from '@mui/material';
import './uploadTool.css';
import FinancialRating from '../../assets/financial_rating.png';
import { Footer } from '../../components/footer/footer';
import { Context } from '../../context/userLoaderContext';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const UploadTool = () => {

    const { SetFlagLoader } = useContext(Context);
    const [activeTab, setActiveTab] = useState(1)
    const [flagOpenMenu, setFlagOpenMenu] = useState(false)
    const [selectedForm, setSelectedForm] = useState("")
    useEffect(() => {
        SetFlagLoader(true)
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            <Box className="upload_tool_page">
                <Box className="page_title_register page_title">
                    <Typography variant="span">Upload Tool</Typography>
                </Box>
                <Grid container justifyContent={{ xs: "center", md: "flex-start" }} mt={4}>
                    <Grid item xs={12} sm={10} md={1} lg={1.8} xl={2.3}></Grid>
                    <Grid item xs={12} sm={10} md={2.5} lg={2} xl={1.7} sx={{ display: { xs: "none", md: "flex" }, borderRadius: "10px", marginRight: "30px" }}>
                        <Grid container direction={"row"} className='upload_tool_type_container' sx={{ border: "1px solid lightgray" }}>
                            <Grid item xs={12} className='upload_tool_type_item' onClick={() => { setActiveTab(1); SetFlagLoader(true) }} >
                                <Typography className={activeTab == 1 ? "upload_tool_type upload_tool_left_border" : "upload_tool_type"}>
                                    New Business
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className='upload_tool_type_item' onClick={() => { setActiveTab(2); SetFlagLoader(true) }}>
                                <Typography className={activeTab == 2 ? "upload_tool_type upload_tool_left_border" : "upload_tool_type"}>
                                    POS
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className='upload_tool_type_item' onClick={() => { setActiveTab(3); SetFlagLoader(true) }}>
                                <Typography className={activeTab == 3 ? "upload_tool_type upload_tool_left_border" : "upload_tool_type"}>
                                    Contracting
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Tablet Header */}

                    <Grid item xs={6} sm={4} className='upload_nav_item' sx={{ textAlign: "center", display: { xs: "flex", md: "none" }, borderRadius: "10px", marginBottom: "20px" }}>
                        <Grid container justifyContent="center">
                            <Grid item sm={9}>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={selectedForm.length ? selectedForm : "Select Form"}
                                        renderValue={(selected) => {
                                            if (selected.length === 0 || selectedForm?.length == 0) {
                                                return "Select Form";
                                            }
                                            return selected;
                                        }}
                                        className='form_type_change_select'
                                        // label="Age"
                                        onChange={(e) => {

                                            setSelectedForm(e.target.value)
                                            if (e.target.value == "New Business") {
                                                setActiveTab(1); SetFlagLoader(true)
                                            }
                                            if (e.target.value == "POS") {
                                                setActiveTab(2); SetFlagLoader(true)
                                            }
                                            if (e.target.value == "Contracting") {
                                                setActiveTab(3); SetFlagLoader(true)
                                            }
                                        }}
                                    >
                                        <MenuItem value={"New Business"}>New Business</MenuItem>
                                        <MenuItem value={"POS"}>POS</MenuItem>
                                        <MenuItem value={"Contracting"}>Contracting</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                    </Grid>


                    <Grid item className="iFrameBox" xs={10} sm={8.5} md={5} lg={4.5} xl={4}>
                        {activeTab == 1 &&
                            <iframe src="https://form.filemaster.io/Z4OXwjy9dBsJGdY83SRajtI6" className='iframe_class' onLoad={() => { SetFlagLoader(false) }} width="100%" height="700" frameborder="0" scrolling="no"></iframe>
                        }
                        {activeTab == 2 &&
                            <iframe src="https://form.filemaster.io/zklrRb22jDmKA0k3DG8r56TC" className='iframe_class' onLoad={() => { SetFlagLoader(false) }} width="100%" height="700" frameborder="0" scrolling="no"></iframe>
                        }
                        {activeTab == 3 &&
                            <iframe src="https://form.filemaster.io/GT3SQeJZjOf5lvn1Zy6PL0nc" className='iframe_class' onLoad={() => { SetFlagLoader(false) }} width="100%" height="700" frameborder="0" scrolling="no"></iframe>
                        }

                    </Grid>
                </Grid>
                <Box>
                    <Footer />
                </Box>
            </Box>
        </>
    )
};

export default UploadTool;