import React, { Fragment, useState } from 'react';
import { Typography, Dialog, Box, Button, Input, InputAdornment, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const URLToSendApplicantDialog = (props) => {

    const [titleForCopyText, setTitleForCopyText] = useState("Click To Copy");

    return (
        <Fragment>
            <Dialog
                open={true}
                onClose={() => {
                    props.closeURLToSendApplicantDialog()
                }}
            >
                <Box className='dialog_content' style={{ display: "flex", justifyContent: "flex-start", textAlign: 'left', alignItems: "flex-start", flexDirection: "column", padding: "20px 50px" }}>
                    <Typography variant='span' style={{ color: "#074a6e", margin: "10px 0" }}>Copy this URL and send to Applicant to fill</Typography>
                    <Input
                        type='text'
                        value={props.value}
                        readOnly={true}
                        disableUnderline={true}
                        endAdornment={<InputAdornment position='end'>
                            <Tooltip title={titleForCopyText}>
                                <ContentCopyIcon onClick={() => {
                                    navigator.clipboard.writeText(props.value);
                                    setTitleForCopyText("Copied");
                                    setTimeout(()=>{
                                        setTitleForCopyText("Click To Copy");
                                    }, 5000)
                                }} style={{cursor: "pointer"}} />
                            </Tooltip>
                        </InputAdornment>}
                        style={{ width: "500px", outline: "none !important", border: "1px solid rgba(0, 0, 0, 0.38)", padding: "5px", borderRadius: "5px" }}
                    />

                    <Button onClick={() => {
                        props.closeURLToSendApplicantDialog()
                    }} style={{ background: "#074a6e", color: "#fff", width: "150px", textTransform: "capitalize", margin: "30px auto 20px" }}>Close</Button>
                </Box>
            </Dialog>
        </Fragment>
    )
};

export default URLToSendApplicantDialog;