import createDataContext from "../createDataContext";

// Reducer
const NewApplicationFormReducer = (state, action) => {
    switch (action.form) {
        case "individualAnnuityApplication": {
            return {
                ...state,
                individualAnnuityApplication: {
                    ...state.individualAnnuityApplication,
                    [action.type]: action.payload
                }
            }
        }
        case "individualFixedIndexAnnuityApplication": {
            return {
                ...state,
                individualFixedIndexAnnuityApplication: {
                    ...state.individualFixedIndexAnnuityApplication,
                    [action.type]: action.payload
                }
            }
        }

        case "suitabilityAnalysis": {
            return {
                ...state,
                suitabilityAnalysis: {
                    ...state.suitabilityAnalysis,
                    [action.type]: action.payload
                }
            }
        }

        case "transferOrExchangeRequest": {
            return {
                ...state,
                transferOrExchangeRequest: {
                    ...state.transferOrExchangeRequest,
                    [action.type]: action.payload
                }
            }
        }
        case "noticeOfReplacement": {
            return {
                ...state,
                noticeOfReplacement: {
                    ...state.noticeOfReplacement,
                    [action.type]: action.payload
                }
            }
        }
        case "individualAnnuityApplicationWhole": {
            return {
                ...state,
                individualAnnuityApplication: action.payload

            }
        }

        case "suitabilityAnalysisWhole": {
            return {
                ...state,
                suitabilityAnalysis: action.payload
            }
        }

        case "noticeOfReplacementWhole": {
            return {
                ...state, noticeOfReplacement: action.payload
            }
        }

        case "transferExchangeRequestWhole": {
            // debugger
            return {
                ...state,
                transferOrExchangeRequest: action.payload
            }
        }

        // case "bankDetailsWhole": {
        //     return {
        //         ...state,
        //         bankDetails: action.payload
        //     }
        // }

        case "annuityDisclosureFormWhole": {
            return {
                ...state,
                annuityDisclosureForm: action.payload
            }
        }

        case "annuityDisclosureForm": {
            return {
                ...state,
                annuityDisclosureForm: {
                    [action.type]: action.payload
                }
            }
        }

        case "annuityDisclosureFormWhole": {
            return {
                ...state,
                annuityDisclosureFormWhole: action.payload
            }
        }

        case "annuityDisclosureForm": {
            return {
                ...state,
                annuityDisclosureForm: {
                    [action.type]: action.payload
                }
            }
        }

        case "disableAllInputs": {
            return {
                ...state,
                disableAllInputs: action.payload
            }
        }

        case "applicationId": {
            return {
                ...state,
                applicationId: action.payload
            }
        }

        case "transferOrExchangeRequestFlag": {
            // debugger
            return {
                ...state,
                transferOrExchangeRequestFlag: action.payload
            }
        }

        default:
            return state;
    }
}

// Function for Individual Annuity Application
const SetIndividualAnnuityApplication = dispatch => (data) => {
    // debugger
    console.log("set individual annuity app data", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "individualAnnuityApplication"
    })
}

const SetIndividualFixedIndexAnnuityApplication = dispatch => (data) => {
    console.log("set individual annuity app data", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "individualFixedIndexAnnuityApplication"
    })
}

// Function for Suitability Analysis
const SetSuitabilityAnalysis = dispatch => (data) => {
    // debugger
    console.log("set suitability analysis data", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "suitabilityAnalysis"
    })
}

//Function for Notice of Replacement
const SetNoticeOfReplacement = dispatch => (data) => {
    console.log("set notice of replacement", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "noticeOfReplacement"
    })
}

// Function for Transfer or Exchange Request
const SetTransferOrExchangeRequest = dispatch => (data) => {
    console.log("set transfer or exchange request data", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "transferOrExchangeRequest"
    })
}

//  Function for Annuity disclosure form
const SetAnnuityDisclosureForm = dispatch => (data) => {
    console.log("set annuity disclosure form  data", data);
    dispatch({
        type: data.type,
        payload: data.value,
        form: "annuityDisclosureForm"
    })
}

// Function to set Whole Individual Annuity Application in One Function
const SetWholeIndividualAnnuityApplication = dispatch => (data) => {
    //   debugger
    console.log("set whole individual application", data);
    dispatch({
        form: 'individualAnnuityApplicationWhole',
        payload: data
    })
}

// Function to set Whole Individual Annuity Application in One Function
const SetWholeSuitabilityAnalysis = dispatch => (data) => {
    // debugger
    console.log("set whole suitability analysis", data);
    dispatch({
        form: 'suitabilityAnalysisWhole',
        payload: data
    })
}

// Function to set Whole notice of Replacement Application in One Function
const SetWholeNoticeOfReplacement = dispatch => (data) => {
    console.log("set whole notice of replacement", data);
    // debugger;
    dispatch({
        form: 'noticeOfReplacementWhole',
        payload: data
    })
}

// Function to set Whole Individual Annuity Application in One Function
const SetWholeTransferExchangeRequest = dispatch => (data) => {
    console.log("set whole transfer exchange request", data);
    dispatch({
        form: 'transferExchangeRequestWhole',
        payload: data
    })
}

// Function to set Whole Individual Annuity Application in One Function
// const SetWholeBankDetails = dispatch => (data)=>{
//     console.log("set whole bank details",data);
//     dispatch({
//         form: 'bankDetailsWhole',
//         payload: data
//     })
// }



// Function to set Whole Annuity Disclosure in One Function
const SetWholeAnnuityDisclosureForm = dispatch => (data) => {
    console.log("set  whole annuity disclosure form  data", data);
    dispatch({
        form: "annuityDisclosureFormWhole",
        payload: data,
    })
}

// Changing disable all input 
const SetDisableAllInputs = dispatch => (data) => {
    console.log("Printing value to be updated as disableAllInputs", data);
    dispatch({
        form: "disableAllInputs",
        payload: data
    })
}

// Changing application id for send this link to applicant api call
const SetApplicationId = dispatch => (data) => {
    console.log("Printing  application id", data);
    // debugger;
    dispatch({
        form: "applicationId",
        payload: data
    })
}
const updateTransferOrExchangeRequestFlag = dispatch => (data) => {
    console.log("updateTransferOrExchangeRequestFlag", data);
    // debugger;
    dispatch({
        form: "transferOrExchangeRequestFlag",
        payload: data
    })
}
const transferOrExchangeRequestFlag = dispatch => (data) => {
    console.log("transferOrExchangeRequestFlag", data);
    //  debugger;
    dispatch({
        form: "transferOrExchangeRequestFlag",
        payload: data
    })
}

// Export Context and Provider
export const { Context, Provider } = createDataContext(
    NewApplicationFormReducer,
    {
        SetIndividualAnnuityApplication, SetSuitabilityAnalysis, SetTransferOrExchangeRequest, SetNoticeOfReplacement,
        SetAnnuityDisclosureForm, SetWholeIndividualAnnuityApplication, SetWholeSuitabilityAnalysis, SetWholeNoticeOfReplacement,
        SetWholeTransferExchangeRequest,// SetWholeBankDetails,
        updateTransferOrExchangeRequestFlag,
        SetWholeAnnuityDisclosureForm, SetDisableAllInputs, SetApplicationId, transferOrExchangeRequestFlag,
        SetIndividualFixedIndexAnnuityApplication

    },
    {
        individualAnnuityApplication: {
            annuitantFullName: "",
            annuitantSSN: "",
            annuitantGender: "",
            annuitantDOB: "",
            annuitantStreetAddress: "",
            annuitantZip: "",
            annuitantCity: "",
            annuitantState: "",
            annuitantPhone: "",
            annuitantEmail: "",
            ownerFullName: "",
            ownerSSN: "",
            ownerGender: "",
            ownerDOB: "",
            ownerStreetAddress: "",
            ownerZip: "",
            ownerCity: "",
            ownerState: "",
            ownerEmail: "",
            ownerRelationToAnnuitant: "",
            jointOwnerFullName: "",
            jointOwnerSSN: "",
            jointOwnerGender: "",
            jointOwnerDOB: "",
            jointOwnerStreetAddress: "",
            jointOwnerZip: "",
            jointOwnerCity: "",
            jointOwnerState: "",
            jointOwnerEmail: "",
            jointOwnerRelationToAnnuitant: "",
            planAppliedFor: [],
            beneficiariesPrimary: [
                {
                    id: 1,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 2,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 3,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 4,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 5,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 6,
                    primaryField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                }
            ],
            beneficiariesContigent: [
                {
                    id: 1,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 2,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 3,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 4,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 5,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                },
                {
                    id: 6,
                    contigentField: "",
                    percentageField: "",
                    ssnField: "",
                    genderField: "",
                    relationshipField: ""
                }

            ],
            // flagNonQualifiedSelected: false,
            selectedNonQualifiedOptions: [],
            // flagQualifiedSelected: false,
            // selectedQualifiedOptions: "",
            selectedQualifiedOptionsGroup2: "",
            otherSelectedQualifiedOptions: "",
            stateToShowNoticeOfReplacement: "",
            flagQualifiedOrNonQualifiedSelected: "",
            amountPaidWithApplication: "",
            expectedPremiumField: "",
            flagHaveExistingLifeInsurance: "",
            reasonOfApplicationToChangeLifeInsurance: "",
            applicationReplacementFieldsArray: [
                {
                    id: 1,
                    companyNameOfPolicyField: "",
                    policyNumberField: ""
                },
                {
                    id: 2,
                    companyNameOfPolicyField: "",
                    policyNumberField: ""
                },
                {
                    id: 3,
                    companyNameOfPolicyField: "",
                    policyNumberField: ""
                }
            ],
            flagSafeguardPlusAnnuityPeriod: ""
        },

        suitabilityAnalysis: {
            ownerFullName: "",
            ownerCurrentAge: "",
            ownerGender: "",
            ownerEmploymentStatus: "",
            ownerCurrentOccupation: "",
            ownerDependents: "",
            spouseFullName: "",
            spouseCurrentAge: "",
            spouseGender: "",
            spouseEmploymentStatus: "",
            spouseCurrentOccupation: "",
            spouseDependents: "",
            ownerAnnualHouseholdIncome: "",
            ownerSourceOfIncome: "",
            ownerIncomeTaxBracket: "",
            ownerTotalOutstandingDebt: "",
            ownerTotalAssets: "",
            ownerTotalLiquidAssets: "",
            flagOwnerIncomeCoverLivingAndMedicalExpenses: "",
            explainOwnerIncomeCoverLivingAndMedicalExpenses: "",
            flagOwnerExpectChangesInLivingExpenses: "",
            explainOwnerExpectChangesInLivingExpenses: "",
            flagOwnerAnticipateChangesInMedicalExpenses: "",
            explainOwnerAnticipateChangesInMedicalExpenses: "",
            flagOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod: "",
            explainOwnerIncomeSufficientToCoverLivingAndMedicalExpensesDuringSurrenderPeriod: "",
            flagOwnerHaveEmergencyFundForUnexpectedExpenses: "",
            explainOwnerHaveEmergencyFundForUnexpectedExpenses: "",
            ownerOtherInvestments: [],
            otherOwnerInvestmentField: "",
            ownerRiskTolerance: "",
            reasonForPurchasingProduct: [],
            otherRasonForPurchasingThisProduct: "",
            flagOwnerPlanToWithdrawFundInSurrenderPeriod: "",
            explainOwnerPlanToWithdrawFundInSurrenderPeriod: "",
            ownerAnticipateNeedingAccessToFunds: "",
            sourceOfFundsToPurchaseThisProduct: [],
            otherSourceOfFundsToPurchaseThisProduct: "",
            flagOwnerPlanToUseFundsFromExistingInsuranceToPurchaseThisProduct: "",
            howLongHasTheLifeInsuranceBeenInForce: "",
            flagSurrenderChargeOnExistingProduct: "",
            currentSurrenderChargeOnExistingProduct: "",
            expirationOfCurrentSurrenderCharge: "",
            typeOfExistingProduct: "",
            interestRateForExistingProduct: "",
            flagTakingRequiredMinimumDistributionsFromExistingProduct: "",
            flagExistingProductIsAnnuityThenTakingOtherPeriodicDistribution: "",
            explainExistingProductIsAnnuityThenTakingOtherPeriodicDistribution: "",
            personsThatAssistedOwnerToPurchaseThisProduct: [],
            otherPersonsThatAssistedOwnerToPurchaseThisProduct: "",
            additionalRelaventInformationForSuitabilityAnalysis: "",
            agentAcknowledgment: ""
        },
        noticeOfReplacement: {
            flagDiscontinuingPremiumPayment: "",
            flagPayForPremiumPolicy: "",
            insurerPolicyDetailFieldArray: [
                {
                    id: 1,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
                {
                    id: 2,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
                {
                    id: 3,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
                {
                    id: 4,
                    existingCarrierName: "",
                    existingCarrierAddress: "",
                    contractOrPolicyNumber: "",
                    nameOfInsuredOrAnnuitant: "",
                    amount: "",
                    effectiveDate: ""
                },
            ],
            reasonForReplacement: ""
        },

        transferOrExchangeRequest: {
            ownerFullName: "",
            ownerSSN: "",
            ownerStreetAddress: "",
            ownerZip: "",
            ownerCity: "",
            ownerState: "",
            annuitantOrInsured: "",
            ownerMaritalStatus: "",
            currentInsuranceCompany: "",
            currentInsuranceCompanyPhone: "",
            currentInsuranceCompanyAccountNumber: "",
            currentInsuranceCompanyStreetAddress: "",
            currentInsuranceCompanyZip: "",
            currentInsuranceCompanyCity: "",
            currentInsuranceCompanyState: "",
            typeOfAccount: "",
            otherTypeOfAccount: "",
            ownerExistingAnnuity: "",
            plans: "",
            nonQualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
            nonQualifiedTransferOrExchangeExistingAccountPartialAmount: "",
            nonQualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate: "",
            nonQualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
            nonQualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy: "",
            nonQualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField: "",
            flagNonQualifiedOwnerWantWithdrawalToExchange: "",
            explainNonQualifiedOwnerWantWithdrawalToExchange: "",
            flagNonQualifiedOwnerWantToContinueExistingperiodWithdrawal: "",
            qualifiedTransferOrExchangeExistingAccountAllOrPartial: "",
            qualifiedTransferOrExchangeExistingAccountPartialAmount: "",
            qualifiedTransferOrExchangeExistingAccountImmediatelyOrOnMaturityDate: "",
            qualifiedTransferOrExchangeExistingAccountOnMaturityDateOfField: "",
            qualifiedTransferOrExchangeExistingAccountToNewPolicyOrToExistingPolicy: "",
            qualifiedTransferOrExchangeExistingAccountToExistingPolicyAccountField: "",
            qualifiedTypeOfTransfer: "",
            qualifiedQualifyingEventForDirectRolloverFunds: "",
            flagQualifiedOwnerReachedAge72OrMoreInThisYear: "",
            flagQualifiedOwnerSatisfiedRMDFromDistributingPlan: "",
            qualifiedInternalExchangeOfExistingAnnuityName: "",
            flagQualifiedOwnerWantRMDBeforeCompletingExchange: "",
            flagQualifiedOwnerWantToContinueExistingRMDSchedule: "",
            flagSignatureDigitalOrPhysical: "",
            // witnessName: "",
            // witnessEmail: ""
        },
        annuityDisclosureForm: {
            flagAnnuityClosure: "",
        },
        annuityDisclosureForm: {
            flagAnnuityClosure: "",
        },
        disableAllInputs: false,
        applicationId: "",
        transferOrExchangeRequestFlag: false,


        // For FIA

        individualFixedIndexAnnuityApplication: {
            ownerFullName: "",
            ownerSSN: "",
            ownerGender: "",
            ownerDOB: "",
            ownerStreetAddress: "",
            ownerZip: "",
            ownerCity: "",
            ownerState: "",
            ownerEmail: "",
            ownerRelationToAnnuitant: "",

            jointOwnerFullName: "",
            jointOwnerSSN: "",
            jointOwnerGender: "",
            jointOwnerDOB: "",
            jointOwnerStreetAddress: "",
            jointOwnerZip: "",
            jointOwnerCity: "",
            jointOwnerState: "",
            jointOwnerEmail: "",
            // jointOwnerRelationToOwner: "",

            annuitantFullName: "",
            annuitantSSN: "",
            annuitantGender: "",
            annuitantDOB: "",
            annuitantStreetAddress: "",
            annuitantZip: "",
            annuitantCity: "",
            annuitantState: "",
            annuitantEmail: "",
            annuitantRelationToOwner: "",

            jointAnnuitantFullName: "",
            jointAnnuitantSSN: "",
            jointAnnuitantGender: "",
            jointAnnuitantDOB: "",
            jointAnnuitantStreetAddress: "",
            jointAnnuitantZip: "",
            jointAnnuitantCity: "",
            jointAnnuitantState: "",
            jointAnnuitantEmail: "",
            jointAnnuitantRelationToAnnuitant: "",

            planHarvest: "",
            ridersPlan: "",

            initialAccountAllocation: {
                fixedStrategy: "",
                pointWithCap: "",
                pointWithEnhancedCap: "",
                pointWithParticipationRate: "",
                pointWithEnhancedParticipationRate: "",
                dividendGrowersParticipationRate: "",
                dividendGrowersEnhancedParticipationRate: "",
                nasdaqParticipationRate: "",
                nasdaqEnhancedParticipationRate: "",
                bloombergGlobalParticipationRate: "",
                bloombergGlobalEnhancedParticipationRate: "",
            },

            beneficiaries: [
                {
                    id: 1,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                },
                {
                    id: 2,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                },
                {
                    id: 3,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                },
                {
                    id: 4,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                }, {
                    id: 5,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                },
                {
                    id: 6,
                    primaryOrContingent: "",
                    name: "",
                    percentageField: "",
                    relationshipField: "",
                    ssnField: "",
                    genderField: ""
                },
            ],

            // flagNonQualifiedSelected: false,
            selectedNonQualifiedOptions: [],
            // flagQualifiedSelected: false,
            // selectedQualifiedOptions: "",
            selectedQualifiedOptionsGroup2: "",
            otherSelectedQualifiedOptions: "",
            stateToShowNoticeOfReplacement: "",
            flagQualifiedOrNonQualifiedSelected: "",
            amountPaidWithApplication: "",
            expectedPremiumField: "",
            flagHaveExistingLifeInsurance: "",
            reasonOfApplicationToChangeLifeInsurance: "",
            flagSafeguardPlusAnnuityPeriod: "",

            producerStateA: "",
            applicantStateA: "",
            producerStateB: "",
            applicantStateB: "",
            producerStateC: "",
            applicantStateC: "",
            producerStateD: "",
            applicantStateD: "",
            producerStateE: "",
            applicantStateE: "",
            producerStateF: "",
            applicantStateF: "",
            producerStateG: "",
            applicantStateG: "",

        },

        accountAllocationArray: [
            {
                key: 1,
                state: "fixedStrategy",
                label: "Fixed Strategy",
            },
            {
                key: 2,
                state: "pointWithCap",
                label: "S&P 500 Annual Point-to Point with Cap"
            },
            {
                key: 3,
                state: "pointWithEnhancedCap",
                label: "S&P 500 Annual Point-to-Point with Enhanced Cap"
            },
            {
                key: 4,
                state: "pointWithParticipationRate",
                label: "S&P 500 Annual Point-to-Point with Participation Rate"
            },
            {
                key: 5,
                state: "pointWithEnhancedParticipationRate",
                label: "S&P 500 Annual Point-to-Point with Enhanced Participation Rate"
            },
            {
                key: 6,
                state: "dividendGrowersParticipationRate",
                label: "S&P Dividend Growers Vol Adjust RC2 7.5% Annual Point-to-Point Participation Rate"
            },
            {
                key: 7,
                state: "dividendGrowersEnhancedParticipationRate",
                label: "S&P Dividend Growers Vol Adjust RC2 7.5% Annual Point-to-Point Enhanced Participation Rate"
            },
            {
                key: 8,
                state: "nasdaqParticipationRate",
                label: "Nasdaq-100 7% Target Volatility Index Annual Point-to-Point Participation Rate"
            },
            {
                key: 9,
                state: "nasdaqEnhancedParticipationRate",
                label: "Nasdaq-100 7% Target Volatility Index Annual Point-to-Point Enhanced Participation Rate"
            },
            {
                key: 10,
                state: "bloombergGlobalParticipationRate",
                label: "Bloomberg Global Momentum Diversified Leaders 5% Volatility Target ER Index Annual Point-to-Point Participation Rate"
            },
            {
                key: 11,
                state: "bloombergGlobalEnhancedParticipationRate",
                label: "Bloomberg Global Momentum Diversified Leaders 5% Volatility Target ER Index Annual Point-to-Point Enhanced Participation Rate"
            }
        ]


    })