import Axios from "./axios";

export const  StateAPI = async(onSuccess ,onError)=>{
    try{
        // console.log("ID Data ",data);
        // debugger;
        const responce =await Axios.get('/api/user/state',  {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
     //   console.log("printing responce", responce)
        // debugger;
        onSuccess && onSuccess(responce);
        // debugger;
    }
    catch(err){
     //   console.log("got an error",err);
        onError && onError(err)
    }
}