import axiosInstance from "./axios";

const ApplicationStatusId = async (data, onSuccess, onError)=>{
    try{
        console.log("prrinting data for ApplicationStatusId", data);

        const response = await axiosInstance.get("/api/application/status", {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });

        onSuccess && onSuccess(response);
    }
    catch(err){
        onError && onError(err);
    }
};

export {ApplicationStatusId};