import createDataContext from "../createDataContext";

const ErrorValidationReducer = (state, action) => {
    let { section } = action;
    switch (action.type) {
        case "pageIndex": {
            return { ...state, "pageIndex": action.payload }
        }
        case "Individual Annuity Application": {
            return { ...state, [section]: action.payload }
        }
        case "Individual Fixed Index Annuity Application": {
            return { ...state, [section]: action.payload }
        }

        case "Suitability Analysis": {
            return { ...state, [section]: action.payload }
        }

        case "Transfer or Exchange Request": {
            return { ...state, [section]: action.payload }
        }
        case "Notice of Replacement": {
            return { ...state, [section]: action.payload }
        }
        case "Producer Disclosure": {
            return { ...state, [section]: action.payload }
        }
        default: {
            return state;
        }
    }
}


const SetPageIndex = dispatch => data => {
    console.log("Printing pageIndex to be update", data);
    dispatch({
        type: "pageIndex",
        section: "",
        payload: data
    })
}

const SetApplicationFormFlagSectionData = dispatch => data => {
    // debugger
    console.log("Printing data to update", data);
    dispatch({
        type: data.type,
        section: data.section,
        payload: data.data
    })
}


export const { Provider, Context } = createDataContext(
    ErrorValidationReducer,
    { SetPageIndex, SetApplicationFormFlagSectionData },
    {
        pageIndex: 1,

        flagFirstPageFirstSection: true,
        flagFirstPageSecondSection: false,
        flagFirstPageThirdSection: false,
        flagFirstPageFourthSection: false,
        flagFirstPageFifthSection: false,
        flagFirstPageSixthSection: false,
        flagFirstPageSeventhSection: false,
        flagFirstPageEighthSection: false,
        flagFirstPageNinthSection: false,

        flagSecondPageFirstSection: true,
        flagSecondPageSecondSection: false,
        flagSecondPageThirdSection: false,
        flagSecondPageFourthSection: false,
        flagSecondPageFifthSection: false,
        flagSecondPageSixthSection: false,
        flagSecondPageSeventhSection: false,

        flagReplacementPageFirstSection: true,
        flagReplacementPageSecondSection: false,
        flagReplacementPageThirdSection: false,
        flagReplacementPageFourthSection: false,

        flagFourthPageFirstSection: true,
        flagFourthPageSecondSection: false,
        flagFourthPageThirdSection: false,

        flagSixthPageFirstSection: true,
        flagSixthPageSecondSection: false,
        flagSixthPageThirdSection: false,
        flagSixthPageFourthSection: false,
        flagFourthPageFifthSection: false
    }
)